/* eslint-disable max-statements */
import { doc, getDoc } from 'firebase/firestore';

import { collections } from '../constants/defines';

export const getWebOrderTracking = async (
  db,
  orderId,
  isCustomOrder = false,
) => {
  let orderData;
  // Exclude DS orders early
  if (typeof orderId === 'string' && orderId.startsWith('DS')) {
    // Ensure orderId is a string for Firestore document reference
    const orderIdStr = orderId;

    if (!isCustomOrder) {
      const ref = doc(db, collections.DS_INLINE_QUEUE, orderIdStr);
      const docSnap = await getDoc(ref);
      orderData = docSnap.data();
    } else {
      const ref = doc(db, collections.DS_CUSTOM_QUEUE, orderIdStr);
      const docSnap = await getDoc(ref);
      orderData = docSnap.data();
    }

    // Check if labelPrinted is an object and exists
    const truthyIfLabelPrintedIsAnObject =
      typeof orderData?.labelPrinted === 'object' &&
      orderData?.labelPrinted !== null;

    // Safely return tracking number or an empty string
    return {
      tracking: orderData?.trackingNumber ?? '',
      orderId: String(orderData?.orderNumber) ?? '',
      isDsOrder: true,
      labelPrinted: truthyIfLabelPrintedIsAnObject
        ? orderData?.labelPrinted
        : false,
    };
  }

  try {
    // Ensure orderId is a string for Firestore document reference
    const orderIdStr = orderId;

    if (!isCustomOrder) {
      const ref = doc(db, collections.INLINE_PRODUCTS_QUEUE, orderIdStr);
      const docSnap = await getDoc(ref);
      orderData = docSnap.data();
    } else {
      const ref = doc(db, collections.CUSTOM_PRODUCTS_QUEUE, orderIdStr);
      const docSnap = await getDoc(ref);
      orderData = docSnap.data();
    }

    // Safely return tracking number or an empty string
    return {
      tracking: orderData?.trackingNumber ?? '',
      orderId: String(orderData?.orderNumber) ?? '',
      labelPrinted: orderData?.labelPrinted ?? false,
    };
  } catch (error) {
    console.error('Error fetching tracking data:', error);
    return ''; // Handle errors gracefully
  }
};
