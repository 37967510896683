import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Dialog,
  InputBase,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import EditOrderModal from './EditOrderModal';
import { API } from '../../constants/defines';
import { Toast } from '../Toast';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '3.125rem 0 3.125rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '.0625rem solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  toolBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '.5625rem 2rem',
    fontSize: '1rem',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '1rem',
  },
  formLabel: {
    color: '#000000',
    fontSize: '.875rem',
    fontWeight: '500',
    letterSpacing: '.0938rem',
  },
  search: {
    width: '470px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // place at the end of the formBox
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const PageToolbar = (props) => {
  const ORDER_KEYS_MAP = {
    orderNumber: 'PO #',
    orderDate: 'PO Date',
    customerName: 'Ship to Name',
    address1: 'Ship to Address1',
    extraAddress: 'Ship to Address2',
    city: 'Ship to City',
    state: 'Ship to State',
    postalCode: 'Ship to Zipcode',
    country: 'Ship to Country',
    quantityOrdered: 'Qty Ordered',
    productId: 'Product ID1',
    simpleCustomization: 'Simple Customization',
    customText: 'Simple Customization Text',
    productName: 'Product Description',
    sku: 'Product ID2',
    dsDate: 'PO Date',
  };
  const classes = useToolbarStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderList, setShowOrderList] = useState(false);
  const [loadingShipsStaionRequest, setLoadingShipStationRequest] =
    useState(false);
  const [errorText, setErrorText] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});

  const {
    numSelected,
    allSelected,
    onSelectAllClick,
    onPrint,
    enable,
    editOnly,
    dsErrors,
    handleSearch,
    handleInputChange,
    searchInput,
  } = props;

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setErrorText('');
    setLoadingShipStationRequest(false);
    toggleOrderList();
  };

  const handleOrderSubmit = async (updatedOrder) => {
    setErrorText('');
    setLoadingShipStationRequest(true);

    try {
      const response = await fetch(API.editOrderShipstation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: selectedOrder.orderId,
          order: updatedOrder,
        }),
      });

      if (!response.ok) {
        console.error('Error updating order in Shipstation: ', response);
        setErrorText(
          'Error updating order in Shipstation',
          response.statusText,
        );
      }

      const responseBody = await response.json();

      if (responseBody?.message !== 'Address validated successfully') {
        console.log(
          'Error updating order in Shipstation: ',
          responseBody.message,
        );
        setErrorText(responseBody.message);
      } else {
        setShowToast(true);
        setToastData({
          type: 'success',
          message:
            'Order updated successfully in Shipstation, address is verified',
        });

        handleModalClose();
      }
    } catch (error) {
      console.log('Error in handleOrderSubmit: ', error);
      setErrorText('Error updating order in Shipstation');
    } finally {
      setLoadingShipStationRequest(false);
    }
  };

  const toggleOrderList = () => {
    setShowOrderList(!showOrderList);
  };

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{
            marginRight: '2.5rem',
            height: '2.875rem',
            width: '10.3125rem',
          }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  disabled={!enable}
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={allSelected ? 'SELECT NONE' : 'SELECT ALL'}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {editOnly && numSelected > 0 && (
          <Button variant="blue" disabled={!enable} onClick={onPrint}>
            Print Selected
          </Button>
        )}
      </div>

      {dsErrors && dsErrors.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffa726',
            color: 'white',
            padding: '10px',
            fontWeight: 'bold',
            fontSize: '1.2rem',
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            sx={{ marginRight: 1 }}
            onClick={toggleOrderList}
          >
            Edit {dsErrors.length > 1 ? 'orders' : 'order'}
          </Button>
          Warning: shipstation has received: {dsErrors.length}{' '}
          {dsErrors.length > 1 ? 'errors' : 'error'}. Please review the errors
          and correct them.
        </Box>
      )}

      {/* Display list of orders in a dialog */}

      <Dialog
        open={showOrderList}
        onClose={toggleOrderList}
        maxWidth="md"
        fullWidth
      >
        <Box sx={{ padding: 2 }}>
          <h3>Select an Order to Edit</h3>
          <List>
            {dsErrors.map((dsError, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleOrderSelect(dsError.order)}
              >
                <ListItemText
                  primary={`=> Order Number: ${
                    dsError.order[ORDER_KEYS_MAP.orderNumber]
                  }`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Dialog>

      {/* Modal for editing a single order */}
      {selectedOrder && (
        <EditOrderModal
          order={selectedOrder}
          isOpen={modalOpen}
          handleClose={handleModalClose}
          handleSubmit={handleOrderSubmit}
          isLoading={loadingShipsStaionRequest}
          errorText={errorText}
          ORDER_KEYS_MAP={ORDER_KEYS_MAP}
        />
      )}
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>

        <InputBase
          placeholder="Search Orders"
          sx={{ width: '100%' }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchInput}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          onKeyUp={handleSearch}
        />
      </div>
      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  enable: PropTypes.bool,
  editOnly: PropTypes.bool,
};

export default PageToolbar;
