import * as React from 'react';

const CartSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={19}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || '#0E0E12'}
      d="M1.237.289a.75.75 0 1 0-.474 1.423l.265.088c.676.225 1.124.376 1.453.529.312.145.447.262.533.382.087.12.155.285.194.626.041.361.042.833.042 1.546v2.672c0 1.368 0 2.47.117 3.337.12.9.38 1.658.982 2.26.601.602 1.36.86 2.26.982.866.116 1.969.116 3.336.116H17a.75.75 0 0 0 0-1.5h-7c-1.435 0-2.436-.001-3.192-.103-.733-.099-1.122-.279-1.399-.556-.235-.235-.4-.55-.506-1.09h10.12c.959 0 1.438 0 1.814-.248.376-.248.565-.69.943-1.571l.428-1c.81-1.889 1.215-2.833.77-3.508C18.533 4 17.506 4 15.45 4H4.745a8.996 8.996 0 0 0-.047-.833c-.055-.484-.176-.93-.467-1.333-.291-.404-.675-.66-1.117-.865C2.697.775 2.168.599 1.542.39L1.237.29ZM6.5 16a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM15.5 16a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
    />
  </svg>
);
export default CartSVG;
