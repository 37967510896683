import * as React from 'react';

const MakeUpSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 44 44" // Add viewBox attribute
    fill="none"
    {...props}
  >
    <path
      fill="#3477F5"
      d="M15.01 35.334a1.375 1.375 0 0 1-1.376 1.374H9.968a1.375 1.375 0 0 1 0-2.75h3.666a1.375 1.375 0 0 1 1.375 1.376Z"
    />
    <path
      fill="#3477F5"
      d="M19.134 33.5v-22c0-2.567 0-3.85-.498-4.831a4.583 4.583 0 0 0-2.004-2.004c-.98-.498-2.264-.498-4.83-.498-2.568 0-3.85 0-4.832.498A4.583 4.583 0 0 0 4.966 6.67c-.498.98-.498 2.264-.498 4.83v22c0 2.567 0 3.85.498 4.832a4.584 4.584 0 0 0 2.004 2.002c.981.5 2.264.5 4.831.5 2.567 0 3.85 0 4.83-.5a4.584 4.584 0 0 0 2.005-2.002c.498-.981.498-2.265.498-4.831Z"
      opacity={0.4}
    />
    <path
      fill="#3477F5"
      d="M19.134 15.612V33.5c0 1.685 0 2.816-.14 3.672l6.047-6.048L35.744 19.96c1.784-1.839 2.677-2.76 3-3.813a4.583 4.583 0 0 0-.02-2.756c-.34-1.05-1.256-1.967-3.09-3.798-1.65-1.625-2.478-2.439-3.446-2.776a4.583 4.583 0 0 0-2.48-.156c-1.004.216-1.924.92-3.765 2.328l-1.309 1.124-5.5 5.5Zm-3.66 25.08h-.006v.006l.005-.006Z"
      opacity={0.7}
    />
    <path
      fill="#3477F5"
      d="M29.792 26.167h3.825c2.566 0 3.85 0 4.83.5a4.584 4.584 0 0 1 2.004 2.002c.499.981.499 2.264.499 4.831 0 2.567 0 3.85-.499 4.83a4.583 4.583 0 0 1-2.004 2.003c-.98.5-2.264.5-4.83.5H11.8c1.681 0 2.812 0 3.666-.14v.005l.006-.006c.45-.073.821-.187 1.159-.357a4.582 4.582 0 0 0 2.003-2.004c.171-.337.285-.71.358-1.159l6.048-6.048 4.752-4.957Z"
    />
  </svg>
);
export default MakeUpSVG;
