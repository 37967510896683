import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';
import moment from 'moment';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Papa from 'papaparse';

import { getRunningLables } from '../../db/dsOrders';
import { API } from '../../constants/defines';
import { Toast } from '../Toast';
import { createCsvFile } from './helpers';

const RunningLabelBatches = ({ isOpen, handleClose, db }) => {
  const [batches, setBatches] = useState([]);
  const [completedBatches, setCompletedBatches] = useState([]);
  const [loadingState, setLoadingState] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    fetchBatches();
  }, [isOpen]);

  const fetchBatches = () => {
    const callBackForOrders = (docs) => {
      setBatches(docs.filter((batch) => !batch.isCompleted));
      setCompletedBatches(docs.filter((batch) => batch.isCompleted));
    };

    getRunningLables(db, (docs) => callBackForOrders(docs));
  };

  // Table headers array
  const tableHeaders = [
    { id: 'date', label: 'Date Of Orders' },
    { id: 'totalLabels', label: 'Total Labels' },
    { id: 'download', label: 'Labels' },
    { id: 'downloadDerivatives', label: 'Derivatives' },
  ];

  const handleDownload = async (date) => {
    try {
      // Set individual loading state
      setLoadingState((prevState) => ({
        ...prevState,
        [date]: true,
      }));

      const formattedDate = moment(date).format('MM.DD.YYYY');

      const response = await fetch(API.getLabelFolderForDs, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: formattedDate,
          isDelete: false,
        }),
      });

      const labels = await response.json();

      if (labels.error) {
        console.log('Error downloading labels: ', labels.message);
        setShowToast(true);
        setToastData({
          message: labels.message,
          isError: true,
        });
        return;
      }

      // If the response contains the download URL
      if (labels.data) {
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = labels.data; // URL to the file
        link.download = `Labels_${formattedDate}.zip`; // The name of the file to be downloaded
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element
      }
    } catch (error) {
      console.log('Error downloading labels: ', error);
      setShowToast(true);
      setToastData({
        message: 'Error downloading labels',
        isError: true,
      });
    } finally {
      // Reset the loading state for this specific batch
      setLoadingState((prevState) => ({
        ...prevState,
        [date]: false,
      }));
    }
  };

  const handleDownloadDerivatives = async (date, batch) => {
    try {
      // Set individual loading state for derivative download
      setLoadingState((prevState) => ({
        ...prevState,
        [`${date}-derivatives`]: true,
      }));

      const formattedDate = moment(date).format('MM.DD.YYYY');

      const response = await fetch(API.getLabelFolderForDs, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: formattedDate,
          isDelete: false,
          isInvoiceFile: true,
        }),
      });

      const invoice = await response.json();

      await createCsvFile(batch, formattedDate);

      if (invoice.data) {
        // Create a temporary link element for downloading the file
        const link = document.createElement('a');
        link.href = invoice.data; // URL to the file
        link.download = `Invoice_${formattedDate}.zip`; // The name of the file to be downloaded
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up the link element
      }
    } catch (error) {
      console.log('Error downloading derivatives: ', error);
      setShowToast(true);
      setToastData({
        message: 'Error downloading derivatives',
        isError: true,
      });
    } finally {
      // Reset the loading state for this specific batch date
      setLoadingState((prevState) => ({
        ...prevState,
        [`${date}-derivatives`]: false,
      }));
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '85%',
          height: '85%',
          bgcolor: 'background.paper',
          p: 4,
          boxShadow: 24,
          overflowY: 'auto',
          maxHeight: '90vh',
          borderRadius: 2,
          outline: 'none',
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Running Label Batches
        </Typography>

        {/* Layout for Running Batches */}
        {batches.length === 0 ? (
          <Typography variant="body1" sx={{ mb: 2 }}>
            No running batches at the moment
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {batches.map((batch, index) => {
              const progress =
                (batch.batchesCompleted / batch.totalBatches) * 100;

              return (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" align="center">
                      Orders from this day:{' '}
                      {moment(batch.batchDate).format('MM/DD/YYYY')}
                    </Typography>
                    <Box sx={{ width: 100, height: 100, mt: 1 }}>
                      <CircularProgressbar
                        value={progress}
                        strokeWidth={8}
                        styles={buildStyles({
                          strokeLinecap: 'round',
                          trailColor: '#d6d6d6',
                          pathColor: '#3f51b5',
                          pathTransitionDuration: 1,
                        })}
                      />
                    </Box>
                    <Typography variant="body2" align="center">
                      {batch.batchesCompleted}/{batch.totalBatches} completed
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}

        {/* Completed Batches with Table */}
        <Typography variant="h6" component="h2" sx={{ mt: 4 }}>
          Completed Batches
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.id}>
                    <Typography variant="body1">
                      <b>{header.label}</b>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {completedBatches.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="body1">
                      No completed batches.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                completedBatches.map((batch, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(batch.batchDate).format('MM/DD/YYYY')}
                    </TableCell>
                    <TableCell>{batch.totalLabels}</TableCell>
                    <TableCell>
                      {!loadingState.hasOwnProperty(batch.batchDate) ||
                      !loadingState[batch.batchDate] ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDownload(batch.batchDate)}
                        >
                          Download Labels
                        </Button>
                      ) : (
                        <CircularProgress />
                      )}
                    </TableCell>
                    <TableCell>
                      {!loadingState[`${batch.batchDate}-derivatives`] ? (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handleDownloadDerivatives(batch.batchDate, batch)
                          }
                        >
                          Download Derivatives
                        </Button>
                      ) : (
                        <CircularProgress />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Toast
          show={showToast}
          data={toastData}
          onClose={() => setShowToast(false)}
        />
      </Box>
    </Modal>
  );
};

export default RunningLabelBatches;
