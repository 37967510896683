import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FaceModal from './faceModal';
import { SIZES } from '../../constants/defines';
import { createTemplate, updateTemplate } from '../../db/templates';
import { withAuthorization } from '../Session';
import { BootstrapInput } from '../Page/input';

const useHeaderStyles = makeStyles((theme) => ({
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px',
  },
  slotHeading: {
    fontSize: '24px',
    fontWeight: '400',
    color: '#000000',
    flexBasis: 'calc(100% - 300px)',
    flexShrink: 0,
  },
  slotLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
  },
  tableLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    marginRight: '10px',
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)',
  },
  expandedAccordion: {
    background: '#F1F7FE',
  },
  deleteBox: {
    position: 'absolute',
    top: '50%',
    right: '0px',
    transform: 'translateY(-50%)',
  },
  accordionControlBar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '300px',
    paddingRight: '40px',
  },
}));

const DEFAULT_STATE = {
  id: '',
  name: '',
  property: '',
  size: '',
  isCustomText: false,
  useDistinctBackArt: false,
  faceSlots: [],
  faceSlotsModern: [],
  faceSlotsBack: [],
  faceSlotsModernBack: [],
  propertySlots: [],
  propertySlotsModern: [],
  propertySlotsBack: [],
  propertySlotsModernBack: [],
  canvas: {
    width: 0,
    height: 0,
  },
  canvasModern: {
    width: 0,
    height: 0,
  },
  printArea: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  printAreaModern: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`face-tabpanel-${index}`}
      aria-labelledby={`face-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function PropertyRow(props) {
  const { row, index, classes, label, onUpdate, onDelete } = props;

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name !== 'field') {
      value = parseFloat(value);
    }

    // Handle face slots if num changed
    const _row = { ...row };
    _row[name] = value;
    onUpdate(_row, index);
  };

  return (
    <TableRow tabIndex={-1} key={label}>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Field</Typography>
          <BootstrapInput
            type="text"
            sx={{ width: '100px' }}
            name="field"
            value={row.field}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Max W</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="width"
            value={row.width}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Max H</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="height"
            value={row.height}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Tilt</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="rotation"
            value={row.rotation}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>X</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="x"
            value={row.x}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Y</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="y"
            value={row.y}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Button onClick={() => onDelete(index)}>
          <DeleteOutlineIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}

function SlotRow(props) {
  const { row, slot, index, classes, label, onUpdate } = props;

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === 'face') {
      value = parseInt(value);
    } else {
      value = parseFloat(value);
    }

    // Handle face slots if num changed
    const _row = { ...row };
    _row[name] = value;
    onUpdate(_row, slot, index);
  };

  const face_options = [];
  for (let i = 0; i <= slot; i++) {
    face_options.push(
      <MenuItem value={i} key={`face-${i + 1}`}>
        {i + 1}
      </MenuItem>,
    );
  }

  return (
    <TableRow tabIndex={-1} key={label}>
      <TableCell align="left" className={classes.tableCell}>
        <Typography className={classes.tableLabel}>SLOT {index + 1}</Typography>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Max W</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="width"
            value={row.width}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Max H</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="height"
            value={row.height}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Tilt</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="rotation"
            value={row.rotation}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>X</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="x"
            value={row.x}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Y</Typography>
          <BootstrapInput
            type="number"
            sx={{ width: '100px' }}
            name="y"
            value={row.y}
            onChange={handleChange}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classes.tableCell}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography className={classes.tableLabel}>Face</Typography>
          <Select
            name="face"
            value={row.face}
            input={<BootstrapInput />}
            onChange={handleChange}
          >
            {face_options}
          </Select>
        </Box>
      </TableCell>
    </TableRow>
  );
}

function TemplateModal(props) {
  const { handleClose, open, item, isCreate } = props;
  const [template, setTemplate] = useState({ ...DEFAULT_STATE });
  const [faceExpanded, setFaceExpanded] = React.useState(-1);
  const [artType, setArtType] = useState('legacy');
  const [openFaceModal, setOpenFaceModal] = useState(false);
  const [faceTab, setFaceTab] = useState(0);
  const [propertyTab, setPropertyTab] = useState(0);
  const db = props.firebase.getdb();

  useEffect(() => {
    if (open) {
      setArtType('legacy');
      setFaceTab(0);
      setPropertyTab(0);
      if (isCreate) {
        setTemplate({ ...DEFAULT_STATE });
      } else {
        setTemplate({ ...props.item });
      }
    }
  }, [open]);

  const handleSave = () => {
    if (isCreate) {
      createTemplate(db, template);
      setTemplate({ ...DEFAULT_STATE });
    } else {
      updateTemplate(db, template);
      setTemplate({ ...props.item });
    }

    handleClose();
  };

  const handleCloseDialog = (event) => {
    if (isCreate) {
      setTemplate({ ...DEFAULT_STATE });
    } else {
      setTemplate({ ...props.item });
    }
    handleClose();
  };

  const handleChange = (event) => {
    let { name, value, checked, type } = event.target;
    value = type === 'checkbox' ? checked : value;

    const _template = { ...template };
    _template[name] = value;

    setTemplate(_template);
  };

  const handleCanvasDimensionChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = '';

    switch (label) {
      case 'canvasWidth':
        property = 'width';
        break;
      case 'canvasHeight':
        property = 'height';
        break;
      default:
        property = '';
    }

    const _template = { ...template };
    if (isLegacy()) {
      _template.canvas = update(_template.canvas, {
        [property]: { $set: cleanValue },
      });
    } else {
      _template.canvasModern = update(_template.canvasModern, {
        [property]: { $set: cleanValue },
      });
    }
    setTemplate(_template);
  };

  const handlePrintAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = '';

    switch (label) {
      case 'printAreaX':
        property = 'x';
        break;
      case 'printAreaY':
        property = 'y';
        break;
      case 'printAreaWidth':
        property = 'width';
        break;
      case 'printAreaHeight':
        property = 'height';
        break;
      default:
        property = '';
    }
    const _template = { ...template };
    if (isLegacy()) {
      _template.printArea = update(_template.printArea, {
        [property]: { $set: cleanValue },
      });
    } else {
      _template.printAreaModern = update(_template.printAreaModern, {
        [property]: { $set: cleanValue },
      });
    }
    setTemplate(_template);
  };

  const handleChangeFaceSlots = (panel) => (event, isExpanded) => {
    setFaceExpanded(isExpanded ? panel : false);
  };

  const handleSelectFace = (face) => {
    console.log('Select Face: ', face);

    const _template = { ...template };
    let field = isLegacy() ? 'faceSlots' : 'faceSlotsModern';
    if (faceTab != 0) {
      field += 'Back';
    }
    const faceSlots = [..._template[field]];
    const index = faceSlots.length;

    if (face < 0) {
      const newItem = { count: 12, slots: [] };
      const slot_info = {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        face: 0,
        rotation: 0,
      };
      for (let i = 0; i < newItem.count; i++) {
        newItem.slots.push(slot_info);
      }
      faceSlots[index] = newItem;
    } else {
      const oldItem = faceSlots[face];
      const newItem = { count: oldItem.count, slots: [] };
      newItem.slots = _.map(oldItem.slots, (item) => ({ ...item }));

      faceSlots[index] = newItem;
    }

    _template[field] = faceSlots;
    setTemplate(_template);
  };

  const handleRemoveFace = () => {
    console.log('Remove Face');

    const _template = { ...template };
    let field = isLegacy() ? 'faceSlots' : 'faceSlotsModern';
    if (faceTab != 0) {
      field += 'Back';
    }
    const faceSlots = [..._template[field]];
    faceSlots.splice(-1, 1);
    _template[field] = faceSlots;
    setTemplate(_template);
  };

  const handleAddFaceSlot = (event) => {
    event.preventDefault();
    setOpenFaceModal(true);
  };

  const handleAddPropertySlot = (event) => {
    event.preventDefault();
    const _template = { ...template };
    let field = isLegacy() ? 'propertySlots' : 'propertySlotsModern';
    if (propertyTab != 0) {
      field += 'Back';
    }

    const propertySlots = [..._template[field]];
    const slot = {};
    slot.field = 'Name';
    slot.x = 0;
    slot.y = 0;
    slot.rotation = 0;
    slot.width = 0;
    slot.height = 0;

    propertySlots.push(slot);
    _template[field] = propertySlots;
    setTemplate(_template);
  };

  const handleChangeAvailableSlots = (index) => (event) => {
    event.preventDefault();
    const val = parseInt(event.target.value);
    const _template = { ...template };

    let field = isLegacy() ? 'faceSlots' : 'faceSlotsModern';
    if (faceTab != 0) {
      field += 'Back';
    }
    const faceSlots = [..._template[field]];
    const oldItem = faceSlots[index];

    const newItem = { count: val, slots: [] };
    const slot_info = { width: 0, height: 0, x: 0, y: 0, face: 0, rotation: 0 };
    for (let i = 0; i < newItem.count; i++) {
      if (oldItem.slots[i]) {
        newItem.slots.push(oldItem.slots[i]);
      } else {
        newItem.slots.push(slot_info);
      }
    }
    faceSlots[index] = newItem;
    _template[field] = faceSlots;
    setTemplate(_template);
  };

  const updateFaceSlotItem = (item, slot, index) => {
    const _template = { ...template };
    let field = isLegacy() ? 'faceSlots' : 'faceSlotsModern';
    if (faceTab != 0) {
      field += 'Back';
    }
    const faceSlots = [..._template[field]];
    faceSlots[slot].slots[index] = { ...item };
    _template[field] = faceSlots;
    setTemplate(_template);
  };

  const updatePropertySlotItem = (item, index) => {
    const _template = { ...template };
    let field = isLegacy() ? 'propertySlots' : 'propertySlotsModern';
    if (propertyTab != 0) {
      field += 'Back';
    }

    const propertySlots = [..._template[field]];
    propertySlots[index] = { ...item };
    _template[field] = propertySlots;

    setTemplate(_template);
  };

  const deletePropertySlotItem = (index) => {
    const _template = { ...template };
    let field = isLegacy() ? 'propertySlots' : 'propertySlotsModern';
    if (propertyTab != 0) {
      field += 'Back';
    }

    const propertySlots = [..._template[field]];
    propertySlots.splice(index, 1);
    _template[field] = propertySlots;

    setTemplate(_template);
  };

  const handleChangeFaceTab = (event, newValue) => {
    setFaceTab(newValue);
  };

  const handleChangePropertyTab = (event, newValue) => {
    setPropertyTab(newValue);
  };

  const renderSlots = (faceSlots) => {
    if (faceSlots.length == 0) {
      return (
        <Box sx={{ padding: '0 0 30px' }}>
          <Typography
            sx={{ padding: '20px', textAlign: 'center', fontStyle: 'italic' }}
          >
            No data
          </Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ padding: '0 0 30px' }}>
        {_.map(faceSlots, (slot, index) => (
          <Accordion
            key={`faceAccordion_${index}`}
            expanded={faceExpanded === index}
            className={
              faceExpanded === index
                ? classes.expandedAccordion
                : classes.normalAccordion
            }
            onChange={handleChangeFaceSlots(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.slotHeading}>
                {index + 1} {index > 0 ? 'Faces' : 'Face'} Purchased
              </Typography>
              <Box className={classes.accordionControlBar}>
                {faceSlots.length - 1 === index && (
                  <Box className={classes.deleteBox} onClick={handleRemoveFace}>
                    <DeleteOutlineIcon />
                  </Box>
                )}

                <Typography className={classes.slotLabel}>
                  Available Slots
                </Typography>
                <BootstrapInput
                  type="number"
                  sx={{ width: '110px' }}
                  value={slot.count}
                  onChange={handleChangeAvailableSlots(index)}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="medium"
                aria-label="enhanced table"
              >
                <TableBody>
                  {_.map(slot.slots, (slotItem, _index) => (
                    <SlotRow
                      classes={classes}
                      key={`faceslot_${_index}_${faceTab}`}
                      slot={index}
                      index={_index}
                      row={slotItem}
                      onUpdate={updateFaceSlotItem}
                    />
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const renderFaces = () => (
    <Box sx={{ width: '100%', marginBottom: '10px' }}>
      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            background: '#3d3d3d',
            color: '#fff',
          }}
        >
          <Tabs
            value={faceTab}
            onChange={handleChangeFaceTab}
            aria-label="Face Tabs"
            textColor="inherit"
          >
            <Tab label="Front" {...a11yProps(0)} />
            <Tab label="Back" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={faceTab} index={0}>
          {renderSlots(
            isLegacy() ? template.faceSlots : template.faceSlotsModern,
          )}
        </TabPanel>
        <TabPanel value={faceTab} index={1}>
          {renderSlots(
            isLegacy() ? template.faceSlotsBack : template.faceSlotsModernBack,
          )}
        </TabPanel>
      </Box>

      <Box sx={{ padding: '0 0 30px 0' }}>
        <Button
          variant="blue"
          color="primary"
          sx={{ width: '300px' }}
          onClick={handleAddFaceSlot}
        >
          Add Faces Purchased Data
        </Button>
      </Box>
    </Box>
  );

  const renderProperties = () => (
    <Box sx={{ width: '100%', marginBottom: '10px' }}>
      <Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            background: '#3d3d3d',
            color: '#fff',
          }}
        >
          <Tabs
            value={propertyTab}
            onChange={handleChangePropertyTab}
            aria-label="Property Tabs"
            textColor="inherit"
          >
            <Tab label="Front" {...a11yProps(0)} />
            <Tab label="Back" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={propertyTab} index={0}>
          {renderPropertySlots(
            isLegacy() ? template.propertySlots : template.propertySlotsModern,
          )}
        </TabPanel>
        <TabPanel value={propertyTab} index={1}>
          {renderPropertySlots(
            isLegacy()
              ? template.propertySlotsBack
              : template.propertySlotsModernBack,
          )}
        </TabPanel>
      </Box>

      <Box sx={{ padding: '20px 0 30px 0' }}>
        <Button
          variant="blue"
          color="primary"
          sx={{ width: '250px' }}
          onClick={handleAddPropertySlot}
        >
          Add Text Box Value
        </Button>
      </Box>
    </Box>
  );

  const renderPropertySlots = (propertySlots) => {
    if (propertySlots.length === 0) {
      return (
        <Box sx={{ padding: '0 0 30px' }}>
          <Typography
            sx={{ padding: '20px', textAlign: 'center', fontStyle: 'italic' }}
          >
            No data
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableBody>
            {_.map(propertySlots, (slotItem, index) => (
              <PropertyRow
                classes={classes}
                key={`propertyslot_${index}`}
                index={index}
                row={slotItem}
                onUpdate={updatePropertySlotItem}
                onDelete={deletePropertySlotItem}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  };

  const renderCanvas = (canvas) => (
    <Box sx={{ padding: '40px 0' }}>
      <InputLabel>Canvas Dimension</InputLabel>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center">
            <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
            <BootstrapInput
              type="number"
              name="canvasWidth"
              value={canvas.width}
              onChange={handleCanvasDimensionChange}
            />
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <Box display="flex" alignItems="center">
            <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
            <BootstrapInput
              type="number"
              name="canvasHeight"
              value={canvas.height}
              onChange={handleCanvasDimensionChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPrintArea = (printArea) => (
    <Box>
      <Box sx={{ padding: '40px 0' }}>
        <InputLabel>Crop Start Position</InputLabel>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              <InputLabel className={classes.inputInlineLabel}>X</InputLabel>
              <BootstrapInput
                type="number"
                name="printAreaX"
                value={printArea.x}
                onChange={handlePrintAreaChange}
              />
            </Box>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              <InputLabel
                htmlFor="demo-customized-textbox"
                className={classes.inputInlineLabel}
              >
                Y
              </InputLabel>
              <BootstrapInput
                type="number"
                name="printAreaY"
                value={printArea.y}
                onChange={handlePrintAreaChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider />

      <Box sx={{ padding: '40px 0' }}>
        <InputLabel>Crop Dimension</InputLabel>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
              <BootstrapInput
                type="number"
                name="printAreaWidth"
                value={printArea.width}
                onChange={handlePrintAreaChange}
              />
            </Box>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <Box display="flex" alignItems="center">
              <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
              <BootstrapInput
                type="number"
                name="printAreaHeight"
                value={printArea.height}
                onChange={handlePrintAreaChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const handleCloseFaceModal = () => {
    setOpenFaceModal(false);
  };

  const handleChangeArtType = (event) => {
    setFaceTab(0);
    setPropertyTab(0);
    setArtType(event.target.value);
  };

  const isLegacy = () => artType === 'legacy';

  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="revision-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="lg"
      scroll={scroll}
    >
      <DialogTitle
        onClose={handleCloseDialog}
        sx={{ padding: '30px 40px 10px', position: 'relative' }}
      >
        <Typography variant="h2">{template.name}</Typography>
        <IconButton
          aria-label="close"
          variant="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0 40px' }} dividers>
        <Box sx={{ padding: '40px 0' }}>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <InputLabel>Name</InputLabel>
              <BootstrapInput
                name="name"
                value={template.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <InputLabel>&nbsp;</InputLabel>
              <Select
                name="artType"
                value={artType}
                input={<BootstrapInput />}
                onChange={handleChangeArtType}
              >
                <MenuItem key="menu-legacy" value="legacy">
                  Legacy
                </MenuItem>
                <MenuItem key="menu-modern" value="modern">
                  Modern
                </MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ marginTop: '25px' }}>
            <Grid item xs={5}>
              <InputLabel>Size</InputLabel>
              <Select
                name="size"
                value={template.size}
                input={<BootstrapInput />}
                onChange={handleChange}
              >
                {_.map(SIZES, (size) => (
                  <MenuItem key={size.id} value={size.id}>
                    {size.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <InputLabel>Property</InputLabel>
              <BootstrapInput
                name="property"
                value={template.property}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider />

        {renderCanvas(isLegacy() ? template.canvas : template.canvasModern)}

        <Divider />

        {renderPrintArea(
          isLegacy() ? template.printArea : template.printAreaModern,
        )}

        <Divider />

        {renderFaces()}

        {renderProperties()}

        <FaceModal
          open={openFaceModal}
          handleClose={handleCloseFaceModal}
          faces={template.faceSlots.length}
          onSelect={handleSelectFace}
        />
      </DialogContent>

      <DialogActions
        sx={{ padding: '20px 40px', justifyContent: 'flex-start' }}
      >
        <Button
          variant="blue"
          color="primary"
          onClick={handleSave}
          sx={{ width: '150px' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(TemplateModal);
