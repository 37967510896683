import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import { collections, API } from '../constants/defines';

const deleteUser = (db, docId) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.USERS, docId))
      .then(() => {
        console.log('User successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting User: ', error);
        reject();
      });
  });

const createUser = (db, data) => {
  const item = { ...data };
  item.createdAt = new Date();
  item.signedIn = '';

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.USERS), item)
      .then((docRef) => {
        console.log('New User created with ID: ', docRef.id);
        resolve(docRef.id);
      })
      .catch((error) => {
        console.error('Error adding User: ', error);
        reject();
      });
  });
};

const resetUser = (docId, password) =>
  new Promise((resolve, reject) => {
    const params = { userId: docId, password };

    fetch(API.resetUserPassword, {
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        resolve();
      });
  });

const getUser = (db, uid) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.USERS, uid);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() };
        resolve(data);
      } else {
        resolve(null);
      }
    });
  });

const getUsers = (db, callback) => {
  const q = query(collection(db, collections.USERS));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    callback(items);
  });
};

const getUserByUid = (db, uid) =>
  new Promise((resolve, reject) => {
    const q = query(collection(db, collections.USERS), where('uid', '==', uid));

    return getDocs(q)
      .then((querySnapshot) => {
        let _item = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          _item = { ...data };
        });
        resolve(_item);
      })
      .catch((error) => {
        console.log('getUserByUid: ', error);
        reject();
      });
  });

const getPermissions = (db, callback) => {
  const q = query(collection(db, collections.PERMISSIONS));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    callback(items);
  });
};

const getPermission = (db, name) =>
  new Promise((resolve, reject) => {
    const q = query(
      collection(db, collections.PERMISSIONS),
      where('name', '==', name),
    );

    return getDocs(q)
      .then((querySnapshot) => {
        let _item = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          _item = { ...data };
        });
        resolve(_item);
      })
      .catch((error) => {
        console.log('getPermission: ', error);
        reject();
      });
  });

const updateUser = (db, docId, item) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.USERS, docId);
    updateDoc(docRef, {
      ...item,
    })
      .then(() => {
        console.log('User Data successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Data: ', error);
        reject();
      });
  });

const updateUserPermission = (db, docId, permission) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.USERS, docId);
    updateDoc(docRef, {
      permission,
    })
      .then(() => {
        console.log('User Permission successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Permission: ', error);
        reject();
      });
  });

const updateUserSignedIn = (db, docId) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.USERS, docId);
    updateDoc(docRef, {
      signedIn: new Date(),
    })
      .then(() => {
        console.log('User Sign successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Sign: ', error);
        reject();
      });
  });

export {
  createUser,
  getUser,
  getUserByUid,
  getUsers,
  deleteUser,
  resetUser,
  updateUser,
  getPermission,
  getPermissions,
  updateUserPermission,
  updateUserSignedIn,
};
