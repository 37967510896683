import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ref, getDownloadURL } from 'firebase/storage';

const useHeaderStyles = makeStyles((theme) => ({
  stImageBox: {
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
  },
  stImageInnerBox: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  imgContain: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}));

function StorageImage(props) {
  const { firebase, path } = props;
  const [imagePath, setImagePath] = useState('');
  const [loading, setLoading] = useState(false);

  const classes = useHeaderStyles();

  useEffect(() => {
    setImagePath('');
    if (path === '') {
      setLoading(false);
    } else {
      setLoading(true);

      const storage = firebase.getstorage();
      getDownloadURL(ref(storage, path))
        .then((url) => {
          setImagePath(url);
          setLoading(true);
          const img = new Image();
          img.onload = function () {
            setLoading(false);
          };
          img.src = url;
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [path]);

  return (
    <Box className={classes.stImageBox}>
      <Box className={classes.stImageInnerBox}>
        {loading && <LinearProgress />}

        {imagePath !== '' && (
          <img key={path} src={imagePath} className={classes.imgContain} />
        )}
      </Box>
    </Box>
  );
}

export { StorageImage };
