import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import Button from '@mui/material/Button';
import _ from 'lodash';

import FilterDropdown from '../Page/filterDropdown';
import FilterContext from '../../context/filterContext';

const DEFAULT_OPTIONS = [
  { id: 1, name: 'All', val: '' },
  // {id: 2, name: 'None', val: ''},
];

import {
  filterTypes,
  filterStyles,
  _filterTemplates,
} from './Products.constants';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  toolBox: {
    display: 'flex',
    width: 'calc(100% - 200px)',
    justifyContent: 'space-between',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '9px 32px!important',
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    '&.Mui-disabled': {
      background: 'rgba(0, 0, 0, 0.12)!important',
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
  iconBox: {
    textAlign: 'right',
    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  viewIconBox: {
    padding: '0 5px',
    cursor: 'pointer',
  },
  viewIcon: {
    fontSize: '30px',
    color: '#e5e5e5',
  },
  viewActiveIcon: {
    fontSize: '30px',
    color: '#bebebe',
  },
}));

const PageToolbar = (props) => {
  const { view, onChangeView } = props;
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();
  const { handleNew } = props;
  const [styles, setStyles] = useState([...filterStyles]);
  const [formats, setFormats] = useState([...DEFAULT_OPTIONS]);
  const [sizes, setSizes] = useState([...DEFAULT_OPTIONS]);
  const [templates, setTemplates] = useState([..._filterTemplates]);

  const viewOnly = props.viewOnly;
  const editOnly = props.editOnly;

  useEffect(() => {
    const _filterFormats = [...DEFAULT_OPTIONS];

    const _formats = _.map(props.formats, (item, index) => ({
      id: index + 3,
      name: item.name,
      val: item.id,
    }));
    const __formats = _filterFormats.concat(_formats);
    setFormats([...__formats]);
  }, [props.formats]);

  useEffect(() => {
    const _filterSizes = [...DEFAULT_OPTIONS];

    const _sizes = _.map(props.sizes, (item, index) => ({
      id: index + 3,
      name: item.name,
      val: item.id,
    }));
    const __sizes = _filterSizes.concat(_sizes);
    setSizes([...__sizes]);
  }, [props.sizes]);

  useEffect(() => {
    setTemplates([...data.templates]);
  }, [data.templates]);

  useEffect(() => {
    const itemType = _.find(filterTypes, { id: data.filterType });

    if (itemType.name === 'All') {
      const _filterFormats = [...DEFAULT_OPTIONS];

      const _formats = _.map(props.formats, (item, index) => ({
        id: index + 3,
        name: item.name,
        val: item.id,
      }));
      const __formats = _filterFormats.concat(_formats);
      setFormats([...__formats]);

      const _filterSizes = [...DEFAULT_OPTIONS];

      const _sizes = _.map(props.sizes, (item, index) => ({
        id: index + 3,
        name: item.name,
        val: item.id,
      }));
      const __sizes = _filterSizes.concat(_sizes);
      setSizes([...__sizes]);

      setStyles([...filterStyles]);

      setTemplates([...data.templates]);
    } else if (itemType.name === 'None') {
      setFormats([{ id: 2, name: 'None' }]);
      setSizes([{ id: 2, name: 'None' }]);
      setStyles([{ id: 2, name: 'None' }]);
      setTemplates([{ id: 'None', name: 'None' }]);
    } else {
      const _filterFormats = [...DEFAULT_OPTIONS];
      const _formats = _.map(props.formats, (item, index) => ({
        id: index + 3,
        name: item.name,
        val: item.id,
        type: item.type,
      }));
      const __formats = _filterFormats.concat(
        _.filter(_formats, { type: itemType.name }),
      );
      setFormats([...__formats]);

      const _filterSizes = [...DEFAULT_OPTIONS];
      const _sizes = _.map(props.sizes, (item, index) => ({
        id: index + 3,
        name: item.name,
        val: item.id,
        type: item.type,
      }));
      const __sizes = _filterSizes.concat(
        _.filter(_sizes, { type: itemType.name }),
      );
      setSizes([...__sizes]);

      const _styles = _.filter(filterStyles, (item) =>
        item.type.includes(itemType.name),
      );

      setStyles([..._styles]);
      if (itemType.name === 'Underwear') {
        setTemplates([{ id: 'None', name: 'None' }]);
      } else {
        setTemplates([...data.templates]);
      }
    }
  }, [data.filterType]);

  useEffect(() => {
    // Initialize updatedSizes without the default options
    let updatedSizes = props.sizes.map((item, index) => ({
      id: index + 3,
      name: item.name,
      val: item.id,
    }));

    // initialize Formats
    let updatedFormats = props.formats.map((item, index) => ({
      id: index + 3,
      name: item.name,
      val: item.id,
    }));

    const selectedStyle = data.filterStyle;
    const selectedType = data.filterType;

    if (selectedStyle === 4 && selectedType === 3) {
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['L/XL', 'S/M', 'Youth L'].includes(size.name),
      );
    }

    if (selectedStyle === 7 && selectedType === 3) {
      // include Kee high, when ready
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['2XL', 'L/XL', 'S/M', 'Youth L'].includes(size.name),
      );
      // Filter out only the formats you need
      const filteredFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );

      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort(
        (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name),
      );

      updatedFormats = filteredFormats;
    }

    // when socks, allow all sizes minus 2XL
    if (selectedType === 3 && selectedStyle === 3) {
      // Apply specific filtering logic here
      updatedSizes = updatedSizes.filter((size) =>
        [
          'L/XL',
          'S/M',
          'YTHL',
          'Youth M',
          'Toddler 2-4Y',
          'Toddler 1-2Y',
          'Baby 0-12M ',
        ].includes(size.name),
      );
      // Filter out only the formats you need
      const filteredFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );

      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort(
        (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name),
      );

      updatedFormats = filteredFormats;

      const customOrderSize = [
        'L/XL',
        'S/M',
        'YTHL',
        'Youth M',
        'Toddler 2-4Y',
        'Toddler 1-2Y',
        'Baby 0-12M ',
      ];

      // Sort the array based on the custom order
      updatedSizes.sort(
        (a, b) =>
          customOrderSize.indexOf(a.name) - customOrderSize.indexOf(b.name),
      );

      updatedFormats = filteredFormats;
    }

    if (selectedType === 5) {
      updatedFormats = updatedFormats.filter((format) =>
        ['Boxer'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['2XL', 'XL', 'L', 'M', 'S'].includes(size.name),
      );
    }

    if (selectedType === 6 && selectedStyle === 9) {
      updatedFormats = updatedFormats.filter((format) =>
        [
          'Greeting Card',
          'Gift Box',
          'NFL 8-Bit Box',
          'Sticker',
          'VHS Box',
          'Coffin Box',
          'Coffin Box',
          'Suitcase',
        ].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) => ['O/S'].includes(size.name));
    }

    if (selectedStyle === 4) {
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['L/XL', 'S/M', 'Youth L'].includes(size.name),
      );
    }

    if (selectedStyle === 8) {
      updatedFormats = updatedFormats.filter((format) =>
        ['Boxer'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['2XL', 'XL', 'L', 'M', 'S'].includes(size.name),
      );
    }

    if (selectedStyle === 7) {
      updatedFormats = updatedFormats.filter((format) =>
        ['Crew', 'Quarter', 'Low'].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) =>
        ['2XL', 'L/XL', 'S/M', 'Youth L'].includes(size.name),
      );
    }

    if (selectedStyle === 9) {
      updatedFormats = updatedFormats.filter((format) =>
        [
          'Greeting Card',
          'Gift Box',
          'NFL 8-Bit Box',
          'VHS Box',
          'Sticker',
          'Coffin Box',
          'Suitcase',
        ].includes(format.name),
      );
      updatedSizes = updatedSizes.filter((size) => ['O/S'].includes(size.name));
    }

    // After filtering, add the "All" option at the end of the list
    updatedSizes.push({ id: 1, name: 'All', val: '' });
    updatedFormats.push({ id: 1, name: 'All', val: '' });

    // Update state

    // Remove duplicate sizes based on the 'name' property
    const uniqueSizes = updatedSizes.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    // Finally, update the state with the unique sizes
    setSizes(uniqueSizes);
    setFormats(updatedFormats);
  }, [props.sizes, data.filterStyle, data.filterFormat]); // Depend on props.sizes and context values

  const onClickViewMode = (mode) => {
    console.log('onClickViewMode');
    if (onChangeView) {
      onChangeView(mode);
    }
  };

  const removeSpecificFormats = (formats, exclusions) =>
    formats.filter((format) => !exclusions.includes(format.name));

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <Button
          variant="blue"
          onClick={handleNew}
          disabled={!editOnly}
          sx={{ width: '170px' }}
        >
          Add Product
        </Button>
      </div>

      <div className={classes.toolBox}>
        <div className={classes.filterBox}>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Type"
              items={data.types}
              active={data.filterType}
              onSelect={data.setFilterType}
            />
          </div>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Style"
              items={styles}
              active={data.filterStyle}
              onSelect={data.setFilterStyle}
            />
          </div>
          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Format"
              items={removeSpecificFormats(formats, ['Mask'])}
              active={data.filterFormat}
              onSelect={data.setFilterFormat}
              isFormat={true}
            />
          </div>

          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Size"
              items={sizes.map((size) => ({
                ...size,
                name: size.name === 'YTHL' ? 'Youth L' : size.name,
              }))}
              active={data.filterSize}
              onSelect={data.setFilterSize}
            />
          </div>

          <div sx={{ marginRight: '30px' }}>
            <FilterDropdown
              title="Template"
              items={templates}
              active={data.filterTemplate}
              onSelect={data.setFilterTemplate}
            />
          </div>
        </div>
        <div className={classes.sortBox}>
          <div className={classes.iconBox}>
            <div className={classes.viewIconBox}>
              <ViewListIcon
                onClick={() => onClickViewMode('list')}
                className={
                  props.view === 'list'
                    ? classes.viewActiveIcon
                    : classes.viewIcon
                }
              />
            </div>
            <div className={classes.viewIconBox}>
              <ViewModuleIcon
                onClick={() => onClickViewMode('grid')}
                className={
                  props.view === 'grid'
                    ? classes.viewActiveIcon
                    : classes.viewIcon
                }
              />
            </div>
          </div>
          <FilterDropdown
            title="Show"
            items={data.shows}
            active={data.filterShow}
            onSelect={data.setFilterShow}
          />
        </div>
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
