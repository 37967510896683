import * as React from 'react';

const ShowPassword = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M9.75 12.5a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M2 12.5c0 1.64.425 2.191 1.275 3.296C4.972 18 7.818 20.5 12 20.5c4.182 0 7.028-2.5 8.725-4.704C21.575 14.692 22 14.139 22 12.5c0-1.64-.425-2.191-1.275-3.296C19.028 7 16.182 4.5 12 4.5 7.818 4.5 4.972 7 3.275 9.204 2.425 10.308 2 10.861 2 12.5Zm10-3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ShowPassword;
