import React from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '../Page';
import TopSingleCards from './TopSingleCards';
import HalfCards from './HalfCards';
import useIsMobile from '../../hooks/useIsMobileHook';
import { withAuthorization } from '../Session';
import TopHalfCards from './TopHalfCards';
import MainDropdown from './MainDropdown';
import ShipCard from './ShipCard';

const defaultProducts = [
  {
    type: 'socks',
    types: {
      shopify: {
        styles: [],
      },
    },
  },
  {
    type: 'boxer',
    types: {
      shopify: {
        styles: [],
      },
    },
  },
];

const addDefaultEntries = (data) => {
  const productTypes = defaultProducts.map((p) => p.type);

  data.forEach((item) => {
    if (item.title === 'Units To Be Produced') {
      const existingProductTypes = item.data[0].products.map((p) => p.type);

      productTypes.forEach((type) => {
        if (!existingProductTypes.includes(type)) {
          item.data[0].products.push(
            defaultProducts.find((p) => p.type === type),
          );
        }
      });
    }
  });

  return data;
};

const Analytics = (props) => {
  const dispatch = useDispatch();

  const {
    units,
    shipmentData,
    isLoading,
    unshippedDataForGraph,
    timedAnalytics,
    itemsWithErrors,
    mainDropDownProduct,
  } = useSelector((state) => state.analyticsState);

  const oldestAndMakeup = timedAnalytics.filter(
    (item) => item.title === 'Oldest Order' || item.title === 'Makeup Orders',
  );

  const filteredTimedAnalytics = timedAnalytics.filter(
    (item) => item.title !== 'Oldest Order' && item.title !== 'Makeup Orders',
  );

  const isMobile = useIsMobile();

  const db = props.firebase.getdb();

  const unitsWithDefaults = addDefaultEntries(units);

  const onlyToBeProduced = unitsWithDefaults.filter(
    (item) => item.title === 'Units To Be Produced',
  );

  return (
    <Layout page="analytics" user={props.user}>
      <Box sx={styles.container}>
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={isMobile ? 1 : 2}
              sx={{
                alignItems: 'center',
              }}
            >
              {filteredTimedAnalytics.map((card, index) => {
                const keyValue = index + card.title;
                // Apply specific width rules for the first card on mobile view
                const xsWidth = index === 0 ? 12 : 6; // 100% width for the first card, 50% for others
                return (
                  <Grid
                    item
                    xs={xsWidth}
                    sm={6}
                    md={4}
                    lg={2}
                    xl={3}
                    key={keyValue}
                  >
                    <TopSingleCards
                      keyValue={keyValue}
                      title={card.title}
                      data={card.data}
                      icon={card.icon}
                      isMobile={isMobile}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: !isMobile ? 'column' : 'row',
                    gap: !isMobile ? 2.5 : 1,
                    justifyContent: 'space-between',
                  }}
                >
                  {oldestAndMakeup.map((card, index) => {
                    const keyValue = index + card.title;
                    return (
                      <TopHalfCards
                        isMobile={isMobile}
                        keyValue={keyValue}
                        title={card.title}
                        data={card.data}
                        icon={card.icon}
                      />
                    );
                  })}
                </Box>
              </Grid>
            </Grid>

            <MainDropdown
              isMobile={isMobile}
              mainDropDownProduct={mainDropDownProduct}
            />

            <Grid container>
              {onlyToBeProduced.map((card, index) => {
                const keyValue = card.title + '_' + index;
                return (
                  <Grid item xl={12} key={keyValue}>
                    <HalfCards
                      mainDropDownProduct={mainDropDownProduct}
                      keyValue={keyValue}
                      title={card.title}
                      data={card.data}
                      dispatch={dispatch}
                      value={card.value}
                      itemsWithErrors={itemsWithErrors}
                      isMobile={isMobile}
                      db={db}
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Grid
              container
              sx={{
                paddingBottom: 5,
              }}
            >
              <ShipCard
                isMobile={isMobile}
                keyValue="shipCard"
                data={shipmentData}
                unshippedDataForGraph={unshippedDataForGraph}
                dispatch={dispatch}
              />
            </Grid>
          </>
        )}
      </Box>
    </Layout>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    paddingTop: 2,
    height: '100vh',
    minHeight: '100vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowX: 'hidden',
  },
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Analytics);
