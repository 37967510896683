import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const getFormats = (db, limit, callback) => {
  const q = query(collection(db, collections.FORMATS), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    callback(items);
  });
};

const createFormat = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.FORMATS), newItem)
      .then((docRef) => {
        console.log('New Format created with ID: ', docRef.id);
        resolve();
      })
      .catch((error) => {
        console.error('Error adding Format: ', error);
        reject();
      });
  });
};

const updateFormat = (db, item) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.FORMATS, item.id);
    updateDoc(docRef, {
      name: item.name,
      type: item.type,
    })
      .then(() => {
        console.log('Format successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Format: ', error);
        reject();
      });
  });

const deleteFormat = (db, item) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.FORMATS, item.id))
      .then(() => {
        console.log('Format successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Format: ', error);
        reject();
      });
  });

export { getFormats, createFormat, updateFormat, deleteFormat };
