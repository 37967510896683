import { useEffect } from 'react';

import { getCustomOrders } from '../db/customOrder';
import {
  injectCustomOrders,
  startFetchCustomOrders,
  storeAllCustomOrders,
} from '../redux/customOrders/actions';

const useFetchCustomOrders = (dispatch, db, storage) => {
  useEffect(() => {
    console.log('Running useFetchCustom Hook');
    const callBackForSearch = async (products, pagesData) => {
      dispatch(injectCustomOrders(products));
      dispatch(storeAllCustomOrders(products));
    };

    const fetchAllData = async () => {
      dispatch(startFetchCustomOrders());
      getCustomOrders(db, callBackForSearch, storage);
    };

    fetchAllData();
  }, []);

  return null;
};

export default useFetchCustomOrders;
