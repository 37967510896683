import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const getShippings = (db, limit, callback) => {
  const q = query(collection(db, collections.SHIPPINGS));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      items.push({
        id: doc.id,
        name: data.name,
      });
    });
    callback(items);
  });
};

export { getShippings };
