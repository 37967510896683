export const INJECT_ANALYTICS = 'INJECT_ANALYTICS';
export const SORT_DATA_BASED_ON_FILTER = 'SORT_DATA_BASED_ON_FILTER';
export const UPDATE_SELECTED_DATE = 'UPDATE_SELECTED_DATE';
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export const UPDATE_SELECTED_TYPE_OF_PRODUCT =
  'UPDATE_SELECTED_TYPE_OF_PRODUCT';
export const INJECT_SHIPMENT_ANALYTICS = 'INJECT_SHIPMENT_ANALYTICS';
export const UPDATE_SELECTED_DATASET_FOR_SHIPPING =
  'UPDATE_SELECTED_DATASET_FOR_SHIPPING';
export const SORT_SHIPMENT_DATA = 'SORT_SHIPMENT_DATA';
export const SET_LOADING = 'SET_LOADING';
export const INSERT_ALL_ORDERS = 'INSERT_ALL_ORDERS';
export const CALCULATE_TOTALS_FOR_ANALYTICS = 'CALCULATE_TOTALS_FOR_ANALYTICS';
export const SET_OF_ITEMS_WITH_ERRORS = 'SET_OF_ITEMS_WITH_ERRORS';
export const SET_PRODUCED_ANALYTICS = 'SET_PRODUCED_ANALYTICS';
export const SET_SHIPPED_YESTERDAY_ANALYTICS =
  'SET_SHIPPED_YESTERDAY_ANALYTICS';
export const SET_FINISHED_FULL_FETCH = 'SET_FINISHED_FULL_FETCH';

export const TYPE_OF_ANALYTICS = {
  TIMED_ANALYTICS: 'TIMED_ANALYTICS',
  UNITS: 'UNITS',
  ORDERS_SHIPPED_AND_NOT_SHIPPED: 'ORDERS_SHIPPED_AND_NOT_SHIPPED',
};

export const INJECT_UNSHIPPED_GRAPH_DATA = 'INJECT_UNSHIPPED_GRAPH_DATA';
