import React, { useState, useEffect } from 'react';
import update from 'immutability-helper';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { createFormat, updateFormat } from '../../db/formats';
import { withAuthorization } from '../Session';
import { BootstrapInput } from '../Page/input';
import { filterTypes } from '../Products/Products.constants';

const useHeaderStyles = makeStyles((theme) => ({
  slotHeading: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    flexBasis: 'calc(100% - 250px)',
    flexShrink: 0,
  },
  slotLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
  },
  tableLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    marginRight: '10px',
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)',
  },
  expandedAccordion: {
    background: '#F1F7FE',
  },
}));

const DEFAULT_STATE = {
  name: '',
  type: '',
};

function FormatModal(props) {
  const { handleClose, open, item, isCreate } = props;
  const [format, setFormat] = useState({ ...DEFAULT_STATE });
  const db = props.firebase.getdb();

  useEffect(() => {
    if (isCreate) {
      setFormat({ ...DEFAULT_STATE });
    } else {
      setFormat({ ...props.item });
    }
  }, [props.item]);

  const handleSave = () => {
    if (isCreate) {
      createFormat(db, format);
      setFormat({ ...DEFAULT_STATE });
    } else {
      updateFormat(db, format);
      setFormat({ ...props.item });
    }

    handleClose();
  };

  const handleCloseDialog = (event) => {
    if (isCreate) {
      setFormat({ ...DEFAULT_STATE });
    } else {
      setFormat({ ...props.item });
    }
    handleClose();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const _format = { ...format };
    _format[name] = value;

    setFormat(_format);
  };

  const types = _.filter(
    filterTypes,
    (item) => item.name !== 'All' && item.name !== 'None',
  );
  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="format-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
      scroll={scroll}
    >
      <DialogTitle
        onClose={handleCloseDialog}
        sx={{ padding: '30px 40px 10px', position: 'relative' }}
      >
        <Typography variant="h2">{props.modal_name}</Typography>
        <IconButton
          aria-label="close"
          variant="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: '0 40px', overflow: 'hidden' }} dividers>
        <Grid container spacing={3} sx={{ padding: '30px 0' }}>
          <Grid item xs={12}>
            <InputLabel>Name</InputLabel>
            <BootstrapInput
              name="name"
              value={format.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={format.type}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="">-</MenuItem>
              {_.map(types, (item) => (
                <MenuItem value={item.name}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ padding: '20px 40px', justifyContent: 'flex-start' }}
      >
        <Button
          variant="blue"
          color="primary"
          sx={{ width: '120px' }}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(FormatModal);
