import { palette } from '../theme/colors';

/**
 * This function checks if the date is exceeding five days from today.
 * @param {string} dateString - The date to be checked.
 * @returns {boolean} - True if the date is exceeding five days from today, false otherwise.
 */
export const isDateExceedingFiveDays = (dateString) => {
  const orderDate = new Date(dateString);
  const today = new Date();
  const differenceInTime = today.getTime() - orderDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays > 5;
};

// change row style if date is exceeding 5 days
export const rowStyle = (date, row, takeOverStyle = false) => {
  if (takeOverStyle && row?.style) {
    return { color: palette.productPurple };
  }

  if (isDateExceedingFiveDays(date)) {
    return { fontWeight: 'bold', color: '#FF0000' };
  } else if (row?.style) {
    return { color: palette.productPurple };
  }
  return {}; // Default style
};

// return a capitalized first letter of the source
export const formatSource = (/** @type {string} */ source) => {
  if (!source) {
    return '';
  } // Return an empty string if source is undefined or null
  return source.charAt(0).toUpperCase() + source.slice(1);
};

export const dsRowStyle = (date, row, takeOverStyle = false) => {
  if (row.style !== '' && row?.style !== 'Graphic') {
    return { color: palette.productPurple };
  }
  return {};
};
