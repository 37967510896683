import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, Modal } from '@mui/material';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';

import { collections } from '../../constants/defines';
import Firebase from '../Firebase';

const firebaseInstance = new Firebase();

const styles = {
  modalOverlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'black',
  },
  alertText: {
    marginBottom: '24px',
    fontWeight: '500',
    fontSize: '24px',
  },
  button: {
    fontSize: '24px',
    color: 'black',
    borderColor: 'black',
    backgroundColor: 'white',
    borderRadius: '10px',
  },
  paulYee: {
    width: '250px',
    height: '250px',
  },
};

const UpdateAlert = ({ userId }) => {
  const [showAlert, setShowAlert] = useState(false);

  const hardcodedReleaseTimestamp = '2025-01-23';

  useEffect(() => {
    if (location.pathname !== '/') {
      return;
    }

    const latestUpdateRef = doc(
      firebaseInstance.db,
      collections.UPDATES,
      'latestRelease',
    );

    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);

    const unsubscribe = onSnapshot(latestUpdateRef, async (snapshot) => {
      if (snapshot.exists()) {
        const latestReleaseTimestamp = snapshot.data().timestamp;

        try {
          const userDoc = await getDoc(userUpdateRef);
          const userLastUpdateReceived =
            userDoc?.data()?.lastUpdateReceived || '';

          // Show alert if the user's last update timestamp is behind the latest
          if (userLastUpdateReceived !== latestReleaseTimestamp) {
            setShowAlert(true);
          }
        } catch (error) {
          console.error("Error checking user's last update:", error);
        }
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [userId]);

  const handleAcknowledgeUpdate = async () => {
    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);
    try {
      console.log('Updating with: ', hardcodedReleaseTimestamp);
      await setDoc(
        userUpdateRef,
        { lastUpdateReceived: hardcodedReleaseTimestamp },
        { merge: true },
      );
      setShowAlert(false);
      // refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error acknowledging update:', error);
    }
  };

  return (
    <Modal open={showAlert} sx={styles.modalOverlay}>
      <Box sx={styles.modalContent}>
        <Typography sx={styles.alertText}>
          A new update is available! Please accept to continue.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleAcknowledgeUpdate}
          sx={styles.button}
        >
          Accept Update
        </Button>
      </Box>
    </Modal>
  );
};

export default UpdateAlert;
