import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import History from './history';
import Layout from '../Page';
import { withAuthorization } from '../Session';
import { EnhancedTableHead } from '../Page/table';
import { getUploads, deleteUploads } from '../../db/uploads';
import { ConfirmDialog } from '../Page/generalModal';
import useStyles from './Uploads.styles';

const calculateProducts = (uploads) => {
  const total = uploads.length;
  let products = 0;
  _.each(uploads, (item) => {
    products += item.items.length;
  });

  return { total, products };
};

const headCells = [
  {
    id: 'identifier',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: '',
    width: '30px',
  },
  {
    id: 'name',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'DATE',
    width: '120px',
  },
  {
    id: 'batch',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'BATCH NAME',
    width: '300px',
  },
  {
    id: 'type',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'BATCH TYPE',
    width: '180px',
  },
  {
    id: 'progress',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'PROGRESS',
    width: 'auto',
  },
  {
    id: 'status',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'STATUS',
    width: '120px',
  },
  {
    id: 'action',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: '',
    width: '180px',
  },
];

function Row(props) {
  const { row, db, classes, label, onDelete } = props;
  const [open, setOpen] = useState(false);
  let progress = parseInt((row.current / row.total) * 100);
  let status = '';
  if (row.total == 0) {
    progress = 100;
    status = 'Ready';
  } else if (row.total > 0 && row.total == row.current) {
    console.log('Total: ', row.total, ', Current:', row.current);
    status = 'Completed';
  } else {
    status = `${row.current} of ${row.total}`;
  }

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={label}
        classes={{
          root: row.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
          selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
        }}
        selected={open}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Typography variant="tableText">{row.date}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="tableText">{row.name}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="tableText">{row.type}</Typography>
        </TableCell>
        <TableCell align="left">
          {status === 'Ready' ? (
            <LinearProgress
              classes={{
                colorPrimary: classes.progressReady,
                barColorPrimary: classes.progressBarReady,
              }}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={progress}
              color="primary"
              classes={{
                colorPrimary: classes.progressPrimaryNormal,
                barColorPrimary: classes.progressBarNormal,
              }}
            />
          )}
        </TableCell>
        <TableCell align="center">
          <Typography variant="tableText">{status}</Typography>
        </TableCell>
        <TableCell align="right">
          {props.editOnly && (
            <Button
              variant="red"
              onClick={() => {
                onDelete(row.batch);
              }}
            >
              <DeleteOutlineIcon />
            </Button>
          )}
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: '#F1F7FE' }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" sx={{ padding: '40px 0 40px 40px' }}>
              <Box flexGrow={1}>
                <Table
                  size="small"
                  aria-label="items"
                  className={classes.itemTable}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Typography variant="tableText">SKU</Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        <Typography variant="tableText">Name</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((item, index) => (
                      <TableRow key={`running_sub_${index}`}>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                          <Typography
                            variant={
                              !item.error ? 'tableText' : 'tableErrorText'
                            }
                          >
                            {item.sku}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                          <Typography
                            variant={
                              !item.error ? 'tableText' : 'tableErrorText'
                            }
                          >
                            {item.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ width: '200px' }}></Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function Uploads(props) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteBatch, setDeleteBatch] = useState('');

  const db = props.firebase.getdb();

  useEffect(() => {
    getUploads(db, fetchJobs);
  }, []);

  const fetchJobs = (items) => {
    console.log('Fetch Jobs');
    setJobs([...items]);
    setLoading(false);
  };

  const handleDeleteRow = (batchId) => {
    console.log('Delete Batch: ', batchId);
    setDeleteBatch(batchId);
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = () => {
    console.log('Modal Delete Batch: ', deleteBatch);
    const activeJob = _.find(jobs, { batch: deleteBatch });

    if (activeJob) {
      console.log('Delete Custom Batch: ', activeJob.id);
      deleteUploads(db, activeJob.id);
    }

    setDeleteModal(false);
  };

  const renderDeleteModal = () => (
    <ConfirmDialog
      label="delete"
      title="Delete"
      open={deleteModal}
      onClose={handleCloseDeleteModal}
      onConfirm={handleDelete}
    >
      <Typography variant="body2" gutterBottom>
        Do you want to remove current batch?
      </Typography>
    </ConfirmDialog>
  );

  const classes = useStyles();
  const runninginfo = calculateProducts(jobs);

  return (
    <Layout page={props.page} user={props.user}>
      <div className="pageHeader">
        <div className="pageHeading">
          <Typography variant="h1">Currently Uploading</Typography>
          <Typography variant="subtitle1">
            Running &nbsp;<strong>{runninginfo.total}</strong> orders,{' '}
            <strong>{runninginfo.products}</strong> products
          </Typography>
        </div>
      </div>

      {loading === false && jobs.length === 0 ? (
        <Box sx={{ marginTop: '40px', minHeight: '150px' }}>
          <Typography variant="tableText">
            No items currently uploading{' '}
          </Typography>
        </Box>
      ) : (
        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={false}
              cells={headCells}
            />
            <TableBody>
              {jobs.map((row, index) => {
                const odd = index % 2 === 0;
                const labelId = `uploading-table-${index}`;
                const keyId = `batch-${row.id}`;
                row.odd = odd;
                return (
                  <Row
                    {...props}
                    key={keyId}
                    db={db}
                    label={labelId}
                    row={row}
                    classes={classes}
                    viewOnly={props.viewOnly}
                    editOnly={props.editOnly}
                    onDelete={handleDeleteRow}
                  />
                );
              })}

              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <History {...props} />

      {renderDeleteModal()}
    </Layout>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Uploads);
