import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Modal,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Layout from '../Page';
import { withAuthorization } from '../Session';
import {
  addMixMatchCollection,
  deleteMixMatchCollection,
  getCollections,
  seeIfCollectionIsDuplicate,
  updateMixMatchCollection,
} from '../../db/mixMatchCollections';
import { Toast } from '../Toast';

const MixAndMatchCMS = (props) => {
  const db = props.firebase.getdb();
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});
  const [collections, setCollections] = useState({});
  const [newCollectionName, setNewCollectionName] = useState('');
  const [collectionTitle, setCollectionTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [skuList, setSkuList] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [subCollections, setSubCollections] = useState([]);
  const [doesHaveSubCollections, setDoesHaveSubCollections] = useState(false);

  useEffect(() => {
    const fetchCollections = async () => {
      const fetchedCollections = await getCollections(db);
      const newCollections = {};
      fetchedCollections.forEach((collection) => {
        newCollections[collection.collection] = {
          skus: collection.skus,
          subCollections: collection.subCollections || [],
          title: collection.title,
        };
      });
      setCollections(newCollections);
    };

    fetchCollections();
  }, [loading, showToast]);

  const handleOpenModal = (editMode = false, collectionName = '', title) => {
    setCollectionTitle(title);
    setIsEditMode(editMode);
    setSelectedCollection(collectionName);
    setNewCollectionName(editMode ? collectionName : '');
    if (editMode) {
      const collection = collections[collectionName];
      setSkuList(collection.skus);
      setSubCollections(collection.subCollections);
      setDoesHaveSubCollections(collection.subCollections.length > 0);
    } else {
      setSkuList([]);
      setSubCollections([]);
      setDoesHaveSubCollections(false);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSkuList([]);
    setNewCollectionName('');
    setDoesHaveSubCollections(false);
    setSubCollections([]);
    setIsEditMode(false);
    setCollectionTitle('');
  };

  const handleAddSku = () => {
    setSkuList([...skuList, '']);
  };

  const handleDeleteSku = (index) => {
    const newSkuList = skuList.filter((_, i) => i !== index);
    setSkuList(newSkuList);
  };

  const handleSkuChange = (index, value) => {
    const newSkuList = skuList.map((sku, i) => (i === index ? value : sku));
    setSkuList(newSkuList);
  };

  const handleAddSubCollection = () => {
    setSubCollections([...subCollections, { name: '', skus: [''] }]);
  };

  const handleDeleteSubCollection = (index) => {
    const newSubCollections = subCollections.filter((_, i) => i !== index);
    setSubCollections(newSubCollections);
  };

  const handleSubCollectionChange = (index, key, value) => {
    const newSubCollections = subCollections.map((subCollection, i) =>
      i === index ? { ...subCollection, [key]: value } : subCollection,
    );
    setSubCollections(newSubCollections);
  };

  const handleSubCollectionSkuChange = (subIndex, skuIndex, value) => {
    const newSubCollections = subCollections.map((subCollection, i) =>
      i === subIndex
        ? {
            ...subCollection,
            skus: subCollection.skus.map((sku, j) =>
              j === skuIndex ? value : sku,
            ),
          }
        : subCollection,
    );
    setSubCollections(newSubCollections);
  };

  const handleAddSubCollectionSku = (index) => {
    const newSubCollections = subCollections.map((subCollection, i) =>
      i === index
        ? { ...subCollection, skus: [...subCollection.skus, ''] }
        : subCollection,
    );
    setSubCollections(newSubCollections);
  };

  const handleDeleteSubCollectionSku = (subIndex, skuIndex) => {
    const newSubCollections = subCollections.map((subCollection, i) =>
      i === subIndex
        ? {
            ...subCollection,
            skus: subCollection.skus.filter((_, j) => j !== skuIndex),
          }
        : subCollection,
    );
    setSubCollections(newSubCollections);
  };

  const handleSubmitCollection = async () => {
    try {
      const name = newCollectionName.trim();

      if (name === '') {
        setToastData({
          message: 'Collection name cannot be empty.',
          isError: true,
        });
        setShowToast(true);
        return;
      }

      if (doesHaveSubCollections) {
        if (subCollections.some((sub) => sub.name.trim() === '')) {
          setToastData({
            message: 'Sub-collection names cannot be empty.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (
          subCollections.some((sub) =>
            sub.skus.some((sku) => sku.trim() === ''),
          )
        ) {
          setToastData({
            message: 'Sub-collection SKUs cannot be empty.',
            isError: true,
          });
          setShowToast(true);
          return;
        }
      } else {
        const originalList = skuList.map((sku) => sku.trim());
        const filteredSkuList = originalList.filter((sku) => sku !== '');
        const uniqueSkuList = [...new Set(filteredSkuList)];

        if (uniqueSkuList.length !== filteredSkuList.length) {
          setToastData({
            message: 'Duplicate SKUs found. Removing duplicates.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (uniqueSkuList.length === 0) {
          setToastData({
            message: 'No SKUs found. Please add SKUs.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (originalList.some((sku) => sku === '')) {
          setToastData({
            message: 'Empty SKU found. Please remove empty SKUs.',
            isError: true,
          });
          setShowToast(true);
          return;
        }
      }

      const collectionData = {
        collection: name,
        title: collectionTitle,
        doesHaveSubCollections,
        skus: doesHaveSubCollections ? [] : skuList,
        subCollections: doesHaveSubCollections ? subCollections : [],
      };

      if (isEditMode) {
        await updateMixMatchCollection(db, collectionData);
        setToastData({
          message: 'Collection updated successfully',
          isError: false,
        });
      } else {
        const didFindDuplicate = await seeIfCollectionIsDuplicate(db, name);
        if (didFindDuplicate) {
          setToastData({
            message: 'Collection name already exists.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        await addMixMatchCollection(db, collectionData);
        setToastData({
          message: 'Collection added successfully',
          isError: false,
        });
      }

      setShowToast(true);

      handleCloseModal();
    } catch (error) {
      console.log('Error handleSubmitCollection: ', error);
      setToastData({
        message: 'Error submitting collection',
        isError: true,
      });
      setShowToast(true);
      handleCloseModal();
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCollection = async () => {
    try {
      const doWantToDelete = window.confirm(
        `Are you sure you want to delete the collection ${selectedCollection}?`,
      );
      if (!doWantToDelete) {
        return;
      }
      await deleteMixMatchCollection(db, selectedCollection);
      setToastData({
        message: 'Collection deleted successfully',
        isError: false,
      });
      setShowToast(true);
      handleCloseModal();
    } catch (error) {
      console.log('Error handleDeleteCollection: ', error);
      setToastData({
        message: 'Error deleting collection',
        isError: true,
      });
      setShowToast(true);
      handleCloseModal();
    }
  };

  const updateMixMatch = async () => {
    try {
      const name = newCollectionName.trim();

      if (name === '') {
        setToastData({
          message: 'Collection name cannot be empty.',
          isError: true,
        });
        setShowToast(true);
        return;
      }

      if (doesHaveSubCollections) {
        if (subCollections.some((sub) => sub.name.trim() === '')) {
          setToastData({
            message: 'Sub-collection names cannot be empty.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (
          subCollections.some((sub) =>
            sub.skus.some((sku) => sku.trim() === ''),
          )
        ) {
          setToastData({
            message: 'Sub-collection SKUs cannot be empty.',
            isError: true,
          });
          setShowToast(true);
          return;
        }
      } else {
        const originalList = skuList.map((sku) => sku.trim());
        const filteredSkuList = originalList.filter((sku) => sku !== '');
        const uniqueSkuList = [...new Set(filteredSkuList)];

        if (uniqueSkuList.length !== filteredSkuList.length) {
          setToastData({
            message: 'Duplicate SKUs found. Removing duplicates.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (uniqueSkuList.length === 0) {
          setToastData({
            message: 'No SKUs found. Please add SKUs.',
            isError: true,
          });
          setShowToast(true);
          return;
        }

        if (originalList.some((sku) => sku === '')) {
          setToastData({
            message: 'Empty SKU found. Please remove empty SKUs.',
            isError: true,
          });
          setShowToast(true);
          return;
        }
      }

      const collectionData = {
        collection: name,
        title: collectionTitle,
        doesHaveSubCollections,
        skus: doesHaveSubCollections ? [] : skuList,
        subCollections: doesHaveSubCollections ? subCollections : [],
      };

      await updateMixMatchCollection(db, collectionData);
      setToastData({
        message: 'Collection updated successfully',
        isError: false,
      });
      setShowToast(true);
    } catch (error) {
      console.log('Error updateMixMatch: ', error);
      setToastData({
        message: 'Error updating collection',
        isError: true,
      });
      setShowToast(true);
      handleCloseModal();
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(skuList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSkuList(items);
  };

  const onSubCollectionSkuDragEnd = (subIndex) => (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(subCollections[subIndex].skus);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const newSubCollections = subCollections.map((subCollection, i) =>
      i === subIndex ? { ...subCollection, skus: items } : subCollection,
    );
    setSubCollections(newSubCollections);
  };

  return (
    <Layout page="mixandmatch" user={props.user}>
      <Box sx={{ padding: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleOpenModal()}
        >
          Add Collection
        </Button>
        {Object.keys(collections).length === 0 ? (
          <Typography>
            No collections found. Please add a new collection.
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
            {Object.keys(collections).map((collection) => (
              <Card
                key={collection}
                sx={{
                  width: 300,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
                onClick={() =>
                  handleOpenModal(
                    true,
                    collection,
                    collections[collection]?.title ?? '',
                  )
                }
              >
                <CardContent>
                  <Typography variant="h6">{collection}</Typography>
                  {collections[collection].subCollections.length > 0 && (
                    <Typography variant="body2">
                      {collections[collection].subCollections.length}{' '}
                      Sub-Collections
                    </Typography>
                  )}
                  {collections[collection].skus.length > 0 && (
                    <Typography variant="body2">
                      {collections[collection].skus.length} SKUs
                    </Typography>
                  )}
                </CardContent>
              </Card>
            ))}
          </Box>
        )}
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        {/* <Modal open={true} onClose={handleCloseModal}> */}
        {loading ? (
          <Box
            sx={{
              width: 400,
              margin: 'auto',
              marginTop: '10%',
              padding: 2,
              backgroundColor: 'white',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '80vh',
              overflowY: 'auto',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
            <Typography variant="h6" gutterBottom>
              Loading...
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              width: 800,
              margin: 'auto',
              marginTop: '10%',
              padding: 2,
              backgroundColor: 'white',
              borderRadius: 2,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '80vh',
              overflowY: 'auto',
              height: '50vh',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">
                {isEditMode
                  ? `Edit SKUs for ${selectedCollection}`
                  : 'Create a Collection'}
              </Typography>
              {!isEditMode && (
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" marginRight={2}>
                    Does this collection have sub-collections?
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      setDoesHaveSubCollections(!doesHaveSubCollections)
                    }
                  >
                    {doesHaveSubCollections ? 'Yes' : 'No'}
                  </Button>
                </Box>
              )}
              {/* X button to close*/}
              <IconButton onClick={handleCloseModal}>X</IconButton>
            </Box>

            {/* add a sub collectiuon */}
            <Box display="flex" alignItems="center">
              <TextField
                label="Collection Name"
                fullWidth
                value={newCollectionName}
                onChange={(e) =>
                  setNewCollectionName(e.target.value.toLowerCase())
                }
                margin="normal"
                disabled={isEditMode}
                sx={{
                  width: !doesHaveSubCollections ? '100%' : '60%',
                }}
              />
              {doesHaveSubCollections && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleAddSubCollection()}
                  style={{
                    width: '40%',
                    height: '70%',
                    marginLeft: '1rem',
                    marginTop: '0.5rem',
                  }}
                >
                  Add Sub-Collection
                </Button>
              )}
            </Box>
            <Box marginBottom={1}>
              <TextField
                label="Collection Title"
                fullWidth
                value={collectionTitle}
                onChange={(e) => setCollectionTitle(e.target.value)}
                margin="normal"
              />
            </Box>

            {doesHaveSubCollections ? (
              <Box
                display="flex"
                flexDirection="column"
                sx={{ flexGrow: 1, overflowY: 'auto' }}
              >
                {subCollections.map((subCollection, subIndex) => (
                  <Box key={subIndex} display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center">
                      <IconButton
                        onClick={() => handleDeleteSubCollection(subIndex)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      <TextField
                        label={`Sub-Collection Name ${subIndex + 1}`}
                        fullWidth
                        value={subCollection.name}
                        onChange={(e) =>
                          handleSubCollectionChange(
                            subIndex,
                            'name',
                            e.target.value.toUpperCase(),
                          )
                        }
                        margin="normal"
                      />
                    </Box>
                    <DragDropContext
                      onDragEnd={onSubCollectionSkuDragEnd(subIndex)}
                    >
                      <Droppable droppableId={`sub-skus-${subIndex}`}>
                        {(provided) => (
                          <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {subCollection.skus.map((sku, skuIndex) => (
                              <Draggable
                                key={skuIndex}
                                draggableId={`${subIndex}-${skuIndex}`}
                                index={skuIndex}
                              >
                                {(provided) => (
                                  <Box
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    display="flex"
                                    alignItems="center"
                                    margin="normal"
                                  >
                                    <Box>
                                      <span
                                        style={{
                                          cursor: 'grab',
                                          padding: '8px',
                                        }}
                                      >
                                        ⠿
                                      </span>
                                    </Box>
                                    <TextField
                                      label={`SKU ${skuIndex + 1}`}
                                      fullWidth
                                      value={sku}
                                      onChange={(e) =>
                                        handleSubCollectionSkuChange(
                                          subIndex,
                                          skuIndex,
                                          e.target.value.toUpperCase(),
                                        )
                                      }
                                      margin="normal"
                                    />
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteSubCollectionSku(
                                          subIndex,
                                          skuIndex,
                                        )
                                      }
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </Box>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </Box>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleAddSubCollectionSku(subIndex)}
                      style={{ marginTop: '1rem' }}
                    >
                      Add SKU to Sub-Collection
                    </Button>
                  </Box>
                ))}
              </Box>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="skus">
                  {(provided) => (
                    <Box
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      sx={{ flexGrow: 1, overflowY: 'auto' }}
                    >
                      {skuList.map((sku, index) => (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              display="flex"
                              alignItems="center"
                              margin="normal"
                            >
                              <Box>
                                <span
                                  style={{ cursor: 'grab', padding: '8px' }}
                                >
                                  ⠿
                                </span>
                              </Box>
                              <TextField
                                label={`SKU ${index + 1}`}
                                fullWidth
                                value={sku}
                                onChange={(e) =>
                                  handleSkuChange(
                                    index,
                                    e.target.value.toUpperCase(),
                                  )
                                }
                                margin="normal"
                              />
                              <IconButton
                                onClick={() => handleDeleteSku(index)}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            <Box display="flex" width="100%" marginTop="1rem">
              <Button
                variant="outlined"
                color="primary"
                onClick={isEditMode ? updateMixMatch : handleSubmitCollection}
                style={{
                  width: doesHaveSubCollections ? '100%' : '50%',
                  marginRight: isEditMode ? '0.5rem' : '0',
                }}
              >
                {isEditMode ? 'Update Collection' : 'Submit Collection'}
              </Button>
              {!doesHaveSubCollections && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleAddSku()}
                  style={{ width: '50%', marginLeft: '0.5rem' }}
                >
                  Add SKU
                </Button>
              )}
              {isEditMode && (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleDeleteCollection()}
                  style={{ width: '50%', marginLeft: '0.5rem' }}
                >
                  Delete Collection
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Modal>

      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </Layout>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(MixAndMatchCMS);
