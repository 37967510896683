import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import JsBarcode from 'jsbarcode';
import { ref, getDownloadURL } from 'firebase/storage';

import { BARCODE_DIMENSION, BARCODE_PREFIX } from '../../constants/defines';

const useHeaderStyles = makeStyles((theme) => ({
  previewBox: {
    position: 'relative',
    paddingTop: `${(BARCODE_DIMENSION.height * 100.0) / BARCODE_DIMENSION.width}%`,
    border: '2px solid #000',
    boxShadow: '2px 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  previewCanvas: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

const getImage = (storage, path) =>
  new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, `${BARCODE_PREFIX}/${path}`))
      .then((url) => {
        const img = new Image();
        img.onload = function () {
          resolve({ image: img, path });
        };
        img.src = url;
        img.crossOrigin = 'anonymous';
      })
      .catch((error) => {
        console.log(error);
      });
  });

const getAllImages = async (storage, modules) => {
  const requests = _.map(modules, (item) => getImage(storage, item.image));

  const imgs = await Promise.all(requests);
  return imgs;
};

const BarcodeCanvas = (props) => {
  const { template, firebase } = props;
  const [loadingImages, setLoadingImages] = useState(false);
  const [images, setImages] = useState([]);

  const storage = firebase.getstorage();
  const canvasRef = useRef();
  const classes = useHeaderStyles();

  useEffect(() => {
    setLoadingImages(true);
  }, [template]);

  useEffect(() => {
    if (loadingImages) {
      loadImages();
    }
    drawCanvas();
  }, [loadingImages]);

  const loadImages = async () => {
    const modules = _.filter(template.modules, (item) => {
      if (item.type !== 'image' || item.image === '') {
        return false;
      }

      const img = _.find(images, { path: item.image });
      if (!img) {
        return true;
      }
      return img.image !== img.path;
    });

    if (modules.length > 0) {
      const _imgs = await getAllImages(storage, modules);
      setImages([..._imgs]);
    }

    setLoadingImages(false);
  };

  const drawCanvas = () => {
    const { current: canvas } = canvasRef;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    _.each(template.modules, (item) => {
      if (item.visible) {
        if (item.name === 'Barcode number') {
          drawBarcode(canvas, template.type, item);
        } else if (item.type === 'text') {
          drawText(canvas, item);
        } else if (item.type === 'image' && item.image !== '') {
          drawImage(canvas, item);
        }
      }
    });
  };

  const drawBarcode = (canvas, type, module) => {
    const ctx = canvas.getContext('2d');

    const barcode = document.createElement('canvas');
    barcode.width = module.pos.width;
    barcode.height = module.pos.height;
    const options = {
      format: type,
      fontSize: module.fontSize,
      font: 'monospace',
      textAlign: 'center',
      width: 4,
      height: module.pos.height,
      displayValue: true,
      textMargin: 0,
    };

    let text = '';
    if (type === 'pharmacode') {
      text = '1234';
    } else if (type === 'codabar') {
      text = '1234567890';
    } else if (type === 'CODE128') {
      text = 'Example1234';
    } else if (type === 'CODE39') {
      text = 'CODE39 Barcode';
    } else if (type === 'EAN13') {
      text = '5901234123457';
    } else if (type === 'UPC') {
      text = '123456789999';
    } else if (type === 'EAN8') {
      text = '96385074';
    } else if (type === 'EAN5') {
      text = '54495';
    } else if (type === 'EAN2') {
      text = '53';
    } else if (type === 'ITF14') {
      text = '12345678901231';
    } else if (type === 'MSI') {
      text = '1234';
    }

    JsBarcode(barcode, text, options);
    ctx.drawImage(
      barcode,
      module.pos.x,
      module.pos.y,
      module.pos.width,
      module.pos.height,
    );
  };

  const drawText = (canvas, module) => {
    const ctx = canvas.getContext('2d');

    ctx.font = `${module.fontWeight} ${module.fontSize}px Arial`;
    ctx.fillText(
      module.name === 'RN Number' ? `RN #: ${template.rnNumber}` : module.text,
      module.pos.x,
      module.pos.y,
    );
  };

  const drawImage = (canvas, module) => {
    const pos = module.pos;
    const ctx = canvas.getContext('2d');
    const img = _.find(images, { path: module.image });
    if (img) {
      ctx.drawImage(img.image, pos.x, pos.y, pos.width, pos.height);
    }
  };

  return (
    <Box className={classes.previewBox}>
      <canvas
        className={classes.previewCanvas}
        ref={canvasRef}
        width={BARCODE_DIMENSION.width}
        height={BARCODE_DIMENSION.height}
      />
    </Box>
  );
};

export { BarcodeCanvas };
