import { query, where, getDocs, collection } from 'firebase/firestore';

import { collections } from '../constants/defines';

const getBySkuBundleOrProduct = async (db, sku) => {
  try {
    const productsRef = collection(db, collections.PRODUCTS);
    const boxsetsRef = collection(db, collections.BOXSETS);

    // Step 1 & 2: Search PRODUCTS for `sku` or `variants`
    const productQueries = [
      query(productsRef, where('sku', '==', sku)),
      query(productsRef, where('variants', 'array-contains', sku)),
    ];

    // Step 3 & 4: Search BOXSETS for `sku` or `variants`
    const boxsetQueries = [
      query(boxsetsRef, where('sku', '==', sku)),
      query(boxsetsRef, where('variants', 'array-contains', sku)),
    ];

    // Execute all queries in parallel
    const queryResults = await Promise.all([
      ...productQueries.map((q) => getDocs(q)),
      ...boxsetQueries.map((q) => getDocs(q)),
    ]);

    // Check results for PRODUCTS
    for (const result of queryResults.slice(0, 2)) {
      if (!result.empty) {
        return result.docs[0].data(); // Return the first matching document
      }
    }

    // Check results for BOXSETS
    for (const result of queryResults.slice(2)) {
      if (!result.empty) {
        const bundle = result.docs[0].data();
        const items = await getBundleItems(db, bundle); // Resolve bundle items
        bundle.items = items;
        return bundle;
      }
    }

    // If nothing is found in any of the steps
    return { found: false, sku };
  } catch (error) {
    console.error('Error searching for SKU:', error);
    throw error;
  }
};

const getBundleItems = async (db, bundle) => {
  const items = await Promise.all(
    bundle.items.map((itemSku) => getBySkuBundleOrProduct(db, itemSku)),
  );
  return items;
};

export default getBySkuBundleOrProduct;
