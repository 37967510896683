import {
  Box,
  Typography,
  Divider,
  Modal,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { useState } from 'react';
import _ from 'lodash';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { CustomDateRangePicker } from '../Calendar';
import CalendarSVG from './Svgs/Calendar';
import DropdownArrowSVG from './Svgs/DropdownArrow';
import { API } from '../../constants/defines';
import {
  injectUnshippedGraphAnalytic,
  setShippedYesterdayAnalytics,
} from '../../redux/analytics/actions';
import GreenArrowSVG from './Svgs/GreenArrow';
import ClipboardSVG from './Svgs/Clipboard';
import CartSVG from './Svgs/Cart';

const formatYAxis = (tickItem) => {
  if (tickItem < 1000) {
    return tickItem;
  }
  return `${tickItem / 1000}k`;
};

const CustomTooltip = ({ active, payload, hoveredLine }) => {
  const formatKey = (key) => {
    if (key.startsWith('unshipped_')) {
      return `Unshipped ${key.replace('unshipped_', '')}`;
    }
    return key;
  };

  if (active && payload && payload.length) {
    const dateKey = payload[0].payload.dateKey;
    return (
      <Box sx={styles.tooltip}>
        <Typography
          sx={{
            fontSize: '16px',
          }}
        >
          {dateKey}
        </Typography>

        {payload.map((entry, index) => {
          const formattedValue = entry.value.toLocaleString();
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
                width: '100%',
              }}
              key={`item-${index}`}
            >
              <Typography
                key={`item-${index}`}
                sx={{
                  fontSize: '14px',
                  fontWeight: hoveredLine === entry.name ? 'bold' : 'normal',
                }}
              >
                {`${formatKey(entry.name)}`}
              </Typography>
              <Typography
                key={`item-${index}`}
                sx={{
                  fontSize: '14px',
                  fontWeight: hoveredLine === entry.name ? 'bold' : 'normal',
                  marginLeft: 'auto',
                }}
              >
                {formattedValue}
              </Typography>
              {hoveredLine === entry.name ? (
                <GreenArrowSVG />
              ) : (
                <Box sx={{ width: '24px', height: '24px' }} />
              )}
            </Box>
          );
        })}
      </Box>
    );
  }
  return null;
};

// Function to parse time in "XX AM/PM" format to a date object for a given base date
const parseTime = (timeString, baseDate) => {
  const [time, modifier] = timeString.split(' ');
  let [hours, minutes] = time.split(':');
  if (!minutes) {
    minutes = '00';
  }
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate(),
    hours,
    minutes,
  );
};

// Function to check if a time is in the future based on the provided base date
const isFutureTime = (timeString, baseDate) => {
  const itemTime = parseTime(timeString, baseDate);
  // console.log('itemTime', itemTime, 'new Date()', new Date() , itemTime > new Date());
  return itemTime > new Date();
};

// Function to check if a date is in the future
const isFutureDate = (dateString) => {
  const itemDate = new Date(dateString);
  return itemDate > new Date();
};

const isToday = (dateString) => {
  // Example input: "Jul 10 9:00 PM"
  const [monthPart, datePart] = dateString.split(' ');

  const monthMap = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const dateMonth = monthMap[monthPart];
  const dateDay = parseInt(datePart, 10);

  const now = new Date();
  const currentMonth = now.getMonth();
  const currentDay = now.getDate();

  return dateMonth === currentMonth && dateDay === currentDay;
};

// Function to sanitize your data for the graph
const sanitizeDataForGraph = (data, view = '') => {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  return data.map((item) => {
    let baseDate;

    if (view === 'today') {
      baseDate = today;
    } else if (view === 'yesterday') {
      baseDate = yesterday;
    } else if (view === 'hourly') {
      if (isToday(item.dateKey)) {
        console.log('Hit on today');
        baseDate = today;
      } else {
        baseDate = new Date(item.dateKey);
      }
    } else {
      baseDate = today; // Default to today for other views
    }

    if (['today', 'yesterday', 'hourly'].includes(view)) {
      if (isFutureTime(item.name, baseDate)) {
        return {
          ...item,
          DTC: null,
          'DTC Custom': null,
        };
      }
    } else {
      // Assuming item.name is a date string for non-time comparisons
      if (isFutureDate(item.dateKey)) {
        return {
          ...item,
          DTC: null,
          'DTC Custom': null,
        };
      }
    }

    return item;
  });
};

const ShipCard = ({
  isMobile,
  keyValue,
  data,
  dispatch,
  unshippedDataForGraph,
}) => {
  const [loadFetchedData, setLoadFetchedData] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [defaultRangeIdentifier, setDefaultRangeIdentifier] =
    useState('Last 30 Days');
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [rangeIdentifier, setRangeIdentifier] = React.useState(null);
  const [hoveredLine, setHoveredLine] = useState(null);
  const [alignment, setAlignment] = useState('left'); // Initial alignment state

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const runFunction = async () => {
    setLoadFetchedData(true);

    const splitSelectedDates = selectedDates.map(
      (date) => date.toISOString().split('T')[0],
    );

    let fetchGraphAnalyticsUrl = `${
      API.getShippedBydate
    }?startDate=${null}&endDate=${null}&lookingSpecificaly=${rangeIdentifier}`;

    const [startDate, endDate] = splitSelectedDates;
    fetchGraphAnalyticsUrl = `${
      API.getShippedBydate
    }?startDate=${startDate}&endDate=${
      endDate ? endDate : startDate
    }&lookingSpecificaly=${rangeIdentifier}`;

    try {
      const response = await fetch(fetchGraphAnalyticsUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      const data = await response.json();

      dispatch(setShippedYesterdayAnalytics(data));
      dispatch(injectUnshippedGraphAnalytic(data.unshipped));

      if (!rangeIdentifier) {
        setDefaultRangeIdentifier('Custom Range');
      }
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setOpenModal(false);
      setLoadFetchedData(false);
    }
  };

  const clonedData = _.cloneDeep(data);

  const clonedUnshipped = _.cloneDeep(unshippedDataForGraph);

  const awaitingShipment = clonedData.find(
    (unit) => unit.value === 'toBeShipped',
  );

  const shippedDataSet = clonedData.find((unit) => unit.value === 'shipped');

  const lineColors = {
    DTC: '#82ABFF',
    'DTC Custom': '#7BFDC9',
    Dropship: '#E3DAFF',
    'Dropship Custom': '#FFB1B6',
  };

  const sanitizedData = sanitizeDataForGraph(
    alignment === 'left'
      ? shippedDataSet.sanatizeForOrdersCount
      : shippedDataSet.sanatizeForGraph,
    shippedDataSet.chosenDateType,
  );

  const sanatizeUnshippedForGraph = sanitizeDataForGraph(
    alignment === 'left'
      ? clonedUnshipped.sanatizeUnshippedForOrdersCount
      : clonedUnshipped.sanatizeUnshippedForGraph,
    clonedUnshipped.chosenDateType,
  );

  const toBeShippedData =
    alignment === 'left'
      ? awaitingShipment.data.byOrders
      : awaitingShipment.data.byItems;

  const renderTypeOfData = alignment === 'left' ? '(Orders)' : '(Items)';

  const combineDataSets = (shipped, unshipped) =>
    shipped.map((item, index) => ({
      ...item,
      ...Object.keys(unshipped[index] || {}).reduce((acc, key) => {
        acc[`unshipped_${key}`] = unshipped[index][key];
        return acc;
      }, {}),
    }));

  const combinedData = combineDataSets(
    sanitizedData,
    sanatizeUnshippedForGraph,
  );

  const lineType = 'bump';

  const handleMouseEnter = (key) => {
    setHoveredLine(key);
  };

  const handleMouseLeave = () => {
    setHoveredLine(null);
  };

  return (
    <>
      <Box
        sx={{
          ...styles.card,
          width: isMobile ? '92%' : '100%',
        }}
        key={keyValue}
      >
        <Box sx={styles.unshipped}>
          <Typography
            fontWeight={500}
            sx={{
              fontSize: isMobile ? '18px' : '28px',
              paddingBottom: isMobile ? 1 : 0,
            }}
          >
            Unshipped {renderTypeOfData}
          </Typography>
          <Box
            sx={{
              ...styles.unshippedBoxes,
              padding: isMobile ? 0 : 1,
            }}
          >
            {toBeShippedData.map((order, index) => (
              <Box key={index} sx={styles.unshippedNumbers}>
                <Box sx={styles.textBox}>
                  <Typography variant="body1">
                    {order.value.toUpperCase()}
                  </Typography>
                  <Typography variant="h4" fontWeight={600}>
                    {order.totalOrders}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Date picker button */}
        <Box sx={styles.shipped}>
          {!isMobile ? (
            <Box sx={styles.titleAndButton}>
              <Typography
                fontWeight={500}
                sx={{
                  fontSize: isMobile ? '18px' : '28px',
                }}
              >
                Shipped {renderTypeOfData}
              </Typography>
              <Box sx={styles.buttonGroup}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  sx={{
                    border: '1px solid rgba(25, 118, 210, 0.5)',
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value="left" sx={styles.toggleButton}>
                    <ClipboardSVG
                      color={alignment !== 'left' ? '#113B8F' : '#ffffff'}
                    />
                  </ToggleButton>
                  <ToggleButton value="right" sx={styles.toggleButton}>
                    <CartSVG
                      color={alignment !== 'right' ? '#113B8F' : '#ffffff'}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenModal(true);
                    setDefaultRangeIdentifier('Last 30 Days');
                    setRangeIdentifier(null);
                  }}
                  sx={{ textTransform: 'none', padding: 1 }}
                  startIcon={<CalendarSVG />}
                  endIcon={<DropdownArrowSVG />}
                >
                  {defaultRangeIdentifier}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={styles.mobileTitleAndButton}>
              <Typography
                fontWeight={500}
                sx={{
                  fontSize: isMobile ? '18px' : '28px',
                  paddingBottom: 1,
                }}
              >
                Shipped
                {renderTypeOfData}
              </Typography>
              <Box sx={styles.mobileButtonGroup}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  sx={{
                    border: '1px solid rgba(25, 118, 210, 0.5)',
                    width: 'fit-content',
                  }}
                >
                  <ToggleButton value="left" sx={styles.toggleButton}>
                    <ClipboardSVG
                      color={alignment !== 'left' ? '#113B8F' : '#ffffff'}
                    />
                  </ToggleButton>
                  <ToggleButton value="right" sx={styles.toggleButton}>
                    <CartSVG
                      color={alignment !== 'right' ? '#113B8F' : '#ffffff'}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenModal(true);
                    setDefaultRangeIdentifier('Last 30 Days');
                    setRangeIdentifier(null);
                  }}
                  sx={{ textTransform: 'none', padding: 1 }}
                  startIcon={<CalendarSVG />}
                  endIcon={<DropdownArrowSVG />}
                >
                  {defaultRangeIdentifier}
                </Button>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              ...styles.legend,
              ...(isMobile && {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: 'auto auto',
                gap: 1.5, // Reduced gap for better spacing on mobile
                padding: 1, // Add padding to ensure space around content
              }),
            }}
          >
            {Object.entries(lineColors).map(([key, color], index) => (
              <Box
                key={key}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ...(isMobile && {
                    padding: 0.5, // Add padding around each legend item
                  }),
                }}
              >
                <Box
                  sx={{
                    ...styles.legendItem,
                    backgroundColor: color,
                    width: isMobile ? 14 : 16,
                    height: isMobile ? 14 : 16,
                  }}
                />
                <Typography
                  sx={{
                    ...styles.legendText,
                    fontSize: isMobile ? '0.75rem' : '1rem', // Smaller font size on mobile
                  }}
                >
                  {key}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <ResponsiveContainer
          width={isMobile ? 350 : '100%'}
          height={isMobile ? 200 : 400}
          style={{
            paddingRight: isMobile ? 30 : 0,
          }}
        >
          {shippedDataSet.sanatizeForGraph.length === 0 ? (
            <Box sx={styles.noDataAvailable}>
              <Typography variant="h6" fontWeight={500}>
                No Data Available
              </Typography>
            </Box>
          ) : (
            <LineChart data={combinedData}>
              <CartesianGrid
                stroke="#f5f5f5"
                horizontal={true}
                vertical={false}
              />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatYAxis} />
              <Tooltip content={<CustomTooltip hoveredLine={hoveredLine} />} />
              {Object.entries(lineColors).map(([key, color]) => (
                <Line
                  key={key}
                  type={lineType}
                  dataKey={key}
                  stroke={color}
                  strokeWidth={hoveredLine === key ? 6 : 4}
                  dot={false}
                  activeDot={{ r: 8 }}
                  opacity={
                    hoveredLine === null || hoveredLine === key ? 1 : 0.2
                  }
                  onMouseEnter={() => handleMouseEnter(key)}
                  onMouseLeave={handleMouseLeave}
                  legendType="rect"
                  hoverAnimation
                />
              ))}
              {Object.entries(lineColors).map(([key, color]) => (
                <Line
                  key={`unshipped_${key}`}
                  type={lineType}
                  dataKey={`unshipped_${key}`}
                  stroke={color}
                  strokeDasharray="5 5"
                  strokeWidth={hoveredLine === `unshipped_${key}` ? 6 : 4}
                  dot={false}
                  activeDot={{ r: 8 }}
                  opacity={
                    hoveredLine === null || hoveredLine === `unshipped_${key}`
                      ? 1
                      : 0.2
                  }
                  onMouseEnter={() => handleMouseEnter(`unshipped_${key}`)}
                  onMouseLeave={handleMouseLeave}
                  legendType="rect"
                  hoverAnimation
                />
              ))}
            </LineChart>
          )}
        </ResponsiveContainer>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={styles.datePickerModal}
      >
        <Box
          sx={{
            ...styles.datePickerBox,
            padding: isMobile ? 0 : 4,
          }}
        >
          <CustomDateRangePicker
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            setRangeIdentifier={setRangeIdentifier}
            functionToRun={runFunction}
            isMobile={isMobile}
            loadFetchedData={loadFetchedData}
            setOpenModal={setOpenModal}
            setDefaultRangeIdentifier={setDefaultRangeIdentifier}
          />
        </Box>
      </Modal>
    </>
  );
};

const styles = {
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
  },
  mobileButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
    justifyContent: 'space-between',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    width: '100%',
    padding: 2,
    borderRadius: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noDataAvailable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
  datePickerModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  datePickerBox: {
    backgroundColor: '#ffffff',
    borderRadius: 2,
    boxShadow: 24,
  },
  toggleButton: {
    textTransform: 'none',
    // padding: "8px 16px",
    '&.Mui-selected': {
      backgroundColor: '#3477F5',
      '&:hover': {
        backgroundColor: '#3477F5',
      },
    },
  },
  titleAndButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 2,
  },
  mobileTitleAndButton: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    width: '100%',
    marginBottom: 2,
  },
  legendItem: {
    width: 20,
    height: 20,
    borderRadius: 1,
  },
  legendText: {
    marginLeft: 1,
  },
  shipped: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    paddingTop: 2,
  },
  unshipped: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    paddingY: 2,
    borderBottom: '1px solid #DEDEDE',
  },
  unshippedBoxes: {
    display: 'flex',
    flexDirection: 'row',
    padding: 1,
    gap: 2,
  },
  unshippedNumbers: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D6E4FF80',
    borderRadius: 2,
    width: 200,
    justifyContent: 'center',
    padding: 2,
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  tooltip: {
    position: 'relative',
    backgroundColor: '#ffffff',
    padding: 2,
    borderRadius: 4,
    width: 250,
    boxShadow: '10px 10px 10px 6px rgba(0, 0, 0, 0.25)',
  },
};

export default ShipCard;
