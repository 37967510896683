import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    fontSize: 13,
    h1: {
      fontSize: 32,
      fontWeight: 500,
      marginBottom: '15px',
    },
    h2: {
      fontSize: 28,
      fontWeight: 500,
      marginBottom: '5px',
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      color: '#000000',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
    },
    tableText: {
      fontSize: 14,
      fontWeight: 500,
      color: '#000000',
      letterSpacing: '1px',
    },
    tableErrorText: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#E0491B',
      letterSpacing: '1px',
    },
    tableGreenText: {
      fontSize: 14,
      fontWeight: 500,
      color: '#14B779',
      letterSpacing: '1px',
    },
    errorText: {
      fontSize: 14,
      color: '#f50057',
      fontStyle: 'italic',
    },
    inputLabel: {
      fontSize: 14,
      fontWeight: 500,
      color: '#000000',
      letterSpacing: '1px',
      marginBottom: '3px',
    },
  },
  button: {
    fontFamily: 'Poppins',
    filterText: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '1px',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: '#000000',
          fontSize: 14,
          letterSpacing: '1px',
          '&:hover': {
            opacity: 0.8,
          },
        },
      },
      variants: [
        {
          props: { variant: 'link' },
          style: {
            fontSize: 16,
            fontWeight: 500,
            color: '#000000',
            padding: '0 0',
            textTransform: 'initial',
            textDecoration: 'none',
            '&:hover': {
              background: 'transparent',
              textDecoration: 'underline',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'blue' },
          style: {
            padding: '8px 20px',
            background: '#1F7CF2',
            fontSize: 16,
            color: '#ffffff',
            borderRadius: 6,
            textTransform: 'initial',
            '&:hover': {
              background: '#1F7CF2',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'black' },
          style: {
            padding: '8px 20px',
            background: '#3D3D3D',
            fontSize: 16,
            color: '#ffffff',
            borderRadius: 6,
            textTransform: 'initial',
            '&:hover': {
              background: '#3D3D3D',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'green' },
          style: {
            padding: '8px 20px',
            background: '#14B779',
            fontSize: 16,
            color: '#ffffff',
            borderRadius: 6,
            textTransform: 'initial',
            '&:hover': {
              background: '#14B779',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'grey' },
          style: {
            padding: '8px 20px',
            background: '#EEE',
            fontSize: 16,
            color: '#ffffff',
            borderRadius: 6,
            textTransform: 'initial',
            '&:hover': {
              background: '#EEE',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'red' },
          style: {
            padding: '8px 20px',
            background: '#FF0057',
            fontSize: 16,
            color: '#ffffff',
            borderRadius: 6,
            textTransform: 'initial',
            '&:hover': {
              background: '#FF0057',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'dashed', color: 'secondary' },
          style: {},
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        // Name of the slot
        root: {},
      },
      variants: [
        {
          props: { variant: 'close' },
          style: {
            position: 'absolute',
            right: '10px',
            top: '10px',
          },
        },
        {
          props: { variant: 'arrow' },
          style: {
            position: 'absolute',
            left: '-30px',
            padding: '0',
          },
        },
        {
          props: { variant: 'red' },
          style: {
            padding: '10px 20px',
            background: '#e0491b',
            fontSize: 16,
            color: '#ffffff',
            fontWeight: 500,
            borderRadius: 0,
            '&:hover': {
              opacity: 0.8,
              background: '#e0491b',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
        {
          props: { variant: 'green' },
          style: {
            padding: '10px 20px',
            background: '#12B275',
            fontSize: 16,
            color: '#ffffff',
            fontWeight: 500,
            borderRadius: 0,
            '&:hover': {
              opacity: 0.8,
              background: '#12B275',
            },
            '&.Mui-disabled': {
              background: 'rgba(0, 0, 0, 0.12)',
              color: 'rgba(0, 0, 0, 0.26)',
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          color: '#666',
          fontFamily: 'Poppins',
          fontWeight: 500,
          fontSize: 14,
          letterSpacing: '1px',
        },
      },
    },
    MuiCssBaseline: {
      body: {
        fontFamily: 'Poppins',
      },
    },
  },
});

export default theme;
