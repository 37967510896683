// immer is a library that allows you to work with immutable state in a more convenient way. It allows you to write code that looks like you’re mutating the state, but it’s actually producing a new state. It’s a great way to keep your reducers clean and easy to read.
import { produce } from 'immer';

import {
  FETCHED_HISTORY,
  FETCH_HISTORY_ERROR,
  START_FETCH_HISTORY,
  PROCESS_ROW_DATA,
  UPDATE_PAGE_NUMBER,
  DELETE_HISTORY_DOC,
  INJECT_ONE_ROW_DATA_HISTORY,
  INJECT_ONE_ITEM_HISTORY,
  UPDATE_STATE_FOR_FETCH_RAN_DOC,
  RESET_STATE_FOR_FETCH_RAN_DOC,
  REPLACE_HISTORY_DOC,
  UPDATE_SINGLE_ITEM_BY_ID,
  REPLACE_ONE_ROW_DATA_HISTORY,
  UPDATE_LOADING,
  SET_DOWNLOAD_STATUS,
  CHANGED_PAGE,
  UPDATE_DOCS_WHEN_CHANGED_SHOW,
  USE_SEARCH,
  FILTER_HISTORY,
  RESET_STATE_HISTORY,
} from './types';
import { useSearchKeyWords } from '../../utils/searchByKeyWord';

const initialState = {
  historyDocs: [],
  initialState: [],
  rowData: [],
  isLoading: true,
  error: null,
  pageData: {},
  responseFetchedRanDoc: false,
  filteredData: [],
  filtersUsed: {
    source: '',
  },
  searchedLast: '',
};

export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCH_HISTORY:
      return { ...state, isLoading: true, error: null };
    case FETCHED_HISTORY:
      return {
        ...state,
        isLoading: false,
        historyDocs: action.payload.products,
        pageData: action.payload.pageData,
        initialState: action.payload.products,
      };
    case FETCH_HISTORY_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case PROCESS_ROW_DATA:
      return { ...state, isLoading: false, rowData: action.payload };
    case UPDATE_PAGE_NUMBER:
      return { ...state, isLoading: false, pageData: action.payload };
    case DELETE_HISTORY_DOC:
      return produce(state, (draftState) => {
        draftState.historyDocs = draftState.historyDocs.filter(
          (item) => item.batchId !== action.payload,
        );
      });
    case INJECT_ONE_ROW_DATA_HISTORY:
      return {
        ...state,
        rowData: [...state.rowData, action.payload],
      };
    case INJECT_ONE_ITEM_HISTORY:
      return produce(state, (draftState) => {
        draftState.historyDocs.push(action.payload);
        draftState.historyDocs.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
      });
    case UPDATE_STATE_FOR_FETCH_RAN_DOC:
      return {
        ...state,
        responseFetchedRanDoc: action.payload,
      };
    case RESET_STATE_FOR_FETCH_RAN_DOC:
      return {
        ...state,
        responseFetchedRanDoc: false,
      };
    case REPLACE_HISTORY_DOC:
      return produce(state, (draftState) => {
        const index = draftState.historyDocs.findIndex(
          (item) => item.batchId === action.payload.batchId,
        );
        if (index !== -1) {
          draftState.historyDocs[index] = action.payload;
        }
      });

    case REPLACE_ONE_ROW_DATA_HISTORY:
      const updatedRowData = state.rowData.map((item) => {
        if (item.data.batchId === action.payload.data.batchId) {
          return action.payload; // Ensure this is the correct, updated item
        }
        return item;
      });

      return {
        ...state,
        rowData: updatedRowData,
      };
    case UPDATE_SINGLE_ITEM_BY_ID:
      const updatedHistoryDocs = state.historyDocs.map((item) => {
        if (item.batchId === action.payload.batchId) {
          return action.payload;
        }
        return item;
      });

      return {
        ...state,
        historyDocs: updatedHistoryDocs,
      };

    case UPDATE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_DOWNLOAD_STATUS:
      const { batchId, downloaded } = action.payload;
      return {
        ...state,
        rowData: state.rowData.map((item) =>
          item.data.batchId === batchId
            ? { ...item, data: { ...item.data, downloaded } }
            : item,
        ),
      };
    case CHANGED_PAGE:
      const { rowsPerPage, pageNumber } = state.pageData;

      const start = (pageNumber - 1) * rowsPerPage;

      const end = pageNumber * rowsPerPage;

      const dataSetToUse = state.initialState;

      const nextPageData = dataSetToUse.slice(start, end);

      if (state.filtersUsed.source !== '') {
        const filteredData = state.filteredData.filter(
          (item) => item.source === state.filtersUsed.source,
        );

        const filteredPages = Math.ceil(filteredData.length / rowsPerPage);

        const nextPageFilteredData = filteredData.slice(start, end);

        return {
          ...state,
          historyDocs: nextPageFilteredData,
          pageData: {
            ...state.pageData,
            currentPageNumber: pageNumber,
            pages: filteredPages,
          },
        };
      }

      return {
        ...state,
        historyDocs: nextPageData,
      };

    case UPDATE_DOCS_WHEN_CHANGED_SHOW:
      const { updatedRowsPerPage } = action.payload;

      // update number of pages based on the new rowsPerPage
      const pages = Math.ceil(
        state.initialState.length / updatedRowsPerPage.value,
      );

      const setThisNewArrayForDocs = state.initialState.slice(
        0,
        updatedRowsPerPage.value,
      );

      if (state.filtersUsed.source !== '' || state.searchedLast !== '') {
        const { source } = state.filtersUsed;

        const filteredData =
          source !== ''
            ? state.filteredData.filter((item) => item.source === source)
            : state.filteredData;

        const filteredPages = Math.ceil(
          filteredData.length / updatedRowsPerPage.value,
        );

        return {
          ...state,
          historyDocs: filteredData.slice(0, updatedRowsPerPage.value),
          pageData: {
            ...state.pageData,
            rowsPerPage: updatedRowsPerPage.value,
            pages: filteredPages,
          },
        };
      }

      return {
        ...state,
        historyDocs: setThisNewArrayForDocs,
        pageData: {
          ...state.pageData,
          rowsPerPage: updatedRowsPerPage.value,
          pages,
        },
      };
    case USE_SEARCH:
      const { searchKeys } = action.payload;

      let data;

      const rowsPerPageSearch = state.pageData.rowsPerPage;

      // Handle the search keys being empty
      if (searchKeys === '') {
        if (
          state.filtersUsed.source !== '' ||
          state.pageData.rowsPerPage !== 25
        ) {
          data = state.initialState.filter(
            (item) => item.source === state.filtersUsed.source,
          );
          return {
            ...state,
            historyDocs: data.slice(0, rowsPerPageSearch),
            pageData: {
              ...state.pageData,
              currentPageNumber: 1,
              pages: Math.ceil(data.length / rowsPerPageSearch),
            },
            searchedLast: searchKeys,
          };
        } else {
          data = state.initialState.slice(0, rowsPerPageSearch);
        }
      } else {
        // Determine if a filter was previously used
        const filterUsed = state.filtersUsed.source;
        const sourceData =
          filterUsed !== ''
            ? state.initialState.filter((item) => item.source === filterUsed)
            : state.initialState;

        data = useSearchKeyWords(searchKeys, sourceData);
      }

      return {
        ...state,
        historyDocs: data.slice(0, rowsPerPageSearch),
        filteredData: data,
        pageData: {
          ...state.pageData,
          currentPageNumber: 1,
          pages:
            searchKeys === ''
              ? Math.ceil(state.initialState.length / rowsPerPageSearch)
              : Math.ceil(data.length / rowsPerPageSearch),
        },
        searchedLast: searchKeys,
      };

    case FILTER_HISTORY:
      const { filterData } = action.payload;

      const valueInFilter = filterData.value;

      if (valueInFilter === '') {
        return {
          ...state,
          historyDocs: state.initialState.slice(0, state.pageData.rowsPerPage),
          filtersUsed: {
            source: '',
          },
          filteredData: state.initialState,
          pageData: {
            ...state.pageData,
            currentPageNumber: 1,
            pages: Math.ceil(
              state.initialState.length / state.pageData.rowsPerPage,
            ),
          },
        };
      }

      const filteredData = state.initialState.filter(
        (item) => item.source === valueInFilter,
      );

      // handle pageData for the filtered data

      const filteredPages = Math.ceil(
        filteredData.length / state.pageData.rowsPerPage,
      );

      return {
        ...state,
        historyDocs: filteredData.slice(0, state.pageData.rowsPerPage),
        filtersUsed: {
          source: valueInFilter,
        },
        filteredData: filteredData,
        pageData: {
          ...state.pageData,
          currentPageNumber: 1,
          pages: filteredPages,
        },
      };

    case RESET_STATE_HISTORY:
      const resetState = {
        ...state,
        historyDocs: state.initialState.slice(0, 25),
        filtersUsed: {
          source: '',
        },
        filteredData: state.initialState,
        pageData: {
          ...state.pageData,
          currentPageNumber: 1,
          rowsPerPage: 25,
          pages: Math.ceil(state.initialState.length / 25),
        },
        loading: false,
      };

      return resetState;

    default:
      return state;
  }
}
