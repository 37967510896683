import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Pagination from '@mui/material/Pagination';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Papa from 'papaparse';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import { FilterProvider } from './filterContext';
import useStyles from './Inventory.styles';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import InventoryDrawer from './inventoryDrawer';
import { ConfirmDialog } from '../Page/generalModal';
import InventoryModal from './InventoryModal';
import {
  getInvetoryUnits,
  deleteInventory,
  addBulkProducts,
  exportProducts,
  getInventoryProducts,
  getInventoryBoxsets,
  getInventoryVariantProducts,
  getInventoryLocations,
} from '../../db/inventory';
import { EnhancedTableHead, getComparator, stableSort } from '../Page/table';
import InventoryGridItem from './inventoryGridItem';
import { getTemplates } from '../../db/templates';
import RenderLocationList from './locations';

const filterShows = [
  { id: 5, name: '50', value: 50 },
  { id: 10, name: '100', value: 10 },
  { id: 20, name: '200', value: 20 },
];

const filterSorts = [
  { id: 1, name: 'Name', value: 'name' },
  { id: 2, name: 'Most Recent', value: 'createdAt' },
];

const headCells = [
  {
    id: 'sku',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'SKU',
    width: '300px',
  },
  {
    id: 'name',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'NAME',
    width: 'auto',
  },
  {
    id: 'location',
    numeric: false,
    align: 'left',
    enableSort: true,
    disablePadding: false,
    label: 'LOCATION',
    width: 'auto',
  },
  {
    id: 'qty',
    numeric: true,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'QTY',
    width: '60px',
  },
  {
    id: 'edit',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: '',
    width: '120px',
  },
  {
    id: 'delete',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: '',
    width: '80px',
  },
];

let productItems = [],
  boxsetItems = [],
  variantItems = [],
  nonItems = [];

const Inventory = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [exporting, setExporting] = useState(false);
  const [filterShow, setFilterShow] = useState(5);
  const [filterSort, setFilterSort] = useState(1);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selected, setSelected] = useState('');
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [csvFile, setCSVFile] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [cropSettings, setCropSettings] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [inventoryLocations, setInventoryLocations] = useState([]);
  const [renderLocations, setRenderLocations] = useState(false);

  const classes = useStyles();
  const db = props.firebase.getdb();

  const sortAlphaNumeric = (locations) =>
    locations.sort((a, b) => {
      const parseValue = (str) => {
        // Split into alphabetic and numeric parts
        const match = str.match(/^([A-Z]+)(\d+)?$/i);
        if (!match) {
          return [str, Infinity];
        } // Handle "NA", "TBD", etc.
        const [, alpha, num] = match;
        return [alpha || '', parseInt(num, 10) || Infinity];
      };

      const [alphaA, numA] = parseValue(a);
      const [alphaB, numB] = parseValue(b);

      // Compare alphabetic part first, then numeric part
      if (alphaA < alphaB) {
        return -1;
      }
      if (alphaA > alphaB) {
        return 1;
      }
      return numA - numB;
    });

  useEffect(() => {
    getTemplates(db, 150, fetchTemplatesData);
  }, []);

  useEffect(() => {
    const fetchInventoryLocations = (items) => {
      const mainDoc = items[0];
      const locations = sortAlphaNumeric(mainDoc.locations);
      setInventoryLocations([...locations]);
    };
    getInventoryLocations(db, fetchInventoryLocations);
  }, []);

  useEffect(() => {
    const sort = _.find(filterSorts, { id: filterSort }).value;
    getInvetoryUnits(db, sort, fetchData);
  }, [filterSort]);

  useEffect(() => {
    fetchCropSettings(props.cropSettings);
  }, [props.cropSettings]);

  const fetchTemplatesData = (items) => {
    setTemplates([...items]);
  };

  const fetchCropSettings = (items) => {
    setCropSettings([...items]);
  };

  const fetchData = (data) => {
    setItems([...data]);
    setLoading(false);
  };

  const handleUpload = (file) => {
    setCSVFile(file);
  };

  const handleExport = () => {
    setExporting(true);
    const sort = _.find(filterSorts, { id: filterSort }).value;
    exportProducts(db, sort, handleCompleteExport);
  };

  const handleCompleteExport = (count) => {
    setExporting(false);
  };

  const handleSearch = (event) => {
    // if user presses enter or clears the box, process the search
    if (event.keyCode === 13 || event.target.value.length === 0) {
      setSearchKey(event.target.value.toLowerCase());
    }
  };

  const handleAddProduct = () => {
    setOpenDrawer(true);
  };

  const handleChangeShow = (id) => {
    // use this to bind the drop-down
    setFilterShow(id);
  };

  const handleChangeSort = (id) => {
    setFilterSort(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(open);
  };

  const handleAddProducts = () => {
    setOpenDrawer(false);
  };

  const handleEdit = (event, id) => {
    setSelected(id);
    setOpenEditModal(true);
  };

  const handleRemove = (event, id) => {
    setSelected(id);
    setOpenRemoveModal(true);
  };

  const handleRemoveInventory = () => {
    console.log('handleRemoveInventory');
    deleteInventory(db, selectedItem);
    handleCloseRemoveDialog();
  };

  const handleCloseRemoveDialog = (event) => {
    setOpenRemoveModal(false);
  };

  const handleCloseInventoryModal = () => {
    setOpenEditModal(false);
  };

  const handleEmpty = () => {
    setOpenRemoveModal(true);
  };

  const renderUploadDialog = () => (
    <ConfirmDialog
      label="csv-dialog-title"
      title="CSV Upload"
      open={csvFile !== null}
      onConfirm={handleUploadCSV}
      onClose={handleCloseUploadDialog}
    >
      <Typography variant="body2" gutterBottom>
        Do you want to upload{' '}
        <strong>{csvFile ? csvFile.name : 'this file'}</strong> ?
      </Typography>
    </ConfirmDialog>
  );

  const handleCloseUploadDialog = () => {
    setCSVFile(null);
  };

  const handleUploadCSV = () => {
    Papa.parse(csvFile, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log('-- results: ', results);

        // Check for invalid data excluding "parent" field
        const hasInvalidData = results.data.some((row) =>
          Object.entries(row).some(
            ([key, value]) =>
              key !== 'parent' && (value === null || value === ''),
          ),
        );

        if (hasInvalidData) {
          alert(
            'The uploaded CSV contains invalid data (null or empty values). Please correct it and try again.',
          );
          setCSVFile(null); // Clear the file for re-upload
          return;
        }

        getInventoryProducts(db, results.data).then((items) => {
          handleProductItems(items);
        });

        setCSVFile(null);
      },
    });
  };

  const handleProductItems = (items) => {
    const _productItems = _.filter(items, { exists: true });
    productItems = _.map(_productItems, (item) => ({
      sku: item.sku,
      name: item.name,
      parent: item.parent,
      quantity: item.qty,
      location: item.location,
    }));

    const _nonItems = _.filter(items, { exists: false });
    return getInventoryVariantProducts(db, _nonItems).then((_items) => {
      handleAllItems(_items);
    });
  };

  const handleBoxsetItems = (items) => {
    const _boxsetItems = _.filter(items, { exists: true });
    nonItems = _.filter(items, { exists: false });

    boxsetItems = _.map(_boxsetItems, (item) => ({
      sku: item.sku,
      name: item.name,
      isBoxset: true,
      quantity: item.qty,
      location: item.location,
    }));

    return getInventoryVariantProducts(db, nonItems).then((_items) => {
      handleAllItems(_items);
    });
  };

  const handleAllItems = (items) => {
    nonItems = _.filter(items, { exists: false });
    _.each(items, (item, index) => {
      if (item.exists) {
        variantItems.push({
          name: item.name,
          sku: item.sku,
          parent: item.parent,
          quantity: item.qty,
          location: item.location,
        });
      } else {
        variantItems.push({
          name: '',
          sku: item.sku,
          parent: item.parent,
          quantity: item.qty,
          location: item.location,
        });
      }
    });

    const _items = [...productItems, ...variantItems];
    addBulkProducts(db, _items);
  };

  const onChangeView = (mode) => {
    console.log('Change View: ', mode);
    setViewMode(mode);
  };

  const handleClick = (event, name) => {
    if (props.editOnly) {
      setSelected(name);
      setOpenEditModal(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderListView = () => (
    <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          useCheckbox={false}
          cells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {currentItems.map((row, index) => {
            const keyId = `key-${row.id}-${index}`;
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={keyId}
                classes={{
                  root: row.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
                  selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
                }}
              >
                <TableCell align="left">
                  <Typography variant="tableText">{row.sku}</Typography>{' '}
                  {row.error !== '' && (
                    <Typography variant="tableErrorText">
                      {row.error}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="left">
                  <Typography variant="tableText">{row.name}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="tableText">{row.location}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="tableText">{row.quantity}</Typography>
                </TableCell>
                <TableCell align="center">
                  {props.editOnly && (
                    <Button
                      variant="blue"
                      sx={{ width: '120px' }}
                      onClick={(event) => handleEdit(event, row.id)}
                    >
                      Edit
                    </Button>
                  )}
                </TableCell>
                <TableCell align="center">
                  {props.editOnly && (
                    <Button onClick={(event) => handleRemove(event, row.id)}>
                      <DeleteOutlineIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}

          {loading && (
            <TableRow>
              <TableCell align="center" colSpan={8}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}

          {!loading && _items.length === 0 && (
            <TableRow>
              <TableCell align="center" colSpan={6}>
                <Typography className={classes.tableCell}>No Items </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderGridView = () => (
    <Box>
      <Grid container spacing={3}>
        {currentItems.map((row, i) => (
          <Grid item xs={3}>
            <InventoryGridItem
              key={`GridItem_${row.sku}`}
              db={db}
              data={row}
              settings={cropSettings}
              templates={templates}
              handleClick={handleClick}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderProduct = () =>
    viewMode === 'list' ? renderListView() : renderGridView();

  const _items = _.filter(items, (item, index) => {
    if (searchKey === '') {
      return true;
    }
    if (item.sku.toLowerCase().includes(searchKey)) {
      return true;
    }

    if (item.name.toLowerCase().includes(searchKey)) {
      return true;
    }

    if (item.parent.toLowerCase().includes(searchKey)) {
      return true;
    }

    const strLocation = item.location.toString();
    if (strLocation.toLowerCase().includes(searchKey)) {
      return true;
    }
    return false;
  });

  let units = 0;
  const rowsPerPage = _.find(filterShows, { id: filterShow }).value;
  const __items =
    orderBy === ''
      ? [..._items]
      : stableSort(_items, getComparator(order, orderBy));

  const currentItems = __items.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const pages = parseInt((__items.length + rowsPerPage - 1) / rowsPerPage);

  const selectedItem = _.find(__items, { id: selected });

  _.each(__items, (item) => {
    units += parseInt(item.quantity);
  });

  // console.log('__items: ', __items);

  return (
    <Layout page={props.page} user={props.user}>
      <PageHeader
        total={__items.length}
        units={units}
        onSearch={handleSearch}
        onUpload={handleUpload}
        onExport={handleExport}
        exporting={exporting}
        viewOnly={props.viewOnly}
        editOnly={props.editOnly}
      />

      <FilterProvider
        value={{
          shows: filterShows,
          filterShow,
          sorts: filterSorts,
          filterSort,
          setFilterShow: handleChangeShow,
          setFilterSort: handleChangeSort,
        }}
      >
        <PageToolbar
          handleNew={handleAddProduct}
          view={viewMode}
          onChangeView={onChangeView}
          viewOnly={props.viewOnly}
          editOnly={props.editOnly}
          renderLocations={renderLocations}
          setRenderLocations={setRenderLocations}
        />
      </FilterProvider>

      {templates.length > 0 &&
        cropSettings.length > 0 &&
        !renderLocations &&
        renderProduct()}

      {renderLocations && (
        <RenderLocationList
          inventoryLocations={inventoryLocations}
          classes={classes}
          setRenderLocations={setRenderLocations}
          handleRequestSort={handleRequestSort}
          db={db}
        />
      )}

      {!renderLocations && (
        <Box className={classes.pagination}>
          <Pagination
            count={pages}
            page={page + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </Box>
      )}

      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <InventoryDrawer
          classes={classes}
          items={items}
          onClose={toggleDrawer(false)}
          onComplete={handleAddProducts}
          locations={inventoryLocations}
        />
      </Drawer>

      {selectedItem && (
        <InventoryModal
          key="Product_Modal_Edit"
          open={openEditModal}
          item={selectedItem}
          onClose={handleCloseInventoryModal}
          onRemove={handleEmpty}
        />
      )}

      {selectedItem && (
        <ConfirmDialog
          label="delete-dialog-title"
          title="Delete Product"
          open={openRemoveModal}
          onConfirm={handleRemoveInventory}
          onClose={handleCloseRemoveDialog}
        >
          <Typography variant="body2" gutterBottom>
            Do you want to remove <strong>{selectedItem.sku}</strong>?
          </Typography>
        </ConfirmDialog>
      )}

      {renderUploadDialog()}
    </Layout>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Inventory);
