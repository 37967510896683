import Typesense from 'typesense';
import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  addDoc,
  or,
  getDoc,
  onSnapshot,
  writeBatch,
  updateDoc,
} from 'firebase/firestore';

import { GCP_PROJECT_ID, collections } from '../constants/defines';
import { itemsChecked } from '../utils/updateItemIfSaysMissingArt';
import { adjustHistoryDocs, getHistoryMatchById } from '../db/history';

const client = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST, // where xxx is the ClusterID of your Typesense Cloud cluster
      port: '443',
      protocol: 'https',
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2,
});

const typesenseCollections = {
  'artomator-sandbox': {
    items: 'sandbox_ITEMS',
    orders: 'sandbox_ORDERS',
    history: 'sandbox_HISTORY',
    dsOrders: 'sandbox_DS_ORDERS',
  },
  'artomator-test': {
    items: 'dev_ITEMS',
    orders: 'dev_ORDERS',
    history: 'dev_HISTORY',
    dsOrders: 'dev_DS_ORDERS',
  },
  'artomator-stage': {
    items: 'stg_ITEMS',
    orders: 'stg_ORDERS',
    history: 'stg_HISTORY',
    dsOrders: 'stg_DS_ORDERS',
  },
  'rock-em-print-file-creator': {
    items: 'ITEMS',
    orders: 'ORDERS',
    history: 'HISTORY',
    dsOrders: 'DS_ORDERS',
  },
  'a2-migration': {
    items: 'ITEMS',
    orders: 'ORDERS',
    history: 'HISTORY',
    dsOrders: 'DS_ORDERS',
  },
};

const sanitizeProducts = (item, sizes, formats) => {
  const itemToReturn = {
    format: _.find(formats, { id: item.format })?.name || '',
    hasTemplate: item.hasTemplate || false,
    id: item.id || '',
    isBoxset: item.isBoxset || false,
    isBundle: item.isBundle || false,
    isCustomProduct: item.isCustomProduct || false,
    isError: item.isError || false,
    isMask: item.isMask || false,
    isSolo: item.isSolo || false,
    name: item.name || '',
    size: _.find(sizes, { id: item.size })?.sku || '',
    sku: item.sku || '',
    style: item.style || '',
    templates: item.templates || [],
    type: item.type || '',
    variants: item.variants
      ? Array.isArray(item.variants)
        ? item.variants.join(', ')
        : item.variants
      : '',
  };

  if (item.variantSingleSku && item.variantSingleSku !== '') {
    itemToReturn.sku = item.variantSingleSku;
  }

  return itemToReturn;
};

const searchProducts = (
  options,
  callback,
  dontAllowBoxsetSearch = false,
  db,
  sizes,
  formats,
  isMainSearch = false,
) => {
  // Adjust the filter for sizes "Youth L" or "YTH"
  if (options.filters && options.filters.includes('size:="YTH"')) {
    options.filters = options.filters.replace('size:="YTH"', 'size:="Youth L"');
  }

  // Adjust the filter for size "S" to "Small", and then map it back to "S" in the results if needed
  if (options.filters && options.filters.includes('size:="S"')) {
    options.filters = options.filters.replace('size:="S"', 'size:="Small"');
  }

  const boxsetFilter = dontAllowBoxsetSearch ? '&&isBoxset:!=true' : '';

  // Construct the filter_by string with the new boxsetFilter condition
  const filter_by = `format:!=h1Crew&&type:!=Masks${boxsetFilter}${options.filters ? '&&' + options.filters : ''}`;

  const search = {
    q: options.textSearch === '' ? '*' : options.textSearch.trim(),
    query_by: 'name,sku,size,format,style,type,variants,items',
    page: options.pageNumber,
    per_page: options.rowsPerPage,
    filter_by: filter_by,
    sort_by: 'sku:asc,name:asc,size:asc',
    drop_tokens_threshold: 0,
  };

  client
    .collections(typesenseCollections[GCP_PROJECT_ID].items)
    .documents()
    .search(search)
    .then(async function (searchResults) {
      let results = searchResults.hits;

      const foundInTrueDb = false;

      const productCount = searchResults.found;

      // Calculate the total number of pages
      const pages = Math.ceil(productCount / search.per_page);

      // Prepare the data for the callback
      const pagesData = {
        productCount,
        pages,
      };

      if (isMainSearch) {
        // Optionally adjust the size display in the search results
        const items = _.map(results, (item) => {
          if (!foundInTrueDb) {
            if (item?.document?.size === 'Small') {
              item.document.size = 'S';
            }
            if (item?.document?.size === 'YTHL') {
              item.document.size = 'Youth L';
            }

            return item?.document || item;
          }

          if (item.size === 'Small') {
            item.size = 'S';
          }

          if (item.size === 'YTHL') {
            item.size = 'Youth L';
          }

          return item;
        });

        callback(items, pagesData);
        return;
      }

      // if 5 or less found search actual db
      if (db && results.length <= 2 && options.pageNumber === 1) {
        const itemsCollRef = collection(db, 'Items');

        const startText = options.textSearch.trim().toUpperCase();
        const endText = startText + '\uf8ff';

        const queries = [
          query(
            itemsCollRef,
            where('sku', '>=', startText),
            where('sku', '<', endText),
          ),
        ];

        const items = [];

        for (const q of queries) {
          const itemSnapshot = await getDocs(q);
          if (!itemSnapshot.empty) {
            itemSnapshot.forEach((doc) => {
              const data = doc.data();
              const sanitizedData = sanitizeProducts(data, sizes, formats);
              items.push(sanitizedData);
            });
          }
        }

        results = [...results, ...items];
      }

      // Optionally adjust the size display in the search results
      const items = _.map(results, (item) => {
        if (!foundInTrueDb) {
          if (item?.document?.size === 'Small') {
            item.document.size = 'S';
          }
          if (item?.document?.size === 'YTHL') {
            item.document.size = 'Youth L';
          }

          return item?.document || item;
        }

        if (item.size === 'Small') {
          item.size = 'S';
        }

        if (item.size === 'YTHL') {
          item.size = 'Youth L';
        }

        return item;
      });

      const uniqueItems = removeDuplicates(items);

      // Loop through and remove any items that has SNGL in the SKU
      const products = uniqueItems.filter((item) => {
        if (item.sku) {
          return !item.sku.includes('SNGL');
        }
      });

      callback(products, pagesData);
    });
};

const sanitizeItems = (items, formats, sizes) =>
  items.map((item) => ({
    type: item.type || 'Socks',
    id: item.id || '',
    size: _.find(sizes, { id: item.size })?.sku || '',
    items: item.items || [],
    style: item.style || '',
    name: item.name || '',
    format: _.find(formats, { id: item.format })?.name || '',
    sku: item.sku || '',
    created: item.created || '',
    isMask: item.isMask || false,
    isBoxset: true,
    isCustomProduct: false,
    isError: false,
    isMask: false,
    isSolo: false,
  }));

const searchBySinglesVariantSku = async (
  db,
  seachKeys,
  formats,
  sizes,
  results,
) => {
  try {
    const itemsCollRef = collection(db, 'Items');
    const startText = seachKeys.trim().toUpperCase();

    const singlesQuery = query(
      itemsCollRef,
      where('variantSingleSku', '>=', startText),
    );

    const snapshot = await getDocs(singlesQuery);

    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        const data = doc.data();
        // add exaxt sku matches only
        if (data.variantSingleSku.toUpperCase() === seachKeys.toUpperCase()) {
          const sanitizedData = sanitizeProducts(data, sizes, formats);
          results.unshift(sanitizedData);
        }
      });
    }

    return results;
  } catch (error) {
    console.log('Error searching by singles variant sku:', error);
    throw error;
  }
};

const searchFirestore = async (
  db,
  textSearch,
  results,
  formats,
  sizes,
  singlesInventoryBypass,
) => {
  if (singlesInventoryBypass) {
    const singlesItems = await searchBySinglesVariantSku(
      db,
      textSearch,
      formats,
      sizes,
      results,
    );
    if (singlesItems.length > 0) {
      // push the eesults to the top
      results = [...singlesItems, ...results];
    }
    return results;
  }

  const boxsetCollectionRef = collection(db, 'BoxSets');
  const itemsCollRef = collection(db, 'Items');

  // Define the range for the "starts with" query
  const startText = textSearch.trim().toUpperCase();
  const endText = startText + '\uf8ff';

  // Create the queries
  const boxsetQuery = query(
    boxsetCollectionRef,
    where('sku', '>=', startText),
    where('sku', '<', endText),
  );
  const itemsQuery = query(
    itemsCollRef,
    where('sku', '>=', startText),
    where('sku', '<', endText),
  );
  const itemsVariantQuery = query(
    itemsCollRef,
    where('variants', 'array-contains', startText),
  );

  // Fetch data from Firestore
  const [snapshot, boxsetSnapshot, itemsVariantSnapshot] = await Promise.all([
    getDocs(itemsQuery),
    getDocs(boxsetQuery),
    getDocs(itemsVariantQuery),
  ]);

  if (!snapshot.empty) {
    snapshot.forEach((doc) => {
      const data = doc.data();
      const sanitizedData = sanitizeProducts(data, sizes, formats);
      results.push(sanitizedData);
    });
  }

  if (!boxsetSnapshot.empty) {
    boxsetSnapshot.forEach((doc) => {
      const data = doc.data();
      const sanitizedData = sanitizeItems([data], formats, sizes);
      results.push(sanitizedData);
    });
  }

  if (!itemsVariantSnapshot.empty) {
    itemsVariantSnapshot.forEach((doc) => {
      const data = doc.data();
      const sanitizedData = sanitizeProducts(data, sizes, formats);
      results.push(sanitizedData);
    });
  }

  return results;
};

const filterNonCustomProducts = (
  textSearch,
  page = 1,
  per_page = 100,
  searchBySku = false,
  db,
  formats,
  sizes,
  singlesInventoryBypass,
) =>
  new Promise((resolve) => {
    const useThisSearchBy = 'name,sku,variants';
    const search = {
      q: textSearch,
      query_by: useThisSearchBy,
      sort_by: 'sku:asc,name:asc',
      page,
      per_page,
      filter_by: 'isCustomProduct:=false',
      sort_by: 'sku:asc,name:asc,size:asc',
    };

    client
      .collections(typesenseCollections[GCP_PROJECT_ID].items)
      .documents()
      .search(search)
      .then(async function (searchResults) {
        let results = searchResults.hits;

        const exactMatchIndex = results.findIndex((item) => {
          if (item.document) {
            return item.document.sku.toUpperCase() === textSearch.toUpperCase();
          }
          return item.sku.toUpperCase() === textSearch.toUpperCase();
        });

        if (exactMatchIndex === -1 && singlesInventoryBypass) {
          results = await searchFirestore(
            db,
            textSearch,
            results,
            formats,
            sizes,
            singlesInventoryBypass,
          );
        }

        // if 5 or less found search actual db
        if (results.length <= 5) {
          results = await searchFirestore(
            db,
            textSearch,
            results,
            formats,
            sizes,
            singlesInventoryBypass,
          );
        }

        const fetchedItems = _.map(results, (item) => item?.document || item);

        // filter out items if they are finished goods, they style should only be graphic
        let items = fetchedItems;
        items = removeDuplicates(items);
        resolve(items);
      });
  });

const removeDuplicates = (items) => {
  const seen = new Set();
  return items.filter((item) => {
    const duplicate = seen.has(item.sku);
    seen.add(item.sku);
    return !duplicate;
  });
};

const parseAndFilter = (arrayOfItems, textSearch) => {
  // First, check for an exact match and return it if found
  const exactMatch = arrayOfItems.find(
    (item) => item.sku && item.sku.toUpperCase() === textSearch,
  );

  if (exactMatch) {
    return [exactMatch];
  }

  // If no exact match, filter items that contain the textSearch as a substring of their sku or have something similar to the textSearch in their variants
  const filteredItems = arrayOfItems.filter((item) => {
    if (item.sku) {
      return (
        item.sku.toUpperCase().includes(textSearch) ||
        (item.variants && item.variants.toUpperCase().includes(textSearch))
      );
    }
  });

  return filteredItems;
};

const searchInlineOrders = async (options, callback, db, isCustoms = false) => {
  const search = {
    q: options.textSearch === '' ? '*' : options.textSearch,
    query_by: 'orderNumber,customer,type,tags,items',
    page: options.pageNumber,
    per_page: options.rowsPerPage,
    filter_by: options.filters,
    sort_by: 'createDate:asc',
    drop_tokens_threshold: 0,
  };

  try {
    const searchResults = await client
      .collections(typesenseCollections[GCP_PROJECT_ID].orders)
      .documents()
      .search(search);

    const productCount = searchResults.found;

    const pages = Math.ceil(productCount / search.per_page);

    const inlineOrders = _.map(searchResults.hits, (item) => item.document);

    // check if options.textSearch in not empty and that every charactr is a number
    if (options.textSearch !== '' && options.textSearch.match(/^\d+$/)) {
      console.log('Looking for orders with order number: ', options.textSearch);

      // check if the text search is a number and if it is a number, then we need to search for orders with order number that starts with the number if none found, then return empty array

      const ordersWithOrderNumber = inlineOrders.filter((item) =>
        item.orderNumber.startsWith(options.textSearch),
      );
      if (ordersWithOrderNumber.length === 0) {
        callback([], { productCount, pages });
        return;
      }
    }

    if (inlineOrders.length === 0) {
      callback([], { productCount, pages });
      return;
    }

    // get ids
    const originalOrders = inlineOrders.map((item) => item.id);

    if (isCustoms) {
      getCustomsByIds(db, originalOrders, async (updatedInlineOrders) => {
        callback(
          updatedInlineOrders,
          {
            productCount,
            pages,
          },
          originalOrders,
        );
      });
      return;
    }

    getDocByIds(
      db,
      inlineOrders.map((item) => item.id),
      async (updatedInlineOrders) => {
        // if item is marked as isPrinting, remove it
        const filteredInlineOrders = updatedInlineOrders.filter(
          (item) => !item.isPrinting,
        );

        // loop trhrough orders and check for order.items that have error !== ""
        const ordersWithErrors = filteredInlineOrders.filter((item) =>
          item.items.some((item) => item.error !== ''),
        );
        if (ordersWithErrors.length > 0) {
          // // loop through the items and update the pnf and document
          // ordersWithErrors?.forEach(async (order) => {
          //     order.items.forEach(async (item) => {
          //         if (item.sku.includes("RE-TH12-HE12MAN-HOODIE")) {
          //             console.log('updating pnf and document: ', item.sku);
          //             await updatePnfAndDocument(db, item, collections.INLINE_PRODUCTS_QUEUE);
          //         }
          //     });
          // });
        }
        callback(
          filteredInlineOrders,
          {
            productCount,
            pages,
          },
          originalOrders,
        );
      },
    );
  } catch (error) {
    console.error('Error searching inline orders:', error);
    callback([], { productCount: 0, pages: 0 });
  }
};

const searchDsOrders = async (options, callback, db, webOrderType) => {
  const search = {
    q: options.textSearch === '' ? '*' : options.textSearch,
    query_by: 'orderNumber,customer,type,tags,items',
    page: options.pageNumber,
    per_page: options.rowsPerPage,
    filter_by: options.filters,
    sort_by: 'createDate:asc',
    drop_tokens_threshold: 0,
  };

  try {
    const searchResults = await client
      .collections(typesenseCollections[GCP_PROJECT_ID].dsOrders)
      .documents()
      .search(search);

    const productCount = searchResults.found;
    const pages = Math.ceil(productCount / search.per_page);

    const orders = _.map(searchResults.hits, (item) => item.document);

    if (orders.length === 0) {
      callback([], { productCount, pages });
      return;
    }

    const collectionToUse =
      webOrderType === 'Inlines'
        ? collections.DS_INLINE_QUEUE
        : collections.DS_CUSTOM_QUEUE;

    getDsOrdersByIds(
      db,
      orders.map((item) => item.id),
      collectionToUse,
      async (updatedInlineOrders) => {
        // if item is marked as isPrinting, remove it
        const filteredOrders = updatedInlineOrders.filter(
          (item) => !item.isPrinting,
        );

        callback(filteredOrders, {
          productCount,
          pages,
        });
      },
    );
  } catch (error) {
    console.error('Error searching inline orders:', error);
    callback([], { productCount: 0, pages: 0 });
  }
};

const updatePnfAndDocument = async (db, item, collectionRef) => {
  // First, check in bundles collection
  const bundle = await findInCollection(db, collections.BOXSETS, item);
  if (bundle) {
    await updateItemAndDocument(db, item, bundle, collectionRef);
    return; // Return early if bundle is found
  }

  // If not found in bundles, check in products collection
  const product = await findInCollection(db, collections.PRODUCTS, item);
  if (product) {
    await updateItemAndDocument(db, item, product, collectionRef);
  }

  return null; // Return null if nothing is found in either collection
};

const updateItemAndDocument = async (db, item, foundItem, collectionRef) => {
  item.error = '';
  item.style = foundItem?.style || '';
  item.type = foundItem?.type || '';
  item.size = foundItem?.size || '';
  item.format = foundItem?.format || '';
  item.exists = true;
  await updateItemInDsOrders(db, item, collectionRef);
};

const updateItemInDsOrders = async (db, item, collectionRef) => {
  // iff this is not allowed: item?.reference.toString(), just return
  if (!item?.reference) {
    console.error('Item reference not found: ', item);
    return;
  }

  const docRef = doc(collection(db, collectionRef), item?.reference.toString());

  // Fetch the document
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();

    // Find the specific item in the 'items' array
    const items = data.items || [];
    const itemIndex = items.findIndex((i) => i.id === item.id);

    if (itemIndex !== -1) {
      // Update the specific item in the array
      items[itemIndex] = item;
      // updating item sku :
      console.log('updating item sku : ', item.sku);
      // Update the document with the modified 'items' array
      await updateDoc(docRef, { items });
    } else {
      console.error(`Item with id ${item.id} not found`);
    }
  } else {
    console.error('Document not found');
  }
};

const findInCollection = async (db, collectionName, item) => {
  const q = query(
    collection(db, collectionName),
    or(
      where('variants', 'array-contains', item.sku),
      where('sku', '==', item.sku),
    ),
  );

  const querySnapshot = await getDocs(q);

  // Return the first item found
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0]; // Get the first document
    return { id: doc.id, ...doc.data() };
  }

  return null; // Return null if nothing is found
};

const getDocByIds = (db, batchIds, callback) => {
  try {
    const chunks = [];
    while (batchIds.length) {
      chunks.push(batchIds.splice(0, 25)); // Split into chunks of 10
    }

    const allResults = [];
    const unsubscribeFunctions = [];

    chunks.forEach((chunk) => {
      const q = query(
        collection(db, collections.INLINE_PRODUCTS_QUEUE),
        where('__name__', 'in', chunk),
      );

      // Use Firestore's onSnapshot for real-time updates
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const results = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          delete data.rawData;
          return {
            id: doc.id,
            ...data,
          };
        });

        // Add to allResults array and trigger callback with combined results
        allResults.push(...results);

        // Deduplicate results by ID (optional, depending on your use case)
        const uniqueResults = Array.from(
          new Map(allResults.map((item) => [item.id, item])).values(),
        );

        callback(uniqueResults);
      });

      unsubscribeFunctions.push(unsubscribe);
    });

    // Return a function to unsubscribe all listeners
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  } catch (error) {
    console.error('Error querying documents by batches:', error);
    throw error;
  }
};

const getDsOrdersByIds = (db, batchIds, collectionRef, callback) => {
  try {
    const chunks = [];
    while (batchIds.length) {
      chunks.push(batchIds.splice(0, 10)); // Split into chunks of 10
    }

    const isCustomQueue = collectionRef === collections.DS_CUSTOM_QUEUE;
    const unsubscribeFunctions = [];
    const allSnapshotItems = [];

    chunks.forEach((chunk) => {
      const q = query(
        collection(db, collectionRef),
        where('__name__', 'in', chunk),
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const snapshotItems = [];

        for (const doc of querySnapshot.docs) {
          // Process each document
          const data = doc.data();
          let cntSocks = 0,
            cntUnderwear = 0;
          let numCustomItems = 0,
            numItems = 0;

          for (const _item of data.items) {
            if (_item.error !== '') {
              await updatePnfAndDocument(db, _item, collectionRef);
            }
            if (_item.type === 'Underwear') {
              cntUnderwear++;
            } else {
              cntSocks++;
            }

            if (_item.isCustomProduct) {
              numCustomItems += parseInt(_item.quantity);
            }
            numItems += parseInt(_item.quantity);
          }

          let type = 'multiple';
          if (cntUnderwear === data.items.length) {
            type = 'underwear';
          } else if (cntSocks === data.items.length) {
            type = 'socks';
          }

          let orderType = 'OTHER';
          if (numItems === 1 && numCustomItems === 1) {
            orderType = 'SINGLE';
          } else if (numItems === numCustomItems) {
            orderType = 'MULTIPLE';
          }

          const updatedData = {
            ...data,
            orderType,
            numItems,
            numCustomItems,
            type,
            ...(isCustomQueue && { isFromCustomsQueue: true }),
          };

          snapshotItems.push(updatedData);
        }
        // Deduplicate the `allSnapshotItems` array
        allSnapshotItems.push(...snapshotItems);

        // Use a Map to deduplicate by a unique key (e.g., `id` or `__name__`)
        const uniqueSnapshotItems = Array.from(
          new Map(
            allSnapshotItems.map((item) => [item?.orderNumber, item]),
          ).values(),
        );

        // Only call the callback when all chunks are processed
        if (uniqueSnapshotItems.length >= batchIds.length) {
          callback(uniqueSnapshotItems);
        }
      });

      unsubscribeFunctions.push(unsubscribe);
    });

    // Return a function to unsubscribe all listeners
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  } catch (error) {
    console.error('Error querying documents by batches:', error);
    throw error;
  }
};

let unsubscribeListeners = null;

const searchHistory = (options, callback, db) => {
  if (unsubscribeListeners) {
    unsubscribeListeners(); // Cleanup previous listeners
  }

  const filters = [];

  if (options.batchSource && options.batchSource !== 'All') {
    if (options.batchSource === 'Web') {
      filters.push('source:=Order');
    } else {
      filters.push(`source:=${options.batchSource}`);
    }
  }

  if (options.batchStyle && options.batchStyle !== 'All') {
    filters.push(`typeOfPress:=${options.batchStyle?.toLowerCase()}`);
  }

  if (options.batchStatus && options.batchStatus !== 'All') {
    const booleanValue = options.batchStatus === 'Fulfilled' ? true : false;
    filters.push(`wasPicked:=${booleanValue}`);
  }

  if (options.filters) {
    filters.push(options.filters);
  }

  let isSkuSearch = false;

  // Update the condition to check for two or more dashes
  if ((options.textSearch.match(/-/g) || []).length >= 2) {
    isSkuSearch = true;
  }

  const search = {
    q: options.textSearch === '' ? '*' : options.textSearch.trim(),
    query_by: 'name,batchId,orderNumbers,skus,customJerseyText',
    page: options.pageNumber,
    per_page: options.textSearch !== '' ? 250 : options.rowsPerPage,
    filter_by: filters.join(' && '),
    sort_by: 'batchId:desc',
    typo_tokens_threshold: 0,
    drop_tokens_threshold: 0,
  };

  client
    .collections(typesenseCollections[GCP_PROJECT_ID].history)
    .documents()
    .search(search)
    .then((searchResults) => {
      const productCount = searchResults.found;
      if (productCount === 0) {
        callback([], { productCount, pages: 0 });
        return;
      }
      const pages = Math.ceil(productCount / search.per_page);

      let items = _.map(searchResults.hits, (item) => item.document);

      if (isSkuSearch) {
        console.log('isSkuSearch: ', items);
        items = items.filter((item) => item.skus.includes(options.textSearch));
        if (items.length === 0) {
          callback([], { productCount: 0, pages: 0 });
          return;
        }
      }

      // Add new real-time listeners
      unsubscribeListeners = getHistoryMatchById(
        db,
        items.map((item) => item.batchId),
        (updatedHistoryItems) => {
          updatedHistoryItems.sort(
            (a, b) =>
              new Date(b.createdAt.seconds * 1000) -
              new Date(a.createdAt.seconds * 1000),
          );

          const adjustedHistoryDocs = adjustHistoryDocs(updatedHistoryItems);

          callback(adjustedHistoryDocs, { productCount, pages });
        },
      );
    })
    .catch((error) => {
      console.error('Error in searchHistory:', error);
    });
};

const searchForFinishedOrder = (orderNumber, callback) => {
  const search = {
    q: orderNumber,
    query_by: 'batchId',
    per_page: 1,
    drop_tokens_threshold: 0,
  };

  client
    .collections(typesenseCollections[GCP_PROJECT_ID].history)
    .documents()
    .search(search)
    .then(function (searchResults) {
      const items = _.map(searchResults.hits, (item) => item.document);

      callback(items[0]);
    });
};

const getCustomsByIds = (db, batchIds, callback) => {
  try {
    const chunks = [];
    while (batchIds.length) {
      chunks.push(batchIds.splice(0, 25)); // Split into chunks of 10
    }

    const allResults = [];
    const unsubscribeFunctions = [];

    chunks.forEach((chunk) => {
      const q = query(
        collection(db, collections.CUSTOM_PRODUCTS_QUEUE),
        where('__name__', 'in', chunk),
      );

      // Use Firestore's onSnapshot for real-time updates
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const results = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          delete data.rawData;
          return {
            id: doc.id,
            ...data,
          };
        });

        // Add to allResults array and trigger callback with combined results
        allResults.push(...results);

        // Deduplicate results by ID (optional, depending on your use case)
        const uniqueResults = Array.from(
          new Map(allResults.map((item) => [item.id, item])).values(),
        );

        // Process each order
        let processedOrders = await Promise.all(
          uniqueResults.map(async (doc) => {
            const data = doc;
            let cntSocks = 0,
              cntUnderwear = 0;
            let numCustomItems = 0,
              numItems = 0;

            await Promise.all(
              data.items.map(async (_item) => {
                if (_item.type === 'Underwear') {
                  cntUnderwear++;
                } else {
                  cntSocks++;
                }

                if (_item.isCustomProduct) {
                  numCustomItems += parseInt(_item.quantity);
                }
                numItems += parseInt(_item.quantity);

                if (_item.sku.toLowerCase().includes('how many faces?')) {
                  data.items = data.items.filter(
                    (item) => item.sku !== _item.sku,
                  );
                }
              }),
            );

            let type = 'multiple';
            if (cntUnderwear === data.items.length) {
              type = 'underwear';
            } else if (cntSocks === data.items.length) {
              type = 'socks';
            }

            let orderType = 'OTHER';
            if (numItems === 1 && numCustomItems === 1) {
              orderType = 'SINGLE';
            } else if (numItems === numCustomItems) {
              orderType = 'MULTIPLE';
            }

            return {
              ...data,
              orderType,
              numItems,
              numCustomItems,
              type,
            };
          }),
        );

        // remove the orders that are not printing
        processedOrders = processedOrders.filter((order) => !order.isPrinting);

        processedOrders.sort((a, b) => {
          if (a.restoreDate && !b.restoreDate) {
            return -1;
          }
          if (!a.restoreDate && b.restoreDate) {
            return 1;
          }
          return a.created_at - b.created_at;
        });

        callback(processedOrders);
      });

      unsubscribeFunctions.push(unsubscribe);
    });

    // Return a function to unsubscribe all listeners
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  } catch (error) {
    console.error('Error querying documents by batches:', error);
    throw error;
  }
};

export {
  searchProducts,
  filterNonCustomProducts,
  searchInlineOrders,
  searchHistory,
  searchForFinishedOrder,
  searchDsOrders,
};
