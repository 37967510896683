/**
 * Inline Order Queue
 *
 * Admin page that shows a list of orders waiting to be printed
 *
 */

// React dependencies
import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import { FilterProvider } from '../../context/filterContext';

// Material UI Dependencies

// component layouts
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import {
  checkIfInlineOrderIsCanceled,
  checkIfOrdersAreCancelled,
  createInlineBatch,
} from '../../db/inlineOrder';
import { getShippings } from '../../db/shippings';
import { GeneralDialog } from '../Page/generalModal';
import { useStyles } from './InlineOrders.styles';
import PrintModal from '../Page/printModal';
import {
  filterShows,
  filterTypes,
  filterStyles,
  filterSizes,
  filterProducts,
  filterProductionTypes,
} from '../ui/filterConstants';
import { EnhancedTableHead } from '../Page/table';
import {
  formatSource,
  isDateExceedingFiveDays,
  rowStyle,
} from '../../utils/fiveDaysExceederHelper';
import { calculateProducts } from '../../utils/calculateOrderItems';
import Loading from '../Loading';
import {
  filteredOrdersArray,
  getNextSetOfOrders,
  handleNextPageData,
  removeInlineOrders,
  updateInlineOrders,
  updatePageNumber,
  updateRowsPerPage,
  useSearchKeys,
} from '../../redux/inlineOrders/actions';
import { ordersWithOrderNumber } from '../../utils/pushOrderNumberToItems';
import { useShiftSelected } from '../../hooks/useSelectMany';
import { handleCopyToBoard } from '../../utils/copyToClip';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { reWriteStyle } from '../../utils/reWriteStyle';
import { convertToRows, headCells } from './helpers';
import useFetchInlineOrders from '../../hooks/useFetchInlineOrders';
import { Toast } from '../Toast';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';

const InlineOrders = (props) => {
  // inline styles

  const dispatch = useDispatch();

  const db = props.firebase.getdb();

  const {
    inlineOrders,
    isLoading,
    pageData,
    initialAllInlineOrders,
    showFilteredData,
    currentPageNumber,
    rowsPerPage,
  } = useSelector((state) => state.inlineOrders);

  const classes = useStyles();
  // main orders and selection variables
  const [selectedOrders, setSelectedOrders] = useState([]);
  // interface variables
  const [textSearch, setTextSearch] = useState('');
  const [filterFormats, setFilterFormats] = useState([]);
  const [filterShippings, setFilterShippings] = useState([]);
  const [filterProduction, setFilterProductionTypes] = useState(1);
  const [filterType, setFilterType] = useState(1);
  const [filterShow, setFilterShow] = useState(1);
  const [filterStyle, setFilterStyle] = useState(1);
  const [filterFormat, setFilterFormat] = useState(1);
  const [filterProduct, setFilterProduct] = useState(1);
  const [filterShipping, setFilterShipping] = useState(1);
  const [filterSize, setFilterSize] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [printModal, setPrintModal] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [name, setName] = useState('');
  const [updates, setUpdates] = useState(0);
  const [press, setPress] = useState(0);
  const [showToastInfo, setShowToastInfo] = useState(false);
  const [showToastData, setShowToastData] = useState(false);
  const [uniqueName, setUniqueName] = useState('');

  const viewOnly = props.viewOnly;
  const editOnly = props.editOnly;

  // Algolia query options
  const baseFilters = [];

  const [options, setOptions] = useState({
    textSearch: textSearch,
    rowsPerPage: rowsPerPage,
    pageNumber: pageNumber,
    filters: baseFilters.join(' AND '),
  });

  // table columns
  const columns = useMemo(
    () => [
      { Header: 'Date', align: 'left', accessor: 'date' },
      { Header: 'Order', align: 'left', accessor: 'orderNumber' },
      { Header: 'Type', align: 'left' },
      { Header: 'SKU', align: 'left' },
      { Header: 'Shipping', align: 'left' },
      { Header: 'Qty', align: 'left' },
      { Header: 'Tags', align: 'left' },
      { Header: 'Source', align: 'center' },
    ],
    [],
  );

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
  } = useTable({
    columns,
    data: inlineOrders,
  });

  useEffect(() => {
    if (press == 13 && !printModal) {
      handlePrint();
    }
  }, [press]);

  useEffect(() => {
    getShippings(db, 100, fetchShippings);
    const downHandler = (e) => {
      setPress(e.keyCode);
    };

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  useEffect(() => {
    const _filterFormats = [
      { id: 1, name: 'All', val: '' },
      { id: 2, name: 'None', val: '' },
    ];

    const formats = _.map(props.formats, (item, index) => ({
      id: index + 3,
      name: item.name,
      value: item.id,
    }));
    const _formats = _filterFormats.concat(formats);
    setFilterFormats([..._formats]);
  }, [props.formats]);

  const fetchShippings = (items) => {
    const _filterShippings = [{ id: 1, name: 'All', val: '' }];

    const shippings = _.map(items, (item, index) => ({
      id: index + 2,
      name: item.name,
      value: item.id,
    }));
    const _shippings = _filterShippings.concat(shippings);
    setFilterShippings([..._shippings]);
  };

  // update options
  useEffect(() => {
    const oldFilters = options.filters;

    // start with base filters for this page and then add user-selected filters
    let filterArray = baseFilters;
    filterArray = processFilterType(filterTypes, filterType, filterArray);
    filterArray = processFilter(
      filterShippings,
      filterShipping,
      'shipping',
      filterArray,
    );
    filterArray = processFilter(
      filterProducts,
      filterProduct,
      'products',
      filterArray,
    );
    // filterArray = processFilter(filterProductionTypes, filterProduction, 'production', filterArray);

    const newFilters = filterArray.join(' && ');

    setOptions({
      textSearch: textSearch,
      rowsPerPage: rowsPerPage,
      pageNumber: oldFilters === newFilters ? pageNumber : 1,
      filters: newFilters,
    });
  }, [
    pageNumber,
    textSearch,
    rowsPerPage,
    filterType,
    filterShipping,
    filterStyle,
    filterFormat,
    filterSize,
    filterProduct,
    updates,
  ]);

  // process selected filter into string for Algolia query
  const processFilter = (collection, itemId, attributeName, filterArray) => {
    // ignore if filter value is 1 (should refer to 'All')
    if (itemId !== 1) {
      let filterString = '';
      if (attributeName === 'products') {
        const selectedFilter = _.find(collection, (item) => item.id == itemId);
        const filterValue = selectedFilter.val;
        if (selectedFilter.name === 'Reprint') {
          filterString = 'reprint:=true';
        } else {
          if (filterValue == 3) {
            filterString = attributeName + ':>=' + filterValue;
          } else {
            filterString = attributeName + ':=' + filterValue;
          }
        }
      } else {
        const selectedFilter = _.find(collection, (item) => item.id == itemId);
        filterString = attributeName + ':="' + selectedFilter.name + '"';
      }
      filterArray.push(filterString);
    }
    return filterArray;
  };

  // process selected filter into string for Algolia query
  const processFilterType = (collection, itemId, filterArray) => {
    // ignore if filter value is 1 (should refer to 'All')
    if (itemId !== 1) {
      let filterString = '';
      const selectedFilter = _.find(collection, (item) => item.id == itemId);
      if (selectedFilter.name === 'Socks Only') {
        filterString = 'type:="socks"';
      } else if (selectedFilter.name === 'Underwear Only') {
        filterString = 'type:="underwear"';
      } else {
        filterString = 'items.type:="' + selectedFilter.name + '"';
      }

      filterArray.push(filterString);
    }
    return filterArray;
  };

  // text search handler
  const handleSearch = (event) => {
    if (event.keyCode === 13 || event.target.value.length === 0) {
      dispatch(useSearchKeys(event.target.value));
      dispatch(
        filteredOrdersArray(null, filterProductionTypes, filterShippings),
      );
    }
  };

  // pagination link handler
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    // dispatch the action to update the page number in the Redux store
    dispatch(updatePageNumber(newPage));
    dispatch(handleNextPageData(newPage));
  };

  // number of rows per page handler
  const handleChangeShow = (id) => {
    // use this to bind the drop-down
    setFilterShow(id);

    // need to get the value to update Algolia
    const selectedObject = _.find(filterShows, (item) => item.id == id);
    dispatch(updateRowsPerPage(parseInt(selectedObject.name)));
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inlineOrders.map((n) => n.orderNumber);

      const _selectedOrders = [...selectedOrders];
      _.each(newSelecteds, (order) => {
        const _selOrder = _.find(_selectedOrders, { orderNumber: order });
        if (!_selOrder) {
          const _item = _.find(inlineOrders, { orderNumber: order });
          _selectedOrders.push(_item);
        }
      });

      setSelectedOrders(_selectedOrders);
      return;
    }

    setSelectedOrders([]);
  };

  const handlePrint = async (event) => {
    await getUniqueBatchName(setUniqueName, setName);

    if (selectedOrders.length == 0) {
      setPress(0);
      return;
    }
    setPrintModal(true);
    setPress(0);
  };

  const handleClosePrintModal = () => {
    setPrintModal(false);
  };

  const handlePrintBatch = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    underwearFormat = 'image',
    barcode = '',
  ) => {
    const orderNumbers = _.map(selectedOrders, 'orderNumber');
    const data = await checkIfOrdersAreCancelled(db, orderNumbers);
    // compare the orderNumbers with the canceled orders
    const canceledOrders = _.intersection(orderNumbers, data);

    if (canceledOrders.length > 0) {
      setShowToastInfo(true);
      setShowToastData({
        isError: true,
        message:
          'Some of the selected orders have been canceled: ' +
          canceledOrders.join(', ') +
          '. They have been removed.',
      });
    }

    // remove the canceled orders from the selected orders and update the state
    const newSelectedOrders = _.filter(
      selectedOrders,
      (order) => !canceledOrders.includes(order.orderNumber),
    );

    dispatch(removeInlineOrders(canceledOrders));

    const selectedOrdersUpdated = newSelectedOrders;

    if (selectedOrdersUpdated.length === 0) {
      setPrintModal(false);
      setPress(0);
      setShowToastInfo(true);
      setShowToastData({
        isError: true,
        message: 'All selected orders have been canceled',
      });
      return;
    }

    createInlineBatch(
      db,
      name,
      secondaryName,
      color,
      type,
      underwearFormat,
      barcode,
      selectedOrdersUpdated,
      handleBatchResult,
    );

    setPrintModal(false);

    setPress(0);

    // case to not make our users re load when all orders have been printed
    if (selectedOrdersUpdated.length === inlineOrders.length) {
      console.log('Re fetch, because all orders have been printed');
      dispatch(getNextSetOfOrders(selectedOrdersUpdated));
      // reset selected orders
      setSelectedOrders([]);
      return;
    }

    // reset selected orders
    setSelectedOrders([]);

    // Dispatch the action to update inline orders in the Redux store
    dispatch(updateInlineOrders(selectedOrdersUpdated));

    if (name === uniqueName) {
      console.log('User used generated name, so we save it');
      await saveNameIfUsed(name);
    }
    setUniqueName('');
  };

  const renderPrintDialog = (ordersCnt, productsCnt, bundlesCnt) => (
    <PrintModal
      {...props}
      open={printModal}
      onPrint={handlePrintBatch}
      onClose={handleClosePrintModal}
      source="Order"
      orders={ordersCnt}
      products={productsCnt}
      bundles={bundlesCnt}
      barcodes={[]}
      setName={setName}
      name={name}
    />
  );

  const renderBatchResultDialog = () => (
    <GeneralDialog
      label="batch"
      title="Batch"
      open={batchModal}
      onClose={handleCloseBatchModal}
      onConfirm={handleCloseBatchModal}
    >
      <Typography variant="body2" gutterBottom>
        Inline BATCH name is <strong>{batchName}</strong>
      </Typography>
    </GeneralDialog>
  );

  const handleBatchResult = (result, batchId, batchName) => {
    setBatchName(batchName);
    setBatchModal(true);
    // setWorking(false);
    if (result) {
      setSelectedOrders([]);
      setTimeout(() => {
        console.log('Refresh Inline Orders Data...');
        setUpdates(updates + 1);
      }, 2000);
    }

    setPress(0);
  };

  const handleCloseBatchModal = () => {
    setBatchModal(false);
  };

  // helper method
  const isSelected = (name) =>
    _.filter(selectedOrders, { orderNumber: name }).length > 0;

  const currentRows = convertToRows(inlineOrders);

  const selectedRows = _.filter(currentRows, (row) =>
    isSelected(row.orderNumber),
  );

  const isSelectedAll = currentRows.length === selectedRows.length;

  const { overallCounter } = calculateProducts(
    ordersWithOrderNumber(initialAllInlineOrders),
  );

  const selectedInfo = React.useMemo(
    () => calculateProducts(selectedOrders),
    [selectedOrders],
  );

  const filteredData = useMemo(
    () => calculateProducts(inlineOrders),
    [inlineOrders],
  );

  // Assuming you have a state to track selected rows
  const [selected, setSelectedRows] = useState([]);

  const handleClick = (event, orderNumbers) => {
    // Ensure orderNumbers is always an array for consistency
    const orderNumbersArray = Array.isArray(orderNumbers)
      ? orderNumbers
      : [orderNumbers];

    let newSelectedOrders = [...selectedOrders];

    orderNumbersArray.forEach((orderNumber) => {
      const matchingOrders = inlineOrders.filter(
        (row) => row.orderNumber === orderNumber,
      );
      const isAnyOrderSelected = newSelectedOrders.some(
        (selectedOrder) => selectedOrder.orderNumber === orderNumber,
      );

      if (isAnyOrderSelected) {
        // Toggle off only if this specific orderNumber is clicked again, not in batch selections
        if (orderNumbersArray.length === 1) {
          newSelectedOrders = newSelectedOrders.filter(
            (order) => order.orderNumber !== orderNumber,
          );
        }
      } else if (matchingOrders.length > 0) {
        // Add all matching orders to the selection
        newSelectedOrders.push(...matchingOrders);
      }
    });

    setSelectedOrders(newSelectedOrders);
  };

  // Initialize the hook
  const handleRowClick = useShiftSelected(
    currentRows,
    setSelectedRows,
    handleClick,
  );

  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  return (
    <Layout page={props.page} user={props.user}>
      <>
        <PageHeader
          initial={overallCounter}
          selected={selectedInfo.overallCounter}
          onSearch={handleSearch}
          showFilteredData={showFilteredData}
          filteredData={filteredData.overallCounter}
        />

        {filterFormats.length > 0 && filterShippings.length > 0 && (
          <FilterProvider
            value={{
              types: filterTypes,
              styles: filterStyles,
              formats: filterFormats,
              shippings: filterShippings,
              sizes: filterSizes,
              shows: filterShows,
              products: filterProducts,
              production: filterProductionTypes,
              filterProduction,
              setFilterProductionTypes,
              filterType,
              setFilterType,
              filterShow,
              setFilterShow: handleChangeShow,
              filterStyle,
              setFilterStyle,
              filterFormat,
              setFilterFormat,
              filterSize,
              setFilterSize,
              filterProduct,
              setFilterProduct,
              filterShipping,
              setFilterShipping,
            }}
          >
            <PageToolbar
              viewOnly={viewOnly}
              editOnly={editOnly}
              numSelected={selectedOrders.length}
              allSelected={isSelectedAll}
              onPrint={handlePrint}
              enable={true}
              onSelectAllClick={handleSelectAllClick}
            />
          </FilterProvider>
        )}

        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table className={classes.table} size="medium" {...getTableProps()}>
            <EnhancedTableHead
              useCheckbox={true}
              cells={headCells}
              numSelected={selectedOrders.length}
              rowCount={currentRows.length}
            />
            <TableBody>
              {!isLoading && currentRows.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <Typography variant="tableText">No Orders </Typography>
                  </TableCell>
                </TableRow>
              )}

              {isLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {!isLoading &&
                    currentRows.map((row, index) => {
                      const isItemSelected = isSelected(row.orderNumber);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      const keyId = `key-${row.orderNumber}-${index}`;
                      reWriteStyle(row);
                      if (row.isMixAndMatch) {
                        if (!row.properties[1].value) {
                          row.sku = "Mix N' Match";
                        }
                        row.sku = `Mix N' Match - ${row.properties[1].value}`;
                        row.style = 'Finished Good';
                        row.type = 'HYPEROPTIC';
                      } else if (row.type === 'T-Shirt') {
                        row.style = 'Finished Good';
                      }

                      if (row.type === '' || !row.type) {
                        // if a sku ends with either -LXL, -SM, -YTH, or -YTHL or -YTHLM then its a type of Socks
                        if (
                          row.sku.endsWith('-LXL') ||
                          row.sku.endsWith('-SM') ||
                          row.sku.endsWith('-YTH') ||
                          row.sku.endsWith('-YTHL') ||
                          row.sku.endsWith('-YTHLM')
                        ) {
                          row.type = 'Socks';
                        }
                        const skuHasTshirt = row.sku.includes('-TSHIRT');
                        if (
                          (row.sku.endsWith('-2XL') ||
                            row.sku.endsWith('-XL') ||
                            row.sku.endsWith('-L') ||
                            row.sku.endsWith('-S') ||
                            row.sku.endsWith('-M')) &&
                          !skuHasTshirt
                        ) {
                          row.type = 'Underwear';
                        }
                      }

                      const socks = row.isMixAndMatch
                        ? row.properties
                            .find((prop) => prop.name === 'Socks')
                            .value.split(' and ')
                        : [];

                      return (
                        <React.Fragment key={keyId}>
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={keyId}
                            classes={{
                              root: row.odd
                                ? classes.tableRow
                                : classes.tableRow2,
                              selected: classes.selectedTableRow,
                            }}
                            className="no-select"
                            selected={isItemSelected}
                          >
                            <TableCell
                              padding="checkbox"
                              className={classes.tableCheckbox}
                            >
                              {row.index == 0 && (
                                <Checkbox
                                  sx={{ paddingRight: '40px' }}
                                  onClick={(event) =>
                                    handleRowClick(event, row)
                                  }
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              padding="none"
                              onClick={() =>
                                handleCopyToBoard(
                                  `Copied Date: ${row.createDate}`,
                                  row.createDate,
                                  setToastMessage,
                                  setShowToast,
                                )
                              }
                            >
                              <Typography
                                variant="tableText"
                                sx={{
                                  fontWeight: isDateExceedingFiveDays(
                                    row.createDate,
                                  )
                                    ? 'bold'
                                    : 'normal',
                                  color: isDateExceedingFiveDays(row.createDate)
                                    ? '#FF0000'
                                    : '#000000',
                                }}
                              >
                                {row.index === 0 ? row.createDate : ''}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                handleCopyToBoard(
                                  `Copied Order Number: ${row.orderNumber}`,
                                  row.orderNumber,
                                  setToastMessage,
                                  setShowToast,
                                )
                              }
                            >
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row)}
                              >
                                {row.index === 0 ? row.orderNumber : ''}
                              </Typography>
                              {row.index === 0 && row.reprint && (
                                <Box className={classes.restoreIcon}>
                                  <FlipCameraAndroidIcon />
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              onClick={() =>
                                handleCopyToBoard(
                                  `Copied type: ${row.type}`,
                                  row.type,
                                  setToastMessage,
                                  setShowToast,
                                )
                              }
                            >
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row, true)}
                              >
                                {row.type}
                              </Typography>
                            </TableCell>
                            <TableCell
                              align="left"
                              onClick={() =>
                                handleCopyToBoard(
                                  `Copied SKU: ${row.sku}`,
                                  row.sku,
                                  setToastMessage,
                                  setShowToast,
                                )
                              }
                            >
                              <Typography
                                variant={
                                  row.exists && row.available
                                    ? 'tableText'
                                    : 'tableErrorText'
                                }
                                sx={rowStyle(row.createDate, row, true)}
                              >
                                {row.sku}
                              </Typography>
                              <Typography
                                variant="tableErrorText"
                                sx={{ marginLeft: '10px' }}
                              >
                                {row.error}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row)}
                              >
                                {row.shipping}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row)}
                              >
                                {row.quantity}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row)}
                              >
                                {row.tags}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant="tableText"
                                sx={rowStyle(row.createDate, row)}
                              >
                                {formatSource(row.source)}
                              </Typography>
                            </TableCell>
                          </TableRow>

                          {row.isMixAndMatch &&
                            socks.map((sock, sockIndex) => (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={`${keyId}-sock-${sockIndex}`}
                                classes={{
                                  root: row.odd
                                    ? classes.tableRow
                                    : classes.tableRow2,
                                  selected: classes.selectedTableRow,
                                }}
                                className="no-select"
                                selected={isItemSelected}
                              >
                                <TableCell
                                  padding="checkbox"
                                  className={classes.tableCheckbox}
                                >
                                  {row.index == 0 && (
                                    <Checkbox
                                      sx={{
                                        display: 'none',
                                      }}
                                      onClick={(event) =>
                                        handleRowClick(event, row)
                                      }
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  )}
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  >
                                    {row.index === 0 ? row.createDate : ''}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  ></Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  ></Typography>
                                </TableCell>
                                <TableCell
                                  align="left"
                                  onClick={() =>
                                    handleCopyToBoard(
                                      `Copied SKU: ${sock}`,
                                      sock,
                                      setToastMessage,
                                      setShowToast,
                                    )
                                  }
                                  sx={{
                                    position: 'relative',
                                    paddingLeft: '30px',
                                  }}
                                >
                                  <Typography
                                    variant={
                                      row.exists && row.available
                                        ? 'tableText'
                                        : 'tableErrorText'
                                    }
                                    sx={rowStyle(row.createDate, row, true)}
                                  >
                                    {sock}
                                  </Typography>
                                  <Typography
                                    variant="tableErrorText"
                                    sx={{ marginLeft: '10px' }}
                                  ></Typography>

                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: 0,
                                      left: '-10px', // Adjust to position the vertical line
                                      width: '20px', // Width of the horizontal connector line
                                      height: '100%',
                                      '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: '50%',
                                        width: '2px',
                                        height:
                                          sockIndex === socks.length - 1
                                            ? '50%'
                                            : '100%', // Conditional height
                                        backgroundColor: '#9714EA',
                                      },
                                      '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '90%',
                                        width: '15px', // Length of the horizontal line, adjust as needed
                                        height: '2px',
                                        backgroundColor: '#9714EA',
                                        transform: 'translateX(-50%)',
                                      },
                                    }}
                                  ></Box>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  ></Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  ></Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="tableText"
                                    sx={{ display: 'none' }}
                                  ></Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography
                                    variant="tableText"
                                    sx={rowStyle(row.createDate, row)}
                                  >
                                    {formatSource(row.source)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </React.Fragment>
                      );
                    })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className={classes.pagination}>
          <Pagination
            count={pageData.pages}
            page={pageNumber}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </div>

        {renderPrintDialog(
          selectedInfo.overallCounter.total,
          selectedInfo.overallCounter.products,
          selectedInfo.overallCounter.boxes,
        )}
        {renderBatchResultDialog()}
      </>
      <ToastCopyCLip show={showToast} message={toastMessage} />
      <Toast
        show={showToastInfo}
        data={showToastData}
        onClose={() => setShowToast(false)}
      />
    </Layout>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(InlineOrders);
