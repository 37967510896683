export const reWriteStyle = (row) => {
  if (!row?.style) {
    return '';
  }

  const styleIncludes = (keyword) => row?.style?.includes(keyword) ?? false;

  switch (true) {
    case styleIncludes('Graphic'):
      row.style = null;
      break;
    case styleIncludes('Hyper'):
      row.style = 'HYPEROPTIC';
      row.type = 'HYPEROPTIC';
      break;
    case styleIncludes('Knit'):
      row.style = 'KNITTED';
      row.type = 'KNITTED';
      break;
    case styleIncludes('Cut & Sew'):
      row.style = 'CUT & SEW';
      row.type = 'CUT & SEW';
      break;
    case styleIncludes('Packaging'):
      row.style = 'ADD-ON';
      row.type = 'ADD-ON';
      break;
    default:
      // No change or default action
      break;
  }
};
