import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';

import FilterDropdown from '../Page/filterDropdown';
import FilterContext from '../Page/filterContext';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  filterBox: {
    display: 'flex',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    zIndex: '100',
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
}));

const PageToolbar = (props) => {
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();
  const { numSelected, allSelected, onSelectAllClick, onPrint, onDelete } =
    props;

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{ marginRight: '40px', height: '46px', width: '165px' }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={numSelected > 0 && allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={
                numSelected > 0 && allSelected ? 'SELECT NONE' : 'SELECT ALL'
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {props.editOnly && numSelected > 0 && (
          <Button
            variant="blue"
            onClick={onPrint}
            sx={{ width: '180px', marginRight: '15px' }}
          >
            Print Selected
          </Button>
        )}

        {props.editOnly && numSelected > 0 && (
          <Button variant="black" onClick={onDelete} sx={{ width: '150px' }}>
            Delete
          </Button>
        )}
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
