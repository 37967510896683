import { produce } from 'immer';

import {
  WHOLESALE_ORDERS,
  START_FETCHING_WHOLESALE_ORDERS,
  FETCH_WHOLESALE_ORDERS_SUCCESS,
  FETCH_WHOLESALE_ORDERS_FAILURE,
  UPDATE_WHOLESALE_ORDER,
  UPDATE_WHOLESALE_ORDER_SUCCESS,
  UPDATE_WHOLESALE_ORDER_FAILURE,
  DELETE_WHOLESALE_ORDER,
  WHOLESALE_ORDERS_PRODUCTION_QUEUE,
  SWITCH_WHOLESALE_ORDER_TO_PRODUCTION,
  UPDATE_TABLE_ORDER,
  SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG,
  UPDATE_ITEM_STATUS_IN_ORDER,
  DELETE_MULTIPLE_ORDERS,
  UPDATE_CHANGED_ORDERS,
  OPEN_ROW,
  CLOSE_ROW,
  SELECTING_ID_IN_ORDER,
} from './types';

const initialState = {
  wholeSaleOrders: [],
  wholesaleProductionOrders: [],
  isLoading: true,
  error: null,
  openRows: new Set(), // Using a Set to easily add/remove row IDs
  selectedRowIds: {},
};

const wholeSaleOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ROW:
      return {
        ...state,
        openRows: new Set(state.openRows).add(action.payload),
      };
    case CLOSE_ROW:
      const updatedOpenRows = new Set(state.openRows);
      updatedOpenRows.delete(action.payload);
      return { ...state, openRows: updatedOpenRows };

    case WHOLESALE_ORDERS:
      return {
        ...state,
        wholeSaleOrders: action.payload,
      };
    case WHOLESALE_ORDERS_PRODUCTION_QUEUE:
      return {
        ...state,
        wholesaleProductionOrders: action.payload,
      };
    case START_FETCHING_WHOLESALE_ORDERS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case FETCH_WHOLESALE_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_WHOLESALE_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_WHOLESALE_ORDER:
      return produce(state, (draftState) => {
        // Determine the target array based on isProduction flag
        const targetArray = action.payload.isProduction
          ? draftState.wholesaleProductionOrders
          : draftState.wholeSaleOrders;

        // Find the order in the target array
        const index = targetArray.findIndex(
          (order) => order.id === action.payload.orderId,
        );
        if (index !== -1) {
          // Apply the updates to the found order
          Object.assign(targetArray[index], action.payload.updates);
          console.log('UPDATES: ', action.payload.updates);
        }
      });
    case UPDATE_WHOLESALE_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_WHOLESALE_ORDER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_WHOLESALE_ORDER:
      return produce(state, (draftState) => {
        const targetArrayKey = action.payload.isProduction
          ? 'wholesaleProductionOrders'
          : 'wholeSaleOrders';

        // Apply the filter operation on the correct array based on isProduction flag
        draftState[targetArrayKey] = draftState[targetArrayKey].filter(
          (order) => order.id !== action.payload.orderId,
        );
      });
    case DELETE_MULTIPLE_ORDERS:
      return produce(state, (draftState) => {
        const targetArrayKey = action.payload.isProduction
          ? 'wholesaleProductionOrders'
          : 'wholeSaleOrders';

        // Ensure action.payload.orderIds is an array to prevent errors
        const idsToDelete = Array.isArray(action.payload.orderIds)
          ? action.payload.orderIds
          : [];

        // Keep only orders whose IDs are not in idsToDelete
        draftState[targetArrayKey] = draftState[targetArrayKey].filter(
          (order) => !idsToDelete.includes(order.id),
        );
      });
    case SWITCH_WHOLESALE_ORDER_TO_PRODUCTION:
      return produce(state, (draftState) => {
        const { orderId, updates } = action.payload;

        // Find and remove the order from wholeSaleOrders
        const orderIndex = draftState.wholeSaleOrders.findIndex(
          (order) => order.id === orderId,
        );
        if (orderIndex !== -1) {
          const [orderToMove] = draftState.wholeSaleOrders.splice(
            orderIndex,
            1,
          );

          // Apply updates to the order
          const updatedOrder = { ...orderToMove, ...updates };

          // Add the updated order to wholesaleProductionOrders
          draftState.wholesaleProductionOrders.push(updatedOrder);
        }
      });

    case SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG:
      return produce(state, (draftState) => {
        const { orderId, updates } = action.payload;

        // Find and remove the order from wholesaleProductionOrders
        const orderIndex = draftState.wholesaleProductionOrders.findIndex(
          (order) => order.id === orderId,
        );
        if (orderIndex !== -1) {
          // Remove the order from wholesaleProductionOrders
          const [orderToMove] = draftState.wholesaleProductionOrders.splice(
            orderIndex,
            1,
          );

          // Apply updates to the order
          const updatedOrder = { ...orderToMove, ...updates };

          // Add the updated order to the beginning of wholeSaleOrders
          draftState.wholeSaleOrders.unshift(updatedOrder);
        }
      });

    case UPDATE_TABLE_ORDER:
      return produce(state, (draftState) => {
        // This assumes your action.payload includes { orderId, tableOrder, isProduction }
        const targetArray = draftState.wholesaleProductionOrders;

        // Find the specific order and update its tableOrder
        const orderIndex = targetArray.findIndex(
          (order) => order.id === action.payload.orderId,
        );
        if (orderIndex !== -1) {
          targetArray[orderIndex].tableOrder = action.payload.tableOrder;
        }

        // Now, sort the target array by tableOrder
        // Note: Since we are using produce, direct mutation like sort() is safe here
        targetArray.sort((a, b) => a.tableOrder - b.tableOrder);
      });

    case UPDATE_ITEM_STATUS_IN_ORDER:
      return produce(state, (draftState) => {
        // Determine the target array based on isProduction flag
        const targetArrayKey = action.payload.isProduction
          ? 'wholesaleProductionOrders'
          : 'wholeSaleOrders';

        const targetArray = draftState[targetArrayKey];

        // Find the order in the target array
        const orderIndex = targetArray.findIndex(
          (order) => order.id === action.payload.orderId,
        );
        if (orderIndex !== -1) {
          // Now find the specific item within the order's items
          const itemIndex = targetArray[orderIndex].items.findIndex(
            (item) => item.id === action.payload.itemId,
          );
          if (itemIndex !== -1) {
            if (action.payload.reprint === true) {
              targetArray[orderIndex].items[itemIndex].status = 0;
            } else {
              // Increment the status of the item
              targetArray[orderIndex].items[itemIndex].status++;
            }
          }
        }
      });
    case UPDATE_CHANGED_ORDERS:
      return produce(state, (draftState) => {
        const { order, isProduction } = action.payload;

        const targetArray = isProduction
          ? draftState.wholesaleProductionOrders
          : draftState.wholeSaleOrders;

        // Find the order in the target array
        const orderIndex = targetArray.findIndex(
          (o) => o.id === order.orderNumber,
        );

        if (orderIndex !== -1) {
          // Apply the updates to the found order
          Object.assign(targetArray[orderIndex], order);
        }
      });

    case SELECTING_ID_IN_ORDER:
      const { orderId, rowId } = action.payload;
      return {
        ...state,
        selectedRowIds: {
          ...state.selectedRowIds,
          [orderId]: rowId,
        },
      };

    default:
      return state;
  }
};

export default wholeSaleOrdersReducer;
