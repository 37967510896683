import _ from 'lodash';
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  writeBatch,
  deleteDoc,
  onSnapshot,
  startAfter,
  arrayUnion,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const getInventoryLocations = (db, fetchData) => {
  // get setings then the inventoryLocations doc and add
  const q = query(collection(db, collections.INVENTORY_LOCATIONS));
  onSnapshot(q, (querySnapshot) => {
    const items = querySnapshot.docs.map((doc) => doc.data());
    fetchData(items);
  });
};

let unsubscribeCurrentQuery = null;

const getInvetoryUnits = (db, sort, fetchData) => {
  if (unsubscribeCurrentQuery) {
    unsubscribeCurrentQuery();
  }

  const direction = sort === 'name' ? 'asc' : 'desc';
  const q = query(
    collection(db, collections.INVENTORY),
    orderBy(sort, direction),
  );

  unsubscribeCurrentQuery = onSnapshot(q, (querySnapshot) => {
    const apiQueue = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      apiQueue.push({
        id: doc.id,
        sku: data.sku,
        name: data.name,
        location: data.location,
        parent: data?.parent ? data?.parent : '',
        quantity: parseInt(data.quantity),
      });
    });
    fetchData(apiQueue);
  });
};

const addInventory = (db, item) =>
  new Promise((resolve, reject) => {
    // addDoc not setDoc

    const dataConstruct = {
      sku: item.sku,
      name: item.name,
      quantity: parseInt(item.quantity),
      location: item.location?.toUpperCase(),
      parent: item.parent,
      createdAt: new Date(),
    };

    addDoc(collection(db, collections.INVENTORY), dataConstruct)
      .then((doc) => {
        console.log('New Inventory created');
        console.log(doc.id);
        resolve();
      })
      .catch((error) => {
        console.error('Error adding Inventory: ', error);
        reject();
      });
  });

const deleteInventory = (db, item) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.INVENTORY, item.id))
      .then(() => {
        console.log('Inventory successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Inventory: ', error);
        reject();
      });
  });

const updateInventory = (db, item) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.INVENTORY, item.id);
    updateDoc(docRef, {
      sku: item.sku,
      name: item.name,
      quantity: parseInt(item.quantity),
      location: item.location?.toUpperCase(),
      parent: item.parent,
      createdAt: new Date(),
    })
      .then(() => {
        console.log('Inventory successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Inventory: ', error);
        reject();
      });
  });

const getInventory = (db, sku, location) =>
  new Promise((resolve, reject) => {
    const q = query(
      collection(db, collections.INVENTORY),
      where('sku', '==', sku),
      where('location', '==', location),
    );
    getDocs(q)
      .then((querySnapshot) => {
        const item = { exists: false, sku, data: null };
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            item.id = doc.id;
            item.exists = true;
            item.sku = sku;
            item.data = { ...data };
          }
        });
        resolve(item);
      })
      .catch((error) => {
        console.log('getInventory Error. ', error.message);
        reject();
      });
  });

const getUserFromLocalStorage = () => {
  const user = localStorage.getItem('currentuser');
  return user;
};

const addBulkProducts = async (db, items) => {
  const user = getUserFromLocalStorage();

  try {
    await processItemsAndGetLocations(db, items);
    // Initialize Firestore batch
    const batch = writeBatch(db);

    // Fetch existing inventory for all items (one request per item)
    const requests = items.map((item) =>
      getInventory(db, item.sku, item.location),
    );

    // Wait for all requests to resolve
    const responses = await Promise.all(requests);

    // Loop through responses to determine updates or inserts
    responses.forEach((response, index) => {
      const item = items[index];
      if (response.exists) {
        // Update existing document
        const docRef = doc(db, collections.INVENTORY, response.id);
        const updatedQuantity =
          response.data.quantity + parseInt(item.quantity, 10);
        batch.update(docRef, {
          ...response.data,
          quantity: updatedQuantity,
          updatedAt: new Date(),
          updatedBy: user ? user : 'N/A',
        });
      } else {
        // Add new document with random ID
        const docRef = doc(collection(db, collections.INVENTORY)); // Generates a random ID
        batch.set(docRef, {
          sku: item.sku,
          name: item.name ? item.name : '',
          quantity: parseInt(item.quantity, 10),
          parent: item.parent ? item.parent : '',
          location: item.location?.toUpperCase()
            ? item.location?.toUpperCase()
            : '',
          createdAt: new Date(),
          createdBy: user ? user : 'N/A',
        });
      }
    });

    // Commit the batch
    await batch.commit();
    console.log('Batch operation completed successfully.');
  } catch (error) {
    console.error('Error processing inventory in bulk: ', error);
  }
};

const updateInventoryLocations = async (db, locationsToAdd) => {
  // apply all CAPS to the locations
  locationsToAdd = _.map(locationsToAdd, (location) => location.toUpperCase());

  try {
    // Reference the specific document in the INVENTORY_LOCATIONS collection
    const docRef = doc(db, collections.INVENTORY_LOCATIONS, 'locations'); // Replace 'locationsDocId' with the actual document ID

    // Update the document by adding new locations using arrayUnion
    await updateDoc(docRef, {
      locations: arrayUnion(...locationsToAdd), // Spread the array to pass each location as a separate argument
    });

    console.log('Locations updated successfully!');
  } catch (error) {
    console.error('Error updating locations:', error);
  }
};

const processItemsAndGetLocations = async (db, items) => {
  // from the items, get the locations
  const locations = _.uniq(_.map(items, 'location'));

  await updateInventoryLocations(db, locations);
};

const addProducts = async (db, items, callback, locations) => {
  await processItemsAndGetLocations(db, items, locations);

  const requests = _.map(items, (item) =>
    getInventory(db, item.sku, item.location),
  );

  Promise.all(requests)
    .then((rsps) => {
      console.log('All Arts Uploaded!');

      const reqs = [];
      for (let i = 0; i < rsps.length; i++) {
        const _item = { ..._.find(items, { sku: rsps[i].sku }) };
        if (rsps[i].exists) {
          _item.id = rsps[i].id;
          _item.name = rsps[i].data.name;
          _item.quantity = _item.quantity + parseInt(rsps[i].data.quantity);
          reqs.push(updateInventory(db, _item));
        } else {
          reqs.push(addInventory(db, _item));
        }
      }
      Promise.all(reqs)
        .then(() => {
          if (callback) {
            callback(true);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.code);
          if (callback) {
            callback(false);
          }
        });
    })
    .catch((err) => {
      console.log(err.code);
      if (callback) {
        callback(false);
      }
    });
};

const getBoxset = (db, sku) =>
  new Promise((resolve, reject) => {
    const q = query(
      collection(db, collections.BOXSETS),
      where('sku', '==', sku),
    );

    onSnapshot(q, (querySnapshot) => {
      let _item = { exists: false, sku: '', name: name, items: [] };
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data();

          if (!data.toggles) {
            _item = {
              ...data,
              exists: true,
              id: doc.id,
              toggles: [true, true, true, true, true],
            };
          } else {
            _item = { ...data, exists: true, id: doc.id };
          }
        }
      });
      resolve(_item);
    });
  });

const exportProducts = async (db, sort, callback) => {
  const rows = [['sku', 'quantity', 'location', 'parent']];
  let csvContent = '';
  rows.forEach(function (rowArray) {
    const row = rowArray.join(',');
    csvContent += row + '\r\n';
  });

  let last = null,
    totalCount = 0,
    matchedCount = 0;
  const direction = sort === 'name' ? 'asc' : 'desc';

  do {
    // code block to be executed

    const q = query(
      collection(db, collections.INVENTORY),
      orderBy('name'),
      startAfter(last),
      limit(1000),
    );

    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      totalCount += snapshot.docs.length;
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        if (doc.exists) {
          const data = doc.data();
          console.log(
            `Inventory SKU:'${data.sku}', Boxset:${data.isBoxset}, Location:${data.location?.toUpperCase()}`,
          );
          if (!!data.sku) {
            csvContent += `${data.sku},${data.quantity},${data.location?.toUpperCase()},${data.parent ? data.parent : ''}\r\n`;
            matchedCount++;
          }
        }
      }
    }
    // Get the last document
    last = snapshot.docs[snapshot.docs.length - 1];
    console.log('Length: ', snapshot.docs.length);
  } while (last);

  console.log('Total Count: ', totalCount);
  console.log('Matched Count: ', matchedCount);
  const blob = new Blob(['\ufeff', csvContent]);
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;

  const baseBatchId = Date.now();
  downloadLink.download = `Exports_${baseBatchId}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  callback(matchedCount);
};

// Helper function to construct the result
const constructResult = (doc, csvItem) => {
  const data = doc ? doc.data() : {};
  return {
    exists: !!doc,
    sku: csvItem.sku,
    name: data.name || '',
    isMask: data.isMask || false,
    qty: csvItem.quantity,
    location: csvItem.location?.toUpperCase() || '',
    parent: csvItem.parent || '',
    format: data.format || '',
    size: data.size || '',
    type: data.type || '',
    style: data.style || '',
  };
};

const getInventoryProducts = async (db, items) => {
  const skus = items.map((item) => item.sku);

  // Helper function to query a collection
  const queryCollection = async (collectionName, sku) => {
    const q = query(
      collection(db, collectionName),
      where('sku', '==', sku),
      limit(1),
    );
    const snap = await getDocs(q);
    return snap.docs[0] || null; // Return the document if found, otherwise null
  };

  // Fetch data for all SKUs
  const results = await Promise.all(
    skus.map(async (sku, index) => {
      const csvItem = items[index];

      // Try fetching from PRODUCTS
      let doc = await queryCollection(collections.PRODUCTS, sku);

      // Fallback to BACKUP_PRODUCTS if not found
      if (!doc) {
        console.log(
          `SKU: ${sku} not found in PRODUCTS. Checking VARIANTS PRODUCTS...`,
        );
        doc = await queryVariantProducts(db, sku, collections.PRODUCTS);
        if (!doc) {
          console.log(
            `SKU: ${sku} not found in VARIANTS PRODUCTS. Checking BOXSETS...`,
          );
          doc = await queryCollection(collections.BOXSETS, sku);
          if (!doc) {
            console.log(
              `SKU: ${sku} not found in BOXSETS. Checking VARIANTS BOXSETS...`,
            );
            doc = await queryVariantProducts(db, sku, collections.BOXSETS);
          }
        }
      }

      // Construct the result
      return constructResult(doc, csvItem);
    }),
  );

  return results;
};

const queryVariantProducts = async (db, sku, collectionToQuery) => {
  const q = query(
    collection(db, collectionToQuery),
    where('variants', 'array-contains', sku),
    limit(1),
  );
  const snap = await getDocs(q);
  return snap.docs[0] || null;
};

const getInventoryItems = (db, items) => {
  const requests = _.map(items, (item) =>
    getInventory(db, item.sku, item.location),
  );

  // Make requests
  return Promise.all(requests)
    .then((results) => {
      const _items = _.map(results, (item) => {
        if (!item.exists) {
          return {
            sku: item.sku,
            location: '',
            quantity: '',
            notes: 'Product not found',
          };
        }
        return {
          sku: item.sku,
          location: item.data.location?.toUpperCase(),
          quantity: item.data.quantity,
          notes: '',
        };
      });

      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
};

const getInventoryBoxsets = (db, items) => {
  const skus = items.map((item) => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(
      collection(db, collections.BOXSETS),
      where('sku', '==', sku),
      limit(1),
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);
      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();

          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            qty: items[index].qty,
            location: !!items[index].location ? items[index].location : '',
            format: '',
            size: '',
            type: '',
            style: '',
          };
        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            qty: items[index].qty,
            location: !!items[index].location ? items[index].location : '',
            format: '',
            size: '',
            type: '',
            style: '',
          };
        }
      });

      return _items;
    })
    .catch((error) => {
      console.log('getBoxsetsBySku: ', error);
    });
};

const getInventoryVariantProducts = (db, items) => {
  const skus = items.map((item) => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(
      collection(db, collections.PRODUCTS),
      where('variants', 'array-contains', sku),
      limit(1),
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);

      const _items = _.map(docs, (doc, index) => {
        const csvItem = items[index];
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            isMask: data.isMask,
            qty: csvItem.qty,
            location: !!csvItem.location ? csvItem.location : '',
            parent: !!csvItem.parent ? csvItem.parent : '',
            format: data.format,
            size: data.size,
            type: data.type,
            style: data.style,
          };
        } else {
          return {
            exists: false,
            sku: csvItem.sku,
            name: '',
            isMask: false,
            qty: csvItem.qty,
            location: !!csvItem.location ? csvItem.location : '',
            parent: !!csvItem.parent ? csvItem.parent : '',
            format: '',
            size: '',
            type: '',
            style: '',
          };
        }
      });
      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
};

export {
  getInvetoryUnits,
  getInventoryItems,
  addProducts,
  addBulkProducts,
  updateInventory,
  deleteInventory,
  exportProducts,
  getInventoryProducts,
  getInventoryBoxsets,
  getInventoryVariantProducts,
  getInventoryLocations,
};
