import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

export const addMixMatchCollection = async (db, collectionData) => {
  try {
    collectionData.collection = _.toLower(collectionData.collection);

    // create the document in the collection
    const docRef = await addDoc(
      collection(db, collections.MIX_MATCH_COLLECTIONS),
      {
        ...collectionData,
      },
    );

    console.log('Document written with ID: ', docRef.id);

    return docRef;
  } catch (error) {
    console.log('Error addMixMatchCollection: ', error);
  }
};

export const seeIfCollectionIsDuplicate = async (db, collectionName) => {
  try {
    console.log('Checking for duplicates: ', collectionName);
    const collectionRef = collection(db, collections.MIX_MATCH_COLLECTIONS);
    const q = query(collectionRef, where('collection', '==', collectionName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No duplicates found');
      return false;
    }

    console.log('Duplicates found: ', querySnapshot.docs.length);

    return true;
  } catch (error) {
    console.log('Error seeIfCollectionIsDuplicate: ', error);
  }
};

export const getCollections = async (db) => {
  try {
    const collectionRef = collection(db, collections.MIX_MATCH_COLLECTIONS);
    const q = query(collectionRef, orderBy('collection'));
    const querySnapshot = await getDocs(q);

    const foundCollections = [];

    querySnapshot.forEach((doc) => {
      foundCollections.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return foundCollections;
  } catch (error) {
    console.log('Error getCollections: ', error);
  }
};

export const deleteMixMatchCollection = async (db, name) => {
  try {
    // find by name where collection: name
    const collectionRef = collection(db, collections.MIX_MATCH_COLLECTIONS);
    const q = query(collectionRef, where('collection', '==', name));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No matching documents.');
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    return;
  } catch (error) {
    console.error('Error removing document: ', error);
  }
};

export const updateMixMatchCollection = async (db, collectionData) => {
  try {
    console.log('Updating collection: ', collectionData);
    // find by name then update
    const collectionRef = collection(db, collections.MIX_MATCH_COLLECTIONS);
    const q = query(
      collectionRef,
      where('collection', '==', collectionData.collection),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log('No matching documents.');
      return;
    }

    querySnapshot.forEach(async (doc) => {
      await updateDoc(doc.ref, collectionData);
    });

    return;
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};
