import React from 'react';
import { NavLink } from 'react-router-dom';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import IconButton from '@mui/material/IconButton';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

import * as ROUTES from '../../constants/routes';

const AnalyticsSubNavBar = ({ classes }) => (
  <div className={classes.secondaryMenu}>
    <div className="Container">
      <div className={classes.toolBar}>
        <NavLink
          to={ROUTES.ANALYTICS}
          exact
          className={classes.navSubLink}
          activeClassName="current"
        >
          <IconButton
            aria-label="Inline Order Queue"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            Workload
          </IconButton>
        </NavLink>

        {/* <NavLink
            to={ROUTES.CUSTOM_ORDER}
            className={classes.navSubLink}
            activeClassName="current"
          >
            Consumtion
          </NavLink> 
          */}

        {/* <NavLink
            to={ROUTES.CUSTOM_ORDER}
            className={classes.navSubLink}
            activeClassName="current"
          >
            Defects
          </NavLink> 
          */}
      </div>
    </div>
  </div>
);

export default AnalyticsSubNavBar;
