import React from 'react';
import { TableRow, TableCell, Typography, Box } from '@mui/material';

const SubItemRow = ({ subItem, color, index, label }) => (
  <TableRow key={`mix_match_sub_${index}`} sx={{ position: 'relative' }}>
    <TableCell padding="checkbox" sx={{ borderBottom: 'none' }} />
    <TableCell align="left" sx={{ borderBottom: 'none' }}>
      <Typography variant="tableText" sx={{ color }}></Typography>
    </TableCell>
    <TableCell align="left" sx={{ borderBottom: 'none' }}>
      <Typography variant="tableText" sx={{ color }}></Typography>
    </TableCell>

    <TableCell
      align="left"
      sx={{
        borderBottom: 'none',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '20px',
          height: '100%',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            left: '50%',
            top: 0,
            bottom: 0,
            width: '2px',
            backgroundColor: color,
            transform: 'translateX(-50%)',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            left: '45%',
            top: '50%',
            width: '15px', // Length of the horizontal line, adjust as needed
            height: '2px',
            backgroundColor: color,
            transform: 'translate(-50%, -50%)',
          },
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          width: '2px',
          backgroundColor: color,
          height: label === 'Second' ? '52%' : '110%',
        }}
      />
      <Typography variant="tableText" sx={{ color, marginLeft: '28px' }}>
        {subItem.sku}
      </Typography>
    </TableCell>

    <TableCell align="left" sx={{ borderBottom: 'none', width: '20%' }}>
      <Typography variant="tableText" sx={{ color }}>
        {label} Sock
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{ borderBottom: 'none' }}>
      <Typography variant="tableText" sx={{ color }}>
        Quantity: {subItem._quantity}
      </Typography>
    </TableCell>
    <TableCell align="left" sx={{ borderBottom: 'none' }}>
      <Typography variant="tableText" sx={{ color }}>
        {subItem.location === ''
          ? 'No Location found'
          : `Location: ${subItem.location}`}
      </Typography>
    </TableCell>
  </TableRow>
);

const styles = {};

export default SubItemRow;
