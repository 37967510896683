import React from 'react';
import { Button, Typography, CircularProgress } from '@mui/material';

const LoadingButton = ({ loading, text, onClick }) => (
  <Button
    type="submit"
    fullWidth
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={loading}
    sx={{
      backgroundColor: '#000', // Use your COMMON_COLORS.blackBackground
      borderRadius: '8px',
      '&:hover': {
        backgroundColor: '#000', // Prevent color change on hover
      },
      textTransform: 'none', // Ensure text stays in the case provided
    }}
  >
    {!loading ? (
      <Typography
        sx={{
          color: 'white',
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '32px',
        }}
      >
        {text}
      </Typography>
    ) : (
      <CircularProgress color="inherit" size={24} />
    )}
  </Button>
);

export default LoadingButton;
