import {
  INLINE_ORDERS_FETCHED,
  FETCH_INLINE_ORDERS_START,
  FETCH_INLINE_ORDERS_FAILURE,
  UPDATE_INLINE_ORDERS,
  FETCH_ALL_INLINE_ORDERS,
  SHOW_FILTER_DATA,
  FILTERED_ORDERS_ARRAY,
  UPDATE_PAGE_NUMBER,
  UPDATE_ROWS_PER_PAGE,
  UPDATE_NEXT_PAGE_DATA,
  DID_USE_FILTER,
  USE_SEARCH,
  GET_NEXT_SET_OF_ORDERS,
  RESET_FILTERS,
  REMOVE_INLINE_ORDERS,
} from './types';

export const resetInlineFilters = () => ({
  type: RESET_FILTERS,
});

export const injectInlineOrders = (inlineOrdersData) => ({
  type: INLINE_ORDERS_FETCHED,
  payload: inlineOrdersData,
});

export const fetchAllInlineOrders = (inlineOrders) => ({
  type: FETCH_ALL_INLINE_ORDERS,
  payload: inlineOrders,
});

export const fetchInlineOrdersStart = () => ({
  type: FETCH_INLINE_ORDERS_START,
});

export const fetchInlineOrdersFailure = (error) => ({
  type: FETCH_INLINE_ORDERS_FAILURE,
  payload: error,
});

export const updateInlineOrders = (updatedOrders) => ({
  type: UPDATE_INLINE_ORDERS,
  payload: updatedOrders,
});

export const showFilterData = (filterData) => ({
  type: SHOW_FILTER_DATA,
  payload: filterData,
});

export const filteredOrdersArray = (option, filterData, shippingData) => ({
  type: FILTERED_ORDERS_ARRAY,
  payload: { option, filterData, shippingData },
});

export const updateRowsPerPage = (rowsPerPage) => ({
  type: UPDATE_ROWS_PER_PAGE,
  payload: rowsPerPage,
});

export const updatePageNumber = (pageNumber) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: pageNumber,
});

export const handleNextPageData = (pageNumber) => ({
  type: UPDATE_NEXT_PAGE_DATA,
  payload: pageNumber,
});

export const usedFilter = (didUseFilter) => ({
  type: DID_USE_FILTER,
  payload: didUseFilter,
});

export const useSearchKeys = (searchKeys) => ({
  type: USE_SEARCH,
  payload: { searchKeys },
});

export const getNextSetOfOrders = (selectedOrders) => ({
  type: GET_NEXT_SET_OF_ORDERS,
  payload: selectedOrders,
});

export const removeInlineOrders = (orderNumbers) => ({
  type: REMOVE_INLINE_ORDERS,
  payload: orderNumbers,
});
