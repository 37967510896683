export const palette = {
  productPurple: '#9714EA',

  errors: {
    tableRowError: '#E0491B',
  },

  profileProductsColors: {
    mediumBlue: '#2870EA',
    lightGrey: 'rgba(0,0,0,0.08)',
    orange: '#FAB270',
    lightestBlue: '#D6F2FD',
    lightBlue: '#6BC7F6',
    red: '#F36668',
    green: '#4CAF50',
  },

  analyticsColors: {
    warningRed: '#E0491B',
    informativeRedLight: '#F36668',
    backgroundColor: 'rgb(241, 241, 241)',
  },
};

export const COMMON_COLORS = {
  blackBackground: '#1D1D1D',
  greyTextFields: '#F5F6F7',
  babyBlue: '#F0F8FF',
  babyPink: '#FFF2F2',
  cancelGrey: '#F3F7FF',
  buttonBlue: '#3477F5',
};

export const GRAPH_COLORS = {
  green: '#40BF14',
  lightGreen: '#64E338',
  blue: '#2298DB',
  lightBlue: '#46BCFF',
};

export const predefinedGreenColors = [
  '#359F11',
  '#40BF14',
  '#4ADE17',
  '#64E338',
  '#7DE759',
  '#97EC79',
  '#B0F19A',
];

export const predefinedBlueColors = [
  '#1D7FB7',
  '#2298DB',
  '#28B1FF',
  '#46BCFF',
  '#65C7FF',
];
