import * as React from 'react';

const SockSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={45} // Decrease this value
    height={45} // Decrease this value
    viewBox="0 0 150 150" // Keep this the same for proper scaling
    {...props}
  >
    <path
      d="M52.148 120.527a18.91 18.91 0 0 0 10.502-3.183l30.87-20.567c5.275-3.511 8.432-9.405 8.448-15.771V21.534h-37.93v49.319L41.62 85.786c-8.7 5.796-11.061 17.588-5.269 26.294a18.955 18.955 0 0 0 15.797 8.447zM89.336 89.56l-3.317-7.157 7.576-.521c-.264 3.065-1.78 5.845-4.259 7.678zm4.372-45.001H72.296V41.87h21.412v2.689zm0-14.765v6.657H72.296v-6.657h21.412zM72.296 75.273V49.979h21.412l-.006 26.375-12.055.833a2.758 2.758 0 0 0-2.183 1.35 2.738 2.738 0 0 0-.124 2.554l5.388 11.615-25.321 16.879-12.55-17.363 25.439-16.949zM42.926 96.19l11.389 15.755c-.715.151-1.425.323-2.167.323a10.708 10.708 0 0 1-8.926-4.769c-2.333-3.507-2.279-7.889-.296-11.309z"
      fill={props.color ? props.color : 'white'}
    />
  </svg>
);
export default SockSvg;
