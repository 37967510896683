import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import { collections, TAGS } from '../constants/defines';

const getTags = (db, limit, callback) => {
  const q = query(collection(db, collections.TAGS), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      items.push({ ...data, name: data.name });
    });
    callback(items);
  });
};

const createTag = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.TAGS), newItem)
      .then((docRef) => {
        console.log('New Tag created with ID: ', docRef.id);
        resolve();
      })
      .catch((error) => {
        console.error('Error adding Tag: ', error);
        reject();
      });
  });
};

const updateTag = (db, item) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.TAGS, item.id);
    updateDoc(docRef, {
      name: item.name,
    })
      .then(() => {
        console.log('Tag successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Tag: ', error);
        reject();
      });
  });

const deleteTag = (db, item) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.TAGS, item.id))
      .then(() => {
        console.log('Tag successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Tag: ', error);
        reject();
      });
  });

export { getTags, createTag, updateTag, deleteTag };
