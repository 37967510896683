import React from 'react';
import { Box, Typography } from '@mui/material';

// Helper function to calculate total for a category
const calculateTotal = (categoryData) =>
  Object.values(categoryData).reduce((sum, value) => sum + value, 0);

// Helper function to merge "Men's Boxer Briefs" into "Boxer"
const preprocessTotals = (totals) => {
  if (totals["Men's Boxer Briefs"] && totals['Boxer']) {
    // Merge sizessizes from "Men's Boxer Briefs" into "Boxer"
    Object.entries(totals["Men's Boxer Briefs"]).forEach(([size, count]) => {
      totals['Boxer'][size] = (totals['Boxer'][size] || 0) + count;
    });
    // Remove "Men's Boxer Briefs" after merging
    delete totals["Men's Boxer Briefs"];
  }
  if (totals['Underwear'] && totals['Boxer']) {
    // Merge sizessizes from "Men's Boxer Briefs" into "Boxer"
    Object.entries(totals['Underwear']).forEach(([size, count]) => {
      totals['Boxer'][size] = (totals['Boxer'][size] || 0) + count;
    });
    // Remove "Men's Boxer Briefs" after merging
    delete totals['Underwear'];
  }
  if (totals['Socks'] && totals['Crew']) {
    Object.entries(totals['Socks']).forEach(([size, count]) => {
      totals['Crew'][size] = (totals['Crew'][size] || 0) + count;
    });
    delete totals['Socks'];
  }
  return totals;
};

export const DisplayTotals = ({ totalsForPrinting }) => {
  // deep copy the totalsForPrinting
  let processedTotals = JSON.parse(JSON.stringify(totalsForPrinting));
  // Preprocess the totals to combine categories as needed
  processedTotals = preprocessTotals(processedTotals);

  // Sorting the categories to put "Crew" first and "Boxer" last
  const sortedCategories = Object.keys(processedTotals).sort((a, b) => {
    if (a === 'Crew') {
      return -1;
    }
    if (b === 'Crew') {
      return 1;
    }
    if (a === 'Boxer') {
      return 1;
    }
    if (b === 'Boxer') {
      return -1;
    }
    return 0;
  });

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={4}
      width="80%" // Ensure it takes the full width of the parent
    >
      {sortedCategories.map((category) => {
        const total = calculateTotal(processedTotals[category]);
        return (
          <Box
            key={category}
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1} // Ensure each category box takes equal space
          >
            {/* Display the category and total */}
            <Typography variant="h6" sx={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {category === 'Crew'
                  ? 'CREW SOCKS'
                  : category === 'Boxer'
                    ? "Men's Boxer Briefs"
                    : category}
              </span>{' '}
              - {total}
            </Typography>

            {/* Display the sizes and their respective totals */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center" // Center the sizes row
              gap={2}
            >
              {Object.keys(processedTotals[category]).map((size) => (
                <Typography key={size} variant="body1">
                  <span style={{ fontWeight: 'bold' }}>
                    {processedTotals[category][size]}
                  </span>{' '}
                  {size}
                </Typography>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
