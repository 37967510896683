import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export function ToastCopyCLip({ show, message }) {
  const [progress, setProgress] = useState(0);
  const duration = 2000; // Define the duration once for consistent use

  useEffect(() => {
    if (show) {
      setProgress(0); // Reset progress when the Snackbar opens
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer); // Ensure the timer stops when progress is complete
            return 100;
          }
          const diff = 100 / (duration / 125); // Increment to complete the bar in the specified duration
          return oldProgress + diff;
        });
      }, 100); // The frequency of updates; adjust if needed to more closely match the duration

      return () => {
        clearInterval(timer); // Clear timer when the component unmounts or the Snackbar closes
      };
    }
  }, [show]);

  return (
    <Snackbar
      open={show}
      autoHideDuration={duration}
      onClose={() => setProgress(0)} // Reset progress when closing
      TransitionComponent={SlideTransition}
      message={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        '.MuiSnackbar-root': {
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          fontSize: '1rem',
          maxWidth: 300,
          '& .MuiSnackbarContent-root': {
            borderRadius: '5px',
            padding: '6px 24px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
            position: 'relative',
          },
        },
      }}
      action={
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%', // Ensuring it spans the entire width without translation
            borderRadius: '5px 5px 0 0',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly see-through to blend with Snackbar
            '& .MuiLinearProgress-bar': {
              borderRadius: '5px 5px 0 0',
            },
          }}
        />
      }
    />
  );
}
