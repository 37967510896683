import _ from 'lodash';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

// Define findLocationDataByLocation with unsubscribe
const findLocationDataByLocation = (db, location, callback, setUnsubscribe) => {
  const q = query(
    collection(db, collections.INVENTORY),
    where('location', '==', location),
  );

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const data = [];
    querySnapshot.forEach((doc) => {
      // push data along with the id
      data.push({ id: doc.id, ...doc.data() });
    });
    callback(data);
  });

  // Save the unsubscribe function
  if (setUnsubscribe) {
    setUnsubscribe(unsubscribe);
  }
};

export { findLocationDataByLocation };
