import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    '& td': {
      position: 'relative',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)',
  },
  tableHeadCell: {
    lineHeight: '27.5px',
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500!important',
    color: '#000000',
    letterSpacing: '1.5px',
  },
}));

function descendingComparator(a, b, orderBy) {
  let aVal, bVal;
  if (orderBy === 'date') {
    // Check if a[orderBy] or b[orderBy] is undefined and handle accordingly
    aVal = a[orderBy] ? a[orderBy].valueOf() : 0;
    bVal = b[orderBy] ? b[orderBy].valueOf() : 0;
  } else {
    aVal = a[orderBy] !== undefined ? a[orderBy] : '';
    bVal = b[orderBy] !== undefined ? b[orderBy] : '';
  }

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { cells, useCheckbox, order, orderBy, onRequestSort } = props;
  const classes = useStyles();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {useCheckbox && <TableCell padding="checkbox"> </TableCell>}
        {cells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width }}
            className={classes.tableHeadCell}
          >
            {headCell.enableSort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableHeadLabel}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Typography
                    variant="tableText"
                    className={classes.visuallyHidden}
                  >
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Typography>
                ) : null}
              </TableSortLabel>
            ) : (
              <Typography variant="tableText">{headCell.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export { EnhancedTableHead, getComparator, stableSort };
