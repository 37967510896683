import { doc, getDoc } from 'firebase/firestore';

import { API, collections } from '../constants/defines';
import { lookForAnyOrderByTrackDb } from './lookForAnyOrderByTrack';

export const lookForAnyOrder = async (number, db) => {
  let orderId = number.trim();
  const isTrackingNumber = orderId.length > 14;

  if (isTrackingNumber) {
    // If it's a tracking number, potentially slice the first 8 characters
    if (/^\d+$/.test(orderId)) {
      orderId = orderId.slice(8);
    }

    // Look for the order by tracking number
    const order = await lookForAnyOrderByTrackDb(orderId, db);

    if (order) {
      console.log('Order found in Firestore by tracking number: ', order);
      return { found: true, data: order[0] };
    }

    try {
      // Fetch order info from ShipStation
      console.log('Fetching order info from ShipStation');
      const response = await fetch(API.getOrderInfoByTrack, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ track: orderId }),
      });
      const data = await response.json();

      if (data?.order) {
        orderId = data.order;
      }
    } catch (error) {
      console.error('Error searching for order in ShipStation:', error);
    }
  }

  console.log('Searching for order by ID in Firestore collections...');

  try {
    // List of collections to search
    const collectionsArray = [
      collections.INLINE_PRODUCTS_QUEUE,
      collections.CUSTOM_PRODUCTS_QUEUE,
      collections.DS_INLINE_QUEUE,
      collections.DS_CUSTOM_QUEUE,
    ];

    // Query all collections in parallel
    const results = await Promise.all(
      collectionsArray.map(async (collectionName) => {
        const docRef = doc(db, collectionName, orderId);
        const result = await getDoc(docRef);

        if (result.exists()) {
          return {
            found: true,
            data: {
              collection: collectionName,
              id: result.id,
              ...result.data(),
            },
          };
        }
        return null; // Return null if not found
      }),
    );

    // Find the first non-null result
    const foundResult = results.find((result) => result !== null);
    if (foundResult) {
      console.log('Order found in Firestore by ID:', foundResult);
      return foundResult;
    }

    return { found: false };
  } catch (error) {
    console.error('Error searching for order in Firestore:', error);
    throw error;
  }
};
