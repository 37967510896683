import React from 'react';
import IconButton from '@mui/material/IconButton';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <IconButton
    aria-label="account of current user"
    aria-controls="menu-appbar"
    aria-haspopup="true"
    color="inherit"
    onClick={firebase.doSignOut}
  >
    <ExitToAppIcon />
  </IconButton>
);

export default withFirebase(SignOutButton);
