import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, Grid, MenuItem, Select } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import { BootstrapInput } from '../Page/input';
import { getMatchedCropSettingByFront } from '../../db/cropSettings';
import UnderwearMockups from './underwearMockups';
import { ART_TYPE, TYPE_OF_PRODUCT } from '../../constants/defines';
import renderSide from './renderSide';
import useAspectRatio from '../../hooks/useAspectRatio';
import VariantsModal from './variantsModal';
import { deleteImage } from '../../module/storage';
import { filterFormats } from './Products.constants';

const colors = {
  lightGrey: 'rgba(0,0,0,0.04)',
};
const SIDES = {
  FRONT: 'front',
  BACK: 'back',
  LEFT: 'left',
  RIGHT: 'right',
  ONE: 'one',
  KNITTED_ONE: 'knittedOne',
  CUT_AND_SEW: 'cutAndSew',
  PACKAGING: 'packaging',
  BOXED: 'boxed',
};

const COLORS = {
  mediumBlue: '#2870EA',
  lightGrey: 'rgba(0,0,0,0.08)',
  orange: '#FAB270',
  lightestBlue: '#D6F2FD',
  lightBlue: '#6BC7F6',
};

export const RevampedProductProfile = ({
  handleCloseDialog,
  product,
  load,
  renderVendorAttributes,
  strPtSize,
  strPtFormat,
  handleOpenDeleteDialog,
  handleOpenDuplicateDialog,
  handleSave,
  frontProgress,
  backProgress,
  renderMockup,
  handleChange,
  types,
  formats,
  sizes,
  rockemStyles,
  frontImg,
  backImg,
  firebase,
  storage,
  classes,
  dropzoneRef1,
  dropzoneRef2,
  handleDropFront,
  handleDropBack,
  setFrontDimension,
  cropSettings,
  handleTagsChange,
  tags,
  renderTags,
  renderVariants,
  StyledBootstrapInput,
  TopStyledBootstrapInput,
  handleCloseModal,
  openVariantsModal,
  handleOpenModal,
  showVendorAttributes,
  setShowVendorAttributes,
  renderTemplates,
  disabledButtons,
  backPath,
  frontPath,
  setBackDimension,
  isUnderwear,
  error,
  errorMsg,
  renderVariantsChild,
  hyperOpticIdenticalImage,
  hyperOpticLeftImage,
  hyperOpticRightImage,
  handleDropHyperOptic,
  hyperOpticIdenticalPath,
  hyperOpticRightPath,
  hyperOpticLeftPath,
  isBoxedImage,
  isBoxedImagePath,
  handleDropBoxedImage,
  renderSingleSkuModal,
}) => {
  if (product.type.toLowerCase() === 'masks') {
    return (
      // masks are not supported yet
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            variant="close"
            size="large"
            onClick={handleCloseDialog}
            sx={styles.variantsIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography variant="h6">Masks are not supported yet.</Typography>
        </Box>
      </Box>
    );
  }

  const [doesHaveFrontImage, setDoesHaveFrontImage] = React.useState(true);
  const [doesHaveBackImage, setDoesHaveBackImage] = React.useState(true);
  const [doesHaveIdenticalHyperOptic, setDoesHaveIdenticalHyperOptic] =
    React.useState(true);
  const [doesHaveLeftHyperOptic, setDoesHaveLeftHyperOptic] =
    React.useState(true);
  const [doesHaveRightHyperOptic, setDoesHaveRightHyperOptic] =
    React.useState(true);

  const [doesHaveBoxedImage, setDoesHaveBoxedImage] = React.useState(true);
  const [showBoxedImage, setShowBoxedImage] = React.useState(true);

  const [showFrontImage, setShowFrontImage] = React.useState(true);
  const [showBackImage, setShowBackImage] = React.useState(true);
  const [showIdenticalHyperOptic, setShowIdenticalHyperOptic] =
    React.useState(true);
  const [showLeftHyperOptic, setShowLeftHyperOptic] = React.useState(true);
  const [showRightHyperOptic, setShowRightHyperOptic] = React.useState(true);

  const [hoverStates, setHoverStates] = React.useState({});
  const [artType, setArtType] = React.useState('');

  const buttonData = [
    { value: 0, placeholder: 'Product Attributes' },
    { value: 1, placeholder: 'Vendor Attributes' },
  ];

  // Initialize with the first button selected by default
  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState(
    buttonData[0].value,
  );

  const handleButtonClick = (index) => {
    // Check if the clicked button is already selected
    if (selectedButtonIndex === index) {
      // If yes, do nothing and return early
      return;
    }

    // Update the selected button index only if a different button is clicked
    setSelectedButtonIndex(index);

    // Based on the clicked button, toggle the display of vendor attributes
    setShowVendorAttributes(index === 1);
  };

  React.useEffect(() => {
    if (!doesHaveFrontImage) {
      setShowFrontImage(false);
    }

    if (!doesHaveBackImage) {
      setShowBackImage(false);
    }

    if (!doesHaveIdenticalHyperOptic) {
      setShowIdenticalHyperOptic(false);
    }

    if (!doesHaveLeftHyperOptic) {
      setShowLeftHyperOptic(false);
    }

    if (!doesHaveRightHyperOptic) {
      setShowRightHyperOptic(false);
    }

    if (!doesHaveBoxedImage) {
      setShowBoxedImage(false);
    }
  }, [
    doesHaveFrontImage,
    doesHaveBackImage,
    doesHaveIdenticalHyperOptic,
    doesHaveRightHyperOptic,
    doesHaveLeftHyperOptic,
    doesHaveBoxedImage,
    product,
  ]);

  const handleMouseEnter = (index) => {
    setHoverStates((prev) => ({ ...prev, [index]: true }));
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prev) => ({ ...prev, [index]: false }));
  };

  const handleDeleteProductFunc = (sideDeleted) => {
    switch (sideDeleted) {
      case SIDES.FRONT:
        product.frontArt = {};
        product.pathFront = '';
        setDoesHaveFrontImage(false);
        setShowFrontImage(false);
        break;
      case SIDES.BACK:
        product.backArt = {};
        product.pathBack = '';
        setDoesHaveBackImage(false);
        setShowBackImage(false);
        break;
      case SIDES.ONE:
        product.hyperOpticIdenticalArt = {};
        product.pathHyperOpticIdentical = '';
        setDoesHaveIdenticalHyperOptic(false);
        setShowIdenticalHyperOptic(false);
        break;
      case SIDES.LEFT:
        product.leftArt = {};
        product.pathLeft = '';
        setDoesHaveLeftHyperOptic(false);
        setShowLeftHyperOptic(false);
        break;
      case SIDES.RIGHT:
        product.rightArt = {};
        product.pathRight = '';
        setDoesHaveRightHyperOptic(false);
        setShowRightHyperOptic(false);
        break;
      case SIDES.BOXED:
        product.isBoxedImageArt = {};
        product.isBoxedImagePath = '';
        setDoesHaveBoxedImage(false);
        setShowBoxedImage(false);
        break;
      default:
        break;
    }
  };

  const getArtTypeByDimensions = () => {
    // Check if frontArt and backArt have the necessary properties
    const hasFrontArtDimensions =
      product.frontArt &&
      typeof product.frontArt.width === 'number' &&
      typeof product.frontArt.height === 'number';
    const hasBackArtDimensions =
      product.backArt &&
      typeof product.backArt.width === 'number' &&
      typeof product.backArt.height === 'number';

    // Attempt to find crop settings for the front art if dimensions are available
    const cropSettingFront = hasFrontArtDimensions
      ? getMatchedCropSettingByFront(
          cropSettings,
          product.frontArt.width,
          product.frontArt.height,
        )
      : null;

    // If found, use its art type
    if (cropSettingFront) {
      setArtType(cropSettingFront.artType);
      return;
    }

    // Attempt to find crop settings for the back art if dimensions are available
    const cropSettingBack = hasBackArtDimensions
      ? getMatchedCropSettingByFront(
          cropSettings,
          product.backArt.width,
          product.backArt.height,
        )
      : null;

    // If found, use its art type
    if (cropSettingBack) {
      setArtType(cropSettingBack.artType);
      return;
    }

    // If neither front nor back art settings are found or if they're missing necessary dimensions, default to a legacy art type
    setArtType(ART_TYPE.LEGACY);
  };

  React.useEffect(() => {
    getArtTypeByDimensions();
  }, [product]);

  React.useEffect(() => {
    if (backPath) {
      product.pathBack = backPath;
      product.backArt = {
        ...product.backArt,
        path: backPath,
      };
      product.isCustomProduct = false;
      setDoesHaveBackImage(true);
      setShowBackImage(true);
    }

    if (frontPath) {
      product.pathFront = frontPath;
      product.frontArt = {
        ...product.frontArt,
        path: frontPath,
      };
      product.isCustomProduct = false;
      setDoesHaveFrontImage(true);
      setShowFrontImage(true);
    }

    if (hyperOpticIdenticalPath) {
      product.pathHyperOpticIdentical = hyperOpticIdenticalPath;
      product.hyperOpticIdenticalArt = {
        ...product.hyperOpticIdenticalArt,
        path: hyperOpticIdenticalPath,
      };
      product.isCustomProduct = false;
      setDoesHaveIdenticalHyperOptic(true);
      setShowIdenticalHyperOptic(true);
    }

    if (hyperOpticLeftPath) {
      product.pathLeft = hyperOpticLeftPath;
      product.leftArt = {
        ...product.leftArt,
        path: hyperOpticLeftPath,
      };
      product.isCustomProduct = false;
      setDoesHaveLeftHyperOptic(true);
      setShowLeftHyperOptic(true);
    }

    if (hyperOpticRightPath) {
      product.pathRight = hyperOpticRightPath;
      product.rightArt = {
        ...product.rightArt,
        path: hyperOpticRightPath,
      };
      product.isCustomProduct = false;
      setDoesHaveRightHyperOptic(true);
      setShowRightHyperOptic(true);
    }

    if (isBoxedImagePath) {
      product.isBoxedImagePath = isBoxedImagePath;
      product.isBoxedImageArt = {
        ...product.isBoxedImageArt,
        path: isBoxedImagePath,
      };
      product.isCustomProduct = false;
      setDoesHaveBoxedImage(true);
      setShowBoxedImage(true);
    }
  }, [
    backPath,
    frontPath,
    hyperOpticIdenticalPath,
    hyperOpticLeftPath,
    hyperOpticRightPath,
    isBoxedImagePath,
  ]);

  const isSockAndQuarter =
    filterFormats.find((format) => format.name === 'Quarter')?.val ===
      product.format && product.type === 'Socks';

  const sidesConfig = {
    front: {
      showImage: showFrontImage,
      handleMouseEnter: () => handleMouseEnter(SIDES.FRONT),
      handleMouseLeave: () => handleMouseLeave(SIDES.FRONT),
      handleDrop: handleDropFront,
      dropzoneRef: dropzoneRef1,
      img: frontImg,
      setDimension: setFrontDimension,
      progress: frontProgress,
      path: product.pathFront,
      setDoesHaveImage: setDoesHaveFrontImage,
      deleteFunc: () => handleDeleteProductFunc(SIDES.FRONT),
      hoverState: hoverStates[SIDES.FRONT],
      addPaddingForUnderwear: isUnderwear,
      isSockAndQuarter: isSockAndQuarter,
    },
    back: {
      showImage: showBackImage,
      handleMouseEnter: () => handleMouseEnter(SIDES.BACK),
      handleMouseLeave: () => handleMouseLeave(SIDES.BACK),
      handleDrop: handleDropBack,
      dropzoneRef: dropzoneRef2,
      img: backImg,
      setDimension: setBackDimension,
      progress: backProgress,
      path: product.pathBack,
      setDoesHaveImage: setDoesHaveBackImage,
      deleteFunc: () => handleDeleteProductFunc(SIDES.BACK),
      hoverState: hoverStates[SIDES.BACK],
      doNotContainImage: artType === ART_TYPE.LEGACY && !isUnderwear,
      addPaddingForUnderwear: isUnderwear,
      isSockAndQuarter: isSockAndQuarter,
    },
    identicalHyperOptic: {
      showImage: showIdenticalHyperOptic,
      handleMouseEnter: () => handleMouseEnter(SIDES.ONE),
      handleMouseLeave: () => handleMouseLeave(SIDES.ONE),
      handleDrop: handleDropHyperOptic,
      dropzoneRef: dropzoneRef2,
      img: hyperOpticIdenticalImage,
      setDimension: setBackDimension,
      progress: backProgress,
      path: product.pathHyperOpticIdentical,
      setDoesHaveImage: setDoesHaveIdenticalHyperOptic,
      deleteFunc: () => handleDeleteProductFunc(SIDES.ONE),
      hoverState: hoverStates[SIDES.ONE],
    },
    hyperOpticLeftConfig: {
      showImage: showLeftHyperOptic,
      handleMouseEnter: () => handleMouseEnter(SIDES.LEFT),
      handleMouseLeave: () => handleMouseLeave(SIDES.LEFT),
      handleDrop: handleDropHyperOptic,
      dropzoneRef: dropzoneRef2,
      img: hyperOpticLeftImage,
      setDimension: setBackDimension,
      progress: backProgress,
      path: product.pathLeft,
      setDoesHaveImage: setDoesHaveLeftHyperOptic,
      deleteFunc: () => handleDeleteProductFunc(SIDES.LEFT),
      hoverState: hoverStates[SIDES.LEFT],
    },
    hyperOpticRightConfig: {
      showImage: showRightHyperOptic,
      handleMouseEnter: () => handleMouseEnter(SIDES.RIGHT),
      handleMouseLeave: () => handleMouseLeave(SIDES.RIGHT),
      handleDrop: handleDropHyperOptic,
      dropzoneRef: dropzoneRef2,
      img: hyperOpticRightImage,
      setDimension: setBackDimension,
      progress: backProgress,
      path: product.pathRight,
      setDoesHaveImage: setDoesHaveRightHyperOptic,
      deleteFunc: () => handleDeleteProductFunc(SIDES.RIGHT),
      hoverState: hoverStates[SIDES.RIGHT],
    },
    boxedImage: {
      showImage: showBoxedImage,
      handleMouseEnter: () => handleMouseEnter(SIDES.BOXED),
      handleMouseLeave: () => handleMouseLeave(SIDES.BOXED),
      handleDrop: handleDropBoxedImage,
      dropzoneRef: dropzoneRef2,
      img: isBoxedImage,
      progress: backProgress,
      path: product.isBoxedImagePath,
      setDoesHaveImage: setDoesHaveBoxedImage,
      deleteFunc: () => handleDeleteProductFunc(SIDES.BOXED),
      hoverState: hoverStates[SIDES.BOXED],
      isBiggerItem: true,
      isBoxedImage: true,
    },
  };

  const renderHyperOpticArt = () => {
    // Hyper Optic Fraternal: Show Left and Right images
    if (product.pathLeft || product.pathRight) {
      return (
        <>
          {renderSide(
            sidesConfig.hyperOpticRightConfig,
            'right',
            styles,
            isUnderwear,
            artType,
            firebase,
            classes,
          )}
          {renderSide(
            sidesConfig.hyperOpticLeftConfig,
            'left',
            styles,
            isUnderwear,
            artType,
            firebase,
            classes,
          )}
        </>
      );
    }
    // Hyper Optic Identical: Show only Front (Identical) image
    else if (product.pathHyperOpticIdentical) {
      return renderSide(
        sidesConfig.identicalHyperOptic,
        '',
        styles,
        isUnderwear,
        artType,
        firebase,
        classes,
      );
    }
  };

  const renderBoxedImageArt = () =>
    renderSide(
      sidesConfig.boxedImage,
      '',
      styles,
      isUnderwear,
      artType,
      firebase,
      classes,
    );

  // Custom hook to calculate the aspect ratio of the product
  const aspectRatio = useAspectRatio(product);

  return (
    <Box style={{ overflow: 'hidden', height: '100%' }}>
      <DialogTitle sx={{ padding: '1.875rem 2.5rem .625rem' }}>
        {error && <Typography variant="errorText">{errorMsg}</Typography>}
        <IconButton
          aria-label="close"
          variant="close"
          size="large"
          onClick={handleCloseDialog}
          sx={styles.variantsIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Grid container spacing={2} sx={styles.header}>
        <Grid item xs={12} sm={6}>
          <TopStyledBootstrapInput
            placeholder="Product Name"
            value={product.name}
            name="name"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TopStyledBootstrapInput
            placeholder="Product Sku"
            name="sku"
            value={product.sku}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          {!showVendorAttributes && (
            <Box sx={styles.variantsIcon} onClick={handleOpenModal}>
              <AutoAwesomeMotionIcon sx={{ fontSize: 30 }} />
            </Box>
          )}
        </Grid>
      </Grid>

      {/* Attributes Section */}
      <Box
        sx={{
          ...styles.attributesSection,
          marginBottom: isUnderwear ? '3.75rem' : '.3125rem',
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* Box for buttons aligned to the left */}
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: '1.875rem',
            }}
          >
            {buttonData.map((button, index) => (
              <Button
                key={button.value}
                variant="contained"
                sx={{
                  ...styles.buttonAttributes,
                  backgroundColor:
                    selectedButtonIndex === button.value
                      ? '#2870EA'
                      : 'lightgrey',
                  color:
                    selectedButtonIndex === button.value ? 'white' : 'black',
                  width: { xs: '50%', sm: 'auto' },
                  margin: { xs: '.5rem .25rem', sm: '.5rem' },
                  fontSize: '.75rem',
                }}
                onClick={() => handleButtonClick(button.value)}
              >
                {button.placeholder}
              </Button>
            ))}
          </Box>

          {/* Conditional rendering for inputs aligned to the right */}
          {selectedButtonIndex === 0 && renderVendorAttributes && (
            <Box sx={styles.inputsContiner}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    value={product.type}
                    onChange={(e) => {
                      handleChange(e, false);
                    }}
                    input={<StyledBootstrapInput />}
                  >
                    {types.map((item) => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    value={product.style}
                    onChange={(e) => {
                      handleChange(e, false);
                    }}
                    disabled={!product.type}
                    input={<StyledBootstrapInput />}
                  >
                    {rockemStyles.map((style) => (
                      <MenuItem key={style.name} value={style.name}>
                        {style.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    value={product.format}
                    onChange={(e) => {
                      if (product.type === TYPE_OF_PRODUCT.ADD_ON) {
                        handleChange(e, true);
                      }
                    }}
                    input={<StyledBootstrapInput />}
                    name="format"
                  >
                    {formats.map((format) => (
                      <MenuItem key={format.id} value={format.id}>
                        {format.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    value={product.size}
                    onChange={(e) => {
                      handleChange(e, false);
                    }}
                    disabled={!product.format}
                    input={<StyledBootstrapInput />}
                  >
                    {sizes.map((size) => (
                      <MenuItem key={size.id} value={size.id}>
                        {size.name === 'YTHL' ? 'Youth L' : size.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Box>

      {!showVendorAttributes ? (
        <>
          {!isUnderwear &&
            product.type !== 'Add-On' &&
            product.style !== 'Knitted' && (
              <Box sx={styles.extraSockAttributes}>
                {/* Existing Select component for tags */}

                <Box sx={styles.inputBoxedIn}>
                  <Select
                    input={<BootstrapInput />}
                    onChange={handleTagsChange}
                    value="select"
                  >
                    <MenuItem value="select">Tags</MenuItem>
                    {_.map(tags, (item, key) => (
                      <MenuItem key={'tagselect-' + key} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                {tags.length !== 0 && renderTags()}

                {/* if the product in hand is not an undear ware but an hyper optic sock dont show template and checkboxes. */}
                {!product.pathHyperOpticIdentical &&
                  !product.pathLeft &&
                  product.type !== 'T-Shirt' && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '75%',
                      }}
                    >
                      {renderTemplates()}

                      <Box
                        sx={{
                          marginLeft: '3.125rem',
                          marginRight: '3.125rem',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          name="identical"
                          checked={product.isBundle}
                          onChange={handleChange}
                        />

                        <Typography variant="body1">Bundle Product</Typography>
                      </Box>

                      <Box
                        sx={{
                          marginLeft: '3.125rem',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Checkbox
                          color="primary"
                          name="identical"
                          checked={product.identical}
                          onChange={handleChange}
                        />
                        <Typography variant="body1">
                          Identical Product
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            )}

          <Box sx={styles.artSection}>
            {/* Left Side */}
            <Box
              sx={{
                ...styles.artSides,
                paddingTop: isUnderwear ? '0rem' : '5px',
              }}
            >
              {/* Top Left Side */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: isUnderwear ? 'center' : 'flex-start',
                  height: !isUnderwear ? '100%' : 'auto',
                  width: '98%',
                }}
              >
                {product.style === 'HyperOptic' ? (
                  renderHyperOpticArt()
                ) : product.style === 'Knitted' ? (
                  renderBoxedImageArt()
                ) : product.style === 'Cut & Sew' ? (
                  renderBoxedImageArt()
                ) : product.style.toLowerCase().trim() == 'packaging' ? (
                  renderBoxedImageArt()
                ) : product.style.toLowerCase().trim() == 'graphic tee' ? (
                  renderBoxedImageArt()
                ) : product.style.toLowerCase().trim() == 'hat' ? (
                  renderBoxedImageArt()
                ) : product.style.toLowerCase().trim() == 'hoodie' ? (
                  renderBoxedImageArt()
                ) : (
                  // Render the default layout for other art types
                  <>
                    {renderSide(
                      sidesConfig.front,
                      SIDES.FRONT,
                      styles,
                      isUnderwear,
                      artType,
                      firebase,
                      classes,
                      aspectRatio,
                    )}
                    {renderSide(
                      sidesConfig.back,
                      SIDES.BACK,
                      styles,
                      isUnderwear,
                      artType,
                      firebase,
                      classes,
                      aspectRatio,
                    )}
                  </>
                )}
              </Box>

              {/* Bottom LEFT */}
              <Box sx={styles.buttonContainer}>
                <Button
                  sx={{
                    ...styles.actionButton,
                    backgroundColor: COLORS.mediumBlue,
                  }}
                  disabled={!disabledButtons}
                  onClick={handleSave}
                  variant="contained"
                >
                  <Typography variant="h6">Save</Typography>
                </Button>
                <Button
                  sx={{
                    ...styles.actionButton,
                    backgroundColor: COLORS.orange,
                  }}
                  onClick={handleOpenDuplicateDialog}
                  disabled={!disabledButtons}
                  variant="contained"
                >
                  <Typography variant="h6">Duplicate</Typography>
                </Button>
                <Button
                  sx={{
                    ...styles.actionButton,
                    backgroundColor: '#F36668',
                  }}
                  variant="contained"
                  disabled={!disabledButtons}
                  onClick={handleOpenDeleteDialog}
                >
                  <Typography variant="h6">Delete</Typography>
                  <DeleteOutlinedIcon
                    sx={{ fontSize: 'inherit', marginLeft: '.3125rem' }}
                  />
                </Button>
              </Box>
            </Box>

            {/* Right Side */}
            <Box sx={styles.productGeneratedImages}>
              {/* Right side content goes here */}

              {isUnderwear && product.style !== 'Cut & Sew' && (
                <Box sx={{ height: '100%', width: '95%' }}>
                  <UnderwearMockups firebase={firebase} product={product} />
                </Box>
              )}

              {!isUnderwear && load && strPtSize !== 'L' && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  {renderMockup(strPtFormat === 'Crew')}
                </Box>
              )}

              {!isUnderwear && load && strPtSize === 'L' && renderMockup()}
            </Box>
          </Box>

          {openVariantsModal && (
            <VariantsModal
              openVariantsModal={openVariantsModal}
              handleCloseModal={handleCloseModal}
              renderVariants={renderVariants}
              product={product}
              colors={COLORS}
              renderVariantsChild={renderVariantsChild}
              renderSingleSkuModal={renderSingleSkuModal}
            />
          )}
        </>
      ) : (
        <Box sx={{ width: '100%', height: '60vh' }}>
          {renderVendorAttributes()}
        </Box>
      )}
    </Box>
  );
};

const styles = {
  variantsIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3.4375rem',
    height: '3.4375rem',
    backgroundColor: colors.lightGrey,
    border: '.0625rem solid lightgrey',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  inputsContiner: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '1.25rem',
    paddingLeft: '1.25rem',
  },
  extraSockAttributes: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 .625rem',
  },
  inputBoxedIn: { width: '10%', padding: '.625rem' },
  buttonContainer: {
    position: 'absolute',
    bottom: '2%',
    left: '1%',
    display: 'flex',
    gap: '.625rem',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem 3.125rem',
    borderRadius: '1.5625rem',
    boxShadow: '0 .125rem .25rem rgba(0,0,0,0.2)',
    cursor: 'pointer',
    fontSize: '1.25rem',
    color: 'white',
  },
  deleteIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    width: '3.75rem',
    height: '3.75rem',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  graphicSocksFrontAndBackArt: {
    backgroundColor: COLORS.lightBlue,
    width: '38%',
    height: '85%',
    margin: '1.25rem',
    borderRadius: '.9375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  underwearFrontAndBackArt: {
    backgroundColor: COLORS.lightBlue,
    width: 'auto',
    height: 'auto',
    margin: '1.8125rem',
    borderRadius: '1.5625rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '2%',
  },
  dropZoneStyle: {
    backgroundColor: 'transparent !important',
  },
  uploadIconAndText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  artSideText: {
    position: 'absolute',
    top: '-8%',
    left: '0',
    bottom: '0',
    textAlign: 'left',
    fontWeight: 'bold',
    zIndex: 1,
  },
  underwearFrontAndBackUpload: {
    backgroundColor: COLORS.lightestBlue,
    width: '90%',
    height: '78%',
    margin: '1.875rem',
    borderRadius: '.9375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: `.375rem dashed ${COLORS.lightBlue}`,
  },
  graphicSocksFrontAndBackUpload: {
    backgroundColor: COLORS.lightestBlue,
    width: '31%',
    height: '80%',
    margin: '1.25rem',
    borderRadius: '.9375rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    border: `.375rem dashed ${COLORS.lightBlue}`,
  },
  underwearImge: {
    width: '100%',
    height: 'auto',
  },
  graphicSocksImge: {
    width: '70%',
    height: '97%',
  },
  productGeneratedImages: {
    width: '50%',
    height: '100%',
  },
  artSides: {
    width: '50%',
    height: '85%',
  },
  artSection: {
    display: 'flex',
    width: '100%', // Takes full width of its parent
    height: '65%',
    paddingTop: '1rem',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    marginBottom: '1.875rem',
    marginLeft: '.625rem',
  },
  outlinedInputName: {
    width: '50%',
    backgroundColor: 'transparent',
    borderRadius: '.3125rem',
    border: '.0625rem solid grey',
    padding: '.625rem',
  },
  outlinedInputSku: {
    width: '30%',
    backgroundColor: 'transparent',
    borderRadius: '.3125rem',
    border: '.0625rem solid grey',
    padding: '.625rem',
  },
  attributesSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '40px',
  },
  buttonAttributes: {
    width: 'auto',
    height: '3.125rem',
    borderRadius: '3.125rem',
    marginLeft: '1.25rem',
    backgroundColor: COLORS.mediumBlue,
    color: 'white',
    fontWeight: 'bold',
  },
  input: { width: 'auto', minWidth: '23.4375rem' },
  buttonGroup: {
    display: 'flex', // Enable flexbox layout
    justifyContent: 'flex-end', // Align items to the start of the container
    alignItems: 'center',
    width: '70%',
    padding: '.625rem',
    gap: '1.25rem',
  },
};
