import _ from 'lodash';
import {
  collection,
  query,
  onSnapshot,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';

import { collections } from '../constants/defines';

const getTotals = (db, callback) => {
  const q = query(collection(db, collections.COUNTS));

  onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      callback(data);
    });
  });
};

export { getTotals };
