import React from 'react';
import { Box, Typography } from '@mui/material';

import HiddenPassword from './passwordHide';
import ShowPassword from './passwordShow';

const InputField = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  showPassword,
  toggleShowPassword,
  isPassword,
}) => (
  <div style={styles.inputContainer}>
    <input
      type={isPassword ? (showPassword ? 'text' : 'password') : type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{
        ...styles.input,
        color: value ? 'black' : '#9C9C9C',
        borderColor: value ? 'black' : '#E7ECF0',
      }}
    />
    {isPassword && (
      <button
        type="button"
        onClick={toggleShowPassword}
        style={styles.passwordToggle}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {showPassword ? <ShowPassword /> : <HiddenPassword />}
        </div>
      </button>
    )}
  </div>
);

const styles = {
  inputContainer: {
    margin: '10px 0',
    width: '100%',
    position: 'relative',
  },
  input: {
    width: '100%',
    padding: '13px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    backgroundColor: '#f0f0f0',
    color: '#9C9C9C',
  },
  passwordToggle: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: '#9C9C9C',
    fontSize: '12px',
  },
};

export default InputField;
