import { withAuthorization } from '../Session';
import WebQueuesPage from './WebQueuesPage';
import Layout from '../Page';

const WebQueues = (props) => (
  <Layout page="" user={props.user}>
    <WebQueuesPage {...props} />
  </Layout>
);

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(WebQueues);
