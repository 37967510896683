export const ordersWithOrderNumber = (orders) =>
  orders.map((order) => {
    // Destructure the order to separate the items from the rest of the order properties
    const { items, ...restOfOrder } = order;

    // Map over each item in the order, adding the order number to each item
    const updatedItems = items.map((item) => ({
      ...item, // Spread the existing item properties
      orderNumber: order.order, // Add the order number to each item
    }));

    // Return the updated order with the modified items array
    return {
      ...restOfOrder, // Spread the rest of the order properties
      items: updatedItems, // Use the updated items array
    };
  });
