import React from 'react';
import Box from '@mui/material/Box';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import Formats from '../Formats';
import Sizes from '../Sizes';
import CropSetting from '../CropSetting';

function FormatsAndSizes(props) {
  return (
    <Layout page={props.page} user={props.user}>
      <CropSetting viewOnly={props.viewOnly} editOnly={props.editOnly} />
      <Box sx={{ marginTop: '150px' }}>
        <Formats viewOnly={props.viewOnly} editOnly={props.editOnly} />
      </Box>
      <Box sx={{ marginTop: '150px' }}>
        <Sizes viewOnly={props.viewOnly} editOnly={props.editOnly} />
      </Box>
    </Layout>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(FormatsAndSizes);
