import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { withFirebase } from '../Firebase';
import { COMMON_COLORS } from '../../theme/colors';
import logoIcon from '../../images/logoandicon.svg';
import InputField from '../AuthComponents/TextField';
import LoadingButton from '../AuthComponents/Button';
import * as ROUTES from '../../constants/routes';
import BadgeSVG from '../AuthComponents/Badge';
import useIsMobile from '../../hooks/useIsMobileHook';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
  showPassword: false,
  loading: false,
};

const PasswordResetForm = ({ firebase, location }) => {
  const isMobile = useIsMobile();

  const [state, setState] = useState(INITIAL_STATE);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [oobCode, setOobCode] = useState(null);
  const [noCode, setNoCode] = useState(false);
  const [successChange, setSuccessChange] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const oobCode = query.get('oobCode');

    if (!oobCode) {
      setNoCode(true);
    }
    setOobCode(oobCode);
  }, [location]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value.trim() }));
  };

  const { passwordOne, passwordTwo, error, loading } = state;

  const handleSubmit = (event) => {
    event.preventDefault();
    setState((prevState) => ({ ...prevState, error: null, loading: true }));

    const { passwordOne, passwordTwo } = state;

    if (passwordOne === '' || passwordTwo === '') {
      setState((prevState) => ({
        ...prevState,
        error: { message: 'Please fill in all fields' },
        loading: false,
      }));
      return;
    }

    const notMatching = passwordOne !== passwordTwo;

    if (notMatching) {
      setState((prevState) => ({
        ...prevState,
        error: { message: 'Passwords do not match' },
        loading: false,
      }));
      return;
    }

    if (!oobCode) {
      setState((prevState) => ({
        ...prevState,
        error: { message: 'Invalid or missing password reset code.' },
        loading: false,
      }));
    }

    firebase
      .doPasswordConfirmReset(oobCode, passwordOne)
      .then(() => {
        setState(INITIAL_STATE);
        // window.location.href = ROUTES.SIGN_IN;
        setSuccessChange(true);
      })
      .catch((error) => {
        console.log('Error', error);
        setState((prevState) => ({
          ...prevState,
          error: { message: error.message },
          loading: false,
        }));
      });
  };

  if (noCode) {
    return (
      <Box sx={styles.container}>
        <Typography sx={styles.resetedPassText}>
          Invalid or missing password reset code.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <style>
        {`
        input:focus {
          border-color: black;
          color: black;
          outline: none;
        }

        input::placeholder {
          color: #9C9C9C;
        }
      `}
      </style>
      <Box
        sx={{
          ...styles.imageAndBox,
          paddingBottom: isMobile ? '40%' : '0',
        }}
      >
        <Box sx={styles.loginHeader}>
          <img src={logoIcon} alt="logo" />
        </Box>
        <Box
          sx={{
            ...styles.loginBox,
            padding: !isMobile ? '40px 30px' : '20px 10px',
            width: !isMobile ? '100%' : '90%',
          }}
        >
          {successChange ? (
            <>
              <BadgeSVG />
              <Box sx={styles.titlesSuccess}>
                <Typography
                  component="h1"
                  variant="h5"
                  fontWeight={500}
                  paddingBottom={0.5}
                >
                  Passcode Changed
                </Typography>
                <Typography
                  component="h1"
                  variant="body1"
                  color="#9C9C9C"
                  textAlign="center"
                >
                  Your passcode has been changed successfully!
                </Typography>
              </Box>
              <Box sx={{ paddingTop: 1, width: '100%' }}>
                <LoadingButton
                  loading={loading}
                  text="Back to Login"
                  onClick={() => {
                    window.location.href = ROUTES.SIGN_IN;
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={styles.titlesLogin}>
                <Typography
                  component="h1"
                  variant="h3"
                  fontWeight={500}
                  paddingBottom={0.5}
                >
                  Change your passcode
                </Typography>
                <Typography
                  component="h1"
                  variant="body2"
                  color="#9C9C9C"
                  textAlign="center"
                >
                  Enter a new passcode below
                </Typography>
              </Box>
              <Box component="form" onSubmit={handleSubmit} sx={styles.formBox}>
                <InputField
                  type={showPasswordOne ? 'text' : 'password'}
                  name="passwordOne"
                  value={passwordOne}
                  onChange={handleChange}
                  placeholder="New Password"
                  showPassword={showPasswordOne}
                  toggleShowPassword={() =>
                    setShowPasswordOne(!showPasswordOne)
                  }
                  isPassword
                />
                <InputField
                  type={showPasswordTwo ? 'text' : 'password'}
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  showPassword={showPasswordTwo}
                  toggleShowPassword={() =>
                    setShowPasswordTwo(!showPasswordTwo)
                  }
                  isPassword
                />
                {error && (
                  <Typography sx={styles.loginError}>
                    {error.message}
                  </Typography>
                )}
                <Box sx={{ paddingTop: 1, width: '100%' }}>
                  <LoadingButton loading={loading} text="Change Passcode" />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: COMMON_COLORS.blackBackground,
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
  },
  resetedPassBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  resetedPassText: {
    fontSize: '18px',
    color: '#7D7D7D',
    textAlign: 'center',
  },
  greyLink: {
    paddingTop: '20px',
    fontSize: '18px',
  },
  loginError: {
    color: '#FF3838',
    fontSize: '12px',
    textAlign: 'left',
    width: '100%',
    paddingBottom: '5px',
  },
  titlesLogin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '5px',
  },
  titlesSuccess: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '20px',
    paddingTop: '10px',
  },
  formBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
    maxWidth: '350px',
  },
  imageAndBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  loginHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
  },
};

export default withRouter(withFirebase(PasswordResetForm));
