import { getDownloadURL, ref } from 'firebase/storage';

import { PRODUCT_ART_PREFIX } from '../constants/defines';

const PRODUCTS_STYLES = {
  CUT_AND_SEW: 'cut & sew',
  HYPER_OPTICS: 'hyperoptic',
  KNITTED: 'knitted',
  ADD_ON: 'add-on',
  PACKAGING: 'packaging',
};

export const keywords = [
  PRODUCTS_STYLES.CUT_AND_SEW,
  PRODUCTS_STYLES.HYPER_OPTICS,
  PRODUCTS_STYLES.KNITTED,
  PRODUCTS_STYLES.ADD_ON,
  PRODUCTS_STYLES.PACKAGING,
];

const checkWhatPathToUse = (data) => {
  const pathsToCheck = [];

  let error = false;

  let skipIfIsBundle = false;

  switch (data?.style.toLowerCase()) {
    case PRODUCTS_STYLES.HYPER_OPTICS:
      if (
        data.pathRight === '' &&
        data.pathLeft === '' &&
        data.pathHyperOpticIdentical !== ''
      ) {
        pathsToCheck.push(data.pathHyperOpticIdentical);
      } else {
        if (data.pathRight === '' || data.pathLeft === '') {
          error = true;
          break;
        }

        if (data.pathRight && data.pathLeft) {
          pathsToCheck.push(data.pathRight);
          pathsToCheck.push(data.pathLeft);
        }
      }
      break;
    case PRODUCTS_STYLES.ADD_ON:
    case PRODUCTS_STYLES.CUT_AND_SEW:
    case PRODUCTS_STYLES.KNITTED:
    case PRODUCTS_STYLES.PACKAGING:
      if (data.isBoxedImagePath !== '') {
        pathsToCheck.push(data.isBoxedImagePath);
      } else {
        error = true;
      }
      break;
    default:
      if (data.isBoxSet) {
        skipIfIsBundle = true;

        break;
      }
      if (data.pathBack) {
        pathsToCheck.push(data.pathBack);
      }
      if (data.pathFront) {
        pathsToCheck.push(data.pathFront);
      }
      break;
  }

  return {
    pathsToCheck,
    error,
    skipIfIsBundle,
  };
};

export const validateImageInStorage = async (item, storage) => {
  if (item?.error.toLowerCase() === 'product not found') {
    return item;
  }

  const { pathsToCheck, error, skipIfIsBundle } = checkWhatPathToUse(item);

  if (error) {
    item = { ...item, error: 'missing art' };
    return item;
  }

  if (skipIfIsBundle) {
    return item;
  }

  try {
    const pathPromises = pathsToCheck.map((path) =>
      getDownloadURL(ref(storage, `${PRODUCT_ART_PREFIX}${path}`)),
    );

    await Promise.any(pathPromises);

    return item; // At least one image fetch is successful
  } catch (error) {
    item = { ...item, error: 'missing art' };

    return item; // All image fetches fail
  }
};
