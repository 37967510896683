// values for number of results per page
const filterShows = [
  { id: 5, name: '50' },
  { id: 10, name: '100' },
  { id: 20, name: '200' },
];

const filterTypes = [
  { id: 3, name: 'Socks' },
  // {id: 2, name: 'None'},
  // {id: 4, name: 'Masks'},
  { id: 5, name: 'Underwear' },
  { id: 6, name: 'Add-On' },
  { id: 7, name: 'T-Shirt' },
  { id: 8, name: 'Accessories' },
  { id: 9, name: 'Outerwear' },
  { id: 1, name: 'All' },
];

const filterStyles = [
  // {id: 2, name: 'None', type: 'Socks,Masks,Underwear,Add-On'},
  { id: 3, name: 'Graphic', type: 'Socks,Masks,Underwear' },
  { id: 4, name: 'HyperOptic', type: 'Socks' },
  { id: 7, name: 'Knitted', type: 'Socks' },
  { id: 8, name: 'Cut & Sew', type: 'Underwear' },
  { id: 9, name: 'Packaging', type: 'Add-On' },
  { id: 10, name: 'Graphic Tee', type: 'T-Shirt' },
  { id: 11, name: 'Hat', type: 'Accessories' },
  { id: 12, name: 'Hoodie', type: 'Outerwear' },
  { id: 1, name: 'All', type: 'Socks,Masks,Underwear,Add-On' },
];

const filterFormats = [
  { id: 1, name: 'All', val: '' },
  // {id: 2, name: 'None', val: ''},
  // {id: 3, name: 'Mask', val: '5WybLrAFexzZyRz3Yx0A'},
  { id: 4, name: 'Crew', val: 'sXll8cHRYFbYR9quepsh' },
  { id: 5, name: 'Quarter', val: 'VFmXYpcFPEjUgubb7bNR' },
  { id: 6, name: 'Low', val: 'NECfTbkHZzJkCVawN5jv' },
  { id: 7, name: 'Heather', val: 'J8KxWmjm2rWtylOX9BQo' },
  { id: 8, name: 'Greeting Card', val: 'fLOMbRPhuAbPHQ4BD0pl' },
  { id: 9, name: 'Gift Box', val: 'RYphsFEwG2j0at4I5uWn' },
  { id: 10, name: 'NFL 8-Bit Box', val: 'hsFucAuW75r66am8hfUo' },
  { id: 11, name: 'VHS Box', val: '7AEVZDOBliqcNkngN4at' },
  { id: 12, name: 'Sticker', val: 'Gut4AcIHJ4gmz5iNjBbO' },
  { id: 13, name: 'Coffin Box', val: '6P2XnFYVjyyj5GlocJk7' },
  { id: 14, name: 'Suitcase', val: 'HVDP26B11aZXIbdMDxja' },
];

const filterSizes = [
  { id: 1, name: 'All', val: '' },
  // {id: 2, name: 'None', val: ''},
  { id: 3, name: 'L/XL', val: '9mIfvRdbpw78UdYA1VZ3' },
  { id: 4, name: 'S/M', val: 'AuXXAwCybPRItw3WRxCN' },
  { id: 5, name: 'YTHL', val: 'qBw4xfNB6ZMUMxI8T2I9' },
  { id: 6, name: 'YM', val: 'Z3XFUStaMILVl22hUH4N' },
  { id: 7, name: 'TDL', val: '1a3uvlwU0548yLVBGLcO' },
  { id: 8, name: 'ADLT', val: 'TXnyH7yzOjq5l64fOAzB' },
  // One Size
  { id: 9, name: 'O/S', val: 'VUqkZMdF7uDp2qiqK7XF' },
  // Cut & Sew Undies
  { id: 10, name: 'L', val: 'ILfpfb4EXPIupykGEoE4' },
];

const _filterTemplates = [
  { id: 'All', name: 'All' },
  // {id: "None", name: 'None'},
];

export {
  filterShows,
  filterTypes,
  filterStyles,
  filterFormats,
  filterSizes,
  _filterTemplates,
};
