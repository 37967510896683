import { cloneDeep } from 'lodash';
import { produce } from 'immer';

import { mergeAndUpdateVariants } from '../../utils/variantsUtil';
import { calculatePages } from '../inlineOrders/helpers';
import {
  FETCHED_BUNDLES,
  CHANGE_BUNDLE,
  ORIGINAL_DROPDOWN_DATA,
  ORIGINAL_SUB_DOCS,
  META_DATA_RECIEVED,
  RESET_STATE,
  UPDATE_VARIANTS,
  UPDATE_MAIN_BUNDLES_VARIANTS,
  UPDATE_EMPTY_VARIANTS,
  SET_PATH_OF_MOCKS,
  UPDATE_MOCK_JOB_STATUS,
  UPDATE_BUNDLE,
  DELETE_BUNDLE,
  ADD_BUNDLE,
  HANDLE_DATA_CHANGE_REQUEST,
} from './types';
import { handleFilters } from './helpers';

const initialState = {
  allBundles: [],
  bundles: [],
  bundleInView: {},
  originalSubDocs: [],
  originalDropDownData: [],
  isLoading: true,
  totalBundles: 0,
  totalPages: 0,
  mockPathsInView: [],
  isMockJobRunning: false,
  currentPage: 1,
  rowsPerPage: 50,
  lastUsedSearchKey: '',
};

const updateBoxset = produce((draft, action) => {
  const { requestType, data } = action.payload;

  const previousFilteringData = {
    currentPage: draft.currentPage,
    rowsPerPage: draft.rowsPerPage,
    lastUsedSearchKey: draft.lastUsedSearchKey,
  };

  const { filteredData, filterState, totalPageCount } = handleFilters(
    draft.allBundles,
    requestType,
    data,
    previousFilteringData,
  );

  // Apply updates to draft state
  draft.currentPage = filterState.currentPage;
  draft.rowsPerPage = filterState.rowsPerPage;
  draft.lastUsedSearchKey = filterState.lastUsedSearchKey;
  draft.bundles = filteredData;
  draft.totalPages = totalPageCount;
});

const bundlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_DATA_CHANGE_REQUEST:
      // using helper functions immer and produce to update the state
      return updateBoxset(state, action);
    case FETCHED_BUNDLES:
      return {
        ...state,
        allBundles: action.payload.allBundles,
        bundles: action.payload.bundles,
        isLoading: false,
      };
    case META_DATA_RECIEVED:
      const { totalBundles, totalPages } = action.payload;

      return {
        ...state,
        totalBundles,
        totalPages,
      };
    case CHANGE_BUNDLE:
      console.log('Opened this bundle => ', action.payload);
      return {
        ...state,
        bundleInView: action.payload,
      };
    case ORIGINAL_DROPDOWN_DATA:
      return {
        ...state,
        originalDropDownData: action.payload,
      };
    case ORIGINAL_SUB_DOCS:
      return {
        ...state,
        originalSubDocs: action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        bundles: state.allBundles.slice(0, 50),
        currentPage: 1,
        rowsPerPage: 50,
        totalPages: calculatePages(state.allBundles, 50),
      };
    case UPDATE_VARIANTS:
      const { item, updatedVariants, newSkuMain } = action.payload;

      // if main sku has an LXL or L at the end, remove it
      const mainSkuWithoutSize = newSkuMain;

      // Find index of mainSku in allBundles
      const bundleIndex = state.allBundles.findIndex(
        (bundle) => bundle.sku === mainSkuWithoutSize,
      );

      if (bundleIndex === -1) {
        console.error('Main SKU not found in allBundles');
        return state;
      }

      // nudate the main bundle
      const updatedBundle = {
        ...state.allBundles[bundleIndex],
        subDocs: updateSubDocs(
          state.allBundles[bundleIndex].subDocs,
          item.originalSku,
          updatedVariants,
        ),
      };

      // Replace the bundle in the cloned array
      const updatedBundles = state.allBundles.map((bundle, index) =>
        index === bundleIndex ? updatedBundle : bundle,
      );

      return {
        ...state,
        allBundles: updatedBundles,
        bundles: updatedBundles.slice(0, state.rowsPerPage),
        bundleInView: updatedBundle,
        originalSubDocs: updatedBundle.subDocs,
      };
    case UPDATE_MAIN_BUNDLES_VARIANTS:
      const {
        boxset: boxsetItem,
        updatedVariants: updatedMainVariants,
        newSkuMain: newMainSku,
      } = action.payload;

      // Find index of mainSku in allBundles
      const mainBundleIndex = state.allBundles.findIndex(
        (bundle) => bundle.sku === newMainSku,
      );

      if (mainBundleIndex === -1) {
        console.error('Main SKU not found in allBundles');
        return state;
      }

      const mainBundleExistingVars =
        state.allBundles[mainBundleIndex].variansts || [];
      const finalMainBundleVars = mergeAndUpdateVariants(
        mainBundleExistingVars,
        updatedMainVariants,
      );

      console.log('Final variants => ', finalMainBundleVars);

      // nudate the main bundle
      const updatedMainBundle = {
        ...state.allBundles[mainBundleIndex],
        variants: finalMainBundleVars,
        subDocs: updateSubDocs(
          state.allBundles[mainBundleIndex].subDocs,
          boxsetItem.originalSku,
          finalMainBundleVars,
        ),
      };

      // Replace the bundle in the cloned array
      const updatedMainBundles = state.allBundles.map((bundle, index) =>
        index === mainBundleIndex ? updatedMainBundle : bundle,
      );

      console.log('Updated main bundle => ', updatedMainBundle);

      return {
        ...state,
        allBundles: updatedMainBundles,
        bundles: updatedMainBundles.slice(0, state.rowsPerPage),
        bundleInView: updatedMainBundle,
        originalSubDocs: updatedMainBundle.subDocs,
      };
    case UPDATE_EMPTY_VARIANTS:
      const {
        boxset,
        variants,
        originalSubDocs,
        newSkuMain: skuWithoutSzie,
      } = action.payload;

      let isMainBundle = false;

      if (
        boxset.originalSku.endsWith('-LXL') ||
        boxset.originalSku.endsWith('-L')
      ) {
        isMainBundle = true;
      }

      // Find index of mainSku in allBundles
      const emptyBundleIndex = state.allBundles.findIndex(
        (bundle) => bundle.sku === skuWithoutSzie,
      );

      if (emptyBundleIndex === -1) {
        console.error('Main SKU not found in allBundles');
        return state;
      }

      const updatedSubDocs = updateSubDocs(
        originalSubDocs,
        boxset.originalSku,
        variants,
      );

      const updatedEmptyBundle = {
        ...state.allBundles[emptyBundleIndex],
        variants,
        subDocs: updatedSubDocs,
      };

      // Replace the bundle in the cloned array
      const updatedEmptyBundles = state.allBundles.map((bundle, index) =>
        index === emptyBundleIndex ? updatedEmptyBundle : bundle,
      );

      console.log('Updated empty bundle => ', updatedEmptyBundle);

      return {
        ...state,
        allBundles: updatedEmptyBundles,
        bundles: updatedEmptyBundles.slice(0, state.rowsPerPage),
        bundleInView: updatedEmptyBundle,
        originalSubDocs: updatedEmptyBundle.subDocs,
      };

    case SET_PATH_OF_MOCKS:
      // update the paths with the original since the path names dont change.
      console.log('Setting mock PATHS => ', action.payload);
      return {
        ...state,
        mockPathsInView: action.payload,
      };
    case UPDATE_MOCK_JOB_STATUS:
      return {
        ...state,
        isMockJobRunning: action.payload,
      };

    case UPDATE_BUNDLE:
      const { newBundle } = action.payload;

      // Deep clone newBundle if it contains nested objects that might be mutated.
      const safeNewBundle = cloneDeep(newBundle);

      const newBaseSku = newBundle.originalSku
        .split('-')
        .slice(0, -1)
        .join('-'); // New base from the newBundle's SKU

      const itemsInNewBundle = safeNewBundle.items;

      // in the originalSubdocs replace the old sku with the new sk
      const subDocsUpdatedBySkuChange = state.originalSubDocs.map((subDoc) => {
        // remove the last segment from the original SKU and add the new size
        const lastSegment = subDoc.originalSku.split('-').pop();
        const newSkuBasePlusSize = `${newBaseSku}-${lastSegment}`;
        // replace the itemsInNewBundle. An array of strings that need to have their sizes updated
        const updatedSkusInItems = itemsInNewBundle.map((item) => {
          const baseOfItems = item.split('-').slice(0, -1).join('-');

          return `${baseOfItems}-${lastSegment}`;
        });

        return {
          ...subDoc,
          items: updatedSkusInItems,
          name: safeNewBundle.name,
          originalSku: newSkuBasePlusSize,
        };
      });

      const inputNewBundles = state.allBundles.map((bundle) =>
        bundle.sku === safeNewBundle.sku
          ? {
              ...safeNewBundle,
              subDocs: subDocsUpdatedBySkuChange,
              sku: newBaseSku,
            }
          : bundle,
      );

      safeNewBundle.sku = newBaseSku;

      return {
        ...state,
        allBundles: inputNewBundles,
        bundles: inputNewBundles.slice(0, state.rowsPerPage),
        bundleInView: safeNewBundle,
        originalSubDocs: subDocsUpdatedBySkuChange,
      };

    case DELETE_BUNDLE:
      const skuToDelete = action.payload;
      // find and remove the single bundle
      const bundlesMinusOne = state.allBundles.filter(
        (bundle) => bundle.sku !== skuToDelete,
      );

      return {
        ...state,
        allBundles: bundlesMinusOne,
        bundles: bundlesMinusOne.slice(0, state.rowsPerPage),
        bundleInView: {},
        originalSubDocs: [],
        totalBundles: state.totalBundles - 1,
      };
    case ADD_BUNDLE:
      const { bundle, sizes, formats } = action.payload;

      // Set up SKU by removing the last segment after the last dash
      bundle.id = bundle.sku;
      bundle.sku = bundle.originalSku.split('-').slice(0, -1).join('-');
      // Find corresponding size and format details
      const size = sizes.find((size) => size.id === bundle.size);
      const format = formats.find((format) => format.id === bundle.format);

      // Update bundle with readable size and format names
      bundle.stringSize = size?.name || '';
      bundle.stringFormat = format?.name || '';

      // Update dropdown data and sub documents
      const updatedDropdownData = [...state.originalDropDownData, bundle.size];
      const originalSubDocsUpdated = [...state.originalSubDocs, { ...bundle }];

      bundle.dropDownData = updatedDropdownData;
      bundle.subDocs = originalSubDocsUpdated;

      // Check if a bundle with the same SKU already exists
      const additionalBundles = state.allBundles.map((existingBundle) => {
        if (existingBundle.sku === bundle.sku) {
          // If exists, return the updated bundle
          return bundle;
        }
        // Otherwise, return the existing bundle untouched
        return existingBundle;
      });

      // Check if the SKU was new, if yes, add it to the list
      const foundExisting = additionalBundles.some((b) => b.sku === bundle.sku);
      if (!foundExisting) {
        additionalBundles.push(bundle);
      }

      // Return the updated state
      return {
        ...state,
        allBundles: additionalBundles,
        bundles: additionalBundles.slice(0, state.rowsPerPage),
        bundleInView: {},
        totalBundles: state.totalBundles + (foundExisting ? 0 : 1), // Only increment if a new bundle was added
      };

    default:
      return state;
  }
};

function updateSubDocs(subDocs, targetSku, updatedVariants) {
  const updatedSubDocs = subDocs.map((subDoc) => {
    if (subDoc.originalSku === targetSku) {
      return {
        ...subDoc,
        variants: updatedVariants,
      };
    }

    return subDoc;
  });
  console.log('Updated subDocs => ', updatedSubDocs);
  return updatedSubDocs;
}

export default bundlesReducer;
