import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const useHeaderStyles = makeStyles((theme) => ({
  search: {
    width: '470px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    marginRight: 0,
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  headerAction: {
    width: '820px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  csvButton: {
    marginLeft: '15px',
    background: '#3D3D3D',
    borderRadius: '6px',
    width: '160px',
    height: '40px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    '&:hover': {
      background: 'rgba (61, 61, 61, 0.8)',
    },
  },
}));

const PageHeader = (props) => {
  const classes = useHeaderStyles();
  const { total, units, onSearch, onUpload, onUploadArt, onExport, exporting } =
    props;

  const handleUploadArt = () => {
    onUploadArt();
  };

  const handleExport = () => {
    onExport();
  };

  const handleUpload = (event) => {
    const csv = event.target.files[0];
    onUpload(csv);
  };

  return (
    <div className="pageHeader">
      <div className="pageHeading">
        <Typography variant="h1">Inventory</Typography>
        <Typography variant="subtitle1">
          <strong>{total}</strong> SKUs, <strong>{units}</strong> UNITs
        </Typography>
      </div>

      <div className={classes.headerAction}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search for Products"
            sx={{ width: '100%' }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
            onKeyUp={onSearch}
          />
        </div>

        <label htmlFor="contained-button-file">
          <input
            hidden
            type="file"
            accept=".csv"
            id="contained-button-file"
            onChange={handleUpload}
          />
          <Button
            variant="blue"
            component="span"
            sx={{ width: '150px' }}
            disabled={!props.editOnly}
          >
            Upload CSV
          </Button>
        </label>

        <Button
          variant="blue"
          component="span"
          sx={{ width: '150px' }}
          disabled={exporting || !props.editOnly}
          onClick={handleExport}
        >
          {exporting ? <CircularProgress size="25px" /> : 'Export CSV'}
        </Button>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  total: PropTypes.number.isRequired,
  units: PropTypes.number.isRequired,
};

export default PageHeader;
