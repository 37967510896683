import _ from 'lodash';
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';

import { collections, storageBucket } from '../constants/defines';
import {
  updateEmptyVariants,
  updateMainBundleOnly,
  updateVariantsLocally,
} from '../redux/bundles/actions';
import { mergeAndUpdateVariants } from '../utils/variantsUtil';

const getBoxset = (db, sku, callback) => {
  const q = query(collection(db, collections.BOXSETS), where('sku', '==', sku));

  onSnapshot(q, (querySnapshot) => {
    let _item = { exists: false, sku: '', name: name, items: [] };
    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data();

        if (!data.toggles) {
          _item = {
            ...data,
            exists: true,
            id: doc.id,
            toggles: [true, true, true, true, true],
          };
        } else {
          _item = { ...data, exists: true, id: doc.id };
        }
      }
    });
    callback(_item);
  });
};

const getBoxsets = (db, limit, callback) => {
  const q = query(collection(db, collections.BOXSETS), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      items.push({
        id: doc.id,
        name: data.name,
        sku: data.sku,
        items: data.items,
      });
    });
    callback(items);
  });
};

const createBoxset = (db, item, uploadedFile, nonGraphicDetected, storage) => {
  item.sku = item?.originalSku || item.sku;
  const newItem = { ...item };
  const currentTime = new Date().toISOString();
  newItem.created = currentTime;
  newItem.createdBy = localStorage.getItem('currentuser') || 'unknownNotFound';

  if (newItem.id === '') {
    newItem.id = item.sku;
  }

  return new Promise((resolve, reject) => {
    const handleBoxsetCreation = () => {
      console.log('Creating Boxset: ', item.sku, 'With =>', newItem);

      const docRef = doc(db, collections.BOXSETS, item.sku);
      setDoc(docRef, newItem)
        .then(() => {
          console.log('New Boxset created: ', item.sku);
          resolve();
        })
        .catch((error) => {
          console.error('Error adding Boxset: ', error);
          reject(error);
        });
    };

    if (nonGraphicDetected && uploadedFile) {
      const storageRef = ref(storage, `boxset/${uploadedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, uploadedFile);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          console.log(
            'Upload is ' +
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100 +
              '% done',
          );
        },
        (error) => {
          console.error('Error uploading file: ', error);
          reject(error);
        },
        () => {
          // File upload complete
          console.log('Upload completed successfully');
          newItem.manualImage = `${uploadedFile.name}`;
          handleBoxsetCreation(); // Proceed with boxset creation after upload
        },
      );
    } else {
      handleBoxsetCreation(); // Directly create the boxset if no upload is needed
    }
  });
};

const updateBoxset = (db, item, _mockups, oldSku) => {
  // save with originalSku / id

  console.log('updateBoxset ', item.sku);

  item.sku = item.originalSku.toUpperCase();

  const newItem = { ...item };

  const updatedSku = oldSku != item.sku;

  const mockups = !_mockups ? [] : [..._mockups];

  // undefined error when updating because not recieving a user.
  newItem.createdBy = localStorage.getItem('currentuser') || 'unknownNotFound';

  if (updatedSku) {
    const docRef = doc(db, collections.BOXSETS, newItem.sku);
    setDoc(docRef, { ...newItem, mockups });

    deleteDoc(doc(db, collections.BOXSETS, oldSku));
  } else {
    const docRef = doc(db, collections.BOXSETS, newItem.sku);
    const sanitizedData = sanitizeData(newItem, 'Missing Data');
    console.log('Updating Boxset: ', item.sku, 'With =>', sanitizedData);
    updateDoc(docRef, { ...sanitizedData, mockups });
  }
};

function sanitizeData(data, defaultValue = '') {
  if (Array.isArray(data)) {
    return data.map((item) => sanitizeData(item, defaultValue));
  } else if (typeof data === 'object' && data !== null) {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        data[key] = defaultValue;
      } else if (typeof data[key] === 'object') {
        data[key] = sanitizeData(data[key], defaultValue);
      }
    });
    return data;
  }
  return data;
}

const updateABoxSetsVariants = async (
  db,
  variants,
  isEmpty,
  boxset,
  dispatch,
  originalSubDocs,
) => {
  if (!boxset.originalSku || !variants || !Array.isArray(variants)) {
    console.error('Invalid SKU or variants provided');
    return false;
  }

  const docRef = doc(db, collections.BOXSETS, boxset.originalSku);

  try {
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.log('Document does not exist:', boxset.originalSku);
      return false;
    }

    const newSkuMain = boxset.originalSku.slice(
      0,
      boxset.originalSku.lastIndexOf('-'),
    );

    if (isEmpty) {
      console.log('Empty Variants:', boxset.originalSku, newSkuMain);

      // item, updatedVariants, boxset.sku, updateMainOnlyLocally

      dispatch(
        updateEmptyVariants(boxset, variants, originalSubDocs, newSkuMain),
      );

      await updateDoc(docRef, {
        variants: [],
      });

      return true;
    }

    // Proceed only if the size is 'LXL' in the SKU
    if (
      boxset.originalSku.endsWith('-LXL') ||
      boxset.originalSku.endsWith('-L')
    ) {
      const upperCaseVariants = variants.map((v) => v.toUpperCase());

      await findSkusSimilarToMainSku(
        db,
        newSkuMain,
        upperCaseVariants,
        boxset,
        dispatch,
        originalSubDocs,
      );
    } else {
      dispatch(updateVariantsLocally(boxset, variants, false, newSkuMain));

      console.log(
        'Updating Variants: BEFORE:: [DB SAVE] [ELSE SINGLE]',
        boxset.originalSku,
        'With =>',
        variants,
      );

      // update single Sku by unique sku
      await updateDoc(docRef, {
        variants: [...variants],
      });
    }

    return true;
  } catch (error) {
    console.error('Failed to update document:', error);
    return false;
  }
};

const findSkusSimilarToMainSku = async (
  db,
  mainSku,
  variants,
  boxset,
  dispatch,
  originalSubDocs,
) => {
  const skuCollection = collection(db, collections.BOXSETS);

  const queryConstraint = query(
    skuCollection,
    where('sku', '>=', mainSku),
    where('sku', '<', mainSku + '\uf8ff'),
  );

  const querySnapshot = await getDocs(queryConstraint);

  for (const doc of querySnapshot.docs) {
    // only update the variants that have -LXL or -L in the SKU
    const variantsWithLs = variants.filter(
      (v) => v.endsWith('-LXL') || v.endsWith('-L'),
    );

    const newSkuMain = boxset.originalSku.slice(
      0,
      boxset.originalSku.lastIndexOf('-'),
    );

    const currentSku = doc.data().sku;

    const sizePart = currentSku.split('-').pop();

    // do not allow this yet.
    // if (sizePart === 'YTH') {
    //   sizePart = 'YTHL';
    // }

    if (currentSku.endsWith('-LXL') || currentSku.endsWith('-L')) {
      dispatch(updateMainBundleOnly(boxset, variants, newSkuMain));

      await updateDoc(doc.ref, {
        variants: variants,
      });
    } else {
      const variantsInDB = doc.data().variants;

      const newVariant = variantsWithLs.map(
        (variant) =>
          `${variant.toUpperCase().split('-').slice(0, -1).join('-')}-${sizePart}`,
      );

      // in the boxset.subDocs array, find the item with the same sku as the currentSku
      const item = originalSubDocs.find(
        (item) => item.originalSku === currentSku,
      );

      console.log('Item:', item);

      const updatedVariants = mergeAndUpdateVariants(
        item.variants || [],
        newVariant,
      );

      if (!item) {
        console.log('Item not found for:', currentSku);
      } else {
        dispatch(
          updateVariantsLocally(item, updatedVariants, false, newSkuMain),
        );
      }

      const savingAs = mergeAndUpdateVariants(variantsInDB, updatedVariants);

      console.log('Saving:', item.originalSku, '=>', 'As: ', savingAs);

      await updateDoc(doc.ref, {
        variants: savingAs,
      });
    }
  }
};

const deleteBoxset = (db, item) => {
  item.sku = item?.originalSku?.toUpperCase() || item.sku;

  console.log('Deleting Boxset: ', item.sku);

  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.BOXSETS, item.sku))
      .then(() => {
        console.log('Boxset successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Boxset: ', error);
        reject();
      });
  });
};

const getBoxsetsByVariant = (db, items) => {
  const skus = items.map((item) => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(
      collection(db, collections.BOXSETS),
      where('variants', 'array-contains', sku),
      limit(1),
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);
      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            qty: items[index].qty,
            items: data.items,
            format: '',
            size: '',
            type: '',
            style: '',
          };
        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            items: null,
            qty: items[index].qty,
            format: '',
            size: '',
            type: '',
            style: '',
          };
        }
      });
      return _items;
    })
    .catch((error) => {
      console.log('getBoxsetsByVariant: ', error);
    });
};

const getBoxsetsBySku = (db, items) => {
  const skus = items.map((item) => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(
      collection(db, collections.BOXSETS),
      where('sku', '==', sku),
      limit(1),
    );
    return getDocs(q);
  });
  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);

      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();
          return {
            ...data,
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            qty: items[index].qty,
          };
        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            items: null,
            qty: items[index].qty,
            format: '',
            size: '',
            type: '',
            style: '',
          };
        }
      });

      return _items;
    })
    .catch((error) => {
      console.log('getBoxsetsBySku: ', error);
    });
};

const getQBSetting = (db, callback) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.SETTINGS, 'QuickBooks');
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() };
        resolve(data);
      } else {
        resolve(null);
      }
    });
  });

const getProduct = (db, sku) =>
  new Promise((resolve, reject) => {
    // Step 1: Create a query to find the product by sku
    const productQuery = query(
      collection(db, collections.PRODUCTS), // Specify the collection
      where('sku', '==', sku), // Search for matching SKU
      limit(1), // Limit the result to 1 document
    );

    // Step 2: Fetch the documents matching the query
    getDocs(productQuery)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0]; // Get the first document from the result
          const data = { ...docSnap.data() };
          resolve({ exists: true, sku, data });
        } else {
          console.log('No such document for SKU: ', sku);
          resolve({ exists: false, sku, data: null });
        }
      })
      .catch((error) => {
        console.error('Error finding product by SKU: ', error);
        reject(error); // Handle errors
      });
  });

const getBoxsetProducts = (db, items) => {
  const requests = _.map(items, (sku) => getProduct(db, sku));
  // Make requests
  return Promise.all(requests)
    .then((response) => response)
    .catch((error) => {
      console.log('getBoxsetProducts: ', error);
    });
};

const updateProductsSize = (db, sku, size, parentSku) => {
  if (!sku || !size) {
    console.error('Invalid SKU or size provided');
    return false;
  }

  const docRef = doc(db, collections.PRODUCTS, sku);

  // only  update if parentSku ends with the same ending as the sku
  // do a .pop() on the parentSku and compare it to the size
  const parentSize = parentSku.split('-').pop();
  const childSize = sku.split('-').pop();

  if (parentSize !== childSize) {
    console.log('Parent Size does not match child size, not updating...');
    return false;
  }

  try {
    console.log('Updating Size:', sku, size);
    updateDoc(docRef, {
      size,
    });

    return true;
  } catch (error) {
    console.error('Failed to update document:', error);
    return false;
  }
};

const updateLowCutBoxset3Pck = (db, item) => {
  // save with originalSku / id

  item.sku = item.originalSku.toUpperCase();

  const newItem = { ...item };

  const docRef = doc(db, collections.BOXSETS, newItem.sku);
  updateDoc(docRef, item);
};

export {
  getProduct,
  getBoxset,
  getBoxsets,
  getBoxsetsBySku,
  getBoxsetsByVariant,
  getBoxsetProducts,
  createBoxset,
  updateBoxset,
  deleteBoxset,
  updateABoxSetsVariants,
  updateProductsSize,
  updateLowCutBoxset3Pck,
};
