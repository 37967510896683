import { filterShows } from '../components/ui/filterConstants';
import { showFilterDataCustom } from '../redux/customOrders/actions';
import {
  filteredOrdersArray,
  showFilterData,
  updateRowsPerPage,
  usedFilter,
} from '../redux/inlineOrders/actions';

export const createOnChangeHandler =
  (
    func,
    dispatch,
    pageToUse = '',
    filterData,
    RowsPerPage,
    dataForTypes = [],
    shippingData,
  ) =>
  // This returns a new function that accepts the value
  (value) => {
    if (func) {
      const filterOption =
        dataForTypes?.find((data) => data.id === value) ?? null;

      if (filterOption && filterOption.name === 'All') {
        dispatch(usedFilter(false));
      } else {
        dispatch(usedFilter(true));
      }

      if (RowsPerPage) {
        const foundData = filterShows.find((data) => data.id === value);
        dispatch(updateRowsPerPage(Number(foundData.name)));
      }

      switch (pageToUse) {
        case 'custom':
          dispatch(showFilterDataCustom(true));
          break;
        case 'inline':
          dispatch(showFilterData(true));
          dispatch(filteredOrdersArray(value, filterData, shippingData));
        default:
          break;
      }

      func(value);
    }
  };
