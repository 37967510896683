import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';
import { ref, getDownloadURL } from 'firebase/storage';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, CircularProgress } from '@mui/material';

import { API } from '../../constants/defines';
import { UploadMockImage } from './components';

function UnderwearMockups(props) {
  const { firebase, storage, product } = props;

  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

  const [items, setItems] = useState([]);

  const classes = useHeaderStyles();

  useEffect(() => {
    const mockups = [
      {
        name: 'Front',
        path: `FS_${product.pathFront}`,
        reverse: `BS_${product.pathBack}`,
        transparent: false,
        selected: false,
      },
      {
        name: 'Front 3/4 (L)',
        path: `${product.pathFront}`,
        reverse: '',
        transparent: false,
        selected: false,
      },
      {
        name: 'Front 3/4 (R)',
        path: `r_${product.pathFront}`,
        reverse: '',
        transparent: false,
        selected: false,
      },
      {
        name: 'Rear',
        path: `BS_${product.pathBack}`,
        reverse: `BQR_${product.pathBack}`,
        transparent: false,
        selected: false,
      },
      {
        name: 'Rear 3/4 (L)',
        path: `BQL_${product.pathBack}`,
        reverse: `BQR_${product.pathBack}`,
        transparent: false,
        selected: false,
      },
      {
        name: 'Rear 3/4 (R)',
        path: `BQR_${product.pathBack}`,
        reverse: `BQR_${product.pathBack}`,
        transparent: false,
        selected: false,
      },
    ].map((mockup) => ({ ...mockup, downloading: false }));
    // get rid of state to show what is downloading...
    setItems([...mockups]);
  }, [product]);

  const handleClickTransparent = (itemIndex) => {
    const newItems = _.map(items, (item, index) => {
      if (itemIndex === index) {
        return { ...item, transparent: !item.transparent };
      }
      return { ...item };
    });
    setItems([...newItems]);
  };

  const handleClickSelectAll = () => {
    const newItems = _.map(items, (item) => ({
      ...item,
      selected: !allSelected,
    }));
    setItems([...newItems]);
  };

  const downloadSelectedItems = () => {
    const selectedIndices = items.reduce((acc, item, index) => {
      if (item.selected) {
        acc.push(index);
      }
      return acc;
    }, []);
    handleDownload(selectedIndices);
  };

  const handleDownload = (selectedIndices) => {
    // Set downloading state for all selected items
    setItems((currentItems) =>
      currentItems.map((item, index) =>
        selectedIndices.includes(index) ? { ...item, downloading: true } : item,
      ),
    );

    const params = {
      name: product.name,
      items: selectedIndices.map((index) => items[index]),
    };

    fetch(API.downloadUnderwearMockup, {
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success) {
          const storage = firebase.getstorage();
          getDownloadURL(ref(storage, data.url))
            .then(async (url) => {
              if (params.items.length === 1) {
                console.log('downloading single item');
                const imageBlob = await fetch(url)
                  .then((res) => res.arrayBuffer())
                  .then((buf) => new Blob([buf], { type: 'image/png' }));

                const link = document.createElement('a');
                link.href = URL.createObjectURL(imageBlob);
                link.download = data.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return;
              }
              console.log('downloading multiple items');

              const downloadLink = document.createElement('a');
              downloadLink.download = `${data.name}`;
              downloadLink.href = url;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .finally(() => {
        // Reset the downloading state for all selected items
        setItems((currentItems) =>
          currentItems.map((item, index) =>
            selectedIndices.includes(index)
              ? { ...item, downloading: false }
              : item,
          ),
        );
      });
  };

  const handleSelect = (itemIndex) => {
    const newItems = _.map(items, (item, index) => {
      if (itemIndex === index) {
        return { ...item, selected: !item.selected };
      }
      return { ...item };
    });
    setItems([...newItems]);
  };

  const numSelected = _.filter(items, { selected: true }).length;
  const allSelected = numSelected === items.length;
  return (
    <Grid container spacing={3}>
      {_.map(items, (item, index) => {
        const nameParts = item.name.split(' ');

        return (
          <Grid
            item
            xs={4}
            key={index}
            onMouseEnter={() => setHoveredItemIndex(index)}
            onMouseLeave={() => setHoveredItemIndex(null)}
          >
            <Box className={classes.mockupHeader}>
              <Checkbox
                checked={item.selected}
                onChange={() => handleSelect(index)}
                style={{
                  padding: '0',
                  marginRight: '8px',
                  paddingBottom: '2%',
                  color: item.selected ? '#1F7CF2' : 'lightgrey', // Apply light grey color when not selected
                }}
              />
              <Typography>
                {
                  nameParts
                    .map((part, partIndex) =>
                      // Check if the part is 'Front' or 'Rear' and make it bold
                      part === 'Front' || part === 'Rear' ? (
                        <span key={partIndex} style={{ fontWeight: 'bold' }}>
                          {part}
                        </span>
                      ) : (
                        <span key={partIndex}>{part}</span>
                      ),
                    )
                    .reduce((prev, curr) => [
                      prev,
                      ' ',
                      curr,
                    ]) /* Add spaces between spans */
                }
              </Typography>
            </Box>

            <Box className={classes.canvasPreview}>
              <UploadMockImage
                name="mockup"
                key={`mockup-${index}`}
                transparent={item.transparent}
                firebase={firebase}
                classes={classes}
                path={item.path}
              />
              {hoveredItemIndex === index && (
                <Box className={classes.hoverIcons}>
                  <Box
                    onClick={(e) => handleClickTransparent(index)}
                    className={classes.whiteButtons}
                  >
                    {item.transparent ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </Box>
                  <Box
                    onClick={() => handleDownload([index])}
                    className={classes.whiteButtons}
                  >
                    {item.downloading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <FileDownloadOutlinedIcon />
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        );
      })}
      <Box
        className={classes.mockupHeader}
        sx={{
          justifyContent: 'flex-end',
          paddingTop: '3%',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={allSelected}
              onChange={handleClickSelectAll}
              sx={{
                padding: '0',
                marginRight: '8px',
                color: 'lightgrey',
                '&.Mui-checked': {
                  color: '#1F7CF2',
                },
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
              Select All
            </Typography>
          }
        />
        <Button
          variant="contained"
          disabled={numSelected > 1 ? false : true}
          onClick={downloadSelectedItems}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: numSelected > 1 ? '#1F7CF2' : 'lightgrey',
          }}
        >
          <Typography
            sx={{ color: '#FFFFFF', fontWeight: '500', paddingTop: '2px' }}
          >
            Download
          </Typography>

          <FileDownloadOutlinedIcon
            sx={{ color: '#FFFFFF', marginLeft: '10px' }}
          />
        </Button>
      </Box>
    </Grid>
  );
}

const useHeaderStyles = makeStyles((theme) => ({
  whiteButtons: {
    cursor: 'pointer',
    width: '35px',
    height: '35px',
    borderRadius: '25%',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Slightly darker on hover
    },
  },
  mockupFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '5px',
  },
  downloadButton: {
    background: '#1F7CF2',
    padding: '8px 50px!important',
    borderRadius: '6px',
    fontFamily: 'Poppins!important',
    fontSize: '16px !important',
    fontWeight: '500!important',
    color: '#FFFFFF',
    textTransform: 'initial',
  },
  inputLabel: {
    width: '85px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
  },
  mockupHeader: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    paddingBottom: '1px',
  },
  artButton: {
    cursor: 'pointer',
  },
  endIcon: {
    position: 'absolute',
    top: '-10px',
    right: '3px',
  },
  // FOR UNDERWEAR MOCKUPS
  canvasPreview: {
    position: 'relative',
  },
  mockImage: {
    width: '80%',
    height: '90%',
  },
  hoverIcons: {
    position: 'absolute',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
}));

export default UnderwearMockups;
