import {
  START_FETCH_CUSTOM_ORDERS,
  FETCHED_CUSTOM_ORDERS,
  FETCH_CUSTOM_ORDERS_ERROR,
  STORE_ALL_CUSTOM_ORDERS,
  SHOW_FILTER_DATA,
} from './types';

export const startFetchCustomOrders = () => ({
  type: START_FETCH_CUSTOM_ORDERS,
});

export const injectCustomOrders = (customOrdersData) => ({
  type: FETCHED_CUSTOM_ORDERS,
  payload: customOrdersData,
});

export const fetchCustomOrdersError = (error) => ({
  type: FETCH_CUSTOM_ORDERS_ERROR,
  payload: error,
});

export const storeAllCustomOrders = (customOrders) => ({
  type: STORE_ALL_CUSTOM_ORDERS,
  payload: customOrders,
});

export const showFilterDataCustom = (filterData) => ({
  type: SHOW_FILTER_DATA,
  payload: filterData,
});
