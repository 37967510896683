import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import { checkExistingInlineOrder } from '../../db/inlineOrder';
import { checkExistingCustomOrder } from '../../db/customOrder';
import { checkExistingHistory } from '../../db/history';
import { validateBatchName } from '../../module/functions';
import { EnhancedTableHead } from '../Page/table';

const headCells = [
  {
    id: 'first',
    numeric: true,
    align: 'left',
    disablePadding: true,
    label: '',
    width: '40px',
  },
  {
    id: 'qty',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'QTY',
    width: '80px',
  },
  {
    id: 'sku',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'SKU',
    width: 'auto',
  },
  {
    id: 'remove',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: '',
    width: '50px',
  },
  {
    id: 'last',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: '',
    width: '20px',
  },
];

function cartDrawer(props) {
  const { classes, products, onClose, onPrint } = props;
  const [batchName, setBatchName] = useState('');
  const [batchNameError, setBatchNameError] = useState(false);
  const [batchNameErrorMsg, setBatchNameErrorMsg] = useState('');
  const [items, setItems] = useState([...products]);

  const db = props.firebase.getdb();

  const handleChangeQty = (event, index) => {
    const qty = parseInt(event.target.value);
    const _products = [...items];
    if (qty == 0) {
      _products.splice(index, 1);
    } else {
      _products[index].qty = qty;
    }
    setItems(_products);
  };

  const handlePrintBatch = async (event) => {
    let exists = false;
    exists = await checkExistingCustomOrder(db, batchName);
    if (exists) {
      setBatchNameError(true);
      setBatchNameErrorMsg('Batch Name already exists');
      return;
    }

    exists = await checkExistingInlineOrder(db, batchName);
    if (exists) {
      setBatchNameError(true);
      setBatchNameErrorMsg('Batch Name already exists');
      return;
    }

    exists = await checkExistingHistory(db, batchName);
    if (exists) {
      setBatchNameError(true);
      setBatchNameErrorMsg('Batch Name already exists');
      return;
    }

    if (onPrint) {
      onPrint(batchName, items);
    }
    setBatchNameError(false);
    setBatchName('');
  };

  const onChangeBatchName = (event) => {
    const value = event.target.value;
    const result = validateBatchName(value);
    if (result !== 'Success') {
      setBatchNameError(true);
      setBatchNameErrorMsg(result);
    } else {
      setBatchNameError(false);
      setBatchNameErrorMsg('');
    }
    setBatchName(value);
  };

  const handleRemoveItem = (event, index) => {
    const _products = [...items];
    _products.splice(index, 1);

    setItems(_products);
  };

  return (
    <div>
      <div className={classes.drawerHeader}>
        <div className={classes.pageHeading}>
          <Typography variant="h1">Batch</Typography>
        </div>
        <div className={classes.drawerClose} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>

      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            useCheckbox={false}
            cells={headCells}
          />
          <TableBody>
            {items.map((row, index) => {
              const keyId = `key-cart-${row.sku}-${index}`;
              const odd = index % 2 === 1;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={keyId}
                  className={odd ? classes.tableRow : classes.tableRow2}
                >
                  <TableCell></TableCell>
                  <TableCell
                    align="left"
                    className={
                      row.exists ? classes.tableCell : classes.tableCellError
                    }
                  >
                    <TextField
                      type="number"
                      value={row.qty}
                      variant="outlined"
                      className={classes.inputQty}
                      onChange={(event) => handleChangeQty(event, index)}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    className={
                      row.exists ? classes.tableCell : classes.tableCellError
                    }
                  >
                    {row.sku}
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <Button onClick={(event) => handleRemoveItem(event, index)}>
                      <DeleteOutlineIcon />
                    </Button>
                  </TableCell>

                  <TableCell></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.drawerBottom}>
        <Typography className={classes.sectionHeading}>Batch Name</Typography>
        <Box>
          <div className={classes.batchNameInput}>
            <TextField
              fullWidth
              placeholder="Enter batch name"
              defaultValue={batchName}
              value={batchName}
              variant="outlined"
              name="batchName"
              onChange={onChangeBatchName}
              error={batchNameError}
              helperText={batchNameErrorMsg}
              classes={{
                root: classes.inputBatchName,
                input: classes.inputInput,
              }}
            />
          </div>
        </Box>

        <Box sx={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            classes={{
              root: classes.batchButton,
              disabled: classes.disabledBatchButton,
            }}
            onClick={handlePrintBatch}
            disabled={batchNameError || items.length == 0 || batchName === ''}
          >
            Print Batch
          </Button>
        </Box>
      </div>
    </div>
  );
}

export default cartDrawer;
