import React, { useState, useEffect } from 'react';
// Material UI dependencies
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';

import { formatDateTime } from '../../module/functions';
import HistoryModal from './historyModal';

// Sub-component for expanded items listing
function HistoryItemsTable(props) {
  const { items, classes } = props;
  const [selected, setSelected] = useState([]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Table size="small" aria-label="items" className={classes.itemTable}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: 'none', width: '20px' }}></TableCell>
          <TableCell sx={{ borderBottom: 'none', width: '460px' }}>
            <Typography variant="tableText">SKU</Typography>
          </TableCell>
          <TableCell sx={{ borderBottom: 'none' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, index) => {
          const isError = item.error;
          const isItemSelected = isSelected(item.id);
          return (
            <TableRow
              key={`history_sub_${index}`}
              onClick={(event) => handleClick(event, item.id)}
              sx={{ color: 'red' }}
            >
              <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}>
                <Checkbox color="primary" checked={isItemSelected} />
              </TableCell>
              <TableCell align="left" sx={{ borderBottom: 'none' }}>
                <Typography variant={isError ? 'tableErrorText' : 'tableText'}>
                  {item.sku}
                </Typography>
              </TableCell>

              {isError ? (
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  <Typography variant="tableErrorText">
                    {item.errorType}
                  </Typography>
                </TableCell>
              ) : (
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  <Typography variant="tableGreenText">
                    {item.message}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

// primary row component
function Row(props) {
  const { row, classes, label, db } = props;
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const handleCloseHistoryDialog = () => {
    setOpenHistory(false);
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={label}
        classes={{
          root: row.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
          selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
        }}
        selected={open}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          padding="none"
          onClick={() => setOpen(!open)}
        >
          <Typography variant="tableText">
            {formatDateTime(row.created)}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={classes.tableCell}
          onClick={() => setOpen(!open)}
        >
          <Box display="flex">
            {row.error && (
              <ErrorOutlineIcon
                sx={{ color: '#E64A19', verticalAlign: 'middle' }}
              />
            )}
            {row.warning && (
              <AssignmentIcon
                sx={{ color: '#14B779', verticalAlign: 'middle' }}
              />
            )}
          </Box>
        </TableCell>
        <TableCell onClick={() => setOpen(!open)}>
          <Typography variant="tableText">{row.name}</Typography>
        </TableCell>
        <TableCell align="left" onClick={() => setOpen(!open)}>
          <Typography variant="tableText">{row.type}</Typography>
        </TableCell>
        <TableCell align="center" onClick={() => setOpen(!open)}>
          <Typography variant="tableText">{row.files}</Typography>
        </TableCell>
        <TableCell align="center">
          <DoneIcon
            sx={{ color: row.completed ? '#1F7CF2' : 'rgba(40,40,40,0.16)' }}
          />
        </TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
      <TableRow sx={{ background: '#F1F7FE' }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" sx={{ padding: '40px 0 40px 40px' }}>
              <Box flexGrow={1}>
                <HistoryItemsTable items={row.items} classes={classes} />
              </Box>
              <Box sx={{ width: '80px' }}>
                {row.completed && (props.editOnly || props.viewOnly) && (
                  <Button variant="blue" onClick={() => setOpenHistory(true)}>
                    <PhotoCameraIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <HistoryModal
        {...props}
        open={openHistory}
        title={row.name}
        items={row.items}
        onClose={handleCloseHistoryDialog}
      />
    </React.Fragment>
  );
}

export default Row;
