import React, { useEffect } from 'react';

const useHotkeyToggle = ({
  setFunction,
  variable,
  extraFunction = () => {},
  updateStorageKey = '', // Optional function to update the storage key
  allowHook = true,
}) => {
  if (!allowHook) {
    return;
  }

  const updateStorage = (value) => {
    window.localStorage.setItem('skuSearchMode', value);
  };

  useEffect(() => {
    // This function toggles SKU search mode on "/" key press.
    const toggleHotkey = (event) => {
      if (event.key === '/') {
        event.preventDefault(); // Prevent the default action
        setFunction(!variable); // Toggle
        extraFunction(false);

        if (updateStorageKey !== '') {
          updateStorage(!variable);
        }
      }
    };

    // Add the event listener to the whole document
    document.addEventListener('keydown', toggleHotkey);

    // Make sure to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', toggleHotkey);
    };
  }, [variable]); // Dependency on variable to toggle correctly
};

export default useHotkeyToggle;
