import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import ProductionQueue from './productionQueue';
import OrderQueue from './orderQueue';
import PageHeader from './pageHeader';
import { API } from '../../constants/defines';
import useFetchWholesale from '../../hooks/useFetchWholesale';

const Wholesale = (props) => {
  const [textSearch, setTextSearch] = useState('');
  const [textSync, setTextSync] = useState('');
  const [isSync, setIsSync] = useState(false);
  const dispatch = useDispatch();
  // useFetchWholesale(dispatch)

  const handleSearch = (event) => {
    // if user presses enter or clears the box, process the search
    if (event.keyCode === 13 || event.target.value.length === 0) {
      setTextSearch(event.target.value);
    }
  };

  const handleSync = (event) => {
    // if user presses enter or clears the box, process the search
    if (event.keyCode === 13 || event.target.value.length === 0) {
      console.log('On Search');
      setIsSync(true);
      setTextSync(event.target.value);

      const params = { estimateId: event.target.value };
      fetch(API.fetchEstimate, {
        method: 'post',
        body: JSON.stringify(params),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          console.log('Response:', data);
          if (data.success) {
            console.log('Success');
          } else {
            console.log('Error');
          }
          setIsSync(false);
        });
    }
  };

  useFetchWholesale(dispatch);

  return (
    <Layout page={props.page} user={props.user}>
      <PageHeader onSearch={handleSearch} onSync={handleSync} isSync={isSync} />

      <Box>
        <ProductionQueue {...props} searchKey={textSearch} />
      </Box>
      <Box sx={{ marginTop: '70px' }}>
        <OrderQueue {...props} searchKey={textSearch} />
      </Box>
    </Layout>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Wholesale);
