import React, { useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';

import { Toast } from '../Toast';

const FileUploadBundles = ({ onFileUpload }) => {
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    onFileUpload(file); // Pass the file to the parent component

    setFileName(file.name);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragActive(false);

    const file = event.dataTransfer.files?.[0];

    if (!file) {
      return;
    }

    setFileName(file.name);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
        height: '50px',
        border: '2px dashed #3477F5',
        borderRadius: '8px',
        backgroundColor: dragActive ? 'rgba(52, 119, 245, 0.1)' : 'white',
        cursor: 'pointer',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={handleBoxClick}
    >
      <Typography variant="h6" sx={{ marginBottom: '10px' }}>
        {fileName
          ? `Uploaded: ${fileName}`
          : 'Drag and drop your file here or click to upload'}
      </Typography>
      <input
        type="file"
        accept=".png, .jpg, .jpeg"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
    </Box>
  );
};

export default FileUploadBundles;
