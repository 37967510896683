import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import { collections, SIZES } from '../constants/defines';

const getSizes = (db, limit, callback) => {
  const q = query(collection(db, collections.SIZES), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    const items = [];
    querySnapshot.forEach((doc) => {
      items.push({ ...doc.data(), id: doc.id });
    });
    callback(items);
  });
};

const createSize = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.created = currentTime;

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.SIZES), newItem)
      .then((docRef) => {
        console.log('New Size created with ID: ', docRef.id);
        resolve();
      })
      .catch((error) => {
        console.error('Error adding Size: ', error);
        reject();
      });
  });
};

const updateSize = (db, item) =>
  new Promise((resolve, reject) => {
    const docRef = doc(db, collections.SIZES, item.id);
    updateDoc(docRef, {
      name: item.name,
      sku: item.sku,
      type: item.type,
      customPrintSheetFront: {
        ...item.customPrintSheetFront,
      },
      customPrintSheetBack: {
        ...item.customPrintSheetBack,
      },
      customMockPath: item.customMockPath,
    })
      .then(() => {
        console.log('Size successfully updated!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating Size: ', error);
        reject();
      });
  });

const deleteSize = (db, item) =>
  new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.SIZES, item.id))
      .then(() => {
        console.log('Size successfully deleted!');
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting Size: ', error);
        reject();
      });
  });

export { getSizes, createSize, updateSize, deleteSize };
