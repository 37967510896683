import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import ItemActionsCell from './reprint';

const ItemsRow = React.memo(
  ({
    item,
    changeTextColorIfFinishedGood,
    changeTypeNameBasedOffStyle,
    isSelected,
    color,
    classes,
    stSize,
    dispatch,
    isProduction,
    order,
    db,
    handleRowClick,
  }) => (
    // console.log('SKU: ', item.sku, 'HAS ID: ', item.id)
    <TableRow
      key={item.id}
      onClick={(event) => {
        if (item.status === 0) {
          handleRowClick(event, item.id);
        }
      }}
    >
      <TableCell padding="checkbox" sx={{ borderBottom: 'none' }}>
        <Checkbox
          color="primary"
          checked={isSelected(item.id)}
          disabled={item.status > 0}
        />
      </TableCell>
      <TableCell
        align="center"
        className={classes.itemTableCell}
        sx={{ color: changeTextColorIfFinishedGood(item?.style) }}
      >
        {item.qty}
      </TableCell>
      <TableCell
        align="center"
        className={classes.itemTableCell}
        sx={{ color: changeTextColorIfFinishedGood(item?.style) }}
      >
        {changeTypeNameBasedOffStyle(item?.style)}
      </TableCell>
      <TableCell
        align="center"
        className={classes.itemTableCell}
        sx={{ color: changeTextColorIfFinishedGood(item?.style) }}
      >
        {stSize}
      </TableCell>
      <TableCell
        align="left"
        className={classes.itemTableCell}
        sx={{ color: changeTextColorIfFinishedGood(item?.style) }}
      >
        {item.name}
      </TableCell>
      <TableCell
        align="left"
        className={classes.itemTableCell}
        sx={{ color: changeTextColorIfFinishedGood(item?.style) }}
      >
        {item.sku}
      </TableCell>
      <TableCell
        align="left"
        className={classes.itemTableCell}
        sx={{ color: color }}
      >
        {item.error}
      </TableCell>

      {item.status !== 0 && (
        <ItemActionsCell
          item={item}
          classes={classes}
          color={color}
          dispatch={dispatch}
          isProduction={isProduction}
          order={order}
          db={db}
        />
      )}
    </TableRow>
  ),
  (prevProps, nextProps) =>
    // Here you can specify the comparison logic for props. If this function returns true, the component will not re-render.
    // This is an example where you compare relevant props to avoid unnecessary re-renders
    prevProps.item.id === nextProps.item.id &&
    prevProps.item.status === nextProps.item.status &&
    prevProps.isSelected(nextProps.item.id) ===
      nextProps.isSelected(nextProps.item.id) &&
    prevProps.color === nextProps.color &&
    prevProps.classes === nextProps.classes,
);

export default ItemsRow;
