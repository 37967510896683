import { getDownloadURL, ref } from 'firebase/storage';

import { ERROR_TYPE, PRODUCT_ART_PREFIX } from '../constants/defines';
import {
  getBundleBySku,
  getBySku,
  getProductByVariantSku,
} from '../db/products';

export const ITEM_TYPES = {
  BOXED: 'Boxed',
  GRAPHIC: 'Graphic',
  HYPER_OPTIC: {
    FRATERNAL: 'Hyper Optic Fraternal',
    IDENTICAL: 'Hyper Optic Identical',
  },
};

export const itemsChecked = async (searchResults, db, storage) =>
  Promise.all(
    searchResults.hits.map(async (hit) => {
      let batchIsWarning = false; // Flag to indicate if any item in the batch has a warning
      const updatedItems = await Promise.all(
        hit.document.items.map(async (item) => {
          // missing art or product not found
          const updatedItem = await updateItemIfSaysMissingArt(
            item,
            db,
            item.errorType,
            storage,
          );
          // console.log("updatedItem", updatedItem.sku === 'RE-5PCK-GIFTBOX' ? updatedItem : "")
          if (updatedItem && updatedItem.isWarning) {
            batchIsWarning = true; // Set the batch warning flag if any item has a warning
          }
          return updatedItem || item; // Return the updated item, or the original item if no updates were made
        }),
      );

      // Return the hit document with the updated items array, and include the batch-level isWarning flag
      return {
        ...hit.document,
        items: updatedItems,
        isWarning: batchIsWarning, // Add the isWarning flag to the batch (parent object)
      };
    }),
  );

const handleWhatTypeOfItemIsIt = async (item, db) => {
  let artFetched = await getBundleBySku(db, item.sku);

  if (artFetched?.sku) {
    // Found as a bundle
    return { type: 'bundle', data: artFetched };
  }

  artFetched = await getBySku(db, item.sku);
  if (artFetched?.sku) {
    // Found as a single product
    return { type: 'single', data: artFetched };
  }

  const variant = await getProductByVariantSku(db, item.sku);
  if (variant?.skus?.length > 0) {
    // Found as a variant
    return { type: 'variant', data: variant.skus[0] };
  }

  // Not found
  return { type: 'notFound', data: null };
};

// Helper function to handle not found items
const handleItemNotFound = (item, runingUpdateValue) => {
  if (!runingUpdateValue) {
    return {
      ...item,
      error: 'Product Not Found',
      available: false,
      exists: false,
      style: '',
      type: '',
      size: '',
    };
  }
  return {
    ...item,
    errorType: 'Product Not Found',
    isError: true,
    style: '',
    type: '',
    size: '',
  };
};

// Helper function to process valid results
const processValidResults = async (
  item,
  validResults,
  storage,
  runingUpdateValue,
  db,
) => {
  for (const result of validResults) {
    const productType = whatProductIsIt(result);
    const hasArt = await validateImagePathFromDB(result, storage, productType);

    // dont manipulate this data if it is unknown
    if (productType === 'unknown') {
      return {
        ...item,
        style: result?.style,
        isWarning: productType !== ITEM_TYPES.GRAPHIC,
        type: result?.type,
      };
    }

    if (!hasArt) {
      if (!runingUpdateValue) {
        return {
          ...item,
          error: 'Missing Art',
          available: true,
          exists: true,
          style: result?.style,
          isWarning: productType !== ITEM_TYPES.GRAPHIC,
          type: result?.type,
        };
      }
      return {
        ...item,
        errorType: 'Missing Art',
        isError: true,
        style: result?.style,
        type: result?.type,
        isWarning: productType !== ITEM_TYPES.GRAPHIC,
      };
    }

    if (!runingUpdateValue) {
      return {
        ...item,
        error: '',
        available: true,
        exists: true,
        style: result?.style,
        isWarning: productType !== ITEM_TYPES.GRAPHIC,
        type: result?.type,
      };
    }

    return {
      ...item,
      errorType: '',
      isError: false,
      style: result?.style,
      isWarning: productType !== ITEM_TYPES.GRAPHIC,
      type: result?.type,
    };
  }
};

// Main function for updating the item based on its type and checking for missing art
const updateItemIfSaysMissingArt = async (
  item,
  db,
  runingUpdateValue = false,
  storage,
) => {
  const { type, data } = await handleWhatTypeOfItemIsIt(item, db);

  let validResults = [];

  switch (type) {
    case 'bundle':
      const promises = data.items.map((sku) => getBySku(db, sku, true));
      const fetchedProducts = await Promise.all(promises);
      validResults = fetchedProducts.filter((result) => result !== null);
      if (validResults.length > 0) {
        return {
          ...item,
          error: '',
          items: validResults,
          isEditedObject: true,
          isBoxSet: true,
        };
      }
      break;
    // Fall through to single and variant cases if no valid results are found for the bundle
    case 'single':
    case 'variant':
      validResults.push(data);
      break;

    case 'notFound':
      // Handle item not found logic
      return handleItemNotFound(item, runingUpdateValue);

    default:
      throw new Error('Unexpected item type');
  }

  // Further process the item based on `validResults`, such as checking for missing art
  return processValidResults(
    item,
    validResults,
    storage,
    runingUpdateValue,
    db,
  );
};

export const validateImagePathFromDB = async (art, storage, productType) => {
  const paths = [];

  // Function to add paths if they exist
  const addPathIfExists = (path) => {
    if (path && !paths.includes(path)) {
      paths.push(path);
    }
  };

  switch (productType) {
    case ITEM_TYPES.HYPER_OPTIC.FRATERNAL:
      // Use alternative paths if primary ones are missing
      addPathIfExists(art.pathLeft || art.leftArt?.path);
      addPathIfExists(art.pathRight || art.rightArt?.path);
      break;
    case ITEM_TYPES.HYPER_OPTIC.IDENTICAL:
      addPathIfExists(art.pathHyperOpticIdentical);
      break;
    case ITEM_TYPES.GRAPHIC:
      // Include both paths for graphics
      addPathIfExists(art.pathFront || art.frontArt?.path);
      addPathIfExists(art.pathBack || art.backArt?.path);
      break;
    case ITEM_TYPES.BOXED:
      addPathIfExists(art.isBoxedImagePath || art.isBoxedImageArt?.path);
      break;
    default:
      console.log(`Unknown product type: ${productType}, art: ${art}`);
      return false;
  }

  if (paths.length === 0) {
    console.log('No paths available for validation.');
    return false;
  }

  return true;

  // try {
  //   console.log('Paths to check:', paths);
  //   // const pathPromises = paths.map(path => getDownloadURL(ref(storage, `${PRODUCT_ART_PREFIX}${path}`)))

  //   // await Promise.any(pathPromises);

  //   return true;  // At least one image fetch is successful
  // } catch (error) {
  //   return false;  // All image fetches fail
  // }
};

const whatProductIsIt = (item) => {
  // Check if it's a boxed item
  if (item.isBoxedImagePath !== '') {
    return ITEM_TYPES.BOXED;
  }
  // Check if it's a graphic item
  else if (item.pathFront !== '' && item.pathBack !== '') {
    return ITEM_TYPES.GRAPHIC;
  }
  // Check if it's a hyper optic item
  else if (item.pathHyperOpticIdentical !== '') {
    return ITEM_TYPES.HYPER_OPTIC.IDENTICAL;
  } else if (item.pathLeft !== '' || item.pathRight !== '') {
    return ITEM_TYPES.HYPER_OPTIC.FRATERNAL;
  }
  // If none of the conditions are met, return 'unknown'
  else {
    console.log('Unknown product type: ', item.sku, item);
    return 'unknown';
  }
};

export const whatProductIsItBasedOffArts = (item) => {
  // Check if it's a boxed item based on the presence of boxed image path
  if (item.isBoxedImageArt?.path) {
    return ITEM_TYPES.BOXED;
  }

  // Check if it's a hyper optic item based on the presence of art paths
  if (item.hyperOpticIdenticalArt?.path) {
    return ITEM_TYPES.HYPER_OPTIC.IDENTICAL;
  } else if (item.leftArt?.path || item.rightArt?.path) {
    return ITEM_TYPES.HYPER_OPTIC.FRATERNAL;
  }
  // Check if it's a graphic item based on the presence of front and back art paths
  else if (item.frontArt?.path && item.backArt?.path) {
    return ITEM_TYPES.GRAPHIC;
  }
  // If none of the conditions are met, return 'unknown'
  else {
    return 'unknown';
  }
};
