import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500!important',
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#000000',
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px',
    },
  },
  drawerHeader: {
    position: 'relative',
    padding: '50px 50px 15px',
  },
  drawerBottom: {
    padding: '0px 50px 30px',
  },
  pageHeading: {
    borderBottom: '1px solid rgba(217, 223, 233, 0.4)',
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#000000',
    marginBottom: '15px',
  },
  inputBatchName: {
    color: 'inherit',
    background: 'white',
    '& input': {
      background: 'rgba(0,0,0,0.04)',
      padding: '11px 16px',
      width: '100%',
      borderRadius: '4px',
      border: 'none',
    },
  },
  batchNameInput: {
    width: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
    marginLeft: 0,
  },
  batchButton: {
    fontSize: '16px',
    fontWeight: '500!important',
    textTransform: 'initial',
    color: '#FFFFFF',
    background: '#1F7CF2',
    borderRadius: '6px',
    width: '170px',
  },
  drawerClose: {
    position: 'absolute',
    right: '40px',
    top: '40px',
    cursor: 'pointer',
  },
  disabledBatchButton: {
    opacity: '0.48',
    backgroundColor: '#1F7CF2 !important',
    color: '#FFFFFF!important',
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important',
  },
  fieldLabel: {
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#000000',
  },
  fieldError: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#f44336',
    marginTop: '5px',
  },
  restoreIcon: {
    position: 'absolute',
    right: '10px',
    top: '22px',
    '& svg': {
      opacity: '0.56',
    },
  },
}));

export { useStyles };
