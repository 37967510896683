/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@mui/styles';
import axios from 'axios';

// Material UI dependencies
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

// Utility functions
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Collapse } from '@mui/material';

import { API } from '../../constants/defines';
import { createCSV } from '../../module/functions';
import { ConfirmDialog } from '../Page/generalModal';
import {
  getHistoryDocument,
  setPrintStatus,
  setHistoryDownloads,
  deleteHistory,
  addOrderError,
  handleResetTags,
} from '../../db/history';
import {
  restoreCustomOrders,
  restoreCustomOrderItems,
} from '../../db/customOrder';
import { addRestoreInlineOrders } from '../../db/inlineOrder';
import { getBundleBySku, getBySku } from '../../db/products';
import { palette } from '../../theme/colors';
import {
  replaceHistoryDoc,
  setDownloadStatus,
} from '../../redux/history/actions';
import { usePrevious } from '../../hooks/usePrevious';
import { listenToShipstationBatchDownloadStatus } from '../../db/getShipstationDownload';
import { handleCopyToBoard } from '../../utils/copyToClip';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { listenToPrintStatus } from '../../db/listenToPrintStatus';
import { listenToDownloadStatus } from '../../db/listenToDownloadButton';
import SubItemRow from './MixMatchCom';
import restoreOrdersBatchForDs from '../../db/restoreDsOrders';
import { Toast } from '../Toast';
import CustomTableRow from './CustomTableRow';
import { DisplayTotals } from './Totals';
import { getWebOrderTracking } from '../../utils/getInlineOrder';
import TrackingSVG from '../SVGS/trackIcon';

const formatFirestoreTimestamp = (timestamp) => {
  if (typeof timestamp === 'string') {
    return timestamp;
  }

  if (typeof timestamp !== 'object' || !timestamp.seconds) {
    return '';
  }
  const date = new Date(timestamp.seconds * 1000);

  // Extract date parts
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);

  // Extract time parts
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert to 12-hour format

  // Construct the formatted date string
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
};

const HistoryInventoryItems = ({ inventory, classes }) => {
  const html = [];
  _.each(inventory, (item) => {
    const offset = item._quantity - item.quantity;
    if (offset > 0) {
      html.push(
        <Typography className={classes.txtLocation}>
          {item.sku}, {item.location}
        </Typography>,
      );
    }
  });

  return <Box sx={{ padding: '5px 0' }}>{html}</Box>;
};

// Sub-component for expanded items listing
function HistoryItemsTable(props) {
  const [showToast, setShowToast] = useState(false);
  const { items, classes, source, setOrders, db, batch } = props;
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const hasFetched = useRef(false); // Ref to track fetch status

  useEffect(() => {
    const fetchTrackingNumbers = async () => {
      if (
        (!hasFetched.current && batch.wasPicked) ||
        batch.source === 'DS' ||
        batch.source === 'DROPSHIP'
      ) {
        // Prevent subsequent fetches
        hasFetched.current = true;

        const uniqueOrderIds = [...new Set(items.map((item) => item.orderId))];
        const customBatch = batch?.productTypeCheckers?.hasCustoms;

        const results = await Promise.all(
          uniqueOrderIds.map((order) =>
            getWebOrderTracking(db, order, customBatch),
          ),
        );
        setTrackingNumbers(results);
      }
    };

    fetchTrackingNumbers();
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    setOrders(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Function to decide the new style based on original style
  const getModifiedStyle = (originalStyle) => {
    if (originalStyle?.includes('Graphic')) {
      return null;
    }
    if (originalStyle?.includes('Hyper')) {
      return 'HYPEROPTIC';
    }
    if (originalStyle?.includes('Knit')) {
      return 'KNITTED';
    }
    if (originalStyle?.includes('Cut & Sew')) {
      return 'CUT & SEW';
    }
    if (originalStyle?.includes('Packaging')) {
      return 'ADD-ON';
    }
    return originalStyle; // Return unchanged if no conditions match
  };

  // Transform items here before rendering
  const transformedItems = items.map((item) => ({
    ...item,
    style: getModifiedStyle(item.style),
  }));

  const [toastMessage, setToastMessage] = useState('');
  const displayedOrders = new Set(); // Track displayed order IDs

  const clickedTracking = (tracking, isVisible, isDsOrder) => {
    if (isVisible) {
      if (tracking === '') {
        return;
      } else {
        if (!isDsOrder) {
          window.open(
            `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking}`,
            '_blank',
          );
          return;
        }
        window.open(
          `https://www.ups.com/track?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${tracking}`,
          '_blank',
        );
      }
    }
  };

  return (
    <Table size="small" aria-label="items" className={classes.itemTableSub}>
      <TableHead>
        <TableRow>
          <TableCell
            className={classes.itemTableCell}
            sx={{ width: '20px' }}
          ></TableCell>
          <TableCell className={classes.itemTableCell} sx={{ width: '70px' }}>
            QTY
          </TableCell>
          {source !== 'Manual' && (
            <TableCell
              className={classes.itemTableCell}
              sx={{ width: '200px' }}
            >
              ORDER
            </TableCell>
          )}
          <TableCell className={classes.itemTableCell} sx={{ width: '700px' }}>
            SKU
          </TableCell>
          <TableCell className={classes.itemTableCell}>Notes</TableCell>
          <TableCell
            className={classes.itemTableCell}
            sx={{ width: '250px' }}
          ></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transformedItems.map((item, index) => {
          const isError = item.isError;

          const isItemSelected = isSelected(item.id);

          let quantity = item.quantity;

          const mixAndMatchItem = item.isMixAndMatch;

          if (item.inventory && !item.isBoxSet) {
            quantity = item.inventory.quantity;
          }

          if (!quantity && mixAndMatchItem) {
            quantity = item.quantity;
          }

          if (mixAndMatchItem) {
            item.style = "HYPEROPTIC MIX N' MATCH";
            item.sku = `MIX N' MATCH - ${item.collection}`;
          }

          if (item.type === 'T-Shirt') {
            item.style = 'T-Shirt';
          }

          const rowTextStyle = item.style ? palette.productPurple : '';

          let hasProperties = false;
          if (item?.properties && item?.properties?.length > 0) {
            hasProperties = true;
          }

          let jerseyText = '';

          if (hasProperties) {
            // combine properties like this "No - Name" ONLY IF VALUES = "No" and "Name"
            jerseyText = item?.properties
              ?.filter(
                (property) =>
                  property?.name === 'No' || property?.name === 'Name',
              )
              ?.map((property) => property?.value)
              .join(' - ');
          }
          // Find tracking number for this orderId
          const trackingEntry = trackingNumbers.find(
            (tracking) => tracking.orderId === String(item.orderId),
          );

          // if ds, show tracking number if trackingEntry.labelPrinted is an object and exists
          const isDsAndLabelPrinted =
            batch.source === 'DS' && trackingEntry?.labelPrinted;

          const showTrackingNumber =
            (batch.source !== 'DS' &&
              trackingEntry &&
              !displayedOrders.has(item.orderId)) ||
            isDsAndLabelPrinted;

          if (showTrackingNumber) {
            displayedOrders.add(item.orderId); // Mark this orderId as displayed
          }

          return (
            <>
              <TableRow key={`history_sub_${index}`}>
                <TableCell
                  padding="checkbox"
                  sx={{ borderBottom: 'none' }}
                  onClick={(event) => handleClick(event, item.id)}
                >
                  <Checkbox color="primary" checked={isItemSelected} />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <Typography
                    variant={isError ? 'tableErrorText' : 'tableText'}
                    sx={{ color: rowTextStyle }}
                  >
                    {quantity}
                  </Typography>
                  {showTrackingNumber && trackingEntry.tracking !== '' ? (
                    <TrackingSVG
                      onClick={() =>
                        clickedTracking(
                          trackingEntry.tracking,
                          true,
                          trackingEntry.isDsOrder ?? false,
                        )
                      }
                    />
                  ) : (
                    <TrackingSVG
                      hide={true}
                      onClick={() => clickedTracking(null, false)}
                    />
                  )}
                </TableCell>
                {source !== 'Manual' && (
                  <TableCell
                    onClick={() =>
                      handleCopyToBoard(
                        `Copied Order ID: ${item.orderId} `,
                        item.orderId,
                        setToastMessage,
                        setShowToast,
                      )
                    }
                    align="left"
                    sx={{ borderBottom: 'none', cursor: 'pointer' }}
                  >
                    <Typography
                      variant={isError ? 'tableErrorText' : 'tableText'}
                      sx={{ color: rowTextStyle }}
                    >
                      {item.orderId}
                    </Typography>
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  sx={{ borderBottom: 'none', cursor: 'pointer' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* SKU with click handler */}
                    <Typography
                      variant={isError ? 'tableErrorText' : 'tableText'}
                      sx={{ color: rowTextStyle, cursor: 'pointer' }}
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied SKU: ${item.sku} `,
                          item.sku,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                    >
                      {item.sku}
                    </Typography>

                    {/* Jersey Text with Icon */}
                    {jerseyText !== '' && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '8px',
                          color: palette.productPurple,
                        }}
                      >
                        <InfoIcon style={{ marginRight: '4px' }} />
                        {jerseyText}
                      </span>
                    )}
                  </div>
                </TableCell>

                {item.initialInventoryLocation && (
                  <Typography variant="tableGreenText">
                    1 Full Bundle at location: {item.initialInventoryLocation}
                  </Typography>
                )}

                {item?.style && (
                  <TableCell
                    align="left"
                    sx={{ borderBottom: 'none', width: '20%' }}
                  >
                    <Typography
                      variant={isError ? 'tableErrorText' : 'tableText'}
                      sx={{ color: rowTextStyle }}
                    >
                      {item?.style}
                    </Typography>
                  </TableCell>
                )}
                {isError ? (
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography variant="tableErrorText">
                      {item.errorType}
                    </Typography>
                  </TableCell>
                ) : (
                  <TableCell align="left" sx={{ borderBottom: 'none' }}>
                    <Typography variant="tableGreenText">
                      {item.message}
                    </Typography>
                    <Typography
                      variant="tableGreenText"
                      sx={{ marginLeft: '100px' }}
                    >
                      {item.location}
                    </Typography>
                  </TableCell>
                )}
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  {item.isBoxSet && item.message !== '' ? (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      className={classes.buttonInventory}
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                      <Typography variant="tableGreenText">
                        {item.location}
                      </Typography>
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  {open && (
                    <HistoryInventoryItems
                      inventory={item.inventory}
                      classes={classes}
                    />
                  )}
                </TableCell>
              </TableRow>
              {mixAndMatchItem && (
                <>
                  <SubItemRow
                    subItem={item.inventory.firstSock}
                    orderId={item.orderId}
                    index={`${index}_1`}
                    label="First"
                    color={palette.productPurple}
                  />
                  <SubItemRow
                    subItem={item.inventory.secondSock}
                    orderId={item.orderId}
                    index={`${index}_2`}
                    label="Second"
                    color={palette.productPurple}
                  />
                </>
              )}
            </>
          );
        })}
      </TableBody>
      <ToastCopyCLip show={showToast} message={toastMessage} />
    </Table>
  );
}

// primary row component
const Row = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});

  const dispatch = useDispatch();

  const data = props.row;
  const { classes, db, onDelete, storage } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  // attributes for print button
  const [loading, setLoading] = useState(false);
  const [printed, setPrinted] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [notifyToShipstation, setNotifyToShipstation] = useState(false);
  const [shipLoading, setShipLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const prevOptions = usePrevious(seconds);

  useEffect(() => {
    // Function to perform deep comparison between two objects
    const isDifferent = (obj1, obj2) =>
      JSON.stringify(obj1) !== JSON.stringify(obj2);

    // Skip the effect on initial mount or if options have not changed
    if (!prevOptions || !isDifferent(prevOptions, seconds)) {
      return;
    }

    console.log('Fetching Inline Orders...');

    setLoading(true);

    getHistoryDocument(
      db,
      data.batchId.toString(),
      fetchHistoryDocument,
      storage,
    );
  }, [prevOptions]);

  // update the print button color based on the current print status
  const fetchHistoryDocument = (item) => {
    if (item.exists) {
      const updateBatchDataForRedux = {
        ...item,
        downloads: item.downloads,
        printed: item.printed,
        notifyToShipstation: item.notifyToShipstation,
      };

      dispatch(replaceHistoryDoc(updateBatchDataForRedux, db, storage));
    }
    setLoading(false);
  };

  const startTimerHandler = () => {
    console.log(`Start Timer...Row Id:'${data.batchId}', Name:'${data.name}'`);
    const newIntervalId = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 5000);

    setIntervalId(newIntervalId);
  };

  // toggle printed status
  const handlePrintButton = (printedIcon) => {
    setPrintStatus(
      db,
      data.batchId,
      !data.didPrintSocks || !data.didPrintUnderwear,
      setPrinted,
      data,
      props.formats,
      props.sizes,
      dispatch,
      printedIcon,
    );
  };

  const restoreOrders = async (orders) => {
    console.log('Restore Orders: ', orders);
    if (isCustomRow) {
      await restoreCustomOrders(db, orders);
    } else {
      // await restoreInlineOrders(db, orders);
      await addRestoreInlineOrders(db, data.batchId, 'all', orders);
    }

    setSelected([]);
  };

  const restoreOrderItems = async (items) => {
    const orders = [];
    _.each(items, (item) => {
      const _sel = _.find(orders, { order: item.order });
      if (!_sel) {
        const newItem = { order: item.order, skus: [item.sku] };
        orders.push(newItem);
      } else {
        _sel.skus.push(item.sku);
      }
    });

    const hasDsOrder = orders.some((order) => order.order.startsWith('DS'));

    if (hasDsOrder) {
      const result = await restoreOrdersBatchForDs(db, orders, isCustomRow);
      if (!result) {
        console.log('Error restoring DS orders');
        setShowToast(true);
        setToastData({
          isError: true,
          message: 'Error restoring DS orders',
        });
        return;
      }
      setShowToast(true);
      setToastData({
        isError: false,
        message: 'DS orders restored successfully',
      });
      // If we get here, the orders were restored successfully
      return;
    }

    if (isCustomRow) {
      await restoreCustomOrderItems(db, orders);
    } else {
      await addRestoreInlineOrders(db, data.batchId, 'partial', orders);
    }
  };

  // restore/restart batch
  const handleModalAction = async () => {
    let _orders = [];
    let orders = [];

    switch (mode) {
      // restore batch
      case 1:
        orders = _.map(data.items, (item) => item.orderId);
        _orders = _.uniq(orders);
        if (_orders.length > 0) {
          restoreOrders(_orders);
        }
        break;

      // restore selected orders
      case 2:
        orders = [];
        _.map(data.items, (item) => {
          if (selected.includes(item.id)) {
            orders.push({ order: item.orderId, sku: item.sku });
          }
        });
        if (orders.length > 0) {
          restoreOrderItems(orders);
        } else {
          setShowToast(true);
          setToastData({
            isError: true,
            message: 'No orders selected!',
          });
        }
        break;

      // delete batch
      case 3:
        deleteHistory(db, data.batchId, dispatch);
        if (onDelete) {
          onDelete(data.batchId);
        }
        break;

      // confirm 'unprint'
      case 4:
        setPrintStatus(db, data.batchId, !printed, setPrinted);
        break;
    }

    setModal(false);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleOpenBackToQueue = () => {
    setMode(1);
    setModalTitle('Restore');
    setModalMsg('Do you want to restore this Batch?');
    setModal(true);
    handleToggleClose();
  };

  const handleOpenSelectedOrdersBackToQueue = () => {
    setMode(2);
    setModalTitle('Restore');
    setModalMsg('Do you want to restore the selected orders?');
    setModal(true);
  };

  const handleOpenDelete = () => {
    setMode(3);
    setModalTitle('Delete');
    setModalMsg('Do you want to remove this Batch?');
    setModal(true);
    handleToggleClose();
  };

  const handleDownloadCSV = () => {
    console.log('Manual Items Download');
    const items = [];
    for (let i = 0; i < data.items.length; i++) {
      const item = data.items[i];
      if (data.customBatch) {
        items.push({
          sku: item.sku,
          quantity: item.qty,
        });
        continue;
      }

      if (!item.inventory) {
        items.push({
          sku: item.sku,
          quantity: item.quantity,
        });
        continue;
      }

      if (item.isBoxSet) {
        for (let j = 0; j < item.inventory.length; j++) {
          const inventoryItem = item.inventory[j];
          if (inventoryItem.quantity > 0) {
            items.push({
              sku: inventoryItem.sku,
              quantity: inventoryItem.quantity,
            });
          }
        }
      } else {
        if (item.inventory.quantity > 0) {
          items.push({
            sku: item.sku,
            quantity: item.inventory.quantity,
          });
        }
      }
    }

    const orderSKUs = _.map(items, (item) => item.sku);
    const _orderSKUs = _.uniq(orderSKUs);
    const orderIds = _.map(_orderSKUs, (sku) => {
      const matchItems = _.filter(items, (_item) => sku === _item.sku);
      let quantity = 0;
      _.each(matchItems, (_item) => {
        quantity += _item.quantity;
      });

      return { sku, quantity };
    });

    if (orderIds.length > 0) {
      createCSV(`${data.name}-${data.batchId}`, orderIds);
    }
  };

  const handleDownloadInventory = async () => {
    const items = [];

    setLoading(true);

    const promises = data.items
      .filter((item) => item.inventory)
      .map(async (item) => {
        if (item.isBoxSet) {
          await processBoxSetItem(db, item, items);
        } else {
          await processSingleItem(db, item, items);
        }
      });

    // Wait for all promises to resolve
    await Promise.all(promises);

    if (items.length > 0) {
      createCSV(`Pick List - ${data?.name || data.batchId}`, items);
    } else {
      console.log('No inventory items found');
      setLoading(false);
    }

    setLoading(false);
  };

  const processBoxSetItem = async (db, item, items) => {
    const bundleInfo = await getBundleBySku(db, item.sku);

    const allItemsInInventory = bundleInfo?.items?.every((bundleItem) => {
      const inventoryItem = item.inventory.find(
        (invItem) => invItem.sku === bundleItem,
      );
      return inventoryItem && inventoryItem.inventory > 0;
    });

    console.log('All Items In Inventory: ', allItemsInInventory);

    if (!allItemsInInventory) {
      // If the bundle is not complete, push individual items that are available
      return await processIndividualItemsInBundle(db, item.inventory, items);
    }

    // Push a special row for the bundle itself if all items are in inventory
    items.push({
      'Product Name': bundleInfo.name || 'N/A',
      'Product SKU': bundleInfo.sku,
      'Quantity Needed': 'BUNDLE',
      'Pick Location': 'BUNDLE',
    });

    // Push each item within the bundle
    return await processIndividualItemsInBundle(
      db,
      item.inventory,
      items,
      bundleInfo.name,
    );
  };

  const processIndividualItemsInBundle = async (
    db,
    inventory,
    items,
    bundleName = null,
  ) => {
    for (const inventoryItem of inventory) {
      const itemData = await getBySku(db, inventoryItem.sku);
      if (inventoryItem.inventory > 0) {
        items.push({
          'Product Name': itemData.name || 'N/A',
          'Product SKU': inventoryItem.sku,
          'Quantity Needed': inventoryItem?._quantity || inventoryItem.quantity,
          'Pick Location': inventoryItem.location,
        });
      }
    }
  };

  const processSingleItem = async (db, item, items) => {
    if (item.isMixAndMatch) {
      return await handleMixAndMatchInventoryDownload(db, item, items);
    }

    const itemData = await getBySku(db, item.sku);

    if (item.inventory.inventory > 0) {
      items.push({
        'Product Name': itemData.name || 'N/A',
        'Product SKU': item.sku,
        'Quantity Needed': item?._quantity || item.quantity,
        'Pick Location': item.inventory.location,
      });
    }
  };

  const handleMixAndMatchInventoryDownload = async (db, item, items) => {
    try {
      const socks = [
        {
          sku: item.firstSock,
          inventory: item.inventory.firstSock,
        },
        {
          sku: item.secondSock,
          inventory: item.inventory.secondSock,
        },
      ];

      const sockPromises = socks.map(async (sock) => {
        const sockData = await getBySku(db, sock.sku, false, true);
        return {
          'Product Name': sockData.name || 'N/A',
          'Product SKU': sock.sku,
          'Quantity Needed': sock.inventory._quantity,
          'Pick Location':
            sock.inventory.location !== ''
              ? sock.inventory.location
              : 'Location not found.',
        };
      });

      const sockItems = await Promise.all(sockPromises);

      const spacerItem = {
        'Product Name': item.collection
          ? `Mix N' Match - ${item.collection}`
          : 'Mix N Match',
        'Product SKU': '--------------------------------------',
        'Quantity Needed': '-------------------',
        'Pick Location': '-------------------',
      };

      items.push(spacerItem, ...sockItems, spacerItem);
    } catch (error) {
      console.log('Error in handleMixAndMatchInventoryDownload: ', error);
    }
  };

  // Check if there's a nested 'items' array where orderId might reside
  const isDsOrder = data?.items?.some((item) => {
    // if orderId is not a string, make it a string
    if (typeof item?.orderId !== 'string') {
      item.orderId = item.orderId.toString();
    }

    if (item?.orderId?.includes('DS')) {
      return true;
    }
    if (item.items) {
      return item.items.some(
        (nestedItem) => nestedItem.orderId && nestedItem.orderId.includes('DS'),
      );
    }
    return false;
  });

  const renderModal = () => (
    <ConfirmDialog
      label="back"
      title={modalTitle}
      open={modal}
      onClose={handleCloseModal}
      onConfirm={handleModalAction}
    >
      <Typography variant="body2" gutterBottom>
        {modalMsg}
      </Typography>
    </ConfirmDialog>
  );

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleClose = () => {
    setAnchorEl(null);
  };

  const setOrders = (orders) => {
    setSelected(orders);
  };

  const callBackLog = (msg) => {
    console.log('msg => ', msg);
  };
  const handleGetJobSheet = async () => {
    try {
      const params = { batchId: data.batchId };

      const response = await fetch(API.downloadJobSheet, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        console.error('Error fetching job sheet:', response.statusText);
        return;
      }

      // Read the response as a Blob (for binary data like PDF)
      const blob = await response.blob();

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link to download the file
      const link = document.createElement('a');
      link.href = url;
      link.download = `BatchJobSheet_${data.folder ?? data.batchId}.pdf`; // Set the file name
      link.style.display = 'none';

      document.body.appendChild(link); // Append the link to the DOM
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the DOM

      // Revoke the Blob URL to release memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading job sheet:', error);
    }
  };

  const handleDownload = (first) => {
    // return if there are no print sheets, this blocks a useless api call.
    if (data.numPrintSheetsTotal === 0) {
      console.log('No Print Sheets');
      // update the flag that controls the button layout
      setDownloaded(true);
      // dispatch update for item in redux
      dispatch(setDownloadStatus(data.batchId, true));
      // first time download
      // handleNotifyToShipstation(true);

      // update download status
      setHistoryDownloads(db, data.batchId, 1, callBackLog);

      return;
    }

    const currentUser = localStorage.getItem('currentuser');

    const params = { batchId: data.batchId, email: currentUser };
    fetch(API.downloadHistory, {
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data.success) {
          // update the flag that controls the button layout
          setDownloaded(true);
          // dispatch update for item in redux
          dispatch(setDownloadStatus(data.batchId, true));

          if (!isDsOrder) {
            // first time download
            if (first) {
              handleNotifyToShipstation(true);
            }
          }

          const link = global.document.createElement('a');
          link.setAttribute('href', data.url);
          link.setAttribute('download', 'Zip');
          global.document.body.appendChild(link); // Required for FF
          link.click();
          global.document.body.removeChild(link);
        } else {
          console.log('Error');
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  };

  const [status, setStatus] = useState('');

  const nonOrderRow = data.source === 'Order' || data.source === 'CustomOrder';

  const handleNotifyToShipstation = (firstTimeDownload = false) => {
    // prevent user from triggering multiple API calls

    if (!nonOrderRow) {
      console.log('Non Order Row');
      return;
    }

    if (shipLoading === false) {
      console.log('Call Ship Sync API: ', data.batchId);
      // toggle loading status
      setShipLoading(true);

      // call internal API to update ShipStation based on this batch
      const url = `${API.shipOrder}/${data.batchId}/${firstTimeDownload}`;

      axios
        .get(url)
        .then(
          (result) => {
            console.log('API Request Success: ', result);
            const data = result.data;

            if (data.success === true) {
              let batchSuccess = true;

              data.responseArray.forEach((order) => {
                // anything other than a statusCode of 200 is an error from ShipStation API
                if (order.statusCode != 200) {
                  // add flag for this order
                  addOrderError(
                    db,
                    data.batchId,
                    order.orderNumber,
                    'ShipStation Error',
                  );
                  // mark batch as unsuccessful so that it can be re-run
                  batchSuccess = false;
                }
              });

              if (batchSuccess) {
                console.log('Ship Sync Success. ', data.batchId);
                // if everything was successful, mark the ship button green
                setNotifyToShipstation(batchSuccess);
              }

              // setPrintStatus(db, row.batchId, !printed, setPrinted);
            }

            // disable loading animation
            setShipLoading(false);
          },

          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log('API Request Error: ', error);
            setShipLoading(false);
          },
        )
        .catch((error) => {
          console.log(
            'API Request Error: [ handleNotifyToShipstation ] ',
            error,
          );
        });
    }
  };

  const isCustomRow =
    data.customBatch || (!!data.customBatchId && data.customBatchId !== '');

  let historyItems = [];

  historyItems = data.items ? [...data.items] : [];

  // set actions for batches data
  useEffect(() => {
    setDownloaded(data.downloads > 0);
    setPrinted(data?.printed);
    setNotifyToShipstation(data?.notifyToShipstation);
  }, []);

  const calculateNumberOfOrders = () => {
    // calculate by orderId, do not count duplicates
    const orders = [];

    historyItems.forEach((item) => {
      if (!orders.includes(item.orderId)) {
        orders.push(item.orderId);
      }
    });

    return orders.length;
  };

  const resetBatchTagging = async () => {
    await handleResetTags(db, data);
  };

  const totalsForPrinting = data?.totalsForPrinting || {};

  return (
    <React.Fragment>
      <>
        <CustomTableRow
          data={data}
          status={status}
          classes={classes}
          formatFirestoreTimestamp={formatFirestoreTimestamp}
          setOpen={setOpen}
          open={open}
          downloaded={downloaded}
          nonOrderRow={nonOrderRow}
          isDsOrder={isDsOrder}
          notifyToShipstation={notifyToShipstation}
          props={props}
          handlePrintButton={handlePrintButton}
          handleDownload={handleDownload}
          handleNotifyToShipstation={handleNotifyToShipstation}
          shipLoading={shipLoading}
          handleToggle={handleToggle}
          anchorEl={anchorEl}
          handleToggleClose={handleToggleClose}
          handleOpenBackToQueue={handleOpenBackToQueue}
          handleDownloadCSV={handleDownloadCSV}
          handleDownloadInventory={handleDownloadInventory}
          handleOpenDelete={handleOpenDelete}
          handleGetJobSheet={handleGetJobSheet}
          loading={loading}
          printed={printed}
          renderModal={renderModal}
          setToastData={setToastData}
          setShowToast={setShowToast}
        />

        <TableRow sx={{ background: '#F1F7FE' }}>
          <TableCell
            sx={{
              paddingBottom: 0,
              paddingTop: 0,
            }}
            colSpan={12}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                display="flex"
                sx={{
                  padding: '20px 0 20px 20px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderBottom: '1px solid #E0E0E0',
                }} // alignItems added for vertical alignment
              >
                {/* Left Side: Order totals and totals */}
                <Box
                  flexGrow={1}
                  display="flex"
                  flexDirection="row"
                  gap={3}
                  alignItems="center"
                >
                  {/* Ensure these elements are in the same row */}
                  {data.source !== 'Manual' && (
                    <Typography variant="h3" sx={{ color: '#1F7CF9' }}>
                      ORDERS: {calculateNumberOfOrders()}
                    </Typography>
                  )}
                  <DisplayTotals totalsForPrinting={totalsForPrinting} />
                </Box>
                <Box sx={{ width: '180px' }}>
                  {data.source !== 'Manual' && (
                    <IconButton
                      variant="red"
                      onClick={handleOpenSelectedOrdersBackToQueue}
                      disabled={!props.editOnly}
                    >
                      &nbsp;&nbsp;Back to Queue
                    </IconButton>
                  )}
                </Box>
              </Box>
              {/* Right Side: HistoryItemsTable */}
              <Box sx={{ width: '100%', padding: '20px 40px 40px 40px' }}>
                <HistoryItemsTable
                  items={historyItems}
                  classes={classes}
                  source={data.source}
                  setOrders={setOrders}
                  batch={data}
                  db={db}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </React.Fragment>
  );
};

export default Row;
