import * as React from 'react';

const ClipboardSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || '#0E0E12'}
      d="M5 1.5A1.5 1.5 0 0 1 6.5 0h5A1.5 1.5 0 0 1 13 1.5v1A1.5 1.5 0 0 1 11.5 4h-5A1.5 1.5 0 0 1 5 2.5v-1Z"
    />
    <path
      fill={props.color || '#0E0E12'}
      fillRule="evenodd"
      d="M3.5 2.037c-1.258.07-2.052.27-2.621.84C0 3.756 0 5.17 0 7.998v6c0 2.829 0 4.243.879 5.122.878.878 2.293.878 5.121.878h6c2.828 0 4.243 0 5.121-.878.879-.88.879-2.293.879-5.122v-6c0-2.828 0-4.242-.879-5.121-.569-.57-1.363-.77-2.621-.84V2.5a3 3 0 0 1-3 3h-5a3 3 0 0 1-3-3v-.463ZM3.25 8.5A.75.75 0 0 1 4 7.75h10a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.75-.75Zm1 3.5a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Zm1 3.5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z"
      clipRule="evenodd"
    />
    <g clipPath="url(#a)">
      <rect
        width={8}
        height={8}
        x={12}
        y={13}
        fill={props.color === '#ffffff' ? 'black' : '#F2F2F2'}
        rx={4}
      />
      <path
        fill={props.color || '#0E0E12'}
        fillRule="evenodd"
        d="M19.333 17a3.333 3.333 0 1 1-6.666 0 3.333 3.333 0 0 1 6.666 0Zm-1.99-1.01a.25.25 0 0 1 0 .354l-1.666 1.666a.25.25 0 0 1-.354 0l-.666-.666a.25.25 0 1 1 .353-.354l.49.49.745-.745.745-.745a.25.25 0 0 1 .353 0Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width={8} height={8} x={12} y={13} fill="#fff" rx={4} />
      </clipPath>
    </defs>
  </svg>
);
export default ClipboardSVG;
