import { combineReducers } from 'redux';

import productReducer from './products/reducer';
import inlineOrdersReducer from './inlineOrders/reducer';
import historyReducer from './history/reducer';
import customOrdersReducer from './customOrders/reducer';
import wholeSaleOrdersReducer from './wholeSaleOrders/reducer';
import bundlesReducer from './bundles/reducer';
import analyticsReducer from './analytics/reducer';

const rootReducer = combineReducers({
  products: productReducer,
  inlineOrders: inlineOrdersReducer,
  history: historyReducer,
  customOrders: customOrdersReducer,
  wholeSaleOrders: wholeSaleOrdersReducer,
  productBundles: bundlesReducer,
  analyticsState: analyticsReducer,
});

export default rootReducer;
