export const START_FETCHING_WHOLESALE_ORDERS =
  'START_FETCHING_WHOLESALE_ORDERS';
export const FETCH_WHOLESALE_ORDERS_SUCCESS = 'FETCH_WHOLESALE_ORDERS_SUCCESS';
export const FETCH_WHOLESALE_ORDERS_FAILURE = 'FETCH_WHOLESALE_ORDERS_FAILURE';
export const WHOLESALE_ORDERS = 'WHOLESALE_ORDERS';
export const UPDATE_WHOLESALE_ORDER = 'UPDATE_WHOLESALE_ORDER';
export const UPDATE_WHOLESALE_ORDER_SUCCESS = 'UPDATE_WHOLESALE_ORDER_SUCCESS';
export const UPDATE_WHOLESALE_ORDER_FAILURE = 'UPDATE_WHOLESALE_ORDER_FAILURE';
export const DELETE_WHOLESALE_ORDER = 'DELETE_WHOLESALE_ORDER';
export const WHOLESALE_ORDERS_PRODUCTION_QUEUE =
  'WHOLESALE_ORDERS_PRODUCTION_QUEUE';
export const SWITCH_WHOLESALE_ORDER_TO_PRODUCTION =
  'SWITCH_WHOLESALE_ORDER_TO_PRODUCTION';
export const UPDATE_TABLE_ORDER = 'UPDATE_TABLE_ORDER';
export const SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG =
  'SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG';
export const UPDATE_ITEM_STATUS_IN_ORDER = 'UPDATE_ITEM_STATUS_IN_ORDER';
export const DELETE_MULTIPLE_ORDERS = 'DELETE_MULTIPLE_ORDERS';
export const UPDATE_CHANGED_ORDERS = 'UPDATE_CHANGED_ORDERS';
export const OPEN_ROW = 'OPEN_ROW';
export const CLOSE_ROW = 'CLOSE_ROW';
export const SELECTING_ID_IN_ORDER = 'SELECTING_ID_IN_ORDER';
