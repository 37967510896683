import React, { useEffect, useState } from 'react';
import {
  Box,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mui/material';
import DatePicker from 'react-datepicker';

import Layout from '../Page';
import { withAuthorization } from '../Session';
import FileUpload from '../DropshipCsvUploader';
import { getDsOrders, returnErrorsInDsOrder } from '../../db/dsOrders';
import { DsOrderTable } from './RowComponent';
import dsStyles from './styles';
import { convertToRows } from './helpers';
import PageToolbar from './ToolBar';
import PrintModal from '../Page/printModal';
import { createInlineBatch } from '../../db/inlineOrder';
import { GeneralDialog } from '../Page/generalModal';
import { countProductsAndBundles } from '../../utils/calculateOrderItems';
import CustomPrintModal from '../Page/customPrintModal';
import { createCustomBatch } from '../../db/customOrder';
import RunningLabelBatches from './RunningBatches';
import PageHeader from './pageHeader';

import 'react-datepicker/dist/react-datepicker.css';
import FilterCheckboxPopover from './FilterCheckboxPopover';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';
import Loading from '../Loading';
import { searchDsOrders, searchInlineOrders } from '../../module/typesense';
import { getDsTotals } from '../../db/getDsTotals';
import StickyInfo from '../Page/stickyFooter';

const DsOrdersPage = (props) => {
  const {
    firebase,
    user,
    collectionName,
    type,
    includeItemsPerPageSelector = false,
    convertToRowsOptions = null,
    sizes,
    formats,
    pageName = 'dsinline',
    viewOnly,
    editOnly,
  } = props;

  const [selectedDate, setSelectedDate] = useState(null);
  const [dsOrders, setDsOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [typeOfOrder, setTypeOfOrder] = useState('All');
  const [productType, setProductType] = useState('All');
  const [printModal, setPrintModal] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [batchModal, setBatchModal] = useState(false);
  const [dsErrors, setDsErrors] = useState([]);
  const [openRunningLabels, setOpenRunningLabels] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [originalAllOrders, setOriginalAllOrders] = useState([]);
  const [tableOrder, setTableOrder] = useState('asc');
  const [uniqueName, setUniqueName] = useState('');
  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');
  const [typeOfBatchBeingRun, setTypeOfBatchBeingRun] = useState('');
  const [loading, setLoading] = useState(false);
  const [webOrderType, setWebOrderType] = useState('Inlines');
  const [itemStyles, setItemStyles] = useState('All');
  const [showProductsNotFound, setShowProductsNotFound] = useState(false);
  const [keyWords, setKeyWords] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showTheseOptions, setShowTheseOptions] = useState({
    hasSocks: false,
    hasUnderwear: false,
  });

  const options = [
    { label: 'LXL CREW', value: 'LXL_Crew' },
    { label: 'SM CREW', value: 'SM_Crew' },
    { label: 'YTH-L CREW', value: 'YTHL_Crew' },
    { label: 'YTH-M CREW', value: 'YTHM_Crew' },
    { label: 'LXL LOW CUT', value: 'LXL_Low' },
    { label: 'SM LOW CUT', value: 'SM_Low' },
    { label: 'YTH LOW CUT', value: 'YTHL_Low' },
    { label: 'L UNDERWEAR', value: 'L_Boxer' },
    { label: 'M UNDERWEAR', value: 'M_Boxer' },
    { label: 'S UNDERWEAR', value: 'S_Boxer' },
    { label: 'XL UNDERWEAR', value: 'XL_Boxer' },
    { label: '2XL UNDERWEAR', value: '2XL_Boxer' },
  ];

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    options.reduce((acc, option) => {
      acc[option.value] = false; // Boolean for checkbox state
      return acc;
    }, {}),
  );

  const [selectedPairs, setSelectedPairs] = useState(
    options.reduce((acc, option) => {
      acc[option.value] = { size: '', format: '' }; // Object to store size and format
      return acc;
    }, {}),
  );

  const orderBy = 'date';
  const { db } = firebase;

  const classes = dsStyles();

  useEffect(() => {
    fetchError();
  }, [db]);

  const fetchError = () => {
    returnErrorsInDsOrder(db, (errors) => {
      setDsErrors(errors);
    });
  };

  const fetchTypesense = async () => {
    console.log('**fetching typesense');
    let searchKeyWords = keyWords;
    if (showProductsNotFound) {
      searchKeyWords = 'product not found';
    } else {
      searchKeyWords = keyWords;
    }

    setLoading(true);
    const filters = [];
    let typeFilter = '';

    switch (productType) {
      case 'All':
        break;
      case 'SockOnly':
        typeFilter = 'type:socks';
        break;
      case 'UnderwearOnly':
        typeFilter = 'type:underwear';
        break;
      case 'Socks':
        typeFilter = 'type:multiple';
        break;
      case 'Underwear':
        typeFilter = 'type:multiple';
        break;
    }

    if (typeFilter) {
      filters.push(typeFilter);
    }

    switch (typeOfOrder) {
      case 'All':
        break;
      case 'Singles':
        filters.push('isSingleItemOrder:true');
        break;
      case 'Multiples':
        filters.push('products:>1');
        break;
      default:
        break;
    }

    // <MenuItem value="All">All</MenuItem>
    // <MenuItem value="FinishedGoods">Finished Goods</MenuItem>
    // <MenuItem value="OnlyFinishedGoods">

    switch (itemStyles) {
      case 'All':
        break;
      case 'FinishedGoods':
        filters.push('hasFinishedGood:true');
        break;
      case 'OnlyFinishedGoods':
        filters.push('isFinishedGoodOnlyOrder:true');
        break;
      case 'Graphic':
        filters.push('hasFinishedGood:false && isFinishedGoodOnlyOrder:false');
        break;
      default:
        break;
    }

    switch (webOrderType) {
      case 'All':
        break;
      case 'Customs':
        filters.push('containsCustomProduct:true');
        break;
      case 'Inlines':
        filters.push('containsCustomProduct:false');
        break;
    }

    const filterBy = filters.join(' && ');

    searchDsOrders(
      {
        textSearch: searchKeyWords,
        rowsPerPage: itemsPerPage,
        pageNumber: currentPage,
        filters: filterBy,
      },
      (orderData, { productCount, pages }) => {
        // Convert paginated orders to rows
        const rows = convertToRows(orderData, webOrderType === 'Customs');
        setDsOrders(rows);
        setOriginalAllOrders(rows);
        setTotalPages(pages);
        setLoading(false);
      },
      db,
      webOrderType,
    );
  };

  useEffect(() => {
    fetchTypesense();
  }, [
    typeOfOrder,
    productType,
    tableOrder,
    selectedDate,
    webOrderType,
    itemStyles,
    showProductsNotFound,
    itemsPerPage,
    currentPage,
    keyWords,
  ]);

  const lookAtSizesAndFormats = (orders) => {
    if (!sizes || !formats) {
      return orders;
    }

    // If all selected pairs are empty, return all orders
    if (
      Object.keys(selectedPairs).every(
        (key) =>
          selectedPairs[key].size === '' && selectedPairs[key].format === '',
      )
    ) {
      return orders;
    }

    // const remove the objects that have one or more missing values in the selectedPairs
    const selectedPairsValues = Object.values(selectedPairs);
    const selectedPairsValuesFiltered = selectedPairsValues.filter(
      (pair) => pair.size !== '' && pair.format !== '',
    );

    // Filter orders based on size and format from selectedPairs
    const filteredOrders = orders.filter((order) =>
      order.items.every((item) => {
        if (!item.isBoxSet) {
          if (!item?.size || !item?.format) {
            return false;
          }
          // Assuming selectedPairs is an array of objects with `size` and `format`
          const matches = selectedPairsValuesFiltered.some((pair) => {
            // Ensure the pair has size and format to compare
            if (pair.size && pair.format) {
              const sizeMatch = item.size === pair.size;
              const formatMatch = item.format === pair.format;

              // Return true if both size and format match
              return sizeMatch && formatMatch;
            }
            return false; // Skip if size or format is missing in the pair
          });

          return matches;
        } else {
          if (!item?.data) {
            return false;
          }

          // Assuming selectedPairs is an array of objects with `size` and `format`
          const matches = selectedPairsValuesFiltered.some((pair) => {
            // Ensure the pair has size and format to compare
            if (pair.size && pair.format) {
              const sizeMatch = item.data.size === pair.size;
              const formatMatch = item.data.format === pair.format;

              // Return true if both size and format match
              return sizeMatch && formatMatch;
            }
            return false; // Skip if size or format is missing in the pair
          });

          return matches;
        }
      }),
    );

    return filteredOrders;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && tableOrder === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setTableOrder(newOrder); // Update the tableOrder state
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target; // `name` is the checkbox identifier (e.g., "LXL_Crew")

    const getSizeAndFormatFromName = (value) => {
      const [size, format] = value.split('_');
      return { size, format };
    };

    const sizeAndFormat = getSizeAndFormatFromName(name);

    const getSizeId = (size) => {
      // remove type == T-Shirt from the sizes array
      const newSizes = sizes.filter((s) => s.type !== 'T-Shirt');
      return newSizes.find((s) => s.sku === size)?.id;
    };

    const getFormatId = (format) => formats.find((f) => f.name === format)?.id;

    // use the sizeAndFormat object to get the size and format
    const sizeId = getSizeId(sizeAndFormat.size);

    const formatId = getFormatId(sizeAndFormat.format);

    // Update the checkbox checked state
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }));

    // If the checkbox is checked, update the selectedPairs (size and format), otherwise clear it
    if (checked) {
      setSelectedPairs((prev) => ({
        ...prev,
        [name]: { size: sizeId, format: formatId },
      }));
    } else {
      setSelectedPairs((prev) => ({
        ...prev,
        [name]: { size: '', format: '' },
      }));
    }
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    const { name, value } = event.target;

    if (name === 'typeOfOrder') {
      setTypeOfOrder(value);
    } else if (name === 'productType') {
      setProductType(value);
    } else if (name === 'itemStyles') {
      setItemStyles(value);
    } else {
      setItemsPerPage(value);
    }
  };

  const isSelectedAll =
    dsOrders.length > 0 &&
    dsOrders.every((order) =>
      selectedOrders.some(
        (selectedOrder) => selectedOrder.orderNumber === order.orderNumber,
      ),
    );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select only the currently visible rows (dsOrders)
      const selectedOrderData = dsOrders.map((order) => order.orderData);
      setSelectedOrders((prevSelected) => [
        ...prevSelected,
        ...selectedOrderData,
      ]);
    } else {
      // Deselect only the currently visible rows
      const visibleOrderIds = dsOrders.map((order) => order.orderNumber);
      const filteredSelectedOrders = selectedOrders.filter(
        (selectedOrder) => !visibleOrderIds.includes(selectedOrder.orderNumber),
      );
      setSelectedOrders(filteredSelectedOrders);
    }
  };

  const handleSelectOrder = (order) => {
    const isSelected = selectedOrders.some(
      (selectedOrder) => selectedOrder.orderNumber === order.orderNumber,
    );

    let newSelected;

    if (isSelected) {
      newSelected = selectedOrders.filter(
        (selectedOrder) => selectedOrder.orderNumber !== order.orderNumber,
      );
    } else {
      newSelected = [...selectedOrders, order];
    }

    setSelectedOrders(newSelected);
  };

  const handlePrintBatch = async (
    name,
    secondaryName = '',
    color = '#000000',
    sheetType = 'belt',
    underwearFormat = 'image',
    barcode = '',
    sockFormat = 'image',
    sockSheetType = 'belt',
    underwearSheetType = 'belt',
  ) => {
    try {
      // log props all of them
      setBatchName(name);
      const hasMissingBackPathOrders = [];

      selectedOrders.forEach((order) => {
        if (
          order.items.some(
            (item) =>
              !item.print &&
              (item.sku.includes('CUSTOM') || item.sku.includes('CUSTOMS')),
          )
        ) {
          console.log('order', order);
          hasMissingBackPathOrders.push(order);
        }
      });

      if (hasMissingBackPathOrders.length > 0) {
        alert(
          'Could not find print files for order(s): ' +
            hasMissingBackPathOrders
              .map((order) => order.orderNumber)
              .join(', ') +
            '. Please alert our team so we can fix this.',
        );
        return;
      }

      if (typeOfBatchBeingRun !== 'customs') {
        createInlineBatch(
          db,
          name,
          secondaryName,
          color,
          sheetType,
          underwearFormat,
          barcode,
          selectedOrders,
          handleBatchResult,
          true,
          formats,
          sizes,
          notes,
          sockFormat,
          sockSheetType,
          underwearSheetType,
        );
      } else {
        // go through the selected orders and  alert and stop if a an order with an item that has a sku of "CUSTOM", "CS", "CUSTOMS", "CUSTOMS" has a missing .print
        const hasMissingBackPathOrders = [];
        selectedOrders.forEach((order) => {
          if (
            order.items.some(
              (item) =>
                !item.print &&
                (item.sku.includes('CUSTOM') || item.sku.includes('CUSTOMS')),
            )
          ) {
            console.log('order', order);
            hasMissingBackPathOrders.push(order);
          }
        });

        if (hasMissingBackPathOrders.length > 0) {
          alert(
            'Could not find print files for order(s): ' +
              hasMissingBackPathOrders
                .map((order) => order.orderNumber)
                .join(', ') +
              '. Please alert our team so we can fix this.',
          );
          return;
        }

        createCustomBatch(
          db,
          name,
          secondaryName,
          color,
          sheetType,
          barcode,
          selectedOrders,
          handleBatchResult,
          true,
          '',
          formats,
          sizes,
          notes,
          sockFormat,
          underwearFormat,
          underwearSheetType,
          sockSheetType,
        );
      }

      setPrintModal(false);

      setSelectedOrders([]);

      if (uniqueName.toLowerCase().trim() === name.toLowerCase().trim()) {
        await saveNameIfUsed(name, db);
      } else {
        console.log('name not uniquely used by API', name);
      }
    } catch (error) {
      console.error('Error in handlePrintBatch: ', error);
    } finally {
      setTimeout(() => {
        fetchTypesense();
      }, 1500);
    }
  };

  const handleBatchResult = (result, batchId, batchName) => {
    setBatchModal(true);
    // setWorking(false);
    if (result) {
      setSelectedOrders([]);
    }
  };

  const handlePrint = async (event) => {
    await getUniqueBatchName(setUniqueName, setName, db);

    const containsMixedOrders = handleCheckForMixedOrders();

    if (containsMixedOrders) {
      return;
    }

    if (selectedOrders.length == 0) {
      return;
    }
    setPrintModal(true);
    setLoading(false);
  };

  const handleCheckForMixedOrders = () => {
    // check if every order is a customs order
    const isCustoms = selectedOrders.every((order) => order.isFromCustomsQueue);
    // check if every order is an inlines order
    const isInlines = selectedOrders.every(
      (order) => !order.isFromCustomsQueue,
    );
    // check if every order has boxers we now have to dig in to items in order.items
    const hasSocks = selectedOrders.some((order) =>
      order.items.some(
        (item) =>
          item.type?.toLowerCase().includes('sock') ||
          item.type?.toLowerCase().includes('mixed'),
      ),
    );
    const hasUnderwear = selectedOrders.some((order) =>
      order.items.some(
        (item) =>
          item.type?.toLowerCase().includes('underwear') ||
          item.type?.toLowerCase().includes('mixed'),
      ),
    );

    if (hasSocks || hasUnderwear) {
      setShowTheseOptions({
        hasUnderwear,
        hasSocks,
      });
    }

    // check if orders contain a mix of customs and inlines
    const hasMixedOrders =
      selectedOrders.some((order) => order.isFromCustomsQueue) &&
      selectedOrders.some((order) => !order.isFromCustomsQueue);

    // if mixed, alert the user and prevent printing
    if (hasMixedOrders) {
      setLoading(false);
      alert(
        'Cannot print mixed orders. Please separate customs and inline orders.',
      );
      return true;
    }

    if (isCustoms) {
      setTypeOfBatchBeingRun('customs');
    } else if (isInlines) {
      setTypeOfBatchBeingRun('inlines');
    }

    return false;
  };

  const handleClosePrintModal = () => {
    setPrintModal(false);
  };

  const handleCloseBatchModal = () => {
    setBatchModal(false);
  };

  const selectedInfo = React.useMemo(() => {
    // Filter out duplicates based on unique order numbers
    const uniqueSelectedOrders = selectedOrders.filter(
      (order, index, self) =>
        index === self.findIndex((o) => o.orderNumber === order.orderNumber),
    );

    // Pass the unique orders to the counting function
    return countProductsAndBundles(uniqueSelectedOrders);
  }, [selectedOrders]);

  const openRunningLabelsFunc = () => {
    setOpenRunningLabels(true);
  };

  const closeRunningLabelsFunc = () => {
    setOpenRunningLabels(false);
  };

  // Filter out duplicate orders based on unique order numbers
  const uniqueOrders = originalAllOrders.filter(
    (order, index, self) =>
      index ===
      self.findIndex(
        (o) => o.orderData?.orderNumber === order.orderData?.orderNumber,
      ),
  );

  // Map to get the clean data
  const intitalData = uniqueOrders.map((order) => order.orderData);

  // Calculate the overall products and bundles
  const overAll = countProductsAndBundles(intitalData);

  // Add total order count
  overAll.total = totalOrders;

  const resetFilters = () => {
    setTypeOfOrder('All');
    setProductType('All');
    setSelectedDate(null);
    setItemsPerPage(25);
    setItemStyles('All');
    setKeyWords('*');
    // make the set functions listed above as the initial state
    setSelectedCheckboxes(
      options.reduce((acc, option) => {
        acc[option.value] = false; // Boolean for checkbox state
        return acc;
      }, {}),
    );

    setSelectedPairs(
      options.reduce((acc, option) => {
        acc[option.value] = { size: '', format: '' }; // Object to store size and format
        return acc;
      }, {}),
    );
  };

  // Handle key press
  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setKeyWords(searchInput); // Update keyWords only when Enter is pressed
      console.log('Search triggered with keyWords:', searchInput);
      setCurrentPage(1);
    }
  };

  // cap first letter in type
  const typeCap = type.charAt(0).toUpperCase() + type.slice(1);

  const handleDateSelected = (date) => {
    setSelectedDate(date);
  };

  const handleWebOrderTypeChange = (event) => {
    setWebOrderType(event.target.value);
  };

  const handleShowProductsNotFoundChange = (event) => {
    setShowProductsNotFound(event.target.checked);
  };
  const [totalsLive, setTotalsLive] = useState(null);
  // use effect hook to get collection: collection("Counts").doc("data"); but on snapshot
  useEffect(() => {
    getDsTotals(db, (data) => {
      setTotalsLive(data);
    });
  }, []);

  // Handle input changes
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  return (
    <Layout page={pageName} user={user}>
      <PageHeader
        initial={overAll}
        selected={selectedInfo}
        type={typeCap}
        totalsLive={totalsLive}
      />
      <PageToolbar
        viewOnly={viewOnly}
        editOnly={editOnly}
        enable={true}
        numSelected={selectedOrders.length}
        allSelected={isSelectedAll}
        onSelectAllClick={handleSelectAllClick}
        onPrint={handlePrint}
        dsErrors={dsErrors}
        handleInputChange={handleInputChange}
        searchInput={searchInput}
        handleSearch={handleSearch}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          justifyItems: 'center',
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={openRunningLabelsFunc}
          sx={{
            marginRight: 2,
            textTransform: 'none',
          }}
        >
          Running Batches
        </Button>

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FileUpload />
        </Box>
        {includeItemsPerPageSelector && (
          <>
            {/* Checkbox for showProductsNotFound */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showProductsNotFound}
                  onChange={handleShowProductsNotFoundChange}
                  color="primary"
                />
              }
              label="Show PNF's only"
              style={{ marginRight: 20, marginBottom: 20 }}
            />
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 100, marginRight: 16 }}
            >
              <InputLabel id="items-per-page-label">Items per page</InputLabel>
              <Select
                labelId="items-per-page-label"
                id="items-per-page"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                label="Items per page"
              >
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 100, marginRight: 16 }}
            >
              <InputLabel id="web-order-type-label">Web order Type</InputLabel>
              <Select
                labelId="web-order-type-label"
                id="web-order-type"
                name="webOrderType"
                value={webOrderType}
                onChange={handleWebOrderTypeChange}
                label="Web order type"
              >
                <MenuItem value="Customs">Customs</MenuItem>
                <MenuItem value="Inlines">Inlines</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 100, marginRight: 16 }}
            >
              <InputLabel id="Productionlabel">Production</InputLabel>
              <Select
                labelId="Productionlabel"
                id="Production"
                name="itemStyles"
                value={itemStyles}
                onChange={handleItemsPerPageChange}
                label="Production"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="FinishedGoods">Finished Goods</MenuItem>
                <MenuItem value="OnlyFinishedGoods">
                  Only Finished Goods
                </MenuItem>
                <MenuItem value="Graphic">Graphic</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 100, marginRight: 16 }}
            >
              <InputLabel id="type-of-order-label">Order</InputLabel>
              <Select
                labelId="type-of-order-label"
                id="type-of-order"
                name="typeOfOrder"
                value={typeOfOrder}
                onChange={handleItemsPerPageChange}
                label="Order"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Singles">Singles</MenuItem>
                <MenuItem value="Multiples">Multiples</MenuItem>
                <MenuItem value="inlineAndCustoms">Inlines & Customs</MenuItem>

                {type === 'customs' && (
                  <MenuItem value="inlineAndCustoms">Inline & Customs</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: 100, marginRight: 16 }}
            >
              <InputLabel id="product-type-label">Type</InputLabel>
              <Select
                labelId="product-type-label"
                id="product-type"
                name="productType" // IMPORTANT: Ensure this is correct
                value={productType}
                onChange={handleItemsPerPageChange}
                label="Type"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Socks">Socks</MenuItem>
                <MenuItem value="SockOnly">Socks only</MenuItem>
                <MenuItem value="Underwear">Underwear</MenuItem>
                <MenuItem value="UnderwearOnly">Underwear Only</MenuItem>
              </Select>
            </FormControl>
            {/* <FilterCheckboxPopover
              selectedCheckboxes={selectedCheckboxes}
              handleCheckboxChange={handleCheckboxChange}
              options={options} // Pass the options array to the popover
            /> */}
            {/* <DatePicker
              selected={selectedDate}
              onChange={handleDateSelected}
              dateFormat="MM/dd/yyyy"
              popperPlacement="top-end"
              customInput={
                <Button
                  variant="outlined"
                  sx={{
                    marginRight: 2,
                    marginBottom: 2,
                  }}
                >
                  Select Date
                </Button>
              }
            /> */}
            <Button
              variant="outlined"
              onClick={resetFilters}
              sx={{
                marginRight: 2,
                marginBottom: 4,
              }}
            >
              Reset
            </Button>
          </>
        )}
      </Box>

      <DsOrderTable
        orders={dsOrders}
        type={typeOfBatchBeingRun}
        classes={classes}
        selectedOrders={selectedOrders}
        onSelectOrder={handleSelectOrder}
        onSelectAllClick={handleSelectAllClick}
        setTableOrder={setTableOrder}
        tableOrder={tableOrder}
        handleRequestSort={handleRequestSort}
      />
      {selectedOrders.length > 0 && (
        <StickyInfo selectedInfo={selectedInfo} onPrint={handlePrint} />
      )}

      <Box className={classes.pagination}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Box>

      {!loading ? (
        <>
          {typeOfBatchBeingRun !== 'customs' ? (
            <PrintModal
              {...props}
              open={printModal}
              onPrint={handlePrintBatch}
              onClose={handleClosePrintModal}
              source="DS"
              orders={selectedInfo.orders}
              bundles={selectedInfo.bundles}
              products={selectedInfo.products}
              name={name}
              setName={setName}
              titleName="Dropship"
              notes={notes}
              setNotes={setNotes}
              isCustom={typeOfBatchBeingRun === 'customs'}
              showTheseOptions={showTheseOptions}
            />
          ) : (
            <PrintModal
              {...props}
              open={printModal}
              onPrint={handlePrintBatch}
              onClose={handleClosePrintModal}
              source="DS"
              orders={selectedInfo.orders}
              bundles={selectedInfo.bundles}
              products={selectedInfo.products}
              barcodes={[]}
              name={name}
              setName={setName}
              titleName="Dropship Customs"
              notes={notes}
              setNotes={setNotes}
              isCustom={typeOfBatchBeingRun === 'customs'}
              showTheseOptions={showTheseOptions}
            />
          )}
        </>
      ) : (
        <Loading />
      )}

      <GeneralDialog
        label="batch"
        title="Batch"
        open={batchModal}
        onClose={handleCloseBatchModal}
        onConfirm={handleCloseBatchModal}
      >
        <Typography variant="body2" gutterBottom>
          Inline BATCH name is <strong>{batchName}</strong>
        </Typography>
      </GeneralDialog>

      <RunningLabelBatches
        isOpen={openRunningLabels}
        handleClose={closeRunningLabelsFunc}
        db={db}
      />
    </Layout>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(DsOrdersPage);
