import { Box } from '@mui/material';
import React from 'react';
import _ from 'lodash';

import { TYPE_OF_PRODUCT } from './constants';
import CardsBarGraphs from './CardsBarGraphs';

const HalfCards = ({ data, keyValue, isMobile, mainDropDownProduct }) => {
  const chosenProductType = mainDropDownProduct;

  // default of DTC selected
  const [selectedProductType, setSelectedProductType] = React.useState(
    TYPE_OF_PRODUCT.DTC,
  );
  const [selectedDate, setSelectedDate] = React.useState('January');

  React.useEffect(
    () =>
      // reset state when the component unmounts
      () => {
        setSelectedProductType(TYPE_OF_PRODUCT.DTC);
        setSelectedDate('January');
      },
    [],
  );

  const depCloneData = _.cloneDeep(data);

  const dataToUse = depCloneData[0];

  const womansPieces = {
    type: 'Women’s Underwear',
    types: {
      shopify: {
        styles: [],
      },
    },
  };

  dataToUse.products.push(womansPieces);

  // Find the index of the "socks" type
  const socksIndex = dataToUse.products.findIndex(
    (product) => product.type === 'socks',
  );

  // If found, move the "socks" type to the first position
  if (socksIndex !== -1) {
    const socksProduct = dataToUse.products.splice(socksIndex, 1)[0];
    dataToUse.products.unshift(socksProduct);
  }

  return (
    <Box
      sx={{
        ...styles.card,
        justifyContent: isMobile ? '' : 'space-evenly',
        gap: 2,
      }}
      key={keyValue}
    >
      {isMobile ? (
        <Box sx={styles.swipeContainer}>
          {dataToUse.products.map((product, index) => (
            <Box
              sx={{
                ...styles.swipeItem,
                // extend the right side if index is the last one
                marginRight:
                  index === dataToUse.products.length - 1 ? '15%' : '1px',
              }}
              key={index}
            >
              <CardsBarGraphs
                product={product}
                chosenProductType={chosenProductType}
                index={index}
                isMobile={isMobile}
              />
            </Box>
          ))}
        </Box>
      ) : (
        dataToUse.products.map((product, index) => (
          <CardsBarGraphs
            product={product}
            chosenProductType={chosenProductType}
            index={index}
            isMobile={isMobile}
            key={index}
          />
        ))
      )}
    </Box>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
  },
  swipeContainer: {
    display: 'flex',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch',
    scrollSnapType: 'x mandatory',
    gap: '16px',
  },
  swipeItem: {
    flex: '0 0 auto',
    scrollSnapAlign: 'start',
    width: '80%',
    marginRight: '1px', // Add margin to create the sliver effect
  },
};

export default HalfCards;
