import { Typography, Box } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { FileArt, ProductArt } from './components';
import { ART_TYPE } from '../../constants/defines';

const renderSide = (
  sideConfig,
  sideName,
  styles,
  isUnderwear,
  artType,
  firebase,
  classes,
  aspectRatio,
) => {
  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const getWidthPercentage = (aspectRatio) => {
    const scaledWidth = aspectRatio * 1000;
    if (sideConfig.doNotContainImage) {
      return `${aspectRatio}`;
    }
    return `${scaledWidth}`;
  };

  const useThisSide = () => {
    if (sideName === '') {
      return 'any';
    }

    return sideName;
  };

  return (
    <>
      {sideConfig.showImage && (
        <Box
          sx={
            isUnderwear
              ? {
                  ...styles.underwearFrontAndBackArt,
                  ...(sideConfig.isMediumItem
                    ? {
                        width: '54%',
                        height: '87%',
                        marginRight: '40%',
                        margin: sideConfig.hidePadding ? '0' : '1.8125rem',
                      }
                    : sideConfig.isBoxedImage
                      ? {
                          width: '54%',
                          height: '87%',
                          marginRight: '40%',
                          marginTop: '0%',
                        }
                      : {}),
                }
              : {
                  ...styles.graphicSocksFrontAndBackArt,
                  ...(sideConfig.isBiggerItem
                    ? {
                        width: '58%',
                        marginTop: sideConfig.hidePadding ? '5%' : '7%',
                      }
                    : {}),
                }
          }
          onMouseEnter={sideConfig.handleMouseEnter}
          onMouseLeave={sideConfig.handleMouseLeave}
        >
          <Typography
            variant="h6"
            sx={{
              ...styles.artSideText,
              top: sideConfig.addPaddingForUnderwear ? '-12%' : '-8%',
            }}
          >
            {capitalize(sideName)} Art
          </Typography>
          <Box
            sx={{
              ...(isUnderwear
                ? {
                    ...styles.underwearImge,
                    ...(sideConfig.isMediumItem ? { height: '90%' } : {}),
                  }
                : styles.graphicSocksImge),
              width: getWidthPercentage(aspectRatio),
              padding: sideConfig.isSockAndQuarter ? '0 10px' : '0',
            }}
          >
            {sideConfig.img ? (
              <FileArt
                name={`${sideName}Image`}
                key={`${sideName}Image`}
                classes={classes}
                file={sideConfig.img}
                progress={sideConfig.progress}
                setDimension={sideConfig.setDimension}
              />
            ) : (
              <ProductArt
                name={`${sideName}Image`}
                key={`${sideName}Image`}
                firebase={firebase}
                classes={classes}
                progress={sideConfig.progress}
                path={sideConfig.path}
                setDoesHaveImage={sideConfig.setDoesHaveImage}
                doNotContainImage={sideConfig.doNotContainImage}
                isUnderwear={isUnderwear}
              />
            )}
            {sideConfig.hoverState && (
              <Box sx={styles.deleteIcon} onClick={sideConfig.deleteFunc}>
                <DeleteOutlinedIcon sx={{ fontSize: 40, color: '#F36668' }} />
              </Box>
            )}
          </Box>
        </Box>
      )}

      {!sideConfig.showImage && (
        <Box
          sx={{
            ...(isUnderwear
              ? styles.underwearFrontAndBackUpload
              : styles.graphicSocksFrontAndBackUpload),
            position: 'relative',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              position: 'absolute',
              top: '-11%',
              left: '0',
              bottom: '0',
              textAlign: 'left',
              fontWeight: 'bold',
              zIndex: 1,
            }}
          >
            {capitalize(sideName)} Art
          </Typography>
          <Dropzone
            ref={sideConfig.dropzoneRef}
            onDrop={(files) =>
              sideName === 'left' || sideName === 'right'
                ? sideConfig.handleDrop(files[0], useThisSide())
                : sideConfig.handleDrop(files)
            }
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps(styles.dropZoneStyle)}
                className={classes.dropBox}
              >
                <input {...getInputProps()} accept="image/png" />
                <Box sx={styles.uploadIconAndText}>
                  <CloudUploadIcon sx={{ fontSize: 90 }} />
                  <p
                    className={classes.newDropLabel}
                    style={{ marginTop: '10px' }}
                  >
                    Drag & drop/Upload
                  </p>
                </Box>
              </Box>
            )}
          </Dropzone>
        </Box>
      )}
    </>
  );
};

export default renderSide;
