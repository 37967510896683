import React from 'react';

import DsOrdersPage from './DsOrdersPage';
import { withAuthorization } from '../Session';
import { collections } from '../../constants/defines';

const DsInline = (props) => (
  <DsOrdersPage
    {...props}
    collectionName={collections.DS_INLINE_QUEUE}
    type="inline"
    includeItemsPerPageSelector={true}
    convertToRowsOptions={true}
    pageName="dsinline"
  />
);

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(DsInline);
