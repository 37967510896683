import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { withAuthorization } from '../Session';
import { BootstrapInput } from '../Page/input';

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
  },
}));

function FaceModal(props) {
  const { handleClose, open, faces, onSelect } = props;
  const [mode, setMode] = useState('new');
  const [face, setFace] = useState(0);

  const db = props.firebase.getdb();

  useEffect(() => {
    if (open) {
      setMode('new');
      setFace(0);
    }
  }, [open]);

  const handleSubmit = () => {
    if (onSelect) {
      if (mode === 'new') {
        onSelect(-1);
      } else {
        onSelect(face);
      }
    }
    handleClose();
  };

  const handleCloseDialog = (event) => {
    handleClose();
  };

  const handleChange = (event) => {
    let { name, value, checked, type } = event.target;
    value = type === 'checkbox' ? checked : value;
    setFace(parseInt(value));
  };

  const handleChangeMode = (event) => {
    setMode(event.target.value);
  };

  const classes = useHeaderStyles();
  const scroll = 'paper';

  const faceItems = [];
  for (let i = 0; i < faces; i++) {
    faceItems.push(<MenuItem value={i}>{i + 1} Face Purchased</MenuItem>);
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="face-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
      scroll={scroll}
    >
      <DialogTitle
        onClose={handleCloseDialog}
        sx={{ padding: '40px 40px 30px', position: 'relative' }}
      >
        <Typography variant="h2">Add Faces Purchased Data</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '30px 40px' }} dividers>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              defaultValue={mode}
              name="mode"
              onChange={handleChangeMode}
            >
              <FormControlLabel
                value="new"
                control={<Radio />}
                label="Create New"
              />
              <FormControlLabel
                value="duplicate"
                control={<Radio />}
                label="Duplicate Form"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ padding: '20px 0' }}>
          <Select
            name="face"
            value={face}
            input={<BootstrapInput />}
            onChange={handleChange}
            disabled={mode === 'new'}
            sx={{ width: '100%' }}
          >
            {faceItems}
          </Select>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: '20px', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.blueButton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(FaceModal);
