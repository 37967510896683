import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import {
  Button,
  Zoom,
  useScrollTrigger,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  CircularProgress,
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Tooltip } from '@mui/material';
import { Brush as MissingArtIcon } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { FilterProvider } from '../Page/filterContext';
import { TableProvider } from '../Page/tableContext';
import { orderTypes } from '../../constants/defines';
import PageToolbar from './pageToolbar';
import { EnhancedTableHead, stableSort, getComparator } from '../Page/table';
import {
  getQBProductionOrderQueue,
  updateTableOrder,
  createBatch,
  markOrdersAsDelete,
  markOrderItemsAsPrinting,
} from '../../db/quickbooks';
import { getBarcodes } from '../../db/barcodes';
import { ConfirmDialog, GeneralDialog } from '../Page/generalModal';
import PrintModal from '../Page/printModal';
import { getTemplates } from '../../db/templates';
import Row from './row';
import { updateItemStatusInOrder } from '../../redux/wholeSaleOrders/actions';
import { calculateWholesale } from '../../utils/calculateOrderItems';
import { palette } from '../../theme/colors';
import { OrderSummary } from '../CustomOrders/pageHeaderHelperOrders';

const headCells = [
  {
    id: 'indentifier',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: true,
    label: '',
    width: '30px',
  },
  {
    id: 'txnDate',
    numeric: false,
    align: 'center',
    enableSort: true,
    disablePadding: true,
    label: 'Order Date',
    width: '140px',
  },
  {
    id: 'customer',
    numeric: true,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Customer',
    width: '200px',
  },
  {
    id: 'poNumber',
    numeric: true,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'PO#',
    width: '100px',
  },
  {
    id: 'docNumber',
    numeric: true,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Estimate #',
    width: '100px',
  },
  {
    id: 'shipDate',
    numeric: false,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Ship Date',
    width: '140px',
  },
  {
    id: 'cancelDate',
    numeric: false,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Cancel Date',
    width: '140px',
  },
  {
    id: 'daysleft',
    numeric: true,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Days left',
    width: '50px',
  },
  {
    id: 'units',
    numeric: true,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Units',
    width: '50px',
  },
  {
    id: 'priority',
    numeric: false,
    align: 'center',
    enableSort: true,
    disablePadding: false,
    label: 'Priority',
    width: '50px',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)',
  },
  tableHeadCell: {
    lineHeight: '27.5px',
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    color: '#000000',
    letterSpacing: '1.5px',
  },
  tableCheckbox: {},
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'initial',
    letterSpacing: '1.2px',
    color: '#000000',
  },
  tableCell1: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'capitalize',
    letterSpacing: '1.2px',
    color: '#000000',
  },
  tableCell2: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#FFFFFF',
    display: 'inline-block',
    background: '#05aa1d',
    padding: '5px 12px',
    borderRadius: '5px',
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red',
  },
  tableError: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.2px',
    color: 'red',
  },
  tableError1: {
    paddingLeft: '10px',
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center',
    },
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    background: '#1f7cf2',
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
  toolbar: {},
  bottomBar: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '10px 40px',
    zIndex: 100,
    background: '#fff',
    boxSizing: 'border-box',
  },
}));

const calculateProductsOld = (orders) => {
  const total = orders.length;
  let products = 0,
    boxes = 0;
  _.map(orders, (order) => {
    if (!!order) {
      _.map(order.items, (item) => {
        if (!!item.isBoxSet) {
          boxes += parseInt(item.qty);
          products += 3 * parseInt(item.qty);
        } else {
          products += parseInt(item.qty);
        }
      });
    }
  });
  return { total, boxes, products };
};

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 500,
  });

  return (
    <Zoom in={trigger}>
      <Box className={classes.bottomBar} role="presentation">
        {children}
      </Box>
    </Zoom>
  );
}

function ProductionQueue(props) {
  const dispatch = useDispatch();

  const storage = props.firebase.getstorage();

  const { wholesaleProductionOrders, selectedRowIds } = useSelector(
    (state) => state.wholeSaleOrders,
  );

  const clonedOrders = _.cloneDeep(wholesaleProductionOrders);

  const [mode, setMode] = useState(0);

  const [row, setRow] = useState(null);

  const [orders, setOrders] = useState(clonedOrders);

  useEffect(() => {
    // replace the ids of the items in the orders with the actual index of them but + 1 to avoid 0
    const newOrders = clonedOrders.map((order, index) => {
      const newItems = order.items.map((item, index) =>
        // dtring the index + 1
        ({ ...item, id: (index + 1).toString() }),
      );
      return { ...order, items: newItems };
    });

    setOrders(newOrders);
  }, [wholesaleProductionOrders]);

  const [printModal, setPrintModal] = useState(false);
  const [batchDialog, setBatchDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [selection, setSelection] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [barcodes, setBarcodes] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [name, setName] = useState('');

  const classes = useStyles();
  const db = props.firebase.getdb();

  useEffect(() => {
    // getQBProductionOrderQueue(db, fetchOrders);
    getBarcodes(db, 10, fetchBarcodes);
    getTemplates(db, 50, fetchTemplates);
  }, []);

  useEffect(() => {
    setSearchKey(props.searchKey);
  }, [props.searchKey]);

  const fetchBarcodes = (items) => {
    setBarcodes([...items]);
  };

  const fetchTemplates = (items) => {
    setTemplates([...items]);
  };

  const filterOrders = () => {
    const key = searchKey.toLowerCase();
    if (key === '') {
      return orders;
    }
    const items = _.filter(
      orders,
      (item) =>
        item.poNumber.toLowerCase().includes(key) ||
        item.customer.toLowerCase().includes(key) ||
        item.docNumber.toLowerCase().includes(key),
    );
    return items;
  };

  const handleSelectRow = (name) => {
    console.log('Select Row: ', name);

    const _selection = { ...selection };
    if (!_selection[name]) {
      const orderItem = _.find(orders, { order: name });
      const orderItems = _.filter(orderItem.items, (item) => item.status < 2);
      const ids = _.map(orderItems, (item) => item.id);
      const strIds = ids.join();
      _selection[name] = strIds;
    } else {
      console.log('Selection');
      delete _selection[name];
    }
    setSelection({ ..._selection });
  };

  const handleMoveRow = (dragIndex, hoverIndex) => {
    console.log(`Move Drag:${dragIndex}, Hover:${hoverIndex}`);

    const dragRecord = orders[dragIndex];
    const _orders = update(orders, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    });
    setOrders(_orders);
  };

  const handleDropRow = (dragIndex) => {
    console.log(`Drop Drag:${dragIndex}`);
    updateTableOrder(db, orders, dispatch, true);
  };

  const renderPageHeader = () => {
    // Function to filter items within an order based on status
    const filterItemsByStatus = (items) =>
      _.filter(items, (item) => item.status === 0 || item.status === undefined);

    // Filter orders to only include items with a status of 0 or undefined before any further processing
    const filteredOrders = _.map(orders, (order) => ({
      ...order,
      items: filterItemsByStatus(order.items),
    }));

    // Now work with filteredOrders for selection and calculations
    let selectedOrders = [];

    const _selected = _.filter(filteredOrders, (order) =>
      isSelection(order.order),
    );

    selectedOrders = _.map(_selected, (orderItem) => {
      const itemSelection = selection[orderItem.order]
        ? selection[orderItem.order]
        : '';
      const strIds = itemSelection.split(',');
      // Given the orders have been pre-filtered, only filter items by ID here
      const items = _.filter(
        orderItem.items,
        (item) => strIds.indexOf(item.id) !== -1,
      );
      return { ...orderItem, items };
    });

    // Calculate wholesale information based on the selected orders
    const info = calculateWholesale(selectedOrders, true);

    // Calculate initial totals based on all eligible orders before selection
    const initial = calculateWholesale(filteredOrders, true);

    return (
      <div
        className="pageHeader"
        sx={{ paddingTop: '25px', paddingBottom: '15px' }}
      >
        <div className="pageHeading">
          <Typography variant="h1">Wholesale Queue</Typography>

          <OrderSummary
            title="Total"
            counter={initial}
            palette={palette}
            hideOrdersString={true}
            hideBundleCount={true}
          />
          <OrderSummary
            title="Selected"
            counter={info}
            palette={palette}
            hideOrdersString={true}
            hideBundleCount={true}
          />

          {props.editOnly &&
            (info.products > 0 || info.notFound > 0 || info.missingArt > 0) && (
              <ScrollTop>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  padding={2}
                  bgcolor="#f1f7fe"
                  borderRadius={5}
                  marginTop={2}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <Typography variant="subtitle1">
                      Selected: <strong>{info.products} products</strong>
                    </Typography>
                    <Tooltip title="Total socks selected">
                      <Typography color="info.main" fontSize="medium">
                        {info.socks} SOCKS
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Total underwear selected">
                      <Typography color="primary.main" fontSize="medium">
                        {info.underwear} UNDERWEAR
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Total bundles selected">
                      <Typography fontSize="medium">
                        {info.boxes} BUNDLES
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Finished products">
                      <Typography color="secondary.main" fontSize="medium">
                        {info.finished} FINISHED
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Products not found">
                      <Typography
                        color="error.main"
                        fontSize="medium"
                        component="div" // Change the component to div to use display flex
                        sx={{
                          display: 'flex',
                          alignItems: 'center', // Centers the icon and text vertically
                          gap: 0.5, // Adds a small space between the icon and the text
                        }}
                      >
                        <ErrorOutlineIcon fontSize="small" />
                        {info.notFound} PRODUCT NOT FOUND
                      </Typography>
                    </Tooltip>
                    <Tooltip title="Products missing artwork">
                      <Typography
                        color="error.main"
                        fontSize="medium"
                        component="div" // Allows the use of flex properties
                        sx={{
                          display: 'flex',
                          alignItems: 'center', // Align items centrally
                          gap: 0.5, // Space between elements
                        }}
                      >
                        <MissingArtIcon fontSize="small" />
                        {info.missingArt} MISSING ART
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Button
                    variant="blue"
                    className={classes.button}
                    onClick={handlePrint}
                    sx={{ width: '180px' }}
                  >
                    Print Selected
                  </Button>
                </Box>
              </ScrollTop>
            )}
        </div>
      </div>
    );
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const _selection = {};
      _.each(orders, (orderItem) => {
        const orderItems = _.filter(
          orderItem.items,
          (item) => item.status == 0,
        );
        const ids = _.map(orderItems, (item) => item.id);
        const strIds = ids.join();
        _selection[orderItem.order] = strIds;
      });
      setSelection({ ..._selection });
      return;
    }
    setSelection({});
  };

  const handlePrint = () => {
    console.log('Clicked Print Button');
    setMode(0);
    setPrintModal(true);
  };

  const handleClickDelete = () => {
    console.log('Clicked Delete Button');
    setDeleteDialog(true);
  };

  const handleDelete = () => {
    let selOrders = [];
    const _selOrders = _.filter(orders, (item) => isSelection(item.order));
    selOrders = _.map(_selOrders, (orderItem) => {
      const itemSelection = !!selection[orderItem.order]
        ? selection[orderItem.order]
        : '';
      const strIds = itemSelection.split(',');
      const items = _.filter(
        orderItem.items,
        (item) => strIds.indexOf(item.id) !== -1,
      );
      return { ...orderItem, items };
    });

    const selOrderIds = _.map(selOrders, (item) => item.order);
    console.log('handleDelete');
    markOrdersAsDelete(db, selOrderIds, dispatch, true);
    setDeleteDialog(false);
  };

  const handlePrintRow = (orderNumber, items) => {
    console.log('Print Row');
    setMode(1);

    const _order = _.find(orders, { order: orderNumber });
    const selOrder = { ..._order, items: [...items] };
    setRow(selOrder);
    setPrintModal(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const renderTable = () => {
    const _orders =
      orderBy !== ''
        ? stableSort(filterOrders(), getComparator(order, orderBy))
        : filterOrders();

    return (
      <DndProvider backend={HTML5Backend}>
        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // large table
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={true}
              cells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {_orders.map((row, index) => {
                const odd = index % 2 === 0;
                const labelId = `wholesale-production-${row.order}/${Math.random()}`;
                const itemSelection = !!selection[row.order]
                  ? selection[row.order]
                  : '';
                row.odd = odd;
                // console.log('Row:', row)
                return (
                  <Row
                    {...props}
                    selectedRowIds={selectedRowIds}
                    storage={storage}
                    key={labelId}
                    label={labelId}
                    index={index}
                    production={true}
                    row={row}
                    dragDrop={true}
                    classes={classes}
                    templates={templates}
                    selection={itemSelection}
                    onSelect={handleSelectRow}
                    onMove={handleMoveRow}
                    onDrop={handleDropRow}
                    onPrint={handlePrintRow}
                    isProduction={true}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DndProvider>
    );
  };

  const handleClosePrintDialog = () => {
    setPrintModal(false);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handlePrintBatch = (
    name,
    secondaryName = '',
    color = '#000000',
    underwearFormat,
    barcode = '',
  ) => {
    let deleteWholeOrder = false;

    const filterItemsByStatus = (items) =>
      _.filter(items, (item) => item.status === 0 || item.status === undefined);

    // Filter orders to only include items with a status of 0 or undefined before any further processing
    const filteredOrders = _.map(orders, (order) => ({
      ...order,
      items: filterItemsByStatus(order.items),
    }));

    let selOrders = [];

    if (mode == 0) {
      const _selOrders = _.filter(filteredOrders, (item) =>
        isSelection(item.order),
      );

      selOrders = _.map(_selOrders, (orderItem) => {
        const itemSelection = !!selection[orderItem.order]
          ? selection[orderItem.order]
          : '';

        const strIds = itemSelection.split(',');

        // Only update status for selected items
        const items = _.map(orderItem.items, (item) => ({
          ...item,
          // Update status only if the item is selected and its status is not already > 0
          status:
            strIds.includes(item.id) && item.status <= 0 ? 1 : item.status,
        }));

        return { ...orderItem, items };
      });
    } else {
      selOrders = [row]; // Assuming 'row' is defined in your context
      deleteWholeOrder = false;
    }

    // Extract all values, join them (if there are multiple), and split into an array
    const allIds = Object.values(selection)
      .join(',') // This handles if there are multiple keys with ID strings
      .split(',')
      .map((id) => id.trim()); // Ensure no extra whitespace

    // conver this arr: allIds into id: 1, id: 2, id: 3
    const selectedAdjusted = allIds.map((id, index) => ({ id: id, value: id }));

    const itemsToGreyOutWithOrderId = selOrders.map(
      ({ order: orderId, items }) => ({
        orderId,
        items: items.filter(({ id }) =>
          (selection[orderId] || '').split(',').includes(id),
        ),
      }),
    );

    itemsToGreyOutWithOrderId.forEach(({ orderId, items }) => {
      items.forEach(({ id }) => {
        dispatch(updateItemStatusInOrder(orderId, id, true, false));
        console.log('Update Item Status:', orderId, id);
      });
    });

    setBatchName(name);

    createBatch(
      db,
      name,
      secondaryName,
      color,
      underwearFormat,
      barcode,
      selOrders,
      handleCreatedOrder,
      deleteWholeOrder,
      selectedAdjusted,
    );

    setPrintModal(false);
  };

  const handleCreatedOrder = (
    result,
    items,
    batchName,
    deleteWholeOrder = false,
    selectedAdjusted,
  ) => {
    if (result) {
      const _order = items[0];
      const originalOrder = _.find(orders, { order: _order.order });

      markOrderItemsAsPrinting(
        db,
        originalOrder,
        _order.items,
        dispatch,
        deleteWholeOrder,
        selectedAdjusted,
      );

      setSelection({});
      setRow(null);
      setBatchDialog(true);
    }
  };

  const renderPrintModal = () => {
    const filterItemsByStatus = (items) =>
      _.filter(items, (item) => item.status === 0 || item.status === undefined);

    // Filter orders to only include items with a status of 0 or undefined before any further processing
    const filteredOrders = _.map(orders, (order) => ({
      ...order,
      items: filterItemsByStatus(order.items),
    }));

    let selOrders = [];

    if (mode == 0) {
      const _selOrders = _.filter(filteredOrders, (item) =>
        isSelection(item.order),
      );
      selOrders = _.map(_selOrders, (orderItem) => {
        const itemSelection = !!selection[orderItem.order]
          ? selection[orderItem.order]
          : '';
        const strIds = itemSelection.split(',');

        const items = _.filter(
          orderItem.items,
          (item) => strIds.indexOf(item.id) !== -1,
        );

        return { ...orderItem, items };
      });
    } else {
      selOrders = [row];
    }

    const info = calculateProductsOld(selOrders);

    return (
      <PrintModal
        {...props}
        open={printModal}
        source="Wholesale"
        onPrint={handlePrintBatch}
        onClose={handleClosePrintDialog}
        orders={info.total}
        products={info.products}
        bundles={info.boxes}
        barcodes={barcodes}
        name={name}
        setName={setName}
      />
    );
  };

  const handleCloseBatchDialog = () => {
    setBatchDialog(false);
  };

  const renderBatchModal = () => (
    <GeneralDialog
      label="batch"
      title="Batch"
      open={batchDialog}
      onClose={handleCloseBatchDialog}
      onConfirm={handleCloseBatchDialog}
    >
      <Typography variant="body2" gutterBottom>
        Inline BATCH name is <strong>{batchName}</strong>
      </Typography>
    </GeneralDialog>
  );

  const renderDeleteDialog = () => (
    <ConfirmDialog
      label="delete"
      title="Delete"
      open={deleteDialog}
      onClose={handleCloseDeleteDialog}
      onConfirm={handleDelete}
    >
      <Typography gutterBottom>
        Are you sure you want to delete selected orders?
      </Typography>
    </ConfirmDialog>
  );

  const handleChangeSelection = (orderNumber, newSel) => {
    console.log('Change Selection:', orderNumber, newSel);
    const _selection = { ...selection };
    if (newSel.length == 0) {
      delete _selection[orderNumber];
    } else {
      const strIds = newSel.join();
      _selection[orderNumber] = strIds;
    }

    setSelection({ ..._selection });
  };

  const isSelection = (name) => !!selection[name];

  const selected = _.map(selection, (item, key) => key);

  return (
    <div>
      {renderPageHeader()}

      <PageToolbar
        viewOnly={props.viewOnly}
        editOnly={props.editOnly}
        numSelected={selected.length}
        allSelected={orders.length === selected.length}
        onSelectAllClick={handleSelectAllClick}
        onPrint={handlePrint}
        onDelete={handleClickDelete}
      />

      <TableProvider value={{ selection, setSelect: handleChangeSelection }}>
        {renderTable()}
      </TableProvider>

      {renderPrintModal()}
      {renderBatchModal()}
      {renderDeleteDialog()}
    </div>
  );
}

export default ProductionQueue;
