import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Box } from '@mui/material';

import { createOnChangeHandler } from '../../utils/createOnChangeHandler';
import FilterContext from './filterContext';
import FilterDropdown from '../Page/filterDropdown';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  filterBox: {
    display: 'flex',
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
  },
}));

const PageToolbar = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Control visibility of DatePicker

  const handleDateChange = (date) => {
    // Convert the date to the desired format: mm/dd/yy
    const formattedDate = date.toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });

    data.setSelectedBatchDate(formattedDate);
    setIsOpen(false);
  };

  const onRestart = () => {
    data.setSelectedBatchDate('');
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen(true); // Open the date picker when button is clicked
  };

  const data = useContext(FilterContext);

  const classes = useToolbarStyles();

  const { numSelected, allSelected, onSelectAllClick, onPrint, onRevision } =
    props;

  const dispatch = useDispatch();

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{ marginRight: '40px', height: '46px', width: '165px' }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={numSelected > 0 && allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={
                numSelected > 0 && allSelected ? 'SELECT NONE' : 'SELECT ALL'
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {props.editOnly && numSelected > 0 && (
          <Button variant="blue" onClick={onPrint} sx={{ marginRight: '15px' }}>
            Print Selected
          </Button>
        )}

        {props.editOnly && numSelected > 0 && (
          <Button
            variant="black"
            className={classes.button}
            onClick={onRevision}
            sx={{ background: '#3d3d3d' }}
          >
            Needs Revisions
          </Button>
        )}
      </div>

      <div className={classes.filterBox}>
        <Box sx={{ marginX: '16px', marginTop: '0px' }}>
          {!isOpen && (
            <Button
              variant="outlined"
              onClick={handleButtonClick}
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: '500',
                color: '#000000',
                border: '1px solid #000000',
                width: '200px',
              }}
            >
              {data.dateSelectedForBatch
                ? `Selected Date: ${data.dateSelectedForBatch.toLocaleDateString()}`
                : 'Select Batch Date'}
            </Button>
          )}

          {isOpen && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <DatePicker
                selected={data.selectedBatchDate}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                showPopperArrow={false}
                inline // Show the date picker inline or as a modal
              />
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering onClickOutside
                  onRestart(); // Reset date logic
                }}
              >
                X
              </Button>
            </Box>
          )}
        </Box>
        <FilterDropdown
          title="Show"
          items={data.shows}
          active={data.filterShow}
          onSelect={createOnChangeHandler(
            data.setFilterShow,
            dispatch,
            'custom',
          )}
        />
        <FilterDropdown
          title="Order"
          items={data.orders}
          active={data.filterOrder}
          onSelect={createOnChangeHandler(
            data.setFilterOrder,
            dispatch,
            'custom',
          )}
        />
        <FilterDropdown
          title="Type"
          items={data.types}
          active={data.filterType}
          onSelect={createOnChangeHandler(
            data.setFilterType,
            dispatch,
            'custom',
          )}
        />
        <FilterDropdown
          title="Shipping"
          items={data.shippings}
          active={data.filterShipping}
          onSelect={createOnChangeHandler(
            data.setFilterShipping,
            dispatch,
            'custom',
          )}
        />
        <FilterDropdown
          title="Source"
          items={data.sources}
          active={data.filterSource}
          onSelect={createOnChangeHandler(
            data.setFilterSource,
            dispatch,
            'custom',
          )}
        />
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
