import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';

import FilterDropdown from '../Page/filterDropdown';
import FilterContext from '../../context/filterContext';
import { createOnChangeHandler } from '../../utils/createOnChangeHandler';
import { resetInlineFilters } from '../../redux/inlineOrders/actions';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '3.125rem 0 3.125rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '.0625rem solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  toolBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '.5625rem 2rem',
    fontSize: '1rem',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '1rem',
  },
  formLabel: {
    color: '#000000',
    fontSize: '.875rem',
    fontWeight: '500',
    letterSpacing: '.0938rem',
  },
}));

const PageToolbar = (props) => {
  const data = useContext(FilterContext);

  const classes = useToolbarStyles();

  const { numSelected, allSelected, onSelectAllClick, onPrint } = props;

  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(resetInlineFilters());
  };

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{
            marginRight: '2.5rem',
            height: '2.875rem',
            width: '10.3125rem',
          }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  disabled={!props.enable}
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={numSelected > 0 && allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={
                numSelected > 0 && allSelected ? 'SELECT NONE' : 'SELECT ALL'
              }
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {props.editOnly && numSelected > 0 && (
          <Button variant="blue" disabled={!props.enable} onClick={onPrint}>
            Print Selected
          </Button>
        )}
      </div>

      <div className={classes.toolBox}>
        <div className={classes.filterBox}>
          <FilterDropdown
            title="Show"
            items={data.shows}
            active={data.filterShow}
            onSelect={createOnChangeHandler(
              data.setFilterShow,
              dispatch,
              'inline',
              { shows: data.shows },
              true,
              null,
              data.shippings,
            )}
          />
          <FilterDropdown
            title="Orders"
            items={data.products}
            active={data.filterProduct}
            onSelect={createOnChangeHandler(
              data.setFilterProduct,
              dispatch,
              'inline',
              {
                products: data.products,
              },
              false,
              data.products,
              data.shippings,
            )}
          />
          <FilterDropdown
            title="Production"
            items={data.production}
            active={data.filterProduction}
            onSelect={createOnChangeHandler(
              data.setFilterProductionTypes,
              dispatch,
              'inline',
              {
                production: data.production,
              },
              false,
              data.production,
              data.shippings,
            )}
          />
          <FilterDropdown
            title="Type"
            items={data.types}
            active={data.filterType}
            onSelect={createOnChangeHandler(
              data.setFilterType,
              dispatch,
              'inline',
              {
                types: data.types,
              },
              false,
              data.types,
              data.shippings,
            )}
          />
          <FilterDropdown
            title="Shipping"
            items={data.shippings}
            active={data.filterShipping}
            onSelect={createOnChangeHandler(
              data.setFilterShipping,
              dispatch,
              'inline',
              {
                shippings: data.shippings,
              },
              false,
              data.shippings,
              data.shippings,
            )}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleReset}
            sx={{ marginRight: '20px', marginLeft: '20px' }}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
