const findOldestOrderDateDs = (orders) => {
  let oldestDate = null;

  orders.forEach((order) => {
    if (order.dsDate) {
      // Parse dsDate (mm/dd/yy format)
      const [month, day, year] = order.dsDate.split('/').map(Number);
      const parsedDate = new Date(2000 + year, month - 1, day); // Adjust for yy to yyyy

      if (!oldestDate || parsedDate < oldestDate) {
        oldestDate = parsedDate;
      }
    }
  });

  // Convert the oldest date back to mm/dd/yy format
  if (oldestDate) {
    const mm = String(oldestDate.getMonth() + 1).padStart(2, '0');
    const dd = String(oldestDate.getDate()).padStart(2, '0');
    const yy = String(oldestDate.getFullYear()).slice(-2);
    return `${mm}/${dd}/${yy}`;
  }

  return null;
};

export const getOldestOrderDate = (orders) => {
  console.log('orders:', orders);
  // Convert Firestore timestamp to JavaScript Date and format it as mm/dd/yy
  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with 0
    const dd = String(date.getDate()).padStart(2, '0'); // Get day and pad with 0
    const yy = String(date.getFullYear()).slice(-2); // Get last two digits of year
    return `${mm}/${dd}/${yy}`;
  };

  // if all orders have value : dsDate, use findOldestOrderDateDs
  if (orders.every((order) => order.dsDate)) {
    console.log('using findOldestOrderDateDs!');
    const oldestDate = findOldestOrderDateDs(orders);
    console.log('oldestDate:', oldestDate);
    return oldestDate;
  }

  // Find the oldest order
  const oldestOrder = orders.reduce((oldest, currentOrder) => {
    const currentCreatedAt = currentOrder.created_at;
    const oldestCreatedAt = oldest.created_at;

    return currentCreatedAt.seconds < oldestCreatedAt.seconds
      ? currentOrder
      : oldest;
  });

  // Return the formatted date of the oldest order
  return formatDate(oldestOrder.created_at);
};
