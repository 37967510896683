import { useEffect } from 'react';

import { API } from '../constants/defines';
import {
  fetchWholeSaleOrdersFailure,
  fetchWholeSaleOrdersSuccess,
  injectWholeSaleOrders,
  injectWholeSaleOrdersProductionQueue,
  injectWholeSaleOrdersQueue,
  startFetchingWholeSaleOrders,
} from '../redux/wholeSaleOrders/actions';

const useFetchWholesale = (dispatch) => {
  useEffect(() => {
    console.log('Running useFetchWholesale Hook');
    const fetchData = async () => {
      try {
        dispatch(startFetchingWholeSaleOrders());

        const response = await fetch(API.getWholeSaleOrders);

        if (!response.ok) {
          dispatch(fetchWholeSaleOrdersFailure());
          throw new Error('Network response was not ok');
        }

        const { wholeSaleProductionQueue, wholesaleOrderQueue } =
          await response.json();

        dispatch(injectWholeSaleOrdersQueue(wholesaleOrderQueue ?? []));

        dispatch(
          injectWholeSaleOrdersProductionQueue(wholeSaleProductionQueue ?? []),
        );

        dispatch(fetchWholeSaleOrdersSuccess());
      } catch (error) {
        console.log('Error in getWholeSaleOrders:', `${error.message}`);
      }
    };

    fetchData();
  }, []); // empty dependencies array
};

export default useFetchWholesale;
