export const TYPE_OF_PRODUCT = {
  DTC: 'DTC',
  DROPSHIP: 'DROPSHIP',
};

export const DATES_BY_MONTH = {
  JANUARY: 'January',
  // FEBRUARY: "February",
};

export const PRODUCT_OPTIONS = {
  SOCKS: 'Socks',
  BOXERS: 'Boxers',
};

export const ORDER_TYPES = {
  DROPSHIP: 'DROPSHIP',
  DTC: 'DTC',
};
