import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import InputBase from '@mui/material/InputBase';
import { ColorButton } from 'material-ui-color';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import { BootstrapInput } from './input';
import { checkExistingInlineOrder } from '../../db/inlineOrder';
import { checkExistingCustomOrder } from '../../db/customOrder';
import { checkExistingHistory } from '../../db/history';
import { validateBatchName } from '../../module/functions';
import useStyles from './modal.styles';
import iconShuttle from '../../images/shuttle.svg';
import iconBelt from '../../images/belts.svg';

const colorCodes = ['#000000', '#ff0000', '#0000ff', '#00ff00', '#A6A6A6'];

const CustomPrintModal = (props) => {
  const {
    onPrint,
    onClose,
    open,
    products,
    bundles,
    orders,
    source,
    barcodes,
    name,
    setName,
  } = props;

  const [secondaryName, setSecondaryName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [batchNameError, setBatchNameError] = useState(false);
  const [batchNameErrorMsg, setBatchNameErrorMsg] = useState('');
  const [batchTypeError, setBatchTypeError] = useState(false);
  const [batchTypeErrorMsg, setBatchTypeErrorMsg] = useState('');
  const [press, setPress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(colorCodes[0]);
  const [batchType, setBatchType] = useState('');

  const db = props.firebase.getdb();
  const nameRef = useRef(null);
  const secondRef = useRef(null);

  useEffect(() => {
    const downHandler = (e) => {
      setPress(e.keyCode);
    };

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setColor(colorCodes[0]);
      setSecondaryName('');
      setBarcode('');
      setBatchType('');
      setBatchNameError(false);
      setBatchNameErrorMsg('');
      setBatchTypeError(false);
      setBatchTypeErrorMsg('');

      setPress(0);
      // nameRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (press == 13 && open) {
      handlePrint();
    } else if (press === 9 && open) {
      console.log('Tab Clicked');
      // secondRef.current.focus();
    }
  }, [press]);

  const handleCloseDialog = (event) => {
    onClose();
  };

  const onChangeName = (event) => {
    const { name, value } = event.target;
    const result = validateBatchName(value);
    if (result !== 'Success') {
      setBatchNameError(true);
      setBatchNameErrorMsg(result);
    } else {
      setBatchNameError(false);
      setBatchNameErrorMsg('');
    }
    if (name === 'name') {
      setName(value);
    } else {
      setSecondaryName(value);
    }
  };

  const onChangeBarcode = (event) => {
    const value = event.target.value;
    setBarcode(value);
  };

  const handlePrint = async () => {
    let exists = false;
    exists = await checkExistingCustomOrder(db, name);
    if (exists) {
    }

    exists = await checkExistingInlineOrder(db, name);
    if (exists) {
    }

    exists = await checkExistingHistory(db, name);
    if (exists) {
    }

    if (batchType === '') {
      setBatchTypeError(true);
      setBatchTypeErrorMsg('Please select Shuttle or Belt');
      return;
    }

    if (onPrint) {
      onPrint(name, secondaryName, color, batchType, barcode);
    }
  };

  const renderPreview = () => (
    <Box className={classes.previewBoxWrap} sx={{ marginTop: '10px' }}>
      <Typography className={classes.fieldLabel}>Preview:</Typography>
      <Box className={classes.previewBox} sx={{ fill: color }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="130">
          <g>
            <text
              x="50%"
              y="25"
              alignment-baseline="middle"
              text-anchor="middle"
              font-size="20"
              font-weight="bold"
            >
              {secondaryName.toUpperCase()}
            </text>
            {name.length > 12 ? (
              <text
                x="50%"
                y="70"
                textLength="440"
                alignment-baseline="middle"
                text-anchor="middle"
                lengthAdjust="spacingAndGlyphs"
                font-size="50"
                font-weight="bold"
              >
                {name.toUpperCase()}
              </text>
            ) : (
              <text
                x="50%"
                y="70"
                alignment-baseline="middle"
                text-anchor="middle"
                font-size="50"
                font-weight="bold"
              >
                {name.toUpperCase()}
              </text>
            )}
            <text
              x="50%"
              y="110"
              alignment-baseline="middle"
              text-anchor="middle"
              font-size="20"
              font-weight="bold"
            >
              SKU INFO
            </text>
          </g>
        </svg>
      </Box>
    </Box>
  );

  const handleClickColor = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColorBox = () => {
    setAnchorEl(null);
  };

  const handleChangeColor = (code) => {
    setColor(code);
    handleCloseColorBox();
  };

  const handleChangeBatchType = (name) => {
    setBatchType(name);
  };

  const renderColorBox = () => {
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <Box sx={{ paddingTop: '30px' }}>
        <IconButton
          aria-describedby={id}
          onClick={handleClickColor}
          className={classes.colorButton}
        >
          <ColorButton color={color} size={20} />
        </IconButton>
        <Popover
          id={id}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseColorBox}
        >
          <Box className={classes.colorListBox}>
            {_.map(colorCodes, (code) => (
              <Box className={classes.colorListBoxItem}>
                <IconButton
                  className={classes.colorButton}
                  onClick={(e) => handleChangeColor(code)}
                >
                  <ColorButton color={code} size={20} />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Popover>
      </Box>
    );
  };

  const renderBatchType = () => (
    <Box sx={{ marginTop: '30px' }}>
      <Box className={classes.nameRow}>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              batchType === 'shuttle' ? classes.typeActiveBox : classes.typeBox
            }
            onClick={(e) => handleChangeBatchType('shuttle')}
          >
            <img src={iconShuttle} alt="Shuttle" className={classes.typeIcon} />
            <Typography className={classes.typeLabel}>SHUTTLE</Typography>
          </Box>
        </Box>
        <Box className={classes.rowMiddleItem}></Box>
        <Box className={classes.rowHalfItem}>
          <Box
            className={
              batchType === 'belt' ? classes.typeActiveBox : classes.typeBox
            }
            onClick={(e) => handleChangeBatchType('belt')}
          >
            <img src={iconBelt} alt="Belt" className={classes.typeIcon} />
            <Typography className={classes.typeLabel}>BELT</Typography>
          </Box>
        </Box>
      </Box>
      {batchTypeError && (
        <Box>
          <Typography className={classes.fieldError}>
            {batchTypeErrorMsg}
          </Typography>
        </Box>
      )}
    </Box>
  );

  const renderBarcodes = () => (
    <Box sx={{ marginTop: '30px' }}>
      <Typography className={classes.fieldLabel}>Barcode Template</Typography>
      <Select
        name="barcode"
        value={barcode}
        input={<BootstrapInput />}
        onChange={onChangeBarcode}
      >
        <MenuItem value="">None</MenuItem>
        {_.map(barcodes, (item) => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}
      </Select>
    </Box>
  );

  const classes = useStyles();

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="print-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle
        id="print-dialog-title"
        onClose={handleCloseDialog}
        sx={{ padding: '10px 40px', marginTop: '40px' }}
      >
        <Typography variant="h2">Print Selected</Typography>
        <IconButton
          variant="close"
          aria-label="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 40px' }}>
        {source !== 'Manual' ? (
          <Typography variant="subtitle1">
            SELECTED <strong>{orders}</strong> ORDERS,{' '}
            <strong>{products}</strong> PRODUCTS, <strong>{bundles}</strong>{' '}
            BUNDLES
          </Typography>
        ) : (
          <Typography variant="subtitle1">
            SELECTED <strong>{products}</strong> PRODUCTS,{' '}
            <strong>{bundles}</strong> BUNDLES
          </Typography>
        )}

        <Divider
          component="div"
          sx={{ marginTop: '10px', marginBottom: '20px' }}
        />

        <Box>
          <Typography variant="subtitle1">Batch Name</Typography>
          <InputBase
            name="name"
            label="1"
            inputProps={{ tabIndex: '1' }}
            ref={nameRef}
            defaultValue={name}
            className={classes.inputBase}
            onChange={onChangeName}
            autoFocus
          />
        </Box>

        {batchNameError && (
          <Box>
            <Typography className={classes.fieldError}>
              {batchNameErrorMsg}
            </Typography>
          </Box>
        )}

        {renderBatchType()}
      </DialogContent>
      <DialogActions sx={{ padding: '40px' }}>
        <Button
          onClick={handlePrint}
          disabled={batchNameError}
          color="primary"
          variant="blue"
          classes={{ disabled: classes.disabledButton }}
          sx={{ width: '150px' }}
          tabIndex="3"
        >
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomPrintModal;
