import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import { fetchForAnyOrder } from '../../db/fetchForAnyOrder';
import { formatDateTime } from '../../module/functions';
import { withAuthorization } from '../Session';
import Layout from '../Page';
import { EnhancedTableHead } from '../Page/table';

const useStyles = makeStyles((theme) => ({
  search: {
    marginTop: '20px',
    width: '470px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)',
  },
  tableHeadCell: {
    lineHeight: '27.5px',
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '1.5px',
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000',
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red',
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center',
    },
  },
}));

const headCells = [
  {
    id: 'status',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'STATUS',
    width: '300px',
  },
  {
    id: 'complete',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'COMPLETED BY',
    width: 'auto',
  },
  {
    id: 'date',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'DATE',
    width: '500px',
  },
];

const Timestamp = (props) => {
  const [orderNumber, setOrderNumber] = useState('');
  const [activities, setActivities] = useState([]);

  const classes = useStyles();
  const db = props.firebase.getdb();

  const fetchData = (items) => {
    if (!items) {
      setActivities([]);
      return;
    }
    if (items.length === 0) {
      setActivities([]);
      return;
    }
    if (items.length > 0) {
      setActivities([...items]);
    }
  };

  const handleChange = (event) => {
    setOrderNumber(event.target.value);
  };

  const handleSearch = (event) => {
    if (event.keyCode === 13) {
      fetchForAnyOrder(db, orderNumber, fetchData);
    }
  };

  return (
    <Layout page={props.page} user={props.user}>
      <div className="pageHeader">
        <div className="pageHeading">
          <Typography variant="h1">Order Timestamps</Typography>

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search Print History"
              sx={{ width: '100%' }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleChange}
              onKeyUp={handleSearch}
            />
          </div>
        </div>
      </div>

      {activities.length === 0 ? (
        <Box sx={{ marginTop: '40px' }}>
          <Typography variant="tableText">No Activity</Typography>
        </Box>
      ) : (
        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={false}
              cells={headCells}
            />
            <TableBody>
              {activities.map((row, index) => {
                const keyId = `key-${index}`;
                const odd = index % 2 === 1;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={keyId}
                    classes={{
                      root: odd ? classes.tableRow : classes.tableRow2,
                      selected: classes.selectedTableRow,
                    }}
                  >
                    <TableCell align="left">
                      <Typography variant="tableText">{row.status}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableText">{row.user}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="tableText">
                        {formatDateTime(
                          new Date(
                            row.createdAt.seconds * 1000 +
                              row.createdAt.nanoseconds / 1000000,
                          ),
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
};

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Timestamp);
