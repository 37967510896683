import React, { useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TableHead,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import {
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
} from 'firebase/firestore';

import { collections } from '../../constants/defines';
import { findLocationDataByLocation } from '../../db/productLocation';
import { EnhancedTableHead } from '../Page/table';

const headCells = [
  {
    id: 'location',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'Location',
    width: '100%',
  },
];

const RenderLocationList = ({
  classes,
  inventoryLocations,
  handleRequestSort,
  db,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('location');
  const [expandedRows, setExpandedRows] = useState({});
  const [rowData, setRowData] = useState({}); // Separate state for each row's data
  const [editingRows, setEditingRows] = useState({}); // Track editing state for each row
  const [addingRows, setAddingRows] = useState({}); // Track "Add Product" state
  const unsubscribeRefs = useRef({});

  const getUser = () => {
    const user = localStorage.getItem('currentuser');
    return user ? user : 'N/A';
  };

  const handleToggleRow = (index, location) => {
    if (expandedRows[index]) {
      // Collapse row and unsubscribe
      if (unsubscribeRefs.current[index]) {
        unsubscribeRefs.current[index]();
        delete unsubscribeRefs.current[index];
      }
      setExpandedRows((prev) => {
        const newState = { ...prev };
        delete newState[index];
        return newState;
      });
    } else {
      setExpandedRows((prev) => ({
        ...prev,
        [index]: true,
      }));
      // Expand row and fetch data
      findLocationDataByLocation(
        db,
        location,
        (data) => {
          setRowData((prev) => ({
            ...prev,
            [index]: data, // Store data specific to this row
          }));
        },
        (unsubscribe) => {
          unsubscribeRefs.current[index] = unsubscribe; // Save the unsubscribe function
        },
      );
    }
  };

  const handleSave = async (itemId, updatedName, updatedQuantity) => {
    try {
      const docRef = doc(db, collections.INVENTORY, itemId);
      await updateDoc(docRef, {
        name: updatedName,
        quantity: updatedQuantity,
        updatedAt: new Date(),
        updatedBy: getUser(),
      });

      // Update local state and exit editing mode
      setRowData((prev) => {
        const updatedRowData = { ...prev };
        Object.keys(updatedRowData).forEach((key) => {
          updatedRowData[key] = updatedRowData[key].map((item) =>
            item.id === itemId
              ? { ...item, name: updatedName, quantity: updatedQuantity }
              : item,
          );
        });
        return updatedRowData;
      });

      setEditingRows((prev) => {
        const newState = { ...prev };
        delete newState[itemId];
        return newState;
      });
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const [itemToDelete, setItemToDelete] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenModal = (itemId) => {
    setItemToDelete(itemId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setItemToDelete(null);
    setOpen(false);
  };

  const handleDelete = async (itemId) => {
    if (!itemToDelete) {
      return;
    }

    try {
      const docRef = doc(db, collections.INVENTORY, itemToDelete);
      await deleteDoc(docRef);

      // Update local state to remove the deleted row
      setRowData((prev) => {
        const updatedRowData = { ...prev };
        Object.keys(updatedRowData).forEach((key) => {
          updatedRowData[key] = updatedRowData[key].filter(
            (item) => item.id !== itemToDelete,
          );
        });
        return updatedRowData;
      });

      // Close the modal after successful deletion
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };

  const handleAddProduct = (location) => {
    setAddingRows((prev) => ({
      ...prev,
      [location]: { name: '', sku: '', quantity: 0, location: location },
    }));
  };

  const handleCancelAddProduct = (location) => {
    setAddingRows((prev) => {
      const newState = { ...prev };
      delete newState[location];
      return newState;
    });
  };

  const handleSaveNewProduct = async (location) => {
    try {
      const newProduct = addingRows[location];
      const dataConstruct = {
        sku: newProduct.sku.trim(),
        name: newProduct.name.trim(),
        quantity: parseInt(newProduct.quantity),
        location: location?.toUpperCase(),
        parent: newProduct.parent ?? '',
        createdAt: new Date(),
        createdBy: getUser(),
      };

      // if something is missing, don't save
      if (
        !dataConstruct.sku ||
        !dataConstruct.name ||
        !dataConstruct.quantity
      ) {
        alert('Please fill in all fields');
        return;
      }

      // check if quantity is a number && greater than 0 not negative
      if (isNaN(dataConstruct.quantity) || dataConstruct.quantity < 0) {
        alert('Quantity must be a positive number');
        return;
      }

      addDoc(collection(db, collections.INVENTORY), dataConstruct)
        .then((doc) => {
          console.log('New Inventory created');
          console.log(doc.id);
        })
        .catch((error) => {
          console.error('Error adding Inventory: ', error);
        });

      // Update local state with the new product
      setRowData((prev) => {
        const updatedRowData = { ...prev };
        if (updatedRowData[location]) {
          updatedRowData[location].push(newProduct);
        } else {
          updatedRowData[location] = [newProduct];
        }
        return updatedRowData;
      });

      setAddingRows((prev) => {
        const newState = { ...prev };
        delete newState[location];
        return newState;
      });
    } catch (error) {
      console.error('Error saving new product:', error);
    }
  };

  return (
    <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          useCheckbox={false}
          cells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {inventoryLocations.map((location, index) => (
            <React.Fragment key={`location-row-${index}`}>
              <TableRow
                hover
                tabIndex={-1}
                onClick={() => handleToggleRow(index, location)}
                classes={{
                  root: classes.tableRow2,
                }}
              >
                <TableCell align="left">
                  <IconButton size="small">
                    {expandedRows[index] ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                  <Typography variant="tableText">{location}</Typography>
                </TableCell>
              </TableRow>
              {/* Collapsible Content */}
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={headCells.length}
                >
                  <Collapse
                    in={expandedRows[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div style={{ padding: '10px 20px' }}>
                      {rowData[index] ? (
                        <TableContainer>
                          <Table size="small" aria-label="items table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                  >
                                    Name
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                  >
                                    SKU
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                  >
                                    Quantity
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rowData[index].map((item) => {
                                const isRowEditing = editingRows[item.id];
                                return (
                                  <TableRow key={`item-${index}-${item.id}`}>
                                    <TableCell align="left">
                                      {isRowEditing ? (
                                        <TextField
                                          value={isRowEditing.name}
                                          onChange={(e) =>
                                            setEditingRows((prev) => ({
                                              ...prev,
                                              [item.id]: {
                                                ...prev[item.id],
                                                name: e.target.value,
                                              },
                                            }))
                                          }
                                          variant="outlined"
                                          size="small"
                                          sx={{ width: '100%' }}
                                        />
                                      ) : (
                                        <Typography variant="body2">
                                          {item.name}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Typography variant="body2">
                                        {item.sku}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                      {isRowEditing ? (
                                        <TextField
                                          value={isRowEditing.quantity}
                                          onChange={(e) =>
                                            setEditingRows((prev) => ({
                                              ...prev,
                                              [item.id]: {
                                                ...prev[item.id],
                                                quantity: e.target.value,
                                              },
                                            }))
                                          }
                                          type="number"
                                          variant="outlined"
                                          size="small"
                                        />
                                      ) : (
                                        <Typography variant="body2">
                                          {item.quantity}
                                        </Typography>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      sx={{ display: 'flex', gap: '10px' }}
                                    >
                                      {isRowEditing ? (
                                        <>
                                          {/* cancel edit */}
                                          <IconButton
                                            onClick={() =>
                                              setEditingRows((prev) => ({
                                                ...prev,
                                                [item.id]: null,
                                              }))
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                          {/* save edit */}
                                          <IconButton
                                            onClick={() =>
                                              handleSave(
                                                item.id,
                                                isRowEditing.name,
                                                isRowEditing.quantity,
                                              )
                                            }
                                          >
                                            <SaveIcon />
                                          </IconButton>
                                        </>
                                      ) : (
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            setEditingRows((prev) => ({
                                              ...prev,
                                              [item.id]: {
                                                name: item.name,
                                                quantity: item.quantity,
                                                id: item.id,
                                              },
                                            }))
                                          }
                                        >
                                          Edit
                                        </Button>
                                      )}
                                      {!isRowEditing && (
                                        <IconButton
                                          onClick={() =>
                                            handleOpenModal(item.id)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              {!addingRows[location] && (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    marginTop: '10px',
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      handleAddProduct(location);
                                    }}
                                  >
                                    Add Product
                                  </Button>
                                </Box>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <Typography variant="body2">
                          <LinearProgress />
                        </Typography>
                      )}
                      {/* Add Product Row */}
                      {addingRows[location] && (
                        <TableRow sx={{ width: '100%' }}>
                          <TableCell align="left">
                            <TextField
                              value={addingRows[location].name}
                              onChange={(e) =>
                                setAddingRows((prev) => ({
                                  ...prev,
                                  [location]: {
                                    ...prev[location],
                                    name: e.target.value,
                                  },
                                }))
                              }
                              placeholder="Enter Name"
                              variant="outlined"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextField
                              value={addingRows[location].sku}
                              onChange={(e) =>
                                setAddingRows((prev) => ({
                                  ...prev,
                                  [location]: {
                                    ...prev[location],
                                    sku: e.target.value,
                                  },
                                }))
                              }
                              placeholder="Enter SKU"
                              variant="outlined"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              value={addingRows[location].quantity}
                              onChange={(e) =>
                                setAddingRows((prev) => ({
                                  ...prev,
                                  [location]: {
                                    ...prev[location],
                                    quantity: e.target.value,
                                  },
                                }))
                              }
                              type="number"
                              placeholder="Enter Quantity"
                              variant="outlined"
                              size="small"
                              sx={{ width: '100%' }}
                            />
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ display: 'flex', gap: '10px', width: '100%' }}
                          >
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => handleCancelAddProduct(location)}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={() => handleSaveNewProduct(location)}
                            >
                              Save
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* Add Product Button */}
                    </div>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}

          {/* Confirmation Modal */}
          <Dialog open={open} onClose={handleCloseModal}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this item? This action cannot be
                undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {!inventoryLocations.length && (
            <TableRow>
              <TableCell align="center" colSpan={headCells.length}>
                <Typography>No locations found</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RenderLocationList;
