import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  fieldLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
  },
  fieldError: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#f44336',
    marginTop: '5px',
  },
  nameRow: {
    display: 'flex',
  },
  notesRow: {
    display: 'flex',
    marginTop: '10px',
    marginBottom: '20px',
  },
  rowHalfItem: {
    width: '100%',
  },
  rowMiddleItem: {
    width: '40px',
    textAlign: 'center',
  },
  text1: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  text2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '50px',
    lineHeight: '55px',
    textTransform: 'uppercase',
  },
  text3: {
    fontSize: '22px',
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  previewBox: {
    border: '1px solid #ced4da',
    padding: '10px',
    '& svg': {
      color: 'inherit',
      fill: 'inherit',
    },
  },
  previewBoxWrap: {
    padding: '0 0 20px',
  },
  colorButton: {
    width: '20px',
    textAlign: 'center',
    padding: '0',
  },
  colorListBox: {
    padding: '15px 10px',
    display: 'flex',
  },
  colorListBoxItem: {
    padding: '0 5px',
  },
  typeBox: {
    color: '#8d9195',
    textAlign: 'center',
    padding: '15px 10px',
    border: '1px solid #ced4da',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  typeActiveBox: {
    color: '#000000',
    textAlign: 'center',
    padding: '15px 10px',
    border: '1px solid #000000',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  typeLabel: {
    color: 'inherit',
    textTransform: 'uppercase',
  },
  typeIcon: {
    height: '35px',
  },
  inputBase: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.04)',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 10px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

export default useStyles;
