import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  Box,
  Button,
  Typography,
} from '@mui/material';

const BUTTON_OPTIONS = {
  PARENT: 'parent',
  CHILD: 'child',
};

const VariantsModal = ({
  openVariantsModal,
  handleCloseModal,
  renderVariants,
  product,
  colors,
  renderVariantsChild,
  renderSingleSkuModal,
}) => {
  // State to track which button is selected, 'parent' or 'child'
  const [selectedButton, setSelectedButton] = useState(BUTTON_OPTIONS.PARENT);

  const [
    openHandleAddSingleIndenticalModal,
    setOpenHandleAddSingleIndenticalModal,
  ] = useState(false);

  const showChildAndParentSkus = () =>
    product.pathLeft !== '' || product.pathRight !== '';

  return (
    <Dialog
      open={openVariantsModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      PaperProps={{
        style: {
          width: '50vw',
          height: '50vh',
          borderRadius: '20px',
          overflow: 'hidden',
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
    >
      {!openHandleAddSingleIndenticalModal ? (
        <>
          <Box sx={styles.container}>
            <DialogTitle id="modal-title">Variants</DialogTitle>
            {!showChildAndParentSkus() && (
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: colors,
                  color: 'white',
                  borderRadius: '50px',
                  padding: 1,
                  color: 'black',
                  marginRight: 2,
                }}
                onClick={() => {
                  setOpenHandleAddSingleIndenticalModal(true);
                }}
              >
                <Typography sx={{ fontWeight: '500' }}>
                  Add Single SKU
                </Typography>
              </Button>
            )}

            {showChildAndParentSkus() && (
              <Box sx={styles.parentChildButtonsContainer}>
                <Button
                  variant="contained"
                  sx={{
                    ...styles.button,
                    backgroundColor:
                      selectedButton === BUTTON_OPTIONS.PARENT
                        ? colors.mediumBlue
                        : colors.lightGrey,
                    color:
                      selectedButton === BUTTON_OPTIONS.PARENT
                        ? 'white'
                        : 'black',
                  }}
                  onClick={() => {
                    setSelectedButton(BUTTON_OPTIONS.PARENT);
                  }}
                >
                  <Typography sx={{ fontWeight: '500' }}>Parent</Typography>
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    ...styles.button,
                    backgroundColor:
                      selectedButton === BUTTON_OPTIONS.PARENT
                        ? colors.lightGrey
                        : colors.mediumBlue,
                    color:
                      selectedButton === BUTTON_OPTIONS.PARENT
                        ? 'black'
                        : 'white',
                  }}
                  onClick={() => {
                    setSelectedButton(BUTTON_OPTIONS.CHILD);
                  }}
                >
                  <Typography sx={{ fontWeight: '500' }}>Child</Typography>
                </Button>
              </Box>
            )}
          </Box>
          <DialogContent>
            <Box sx={styles.renderVariantsBox}>
              {selectedButton === BUTTON_OPTIONS.PARENT
                ? renderVariants()
                : renderVariantsChild()}
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <Box sx={styles.container}>
            <DialogTitle id="modal-title">Single SKU</DialogTitle>
          </Box>
          <DialogContent>
            <Box sx={styles.renderVariantsBox}>{renderSingleSkuModal()}</Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

const styles = {
  container: {
    paddingTop: '2%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Add this line
  },
  parentChildButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '33%',
  },
  button: {
    height: '50px',
    padding: '0 20px',
    borderRadius: '50px',
    color: 'white',
  },
  renderVariantsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
};

export default VariantsModal;
