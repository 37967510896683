import {
  filterTypes,
  filterProducts,
  filterProductionTypes,
} from '../../components/ui/filterConstants';
import { searchDeep } from '../../utils/searchByKeyWord';

export const goThroughFilters = (updatedFilters, shippingData) => ({
  products: filterProducts.find((data) => data.id === updatedFilters.products),
  types: filterTypes.find((data) => data.id === updatedFilters.types),
  production: filterProductionTypes.find(
    (data) => data.id === updatedFilters.production,
  ),
  shippings: shippingData.find((data) => data.id === updatedFilters.shippings),
});

export const updateAllFilters = (
  updatedFilters,
  fullFilteredData,
  filterData,
) => {
  let filteredData = fullFilteredData;

  filteredData = filterByTypeOfProduct(
    filteredData,
    updatedFilters.types,
    filterData,
  );

  filteredData = filterByProdutionType(
    filteredData,
    updatedFilters.production,
    filterData,
  );

  filteredData = filtrByShippingStatus(
    filteredData,
    updatedFilters.shippings,
    filterData,
  );

  filteredData = filterByItemCount(
    filteredData,
    updatedFilters.products,
    filterData,
  );

  return filteredData;
};

export const filterByTypeOfProduct = (filteredData, updatedFilters) => {
  const { id } = updatedFilters;

  if (id === 1) {
    return filteredData;
  }

  const ordersMatched = filteredData.filter((order) => {
    const items = order.items || [];
    const hasSocks = items.some((item) => item.type === 'Socks');
    const hasUnderwear = items.some((item) => item.type === 'Underwear');
    const onlyUnderwear = items.every((item) => item.type === 'Underwear');
    const onlySocks = items.every((item) => item.type === 'Socks');
    const mixed = items.some((item) => item.type === 'Mixed');

    // Check if there is any underwear that is not 'Graphic'
    const hasNonGraphicUnderwear = items.some(
      (item) => item.type === 'Underwear' && item.style !== 'Graphic',
    );

    if (id === 2) {
      return hasSocks;
    } else if (id === 3) {
      if (hasNonGraphicUnderwear && hasSocks) {
        return false;
      }
      return hasUnderwear;
    } else if (id === 4) {
      return onlyUnderwear;
    } else if (id === 5) {
      return onlySocks;
    } else if (id === 6) {
      return mixed;
    }
  });

  return ordersMatched;
};

export const filterByProdutionType = (filteredData, updatedFilters) => {
  // Assume filterProductionTypes is imported or defined elsewhere

  const { val } = updatedFilters;

  const valueLowered = val.toLowerCase();

  if (valueLowered === '') {
    return filteredData;
  }

  const ordersMatched = filteredData.filter((order) => {
    const items = order.items || [];

    if (valueLowered === 'finished') {
      // Ensure no items have 'graphic' or empty style when typeToMatch is 'finished'
      return !items.some((item) => {
        const style = item.style?.toLowerCase();
        return style === 'graphic' || style === '' || style === null || !style;
      });
    } else {
      // For other types, maintain previous logic or add different conditions as necessary
      return items.some((item) => {
        const style = item.style?.toLowerCase();
        return style === valueLowered;
      });
    }
  });

  return ordersMatched;
};

export const filtrByShippingStatus = (filteredData, updatedFilters) => {
  const { id, name } = updatedFilters;

  if (id === 1) {
    return filteredData;
  }

  const ordersMatched = filteredData.filter((order) => {
    const show = order.shipping ?? '';
    return show === name;
  });

  return ordersMatched;
};

export const filterByItemCount = (filteredData, updatedFilters) => {
  const { val } = updatedFilters;

  filteredData = filteredData.filter((order) => {
    const productCount = order?.products || 0;

    if (val === 0) {
      // 'All'
      return true;
    } else if (val === 4) {
      // 'Reprint'
      return;
    } else if (val === 3) {
      // '3+ Products'
      return productCount >= 3;
    } else if (val === 2) {
      // 'Two Products'
      return productCount === 2;
    } else if (val === 1) {
      // 'One Product'
      return productCount === 1;
    }
  });

  return filteredData;
};

export const calculatePages = (filteredData, rowsPerPage) => {
  const totalRows = filteredData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  return totalPages;
};
