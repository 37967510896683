import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-lazy-load-image-component/src/effects/blur.css';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ref, getDownloadURL } from 'firebase/storage';

import {
  PRODUCT_ART_PREFIX,
  PRODUCT_THUMB_PREFIX,
} from '../../constants/defines';
import {
  getProductBySku,
  validateProduct,
  validateCustomProduct,
} from '../../db/products';
import {
  getMatchedCropSettingByFront,
  getMatchedCropSettingByBack,
} from '../../db/cropSettings';
import { withAuthorization } from '../Session';
import { formatBytes, cropImg } from '../../module/functions';
import blankImg from '../../images/placeholder.png';
import { lowerImageResolution } from '../../utils/lowerImageRes';

const useStyles = makeStyles((theme) => ({
  itemBox: {
    border: '1px solid #CDD4DB',
    background: '#F4F4F4',
    borderRadius: '3px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '60vh',
  },
  textSKU: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  textName: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1,
    marginBottom: '10px',
  },
  textBold: {
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: 1,
  },
  textSmall: {
    fontWeight: 400,
    fontSize: '14px',
  },
  specBox: {
    padding: '8px 20px',
  },
  titleBox: {
    padding: '5px 20px 5px',
    minHeight: '90px',
  },
  customBox: {
    minHeight: '22px',
    padding: '7px 20px',
  },
  fullImg: {
    width: '100%',
    height: 'auto',
  },
  hyperIdentical: {
    width: 'auto',
    height: 'auto',
  },
  soloImg: {
    width: '50%',
    height: 'auto',
  },
  sizeText: {
    fontSize: '18px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center',
  },
  artBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '120px',
  },
  overlayBox: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '120px',
    marginTop: '6px',
  },
  overlayBoxIdenticalHo: {
    minHeight: '340px',
    minWidth: '150px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  soloImg: {
    display: 'inline-block',
    width: '50%',
  },
}));

function ArtImage(props) {
  const {
    firebase,
    path,
    classes,
    isMask,
    isSolo,
    isFront,
    size,
    isNewProduct,
    isHypeOptic,
    type = null,
  } = props;
  const [imagePath, setImagePath] = useState('');
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (path === '') {
      setLoad(false);
    } else {
      const storage = firebase.getstorage();
      const prefix = isNewProduct ? PRODUCT_ART_PREFIX : PRODUCT_THUMB_PREFIX;

      const pathForImage = isHypeOptic
        ? `${prefix}thumb_${path}`
        : `${prefix}${path}`;

      getDownloadURL(ref(storage, pathForImage))
        .then((url) => {
          setLoad(true);
          setImagePath(url);
          setLoad(false);
        })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        });
    }
  }, [props.path]);

  const renderLoading = () => (
    <Box className={classes.overlayLoading}>
      <CircularProgress />
    </Box>
  );

  const renderSoloArts = () => {
    if (!imagePath) {
      return (
        <LazyLoadImage
          alt={path}
          effect="blur"
          src={blankImg}
          className={classes.fullImg}
        />
      );
    }
    return (
      <Box>
        <Box className={classes.soloImg}>
          <LazyLoadImage
            alt={path}
            effect="blur"
            src={imagePath}
            className={classes.fullImg}
          />
        </Box>
        <Box className={classes.soloImg}>
          <LazyLoadImage
            alt={path}
            effect="blur"
            src={imagePath}
            className={classes.fullImg}
          />
        </Box>
      </Box>
    );
  };

  const sizeMB = formatBytes(size, 1);
  let frontSizeColor;
  const frontSize = sizeMB.value;
  const frontUnit = sizeMB.unit;

  if (frontSize >= 15.0 && frontSize < 25.0 && frontUnit === 'MB') {
    frontSizeColor = 'red';
  } else if (frontSize >= 25.0 && frontUnit === 'MB') {
    frontSizeColor = 'red';
  } else {
    frontSizeColor = 'rgb(20, 183, 121)';
  }

  return (
    <Box
      className={
        isNewProduct ? classes.artBox : isHypeOptic ? classes.artBox : ''
      }
    >
      {!isNewProduct && !isHypeOptic && (
        <Typography className={classes.sizeText} sx={{ color: frontSizeColor }}>
          {frontSize > 0 ? `${frontSize} ${frontUnit}` : '-'}
        </Typography>
      )}

      {isSolo ? (
        <Box className={classes.overlayBox}>
          {load ? renderLoading() : renderSoloArts()}
        </Box>
      ) : (
        <Box
          className={
            !isHypeOptic ? classes.overlayBox : classes.overlayBoxIdenticalHo
          }
        >
          {load ? (
            renderLoading()
          ) : (
            <LazyLoadImage
              alt={path}
              effect="blur"
              src={imagePath ? imagePath : blankImg}
              className={
                !isHypeOptic ? classes.fullImg : classes.hyperIdentical
              }
              style={{
                width: isNewProduct ? '18vw' : undefined, // Only override width for new products. These are needed to fit the new product images
                height: isNewProduct ? 'auto' : undefined, // Adjust height accordingly
              }}
              on
            />
          )}
        </Box>
      )}
    </Box>
  );
}

// primary row component
function ProductGridItem(props) {
  const { data, db, templates, settings, handleClick, storage } = props;
  const classes = useStyles();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!!data.sku) {
      getProductBySku(db, data.sku, fetchData);
    }
  }, [data.sku]);

  const fetchData = async (_product) => {
    const ret = _product.isCustomProduct
      ? validateCustomProduct(_product, templates)
      : await validateProduct(_product, settings, storage, false);

    const isIdentical =
      _product.style === 'HyperOptic' &&
      _product.pathHyperOpticIdentical !== '';

    setProduct({ ..._product, valid: ret.valid, isIdentical: isIdentical });

    setLoading(false);
  };

  const renderArtImage = ({
    path,
    isFront,
    size,
    isNewProduct,
    isHypeOptic,
    type = null,
  }) => {
    if (!path) {
      return null;
    } // Do not render anything if the path is not available

    const width = isFront !== undefined ? '50%' : '100%'; // Adjust width based on whether it's a front/back image or another type
    const padding = '0 10px';

    const paddingTop = '10%';

    return (
      <Box sx={{ padding, width, paddingTop }}>
        <ArtImage
          key={`art_${product.sku}_${isFront ? 'front' : 'back'}`}
          isFront={isFront}
          firebase={props.firebase.getFirebase()}
          cropSettings={settings}
          classes={classes}
          isMask={product.isMask}
          isSolo={type === 'T-Shirt' ? false : product.identical}
          size={size}
          path={path}
          isNewProduct={isNewProduct}
          isHypeOptic={isHypeOptic}
          type={type}
        />
      </Box>
    );
  };

  const handleClickItem = (event) => {
    if (handleClick) {
      handleClick(event, data.sku);
    }
    console.log('handle Click');
  };

  const getSpecColor = () => {
    if (product.isBundle) {
      return '#AEDBC7';
    } else if (!product.valid) {
      return '#FF8F92';
    }
    return '#AEDBC7';
  };

  let bgColor = '#AEDBC7',
    strTemplate = '',
    strCustom = '';
  if (!loading) {
    bgColor = getSpecColor();
    if (product.templates.length > 0) {
      strTemplate = _.find(templates, {
        id: product.templates[0].template,
      }).name;
    }
    if (product.isCustomProduct) {
      strCustom = 'Custom';
    }
  }

  return (
    <Box className={classes.itemBox} onClick={handleClickItem}>
      {!loading && (
        <Box display="flex" justifyContent="space-around">
          {renderArtImage({
            path: product.pathFront,
            isFront: true,
            size: product.frontArt.size,
          })}
          {renderArtImage({
            path: product.pathBack,
            isFront: false,
            size: product.backArt.size,
          })}
          {renderArtImage({
            path: product.isBoxedImagePath,
            isFront: false,
            size: product.isBoxedImageArt.size,
            isNewProduct: true,
            type: product.type,
          })}
          {renderArtImage({
            path: product.pathRight,
            isFront: false,
            size: product.rightArt?.size,
            isHypeOptic: true,
          })}
          {renderArtImage({
            path: product.pathLeft,
            isFront: false,
            size: product.leftArt?.size,
            isHypeOptic: true,
          })}
          {renderArtImage({
            path: product.pathHyperOpticIdentical,
            isFront: false,
            size: product.hyperOpticIdenticalArt?.size,
            isHypeOptic: true,
          })}
        </Box>
      )}
      <Box className={classes.titleBox}>
        <Typography className={classes.textName}>{data.name}</Typography>
        <Typography className={classes.textSKU}>{data.sku}</Typography>
      </Box>

      <Box
        sx={{
          background: `${bgColor}`,
          display: 'flex',
          padding: '10px 0px',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Typography className={classes.textBold}>{data.type}</Typography>
        <Typography className={classes.textBold}>{data.style}</Typography>
        <Typography className={classes.textBold}>{data.format}</Typography>
        <Typography className={classes.textBold}>{data.size}</Typography>
      </Box>
      <Box
        className={classes.customBox}
        display="flex"
        justifyContent="space-between"
      >
        <Typography className={classes.textSmall}>{strTemplate}</Typography>
        <Typography className={classes.textSmall}>{strCustom}</Typography>
      </Box>
    </Box>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(ProductGridItem);
