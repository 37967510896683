import React from 'react';
import { Box, Typography, Select, MenuItem, FormControl } from '@mui/material';

const MainDropdown = ({ isMobile, mainDropDownProduct }) => {
  const [selectedValue, setSelectedValue] = React.useState(mainDropDownProduct);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Box
      sx={{
        ...styles.container,
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
      }}
    >
      <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
        To be Produced Today (DTC)
      </Typography>
      <FormControl
        variant="outlined"
        sx={{
          ...styles.formControl,
          width: isMobile ? '100%' : '200px',
          marginTop: isMobile ? '10px' : '0',
          borderRadius: isMobile ? '10px' : '5px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '& .MuiSelect-select': {
            padding: '8px 14px', // Adjust padding inside the select element
          },
        }}
      >
        <Select
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Select DTC' }}
        >
          <MenuItem value="DTC">DTC</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px',
  },
  formControl: {
    backgroundColor: '#ffffff',
  },
};

export default MainDropdown;
