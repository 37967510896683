import _ from 'lodash';
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  limit,
  writeBatch,
} from 'firebase/firestore';

import { collections, API } from '../constants/defines';
import { getFormattedDate } from '../utils/getTodaysDate';
import { getOldestOrderDate } from './helpers';

const getCountsOfItemsManual = async (items, formats, sizes, db) => {
  const counts = {};
  const checkers = {
    hasSocks: false,
    hasBoxers: false,
    hasCustoms: false, // This will be set to true if a custom product is found
  };
  // Process orders asynchronously using map
  const processItems = items.map(async (item) => {
    // Check the style of the item, skip it if it's "Graphic" (case insensitive) or if the style is not empty and it's not a box set
    if (item.style?.toLowerCase() !== 'graphic' && !item.boxset) {
      return; // Skip this item
    }

    // If it's a box set, calculate the total quantity by multiplying the number of items in the box set
    let totalQuantity = item.quantity;

    if (item.boxset) {
      item.format = item?.format || null;
      item.size = item?.size || null;
    }

    if (item.boxset && item.items?.length) {
      totalQuantity *= item.items.length; // Multiply by the number of items in the box set
    }

    // **Check for custom products**: If `isCustomProduct` is true, set `hasCustoms` flag to true
    if (item.isCustomProduct) {
      checkers.hasCustoms = true;
    }

    // **First check**: Use the `type` property to detect socks or boxers
    if (item.type?.toLowerCase() === 'socks') {
      checkers.hasSocks = true;
    } else if (item.type?.toLowerCase() === 'underwear') {
      checkers.hasBoxers = true;
    } else {
      // **Fallback check**: If `type` is missing, use the format
      const format = formats.find((f) => f.id === item.format);
      if (format) {
        if (format.type.toLowerCase() === 'socks') {
          checkers.hasSocks = true;
        } else if (format.type.toLowerCase() === 'underwear') {
          checkers.hasBoxers = true;
        }
      }
    }

    // Find the format name based on the format ID
    const format = formats.find((f) => f.id === item.format);

    // Find the size name based on the size ID and convert it to a string explicitly
    const size = sizes.find((s) => s.id === item.size)?.name || 'Unknown Size';

    if (format && size) {
      // Create a category for the format type if it doesn't exist
      if (!counts[format.name]) {
        counts[format.name] = {};
      }

      // Ensure the size is always treated as a string
      const sizeKey = String(size);

      // Create an entry for the size if it doesn't exist
      if (!counts[format.name][sizeKey]) {
        counts[format.name][sizeKey] = 0;
      }

      // Increment the count for the size under the format
      counts[format.name][sizeKey] += totalQuantity;
    }
  });

  // Wait for all items to be processed
  await Promise.all(processItems);

  // Log the checkers object for debugging
  console.log('Product type checkers:', checkers);
  console.log('Final counts:', counts);

  return { counts, checkers };
};

const createManualBatch = async (
  db,
  batchName,
  secondaryBatchName,
  color,
  sheetType,
  underwearFormat,
  barcode,
  items,
  callback,
  formats,
  sizes,
  notes,
  sockFormat,
  sockSheetType,
  underwearSheetType,
) => {
  const printList = {};
  const currentUser = localStorage.getItem('currentuser');
  const batchId = Date.now().toString();
  const itemsForCOunts = _.map(items, (item) => {
    let error = '';
    if (!item.exists) {
      error = 'Product Not Found';
    } else if (!!item.errors && item.errors.length > 0) {
      error = item.errors[0];
    }
    return { ...item, sku: item.sku, quantity: item.qty, error, itemId: '' };
  });
  // process product not found first and then process the rest
  const countsOfItems = await getCountsOfItemsManual(
    itemsForCOunts,
    formats,
    sizes,
    db,
  );

  const _items = _.map(items, (item) => {
    let error = '';
    if (!item.exists) {
      error = 'Product Not Found';
    } else if (!!item.errors && item.errors.length > 0) {
      error = item.errors[0];
    }
    return { sku: item.sku, quantity: item.qty, error, itemId: '' };
  });

  const data = {
    lineItems: _items,
    date: new Date(),
    sheetType,
    source: 'Manual',
    baseName: batchName,
    name: batchName,
    secondary: secondaryBatchName,
    underwearFormat,
    sockFormat,
    color,
    underwearSheetType,
    sockSheetType,
    reference: '1234567890JG',
    printed: false,
    email: currentUser,
    bucket: '',
    batch: batchId,
    barcode,
    priority: 1,
    totalsForPrinting: countsOfItems.counts,
    productTypeCheckers: countsOfItems.checkers,
    oldestOrderDate: '',
    notes: notes,
  };

  // if a sku is missing, alert user and return
  if (_.some(data.lineItems, (item) => !item.sku)) {
    alert(
      'One or more SKUs are missing. Please check your input and try again.',
    );
    return false;
  }

  addDoc(collection(db, collections.ORDERS), data)
    .then((docRef) => {
      callback(true, batchId, batchName);
    })
    .catch((error) => {
      callback(false, batchId, batchName);
    });

  return true;
};

const lookForSku = async (db, sku) => {
  try {
    // Query 1: Find document where SKU equals the given SKU
    const skuQuery = query(
      collection(db, collections.BOXSETS), // Use the correct collection reference
      where('sku', '==', sku),
      limit(1), // Limit to 1 result to stop early
    );

    // Query 2: Find document where the variants array contains the given SKU
    const variantsQuery = query(
      collection(db, collections.BOXSETS), // Use the correct collection reference
      where('variants', 'array-contains', sku),
      limit(1), // Limit to 1 result to stop early
    );

    // Execute both queries in parallel using getDocs
    const [skuSnapshot, variantsSnapshot] = await Promise.all([
      getDocs(skuQuery),
      getDocs(variantsQuery),
    ]);

    // Return the first document found in the `sku` query, if any
    if (!skuSnapshot.empty) {
      const doc = skuSnapshot.docs[0];
      return { id: doc.id, ...doc.data() };
    }

    // If no document was found in the `sku` query, check the `variants` query
    if (!variantsSnapshot.empty) {
      const doc = variantsSnapshot.docs[0];
      const docData = { id: doc.id, ...doc.data() };
      console.log('docData: ', docData);
      return docData;
    }

    // If no document was found in either query, return null or an empty object
    return null;
  } catch (error) {
    console.error('Error finding SKU or variants:', error);
    throw new Error('Failed to retrieve SKU or variants from Firestore');
  }
};

// Helper function to process mixed BoxSet items based on SKU suffix
const processMixedBoxSet = async (item, counts, checkers, sizes) => {
  let socksCount = 0;
  let boxersCount = 0;

  // Define suffixes for socks and underwear
  const sockSuffixes = ['LXL', 'SM', 'YTHL', 'TDLR24', 'TDLR12'];
  const underwearSuffixes = ['L', 'S', 'M', 'XL', '2XL', '3XL'];

  await Promise.all(
    item.data.items.map(async (subItemSku) => {
      // Extract size suffix from SKU
      const sizeSuffix = subItemSku.split('-').pop();

      // Determine if the SKU belongs to socks or underwear based on the suffix
      const isSock = sockSuffixes.some((suffix) =>
        subItemSku.endsWith(`-${suffix}`),
      );
      const isUnderwear = underwearSuffixes.some((suffix) =>
        subItemSku.endsWith(`-${suffix}`),
      );

      // Find the size based on SKU suffix in the sizes array
      const size =
        sizes.find((s) => s.sku === sizeSuffix)?.name || 'Unknown Size';

      if (isSock) {
        socksCount++;
        checkers.hasSocks = true;

        // Increment the counts for socks by size
        if (!counts['CREW SOCKS']) {
          counts['CREW SOCKS'] = {};
        }
        counts['CREW SOCKS'][size] =
          (counts['CREW SOCKS'][size] || 0) + item.quantity;
      } else if (isUnderwear) {
        boxersCount++;
        checkers.hasBoxers = true;

        // Increment the counts for underwear by size
        if (!counts["Men's Boxer Briefs"]) {
          counts["Men's Boxer Briefs"] = {};
        }
        counts["Men's Boxer Briefs"][size] =
          (counts["Men's Boxer Briefs"][size] || 0) + item.quantity;
      }
    }),
  );

  // Log counts for debugging
  console.log(
    `BoxSet "${item.sku}" has ${socksCount} socks and ${boxersCount} Men's Boxer Briefs.`,
  );
};

const getCountsOfItems = async (_orders, formats, sizes, db) => {
  const orders = _orders.reduce((acc, currentOrder) => {
    const orderExists = acc.find(
      (order) => order.orderNumber === currentOrder.orderNumber,
    );
    if (!orderExists) {
      acc.push(currentOrder);
    }
    return acc;
  }, []);

  const counts = {};
  const checkers = {
    hasSocks: false,
    hasBoxers: false,
    hasCustoms: false, // This will be set to true if a custom product is found
  };

  // Process orders asynchronously using map
  const processOrders = orders.map(async (order) => {
    const processItems = order.items.map(async (item) => {
      // Check the style of the item, skip it if it's "Graphic" (case insensitive) or if the style is not empty and it's not a box set
      if (item.style?.toLowerCase() !== 'graphic' && !item.isBoxSet) {
        return; // Skip this item
      }

      // Handle Mixed BoxSets separately
      if (item.isBoxSet && item?.data?.type === 'Mixed') {
        if (!item.data) {
          console.log('Looking for SKU:', item.sku);
          item.data = await lookForSku(db, item.sku);
        }
        await processMixedBoxSet(item, counts, checkers, sizes);
        return;
      }

      // If it's a box set, calculate the total quantity by multiplying the number of items in the box set
      let totalQuantity = item.quantity;

      if (item.isBoxSet && !item.data) {
        console.log('looking for sku:', item.sku);
        item.data = await lookForSku(db, item.sku);
      }

      if (item.isBoxSet && item?.data) {
        item.format = item?.data?.format || null;
        item.size = item?.data?.size || null;
      }

      if (item.isBoxSet && item.data?.items?.length) {
        totalQuantity *= item.data.items.length; // Multiply by the number of items in the box set
      }

      // **Check for custom products**: If `isCustomProduct` is true, set `hasCustoms` flag to true
      if (item.isCustomProduct) {
        checkers.hasCustoms = true;
      }

      // Primary check: Use `item.type` to detect both socks and underwear independently
      if (item.type?.toLowerCase().includes('sock')) {
        checkers.hasSocks = true;
      }
      if (item.type?.toLowerCase().includes('underwear')) {
        checkers.hasBoxers = true;
      }

      // Fallback: Use `format` if neither socks nor underwear were found in `item.type`
      if (!checkers.hasSocks && !checkers.hasBoxers) {
        const format = formats.find((f) => f.id === item.format);
        if (format) {
          if (format.type.toLowerCase().includes('sock')) {
            checkers.hasSocks = true;
          }
          if (format.type.toLowerCase().includes('underwear')) {
            checkers.hasBoxers = true;
          }
        } else {
          console.log('No valid type detected');
        }
      }

      // Find the format name based on the format ID
      const format = formats.find((f) => f.id === item.format);

      // Find the size name based on the size ID and convert it to a string explicitly
      const size =
        sizes.find((s) => s.id === item.size)?.name || 'Unknown Size';

      if (format && size) {
        // Create a category for the format type if it doesn't exist
        if (!counts[format.name]) {
          counts[format.name] = {};
        }

        // Ensure the size is always treated as a string
        const sizeKey = String(size);

        // Create an entry for the size if it doesn't exist
        if (!counts[format.name][sizeKey]) {
          counts[format.name][sizeKey] = 0;
        }

        // Increment the count for the size under the format
        counts[format.name][sizeKey] += totalQuantity;
      }
    });

    // Wait for all items to be processed
    await Promise.all(processItems);
  });

  // Wait for all orders to be processed
  await Promise.all(processOrders);

  return { counts, checkers };
};

const createInlineBatch = async (
  db,
  batchName,
  secondaryBatchName,
  color,
  sheetType,
  underwearFormat,
  barcode,
  orders,
  callback,
  isDropShip = false,
  formats,
  sizes,
  notes,
  sockFormat,
  sockSheetType,
  underwearSheetType,
) => {
  const currentUser = localStorage.getItem('currentuser');
  const batchId = Date.now().toString();

  const inlinePrintBatch = {
    batch: batchId,
    date: new Date(),
    sheetType,
    source: 'Order',
    name: batchName,
    baseName: batchName,
    secondary: secondaryBatchName,
    underwearFormat,
    color,
    email: currentUser,
    lineItems: [],
    printed: false,
    bucket: '',
    priority: 2,
    barcode,
    reference: '1234567890JG',
    isDropShip,
    notes,
    sockFormat,
    sockSheetType,
    underwearSheetType,
  };

  const processedItems = new Set(); // To track processed itemIds

  const countsOfItems = await getCountsOfItems(orders, formats, sizes, db);

  inlinePrintBatch.totalsForPrinting = countsOfItems.counts;
  inlinePrintBatch.productTypeCheckers = countsOfItems.checkers;
  inlinePrintBatch.oldestOrderDate = getOldestOrderDate(orders);

  orders.forEach((orderItem) => {
    const { orderNumber } = orderItem;

    orderItem.items.forEach((lineItem) => {
      const { id, sku, quantity, error } = lineItem;

      const uniqueItemId = `${orderNumber}-${id}`;

      // Only push the item if it hasn't been processed already
      if (!processedItems.has(uniqueItemId)) {
        const randomId = Math.floor(Math.random() * 1000000); // Generate a random ID

        const pushableItem = {
          orderNumber,
          sku,
          quantity,
          error,
          itemId: `${orderNumber}-${id}-${randomId}`,
        };

        if (lineItem.isMixAndMatch) {
          pushableItem.isMixAndMatch = true;
          pushableItem.properties = lineItem.properties;
        }

        inlinePrintBatch.lineItems.push(pushableItem);
        processedItems.add(uniqueItemId); // Mark the item as processed
      }
    });
  });

  console.log('inlinePrintBatch:', inlinePrintBatch);

  addDoc(collection(db, collections.ORDERS), inlinePrintBatch)
    .then((docRef) => {
      if (isDropShip) {
        markDropShipOrderAsPrintied(
          inlinePrintBatch,
          batchId,
          batchName,
          callback,
        );
        return;
      }
      markOrdersAsPrinted(inlinePrintBatch, batchId, batchName, callback, db);
    })
    .catch((error) => {
      callback(false, batchId, batchName);
    });
};

const markOrdersAsPrinted = async (
  printBatch,
  batchId,
  batchName,
  callback,
  db,
) => {
  const orderIds = Array.from(
    new Set(printBatch.lineItems.map((item) => item.orderNumber.toString())),
  );
  const email = printBatch.email || '';

  try {
    const batch = writeBatch(db);

    for (const orderId of orderIds) {
      // Get a reference to each order document in the INLINE_PRODUCTS_QUEUE collection
      const orderRef = doc(db, collections.INLINE_PRODUCTS_QUEUE, orderId);

      // Add the update to the batch
      batch.set(
        orderRef,
        {
          isPrinting: true,
          printedBy: email,
          printedOn: new Date(),
        },
        { merge: true },
      );
    }

    // Commit the batch
    await batch.commit();
    console.log('[markOrdersAsPrinted] Successfully marked orders as printed');

    // Call the callback with success
    callback(true, batchId, batchName);
  } catch (error) {
    console.error(
      '[markOrdersAsPrinted] Error marking orders as printed:',
      error,
    );

    // Call the callback with failure
    callback(false, batchId, batchName);
  }
};

const markDropShipOrderAsPrintied = (
  printBatch,
  batchId,
  batchName,
  callback,
) => {
  const orderIds = _.map(printBatch.lineItems, (item) =>
    item.orderNumber.toString(),
  );

  const params = {
    orderIds: _.uniq(orderIds),
    email: !printBatch.email ? '' : printBatch.email,
  };
  fetch(API.printDsInline, {
    method: 'post',
    body: JSON.stringify(params),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      console.log('Response:', data);
      callback(data.success, batchId, batchName);
    });
};

const restoreInlineOrders = (db, orders) => {
  const batch = writeBatch(db);
  // Add deletes to batch
  orders.forEach((order) => {
    const docRef = doc(db, collections.INLINE_PRODUCTS_QUEUE, order.toString());
    batch.set(
      docRef,
      { isPrinting: false, restoreDate: new Date() },
      { merge: true },
    );
  });

  batch
    .commit()
    .then(function () {
      console.log('restoreInlineOrders Successful');
    })
    .catch(function (error) {
      console.error(`Error comitting batch: ${error.message}`);
    });
};

const restoreInlineOrderItems = (db, items) => {
  const batch = writeBatch(db);
  const requests = _.map(items, (item) =>
    getDoc(doc(db, collections.INLINE_PRODUCTS_QUEUE, item.order.toString())),
  );

  Promise.all(requests).then(function (responses) {
    // Scrape resonses for errors
    responses.forEach(function (response, index) {
      if (response.exists) {
        const data = response.data();
        const _order = items[index];
        const orderItems = _.map(data.items, (_item) => {
          const skuItem = _.find(_order.skus, (sku) => sku === _item.sku);
          const available = !!skuItem;

          return { ..._item, available };
        });

        const ref = doc(
          db,
          collections.INLINE_PRODUCTS_QUEUE,
          _order.order.toString(),
        );
        batch.set(
          ref,
          { isPrinting: false, items: orderItems, restoreDate: new Date() },
          { merge: true },
        );
      }
    });

    batch
      .commit()
      .then(function () {
        console.log('restoreInlineOrderItems Successful');
      })
      .catch(function (error) {
        console.error(`Error comitting batch: ${error.message}`);
      });
  });
};

const checkExistingInlineOrder = (db, name) =>
  new Promise((resolve, reject) => {
    const q = query(
      collection(db, collections.ORDERS),
      where('baseName', '==', name),
    );

    return getDocs(q)
      .then((querySnapshot) => {
        resolve(querySnapshot.docs.length > 0);
      })
      .catch((error) => {
        resolve(false);
      });
  });

const addRestoreInlineOrders = (db, batchId, type, items) =>
  new Promise((resolve, reject) => {
    const item = {
      historyBatch: batchId,
      date: new Date(),
      type,
      items,
    };

    addDoc(collection(db, collections.RESTORE), item)
      .then((docRef) => {
        resolve();
      })
      .catch((error) => {
        resolve();
      });
  });

// Function to save daily analytic units produced
const saveDailyAnalyticUnitsProduced = (db, producing, errorData) =>
  new Promise((resolve, reject) => {
    // Get today's date in the required format
    const date = getFormattedDate();

    // Define the document reference correctly
    const dailyAnalyticsDocRef = doc(
      db,
      collections.DAILY_ANALYTICS,
      collections.DAILY_PRODUCED_AND_TO_BE,
    );

    const datesCollectionRef = collection(
      dailyAnalyticsDocRef,
      collections.DATES,
    );

    setDoc(doc(datesCollectionRef, date), { producing, errorData })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error('Error saving daily analytic units produced:', error);
        reject();
      });
  });

const checkIfOrdersAreCancelled = async (db, orderNumbers) => {
  const canceledOrders = [];
  const printingOrders = [];
  try {
    const promises = orderNumbers.map(async (orderNumber) => {
      const convertToNumber = Number(orderNumber);
      // Ensure the correct field name in the `where` clause (e.g., "orderNumber")
      const q = query(
        collection(db, collections.INLINE_PRODUCTS_QUEUE),
        where('orderNumber', '==', convertToNumber),
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('Order is canceled (no record found):', orderNumber);
      }

      // Check each document for the 'canceled' field
      const docData = querySnapshot.docs[0].data(); // Assuming you only expect one document per order

      // If the document has a 'canceled' field set to true
      if (docData.isCanceled) {
        canceledOrders.push(orderNumber);
      }

      if (docData.isPrinting) {
        printingOrders.push(orderNumber);
      }
    });

    // Resolve all promises and return true if any order is canceled
    await Promise.all(promises);
    return { canceledOrders, printingOrders };
  } catch (error) {
    console.error('Error checking canceled orders:', error);
    return false;
  }
};

export {
  createManualBatch,
  createInlineBatch,
  restoreInlineOrders,
  restoreInlineOrderItems,
  checkExistingInlineOrder,
  addRestoreInlineOrders,
  saveDailyAnalyticUnitsProduced,
  checkIfOrdersAreCancelled,
};
