import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Pagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import PageHeader from './pageHeader';
import PageToolbar from './ToolBar';
import webStyles from './stayles';
import { WebOrderTable } from './RowComponent';
import { getWebOrders } from '../../db/getWebOrders';
import {
  applyFilters,
  convertToRows,
  countProductsAndBundlesWeb,
} from './helpers';
import PrintModal from '../Page/printModal';
import CustomPrintModal from '../Page/customPrintModal';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';
import {
  checkIfOrdersAreCancelled,
  createInlineBatch,
} from '../../db/inlineOrder';
import { Toast } from '../Toast';
import { GeneralDialog } from '../Page/generalModal';
import { createCustomBatch } from '../../db/customOrder';
import Loading from '../Loading';
import { getShippings } from '../../db/shippings';
import { checkIfPrinted } from '../../utils/checkIfPrinted';
import { searchInlineOrders } from '../../module/typesense';
import { getTotals } from '../../db/getTotals';
import StickyInfo from '../Page/stickyFooter';
import { API } from '../../constants/defines';

const WebQueuesPage = (props) => {
  const { viewOnly, editOnly, firebase, formats, sizes } = props;
  const { db } = firebase;

  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [typeOfOrder, setTypeOfOrder] = useState('All');
  const [productType, setProductType] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [originalOrders, setOriginalOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tableOrder, setTableOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('orderNumber');
  const [totalPages, setTotalPages] = useState(null);
  const classes = webStyles();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [webOrderType, setWebOrderType] = useState('Inlines');
  const [printModalType, setPrintModalType] = useState('');
  const [uniqueName, setUniqueName] = useState('');
  const [name, setName] = useState('');
  const [printCustomModal, setPrintCustomModal] = useState(false);
  const [printInlineModal, setPrintInlineModal] = useState(false);
  const [showToastInfo, setShowToastInfo] = useState(false);
  const [showToastData, setShowToastData] = useState({});
  const [batchName, setBatchName] = useState('');
  const [batchModal, setBatchModal] = useState(false);
  const [production, setProduction] = useState('All');
  const [loadingName, setLoadingName] = useState(false);
  const [shippingArray, setShippingArray] = useState([]);
  const [shipping, setShipping] = useState('All');
  const [showProductsNotFound, setShowProductsNotFound] = useState(false);
  const [notes, setNotes] = useState('');
  const [keyWords, setKeyWords] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [showTheseOptions, setShowTheseOptions] = useState({
    hasSocks: false,
    hasUnderwear: false,
  });

  useEffect(() => {
    const fetchShippingArray = async () => {
      getShippings(db, null, (items) => {
        // append a new item to the array with id = "All"
        items.unshift({ id: 'All', name: 'All' });
        setShippingArray(items);
      });
    };
    fetchShippingArray();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // Select only the currently visible rows (orders)
      const selectedOrderData = orders.map((order) => order.orderData);
      setSelectedOrders(selectedOrderData); // Select all visible orders
    } else {
      // Clear the selected orders
      setSelectedOrders([]);
    }
  };

  const isSelectedAll =
    orders.length > 0 &&
    orders.every((order) =>
      selectedOrders.some(
        (selectedOrder) =>
          selectedOrder.orderNumber.toString() === order.orderNumber.toString(),
      ),
    );

  const checkOrderTypes = (orders) => {
    const isCustoms = orders.every((order) => order.containsCustomProduct);
    const isInlines = orders.every((order) => !order.containsCustomProduct);
    const hasMixedOrders = !isCustoms && !isInlines;

    let hasSocks = orders.some((order) =>
      order.items.some((item) => item.type?.toLowerCase().includes('sock')),
    );
    let hasUnderwear = orders.some((order) =>
      order.items.some((item) =>
        item.type?.toLowerCase().includes('underwear'),
      ),
    );

    // if any product is missing a type, set hasSocks and hasUnderwear to true
    if (_.some(orders, (order) => _.some(order.items, (item) => !item.type))) {
      hasSocks = true;
      hasUnderwear = true;
    }

    setShowTheseOptions({ hasSocks, hasUnderwear });

    return { isCustoms, isInlines, hasMixedOrders };
  };

  const handlePrint = async () => {
    try {
      setLoadingName(true);
      const { isCustoms, isInlines, hasMixedOrders } =
        checkOrderTypes(selectedOrders);

      // if mixed, alert the user and prevent printing
      if (hasMixedOrders) {
        setLoadingName(false);
        alert(
          'Cannot print mixed orders. Please separate customs and inline orders.',
        );
        return;
      }

      const ordersPrinted = await checkIfPrinted(selectedOrders, db, isCustoms);

      if (ordersPrinted.length > 0) {
        setShowToastInfo(true);
        // get the order numbers that are printed
        const orderNumbersPrinted = ordersPrinted.map(
          (order) => order.orderNumber,
        );
        setShowToastData({
          isError: true,
          message:
            'Some of the selected orders have been printed: ' +
            orderNumbersPrinted.join(', '),
        });
        // remove the printed orders from the selected orders
        const newSelectedOrders = selectedOrders.filter(
          (order) => !orderNumbersPrinted.includes(order.orderNumber),
        );
        setSelectedOrders(newSelectedOrders);
        // remove from the original orders
        const newOriginalOrders = originalOrders.filter(
          (order) => !orderNumbersPrinted.includes(order.orderNumber),
        );
        setOriginalOrders(newOriginalOrders);
        return;
      }

      await getUniqueBatchName(setUniqueName, setName, db);

      setLoadingName(false);
      // if all orders are custom, set the print modal type to 'custom'
      if (isCustoms) {
        setPrintModalType('custom');
        setPrintCustomModal(true);
        // open print modal logic for customs
      }

      // if all orders are inline, set the print modal type to 'inline'
      if (isInlines) {
        setPrintModalType('inline');
        setPrintInlineModal(true);
      }
    } catch (error) {
      console.error('Error handling print: ', error);
    } finally {
      setLoadingName(false);
    }
  };

  const handleSelectOrder = (order) => {
    const isSelected = selectedOrders.some(
      (selectedOrder) =>
        selectedOrder.orderNumber.toString() === order.orderNumber.toString(),
    );

    let newSelected;

    if (isSelected) {
      newSelected = selectedOrders.filter(
        (selectedOrder) =>
          selectedOrder.orderNumber.toString() !== order.orderNumber.toString(),
      );
    } else {
      newSelected = [...selectedOrders, order];
    }

    setSelectedOrders(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && tableOrder === 'asc';
    setTableOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const fetchTypesenseCustoms = async () => {
    if (webOrderType === 'Customs') {
      let searchKeyWords = keyWords;
      if (showProductsNotFound) {
        searchKeyWords = 'product not found';
      } else {
        searchKeyWords = keyWords;
      }
      setLoading(true);
      const filters = [];
      let typeFilter = '';

      filters.push('containsCustomProduct:true');

      switch (productType) {
        case 'All':
          break;
        case 'SockOnly':
          typeFilter = 'type:socks';
          break;
        case 'UnderwearOnly':
          typeFilter = 'type:underwear';
          break;
        case 'Socks' || 'Underwear':
          typeFilter = 'type:multiple';
          break;
      }

      if (typeFilter) {
        filters.push(typeFilter);
      }

      switch (typeOfOrder) {
        case 'All':
          break;
        case 'Singles':
          filters.push('isSingleItemOrder:true');
          break;
        case 'Multiples':
          filters.push('products:>1');
          break;
        default:
          break;
      }

      switch (production) {
        case 'All':
          break;
        case 'Finished Goods':
          filters.push('hasFinishedGood:true');
          break;
        case 'Only Finished Goods':
          filters.push('isFinishedGoodOnlyOrder:true');
          break;
        case 'Graphic':
          filters.push(
            'hasFinishedGood:false && isFinishedGoodOnlyOrder:false',
          );
          break;
        default:
          break;
      }

      if (shipping !== 'All') {
        filters.push('shipping:' + shipping);
      }
      // Join all filters with logical AND
      const filterBy = filters.join(' && ');
      console.log('filterBy', filterBy);
      searchInlineOrders(
        {
          textSearch: searchKeyWords,
          rowsPerPage: itemsPerPage,
          pageNumber: currentPage,
          filters: filterBy,
        },
        async (orderData, { productCount, pages }) => {
          // Convert paginated orders to rows
          const rows = convertToRows(orderData);
          setOrders(rows);
          setOriginalOrders(orderData);
          setTotalPages(pages);
          setLoading(false);
        },
        db,
        true,
      );
    }
  };

  const fetchTypesense = async (isCustoms = false) => {
    let searchKeyWords = keyWords;
    if (showProductsNotFound) {
      searchKeyWords = 'product not found';
    } else {
      searchKeyWords = keyWords;
    }
    if (webOrderType === 'Inlines') {
      setLoading(true);
      const filters = [];
      let typeFilter = '';

      switch (productType) {
        case 'All':
          break;
        case 'SockOnly':
          typeFilter = 'type:socks';
          break;
        case 'UnderwearOnly':
          typeFilter = 'type:underwear';
          break;
        case 'Socks' || 'Underwear':
          typeFilter = 'type:multiple';
          break;
      }

      if (typeFilter) {
        filters.push(typeFilter);
      }

      switch (typeOfOrder) {
        case 'All':
          break;
        case 'Singles':
          filters.push('isSingleItemOrder:true');
          break;
        case 'Multiples':
          filters.push('products:>1');
          break;
        default:
          break;
      }

      switch (production) {
        case 'All':
          break;
        case 'Finished Goods':
          filters.push('hasFinishedGood:true');
          break;
        case 'Only Finished Goods':
          filters.push('isFinishedGoodOnlyOrder:true');
          break;
        case 'Graphic':
          filters.push(
            'hasFinishedGood:false && isFinishedGoodOnlyOrder:false',
          );
          break;
        default:
          break;
      }

      if (shipping !== 'All') {
        filters.push('shipping:' + shipping);
      }

      // !TEST ENV ONLY
      // if (!process?.env?.REACT_APP_PROJECT_ID === "artomator-test") {
      filters.push('containsCustomProduct:false');
      // }

      // Join all filters with logical AND
      const filterBy = filters.join(' && ');

      searchInlineOrders(
        {
          textSearch: searchKeyWords,
          rowsPerPage: itemsPerPage,
          pageNumber: currentPage,
          filters: filterBy,
        },
        async (orderData, { productCount, pages }, originalOrders) => {
          // Convert paginated orders to rows
          const rows = convertToRows(orderData);
          setOrders(rows);
          setOriginalOrders(orderData);
          setTotalPages(pages);
          setLoading(false);
        },
        db,
      );
    }
  };

  useEffect(() => {
    if (webOrderType === 'Inlines') {
      fetchTypesense();
    } else {
      fetchTypesenseCustoms();
    }
  }, [
    typeOfOrder,
    productType,
    production,
    shipping,
    itemsPerPage,
    currentPage,
    keyWords,
    webOrderType,
    shipping,
    showProductsNotFound,
  ]);

  const handleItemsPerPageChange = (event) => {
    const { name, value } = event.target;

    if (name === 'typeOfOrder') {
      setTypeOfOrder(value);
    } else if (name === 'productType') {
      setProductType(value);
    } else {
      setItemsPerPage(value);
    }

    // Reset to the first page when filters change
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setTypeOfOrder('All');
    setProductType('All');
    setKeyWords('*');
    setSearchInput('');
    setShipping('All');
    setProduction('All');
    setItemsPerPage(25);
    setCurrentPage(1);
    const totalPages = Math.ceil(originalOrders.length / itemsPerPage);
    setTotalPages(totalPages);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate the overall products and bundles
  const overAll = {};

  const selectedInfo = useMemo(() => {
    // Filter out duplicates based on unique order numbers
    const uniqueSelectedOrders = selectedOrders.filter(
      (order, index, self) =>
        index ===
        self.findIndex(
          (o) => o.orderNumber.toString() === order.orderNumber.toString(),
        ),
    );

    // Pass the unique orders to the counting function
    return countProductsAndBundlesWeb(uniqueSelectedOrders, true);
  }, [selectedOrders]);

  const handleWebOrderTypeChange = (event) => {
    console.log('event', event.target.value);
    setWebOrderType(event.target.value);
  };

  const handleClosePrintModal = () => {
    setPrintCustomModal(false);
    setPrintInlineModal(false);
  };

  const handleBatchResult = (result, batchId, batchName) => {
    setBatchModal(true);
    // setWorking(false);
    if (result) {
      setSelectedOrders([]);
    }
  };

  const handleCloseBatchModal = () => {
    setBatchModal(false);
  };

  // ! CUSTOMS PRINT BATCH
  const handlePrintBatchCustom = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    barcode = '',
    sockFormat,
    underwearFormat,
    sockBatchType,
    underwearBatchType,
  ) => {
    const numberOfOrders = selectedOrders.length;

    const hasMissingBackPathOrders = [];

    selectedOrders.forEach((order) => {
      if (
        order.items.some(
          (item) =>
            !item.print &&
            (item.sku.includes('CUSTOM') || item.sku.includes('CUSTOMS')),
        )
      ) {
        console.log('order', order);
        hasMissingBackPathOrders.push(order);
      }
    });

    if (hasMissingBackPathOrders.length > 0) {
      alert(
        'Could not find print files for order(s): ' +
          hasMissingBackPathOrders
            .map((order) => order.orderNumber)
            .join(', ') +
          '. Please alert our team so we can fix this.',
      );
      return;
    }

    createCustomBatch(
      db,
      name,
      secondaryName,
      color,
      type,
      barcode,
      selectedOrders,
      handleBatchResult,
      false,
      'web',
      formats,
      sizes,
      notes,
      sockFormat,
      underwearFormat,
      sockBatchType,
      underwearBatchType,
    );

    // remove the selected orders from the original orders
    const newOriginalOrders = originalOrders.filter(
      (order) => !selectedOrders.includes(order),
    );

    setOriginalOrders(newOriginalOrders);

    setOrders(convertToRows(newOriginalOrders, true));

    setSelectedOrders([]);
    setPrintCustomModal(false);
    handleClosePrintModal();

    if (uniqueName === name) {
      saveNameIfUsed(name, db);
    }

    if (numberOfOrders === itemsPerPage) {
      setCurrentPage(1);
    }

    setTimeout(() => {
      console.log('Setting current page to 1 after 2.5 seconds');
      setCurrentPage(1);
    }, 2500);
  };

  // ! INLINE PRINT BATCH
  const handlePrintBatchInline = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    underwearFormat = 'image',
    barcode = '',
    sockFormat = 'png',
    sockBatchType,
    underwearBatchType,
  ) => {
    setBatchName(name);

    let selectedOrdersInNewBatch = selectedOrders;
    const originalOrderNumbers = _.map(selectedOrdersInNewBatch, 'orderNumber');
    const orderNumbers = [...new Set(originalOrderNumbers)];

    const numberOfOrders = orderNumbers.length;

    const data = await checkIfOrdersAreCancelled(db, orderNumbers);
    // compare the orderNumbers with the canceled orders
    const canceledOrders = _.intersection(orderNumbers, data.canceledOrders);

    if (canceledOrders.length > 0) {
      setShowToastInfo(true);
      setShowToastData({
        isError: true,
        message:
          'Some of the selected orders have been canceled: ' +
          canceledOrders.join(', ') +
          '. They have been removed.',
      });
      // remove the canceled orders from the selected orders
      const newSelectedOrders = _.filter(
        selectedOrdersInNewBatch,
        (order) => !canceledOrders.includes(order.orderNumber),
      );
      selectedOrdersInNewBatch = newSelectedOrders;
    }

    if (data.printingOrders.length > 0) {
      console.log('data.printingOrders', data.printingOrders);
      setShowToastInfo(true);
      // get the order numbers that are printed
      const orderNumbersPrinted = data.printingOrders.map((order) => order);
      setShowToastData({
        isError: true,
        message:
          'Some of the selected orders have been printed: ' +
          orderNumbersPrinted.join(', '),
      });
      // remove the printed orders from the selected orders
      const newSelectedOrders = selectedOrders.filter(
        (order) => !orderNumbersPrinted.includes(order.orderNumber),
      );
      setSelectedOrders(newSelectedOrders);
      // remove from the original orders
      const newOriginalOrders = originalOrders.filter(
        (order) => !orderNumbersPrinted.includes(order.orderNumber),
      );
      setOriginalOrders(newOriginalOrders);
      return;
    }

    const selectedOrdersUpdated = selectedOrdersInNewBatch;

    if (selectedOrdersUpdated.length === 0) {
      setPrintInlineModal(false);
      setShowToastInfo(true);
      setShowToastData({
        isError: true,
        message: 'All selected orders have been canceled',
      });
      return;
    }

    const hasMissingBackPathOrders = [];

    selectedOrders.forEach((order) => {
      if (
        order.items.some(
          (item) =>
            !item.print &&
            (item.sku.includes('CUSTOM') || item.sku.includes('CUSTOMS')),
        )
      ) {
        console.log('order', order);
        hasMissingBackPathOrders.push(order);
      }
    });

    if (hasMissingBackPathOrders.length > 0) {
      alert(
        'Could not find print files for order(s): ' +
          hasMissingBackPathOrders
            .map((order) => order.orderNumber)
            .join(', ') +
          '. Please alert our team so we can fix this.',
      );
      return;
    }

    createInlineBatch(
      db,
      name,
      secondaryName,
      color,
      type,
      underwearFormat,
      barcode,
      selectedOrdersUpdated,
      handleBatchResult,
      false,
      formats,
      sizes,
      notes,
      sockFormat,
      sockBatchType,
      underwearBatchType,
    );

    if (numberOfOrders === itemsPerPage) {
      new Promise((resolve) => setTimeout(resolve, 1000)).then(() => {
        // trigger a re-fetch of the typesense results
        setCurrentPage(currentPage + 1);
      });
    }
    // reset selected orders
    setSelectedOrders([]);

    handleClosePrintModal();

    if (name?.toLowerCase().trim() === uniqueName?.toLowerCase().trim()) {
      await saveNameIfUsed(name, db);
    }

    setUniqueName('');
  };

  const handleProductionChange = (event) => {
    setProduction(event.target.value);
  };

  const handleShowProductsNotFoundChange = (event) => {
    setShowProductsNotFound(event.target.checked);
  };

  // Handle key press
  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setKeyWords(searchInput); // Update keyWords only when Enter is pressed
      console.log('Search triggered with keyWords:', searchInput);
    }
  };

  // Handle input changes
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const [totalsLive, setTotalsLive] = useState(null);

  // use effect hook to get collection: collection("Counts").doc("data"); but on snapshot
  useEffect(() => {
    getTotals(db, (data) => {
      setTotalsLive(data);
    });
  }, []);

  return (
    <>
      <PageHeader
        initial={overAll}
        selected={selectedInfo}
        type="Web"
        totalsLive={totalsLive}
      />

      <PageToolbar
        viewOnly={viewOnly}
        editOnly={editOnly}
        enable={true}
        numSelected={selectedOrders.length}
        allSelected={isSelectedAll}
        onSelectAllClick={handleSelectAllClick}
        onPrint={handlePrint}
        searchInput={searchInput}
        keyWords={keyWords}
        setKeyWords={setKeyWords}
        handleSearch={handleSearch}
        handleInputChange={handleInputChange}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          justifyItems: 'center',
          marginBottom: 2,
          marginTop: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />

        <>
          {/* Checkbox for showProductsNotFound */}
          <FormControlLabel
            control={
              <Checkbox
                checked={showProductsNotFound}
                onChange={handleShowProductsNotFoundChange}
                color="primary"
              />
            }
            label="Show PNF's only"
            style={{ marginRight: 20, marginBottom: 20 }}
          />

          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="items-per-page-label">Items per page</InputLabel>
            <Select
              labelId="items-per-page-label"
              id="items-per-page"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              label="Items per page"
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={200}>200</MenuItem>
            </Select>
          </FormControl>

          {/* add a dropdown for web order type. Where we see customs or inlines  */}
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="web-order-type-label">Web order Type</InputLabel>
            <Select
              labelId="web-order-type-label"
              id="web-order-type"
              name="webOrderType"
              value={webOrderType}
              onChange={handleWebOrderTypeChange}
              label="Web order type"
            >
              <MenuItem value="Customs">Customs</MenuItem>
              <MenuItem value="Inlines">Inlines</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="items-per-page-label">Production</InputLabel>
            <Select
              labelId="production-label"
              id="production"
              name="production"
              value={production}
              onChange={handleProductionChange}
              label="Production"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Finished Goods">Finished Goods</MenuItem>
              <MenuItem value="Only Finished Goods">
                Only Finished Goods
              </MenuItem>
              <MenuItem value="Graphic">Graphic</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="type-of-order-label">Order</InputLabel>
            <Select
              labelId="type-of-order-label"
              id="type-of-order"
              name="typeOfOrder"
              value={typeOfOrder}
              onChange={handleItemsPerPageChange}
              label="Order"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Singles">Singles</MenuItem>
              <MenuItem value="Multiples">Multiples</MenuItem>
              <MenuItem value="customAndInline">Inlines & Customs</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="product-type-label">Type</InputLabel>
            <Select
              labelId="product-type-label"
              id="product-type"
              name="productType"
              value={productType}
              onChange={handleItemsPerPageChange}
              label="Type"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Socks">Socks</MenuItem>
              <MenuItem value="SockOnly">Socks only</MenuItem>
              <MenuItem value="Underwear">Underwear</MenuItem>
              <MenuItem value="UnderwearOnly">Underwear Only</MenuItem>
            </Select>
          </FormControl>

          {/* shipping */}
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 100, marginRight: 16 }}
          >
            <InputLabel id="shipping-label">Shipping</InputLabel>
            <Select
              labelId="shipping-label"
              id="shipping"
              name="shipping"
              value={shipping}
              onChange={(e) => setShipping(e.target.value)}
              label="Shipping format"
            >
              {shippingArray.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="outlined"
            onClick={resetFilters}
            sx={{
              marginRight: 2,
              marginBottom: 2,
            }}
          >
            Reset
          </Button>
        </>
      </Box>

      <WebOrderTable
        orders={orders}
        classes={classes}
        type="Web"
        onSelectOrder={handleSelectOrder}
        selectedOrders={selectedOrders}
        tableOrder={tableOrder}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        loading={loading}
        db={db}
      />

      {selectedOrders.length > 0 && (
        <StickyInfo selectedInfo={selectedInfo} onPrint={handlePrint} />
      )}

      <Box className={classes.pagination}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </Box>

      {loadingName ? (
        <Loading />
      ) : (
        <>
          {printModalType !== 'custom' ? (
            <PrintModal
              {...props}
              open={printInlineModal}
              orders={selectedInfo.orders}
              onPrint={handlePrintBatchInline}
              onClose={handleClosePrintModal}
              source="Order"
              bundles={selectedInfo.bundles}
              products={selectedInfo.products}
              name={name}
              setName={setName}
              titleName="Web"
              isCustom={printModalType === 'custom'}
              notes={notes}
              setNotes={setNotes}
              showTheseOptions={showTheseOptions}
            />
          ) : (
            <PrintModal
              {...props}
              open={printCustomModal}
              onPrint={handlePrintBatchCustom}
              onClose={handleClosePrintModal}
              source="Order"
              orders={selectedInfo.orders}
              bundles={selectedInfo.bundles}
              products={selectedInfo.products}
              barcodes={[]}
              name={name}
              setName={setName}
              titleName="Web"
              isCustom={printModalType === 'custom'}
              notes={notes}
              setNotes={setNotes}
              showTheseOptions={showTheseOptions}
            />
          )}
        </>
      )}

      <GeneralDialog
        label="batch"
        title="Batch"
        open={batchModal}
        onClose={handleCloseBatchModal}
        onConfirm={handleCloseBatchModal}
      >
        <Typography variant="body2" gutterBottom>
          Inline BATCH name is <strong>{batchName}</strong>
        </Typography>
      </GeneralDialog>

      <Toast
        show={showToastInfo}
        data={showToastData}
        onClose={() => setShowToastInfo(false)}
      />
    </>
  );
};

export default WebQueuesPage;
