import {
  STORE_ALL_CUSTOM_ORDERS,
  FETCH_CUSTOM_ORDERS_ERROR,
  FETCHED_CUSTOM_ORDERS,
  UPDATE_PAGE_NUMBER,
  START_FETCH_CUSTOM_ORDERS,
  SHOW_FILTER_DATA,
} from './types';

const initialState = {
  customOrders: [],
  initialCustomOrders: [],
  isLoading: true,
  pageData: {},
  showFilterData: false,
};

const customOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_CUSTOM_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCHED_CUSTOM_ORDERS:
      return {
        ...state,
        customOrders: action.payload,
        isLoading: false,
      };
    case FETCH_CUSTOM_ORDERS_ERROR:
      return {
        ...state,
        isLoading: false,
        // Optionally, handle the error in your state as well
      };
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        pageData: action.payload,
        isLoading: false,
      };
    case STORE_ALL_CUSTOM_ORDERS:
      return {
        ...state,
        initialCustomOrders: action.payload,
        isLoading: false,
      };
    case SHOW_FILTER_DATA:
      return {
        ...state,
        showFilterData: action.payload,
      };
    default:
      return state;
  }
};

export default customOrdersReducer;
