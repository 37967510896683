import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

import { PRODUCT_MOCK_PREFIX, PRODUCT_ART_PREFIX } from '../constants/defines';

const uploadToStorage = (storage, imgPath, file, statusCallback = null) =>
  new Promise((resolve, reject) => {
    const storageRef = ref(storage, imgPath);
    const uploadTask = uploadBytesResumable(storageRef, file);

    if (statusCallback) {
      statusCallback(0);
    }
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            if (statusCallback) {
              statusCallback(progress);
            }
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        if (statusCallback) {
          statusCallback(100);
        }
        resolve();
      },
    );
  });

const existStorageFile = (storage, path) =>
  new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, path))
      .then((url) => {
        resolve(true);
      })
      .catch((err) => {
        resolve(false);
      });
  });

const existProductArt = (storage, path) =>
  new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, `${PRODUCT_ART_PREFIX}${path}`))
      .then((url) => {
        resolve(true);
      })
      .catch((err) => {
        resolve(false);
      });
  });

// delete imag from storage
const deleteImage = async (storage, path) => {
  console.log('storage: ', storage);
  const prefix = PRODUCT_ART_PREFIX;
  const storageRef = ref(storage, `${prefix}${path}`);
  console.log('storageRef: ', storageRef);
  try {
    const response = await deleteObject(storageRef);
    console.log('response: ', response);
  } catch (error) {
    console.log('error: ', error);
  }
};

export { uploadToStorage, existStorageFile, existProductArt, deleteImage };
