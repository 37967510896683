import { useSearchKeyWords } from '../../utils/searchByKeyWord';
import { useFuzzySearch } from '../../utils/useFuzzySearchForBundles';
import { calculatePages, goThroughFilters, updateAllFilters } from './helpers';
import {
  INLINE_ORDERS_FETCHED,
  FETCH_INLINE_ORDERS_START,
  UPDATE_INLINE_ORDERS,
  FETCH_ALL_INLINE_ORDERS,
  SHOW_FILTER_DATA,
  FILTERED_ORDERS_ARRAY,
  UPDATE_ROWS_PER_PAGE,
  UPDATE_PAGE_NUMBER,
  UPDATE_NEXT_PAGE_DATA,
  DID_USE_FILTER,
  FILTER_TYPES,
  USE_SEARCH,
  GET_NEXT_SET_OF_ORDERS,
  RESET_FILTERS,
  REMOVE_INLINE_ORDERS,
} from './types';

const initialState = {
  initialAllInlineOrders: [],
  inlineOrders: [],
  isLoading: true,
  pageData: {},
  showFilteredData: false,
  filteredData: [],
  rowsPerPage: 25,
  currentPageNumber: 1,
  didUseFilter: false,
  filtersUsed: {
    products: 1,
    shows: 1,
    production: 1,
    types: 1,
    shippings: 1,
  },
  searchKeys: '',
};
// return {
//   ...state,
//   filteredData: fullFilteredData,
//   inlineOrders: currentPageData,
//   pageData: {
//     ...state.pageData,
//     pages: totalPages,
//   },
//   filtersUsed: updatedFiltersUsed,
//   currentPageNumber: updatedPageNumber,
// };
const inlineOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_FILTERS:
      const resetTotalPages = calculatePages(
        state.initialAllInlineOrders,
        state.rowsPerPage,
      );

      const resetedState = {
        ...state,
        filteredData: state.initialAllInlineOrders, // Reset filtered data to initial state
        inlineOrders: state.initialAllInlineOrders.slice(0, state.rowsPerPage), // Reset inline orders to first page of initial state
        pageData: {
          ...state.pageData,
          pages: resetTotalPages,
        },
        filtersUsed: {
          products: 1,
          shows: 1,
          production: 1,
          types: 1,
          shippings: 1,
        },
        searchKeys: '',
        didUseFilter: false,
        currentPageNumber: 1,
        showFilteredData: false,
      };

      return resetedState;

    case FETCH_INLINE_ORDERS_START:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_ALL_INLINE_ORDERS:
      return {
        ...state,
        initialAllInlineOrders: action.payload,
        filteredData: action.payload,
        isLoading: false,
      };
    case INLINE_ORDERS_FETCHED:
      return {
        ...state,
        inlineOrders: action.payload.products,
        isLoading: false,
        pageData: action.payload.pagesData,
      };
    case UPDATE_INLINE_ORDERS:
      const processedOrderIds = new Set(
        action.payload.map((order) => order.id),
      );

      const remainingOrders = state.inlineOrders.filter(
        (order) => !processedOrderIds.has(order.orderNumber),
      );

      const remainingInitial = state.initialAllInlineOrders.filter(
        (order) => !processedOrderIds.has(order.orderNumber),
      );

      return {
        ...state,
        initialAllInlineOrders: remainingInitial,
        inlineOrders: remainingOrders,
      };
    case SHOW_FILTER_DATA:
      return {
        ...state,
        showFilteredData: action.payload,
      };
    case FILTERED_ORDERS_ARRAY:
      const { option, filterData, shippingData } = action.payload;
      const NUMBER_OF_ROWS_PER_PAGE = state.rowsPerPage;
      let fullFilteredData = [...state.initialAllInlineOrders];
      const updatedFiltersUsed = { ...state.filtersUsed };

      // Apply search filter if searchKeys is not empty
      if (state.searchKeys) {
        fullFilteredData = useFuzzySearch(
          state.searchKeys,
          fullFilteredData,
          'inline',
        );
      }

      Object.keys(FILTER_TYPES).forEach((filterTypeKey) => {
        const filterType = FILTER_TYPES[filterTypeKey];
        if (filterData.hasOwnProperty(filterType)) {
          switch (filterType) {
            case FILTER_TYPES.PRODUCTS:
              updatedFiltersUsed.products = option;
              break;
            case FILTER_TYPES.PRODUCTION_TYPES:
              updatedFiltersUsed.production = option;
              break;
            case FILTER_TYPES.TYPES:
              updatedFiltersUsed.types = option;
              break;
            case FILTER_TYPES.SHIPPING:
              updatedFiltersUsed.shippings = option;
              break;
            default:
              break;
          }
        }
      });

      const filtersToUse = goThroughFilters(updatedFiltersUsed, shippingData);
      fullFilteredData = updateAllFilters(filtersToUse, fullFilteredData);

      let updatedPageNumber = state.currentPageNumber;
      const totalPages = calculatePages(
        fullFilteredData,
        NUMBER_OF_ROWS_PER_PAGE,
      );

      if (updatedPageNumber > totalPages) {
        updatedPageNumber = Math.max(totalPages, 1);
      }

      const startIndex = (updatedPageNumber - 1) * NUMBER_OF_ROWS_PER_PAGE;
      const endIndex = startIndex + NUMBER_OF_ROWS_PER_PAGE;
      const currentPageData = fullFilteredData.slice(startIndex, endIndex);

      return {
        ...state,
        filteredData: fullFilteredData,
        inlineOrders: currentPageData,
        pageData: {
          ...state.pageData,
          pages: totalPages,
        },
        filtersUsed: updatedFiltersUsed,
        currentPageNumber: updatedPageNumber,
      };

    case UPDATE_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };

    case DID_USE_FILTER:
      return {
        ...state,
        didUseFilter: action.payload,
      };

    case UPDATE_NEXT_PAGE_DATA:
      const { currentPageNumber, rowsPerPage } = state;

      const start = (currentPageNumber - 1) * rowsPerPage;

      const end = currentPageNumber * rowsPerPage;

      const dataSetToUse = state.didUseFilter
        ? state.filteredData
        : state.initialAllInlineOrders;

      const nextPageData = dataSetToUse.slice(start, end);

      return {
        ...state,
        inlineOrders: nextPageData,
      };

    case USE_SEARCH:
      const { searchKeys } = action.payload;
      const data = useFuzzySearch(
        searchKeys,
        state.initialAllInlineOrders,
        'inline',
      );
      const totalPagesSearch = calculatePages(data, state.rowsPerPage);

      return {
        ...state,
        inlineOrders: data.slice(0, state.rowsPerPage),
        pageData: {
          ...state.pageData,
          pages: totalPagesSearch,
        },
        currentPageNumber: 1,
        searchKeys, // Update the searchKeys in state
      };

    // Assuming the action payload contains the IDs of orders that have been processed
    case GET_NEXT_SET_OF_ORDERS: {
      const processedOrderIds = new Set(
        action.payload.map((order) => order.id),
      );
      const remainingOrders = state.initialAllInlineOrders.filter(
        (order) => !processedOrderIds.has(order.orderNumber),
      );

      // Update the filtered data as well if you maintain a separate filtered view
      const remainingFilteredData = state.filteredData.filter(
        (order) => !processedOrderIds.has(order.orderNumber),
      );

      return {
        ...state,
        initialAllInlineOrders: remainingOrders,
        filteredData: remainingFilteredData,
        inlineOrders: remainingOrders.slice(0, state.rowsPerPage), // Load the first page of the new dataset
        currentPageNumber: 1, // Reset to the first page
        pageData: {
          ...state.pageData,
          pages: Math.ceil(remainingOrders.length / state.rowsPerPage), // Recalculate total pages
        },
      };
    }

    case REMOVE_INLINE_ORDERS: {
      const processedOrderIds = action.payload;
      console.log('processedOrderIds', processedOrderIds);
      //  remove all the orders from the array that have been processed
      const remainingOrders = state.inlineOrders.filter(
        (order) => !processedOrderIds.includes(order.orderNumber),
      );

      // Update the filtered data as well if you maintain a separate filtered view
      const remainingFilteredData = state.filteredData.filter(
        (order) => !processedOrderIds.includes(order.orderNumber),
      );

      return {
        ...state,
        initialAllInlineOrders: remainingOrders,
        filteredData: remainingFilteredData,
        inlineOrders: remainingOrders, // Load the first page of the new dataset
        currentPageNumber: 1, // Reset to the first page
        pageData: {
          ...state.pageData,
          pages: Math.ceil(remainingOrders.length / state.rowsPerPage), // Recalculate total pages
        },
      };
    }

    default:
      return state;
  }
};

export default inlineOrdersReducer;
