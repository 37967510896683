import React from 'react';

import { withAuthorization } from '../Session';
import Layout from '../Page';

const Report = (props) => (
  <Layout page={props.page} user={props.user}>
    Coming Soon
  </Layout>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(Report);
