import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import { ConfirmDialog } from './generalModal';

const RouteLeavingGuard = (props) => {
  const { when, navigate, shouldBlockNavigation } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (when) {
      window.onbeforeunload = leavePageAlert;
    } else {
      window.onbeforeunload = null;
    }
  }, [when]);

  const leavePageAlert = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {/* Your own alert/dialog/modal component */}

      <ConfirmDialog
        open={modalVisible}
        title="Close without saving?"
        onClose={closeModal}
        onConfirm={handleConfirmNavigationClick}
      >
        <Typography>Are you sure you want to leave this page?</Typography>
      </ConfirmDialog>
    </>
  );
};

export default RouteLeavingGuard;
