import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { useDispatch, useSelector } from 'react-redux';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import { ConfirmDialog, GeneralDialog } from '../Page/generalModal';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import { FilterProvider } from './filterContext';
import { orderTypes } from '../../constants/defines';
import {
  getCustomOrders,
  markAsRevision,
  createCustomBatch,
} from '../../db/customOrder';
import { getShippings } from '../../db/shippings';
import { formatDate } from '../../module/functions';
import { EnhancedTableHead, getComparator, stableSort } from '../Page/table';
import CustomPrintModal from '../Page/customPrintModal';
import useStyles from './styles';
import {
  formatSource,
  isDateExceedingFiveDays,
  rowStyle,
} from '../../utils/fiveDaysExceederHelper';
import {
  calculateProducts,
  getWhatTypeOfBundle,
} from '../../utils/calculateOrderItems';
import Loading from '../Loading';
import { ordersWithOrderNumber } from '../../utils/pushOrderNumberToItems';
import useFetchCustomOrders from '../../hooks/useFetchCustomOrders';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { handleCopyToBoard } from '../../utils/copyToClip';
import { reWriteStyle } from '../../utils/reWriteStyle';
import { useShiftSelected } from '../../hooks/useSelectMany';
import {
  getUniqueBatchName,
  saveNameIfUsed,
} from '../../utils/getUniqueBatchName';

function createData(
  date,
  odd,
  order,
  exist,
  available,
  type,
  shipping,
  sku,
  qty,
  tags,
  source,
  boxset,
  restored,
  error,
  style,
  index,
  items,
  batchDate,
) {
  return {
    date: formatDate(date),
    odd,
    order,
    exist,
    available,
    type,
    shipping,
    sku,
    qty,
    tags,
    source,
    boxset,
    restored,
    error,
    style,
    index,
    items,
    batchDate: formatDate(batchDate),
  };
}

const convertToRows = (_orders) => {
  const rows = [];
  _.map(_orders, (order, index) => {
    const odd = index % 2 === 1;
    _.map(order.items, (item, index) => {
      rows.push(
        createData(
          order.date,
          odd,
          order.order,
          item.exist,
          item.available,
          item.type,
          order.shipping,
          item.sku,
          item.qty,
          '',
          item.source,
          item.boxset,
          order.restored,
          item.error,
          item.style,
          index,
          item.items,
          order.batchDate,
        ),
      );
    });
  });
  return rows;
};

const filterShows = [
  { id: 1, name: '50', value: 50 },
  { id: 2, name: '100', value: 100 },
  { id: 3, name: '200', value: 200 },
  { id: 4, name: '500', value: 500 },
];

const filterOrders = [
  { id: 1, name: 'All', value: '' },
  { id: 2, name: 'Single', value: orderTypes.SINGLE },
  { id: 3, name: 'Multiple', value: orderTypes.MULTIPLE },
  { id: 4, name: 'Custom & Inline', value: orderTypes.OTHER },
  { id: 5, name: 'Manual', value: orderTypes.MANUAL },
  { id: 6, name: 'Reprint', value: 'Reprint' },
];

const filterTypes = [
  { id: 1, name: 'All', value: '' },
  { id: 2, name: 'Socks', value: 'socks' },
  { id: 3, name: 'Masks', value: 'masks' },
  // {id: 4, name: 'Underwear', value: 'underwear'},
  { id: 5, name: 'Socks & Masks', value: 'multiple' },
];

const filterSources = [
  { id: 1, name: 'All', value: '' },
  { id: 2, name: 'Shopify', value: 'shopify' },
  { id: 3, name: 'Fanatics', value: 'fanatics' },
  // { id: 4, name: 'Quickbooks', value: 'quickbooks' },
];

const dateSelectedForBatch = '';

// renamed variables for clarity
function filterCustomOrders(
  ordersArray,
  typeId,
  sourceId,
  orderId,
  shippingMethod,
  selectedBatchDate,
) {
  // Find the corresponding values for the type, source, and order parameters
  const selectedType = _.find(filterTypes, { id: typeId }).value.toLowerCase();
  const selectedSource = _.find(filterSources, {
    id: sourceId,
  }).value.toLowerCase();
  const selectedOrder = _.find(filterOrders, {
    id: orderId,
  }).value.toLowerCase();
  const selectedBatch = selectedBatchDate;

  // Filter the orders array based on the selected values
  const filteredOrders = _.filter(ordersArray, (order) => {
    let isOrderMatch = true;

    // If a type is selected, check if the order type matches the selected type
    if (selectedType !== '') {
      isOrderMatch = order.type.toLowerCase() === selectedType;
    }

    // If a source is selected and the previous conditions are met, check if the order source matches the selected source
    if (isOrderMatch && selectedSource !== '') {
      if (selectedSource === 'fanatics') {
        isOrderMatch =
          (typeof order.order === 'string' &&
            String(order.order).toLowerCase().startsWith('ds')) ||
          order.source.toLowerCase() === selectedSource;
      } else {
        isOrderMatch = order.source.toLowerCase() === selectedSource;
      }
    }

    // If an order is selected and the previous conditions are met, check if the order matches the selected order
    if (isOrderMatch && selectedOrder !== '') {
      if (selectedOrder === 'reprint') {
        isOrderMatch = order.restored;
      } else if (selectedOrder === orderTypes.MANUAL.toLowerCase()) {
        isOrderMatch = order.source.toLowerCase() === selectedOrder;
      } else {
        isOrderMatch = order.orderType.toLowerCase() === selectedOrder;
      }
    }

    // If a shipping method is selected and the previous conditions are met, check if the order shipping method matches the selected shipping method
    if (isOrderMatch && shippingMethod !== '') {
      isOrderMatch =
        order.shipping.toLowerCase() === shippingMethod.toLowerCase();
    }

    if (isOrderMatch && selectedBatch !== '') {
      isOrderMatch = formatDate(order.batchDate) === selectedBatch;
    }

    // Return whether the order matches all the selected filters
    return isOrderMatch;
  });
  return filteredOrders;
}

const headCells = [
  {
    id: 'date',
    numeric: false,
    align: 'left',
    enableSort: true,
    disablePadding: true,
    label: 'DATE',
    width: '140px',
  },
  {
    id: 'order',
    numeric: true,
    align: 'left',
    enableSort: true,
    disablePadding: false,
    label: 'ORDER',
    width: '140px',
  },
  {
    id: 'type',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'TYPE',
    width: '110px',
  },
  {
    id: 'sku',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'SKU',
    width: 'auto',
  },
  {
    id: 'shipping',
    numeric: false,
    align: 'left',
    enableSort: false,
    disablePadding: false,
    label: 'Shipping',
    width: 'auto',
  },
  {
    id: 'qty',
    numeric: true,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'QTY',
    width: '60px',
  },
  {
    id: 'source',
    numeric: false,
    align: 'center',
    enableSort: false,
    disablePadding: false,
    label: 'SOURCE',
    width: '100px',
  },
];

function CustomOrders(props) {
  const dispatch = useDispatch();
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();
  useFetchCustomOrders(dispatch, db, storage);

  const { customOrders, isLoading, initialCustomOrders, showFilterData } =
    useSelector((state) => state.customOrders);

  const orders = customOrders;

  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('date');
  const [selected, setSelected] = useState([]);
  // const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [selectedBatchDate, setSelectedBatchDate] = useState('');
  const [filterType, setFilterType] = useState(1);
  const [filterShow, setFilterShow] = useState(1);
  const [filterSource, setFilterSource] = useState(1);
  const [filterOrder, setFilterOrder] = useState(1);
  const [revision, setRevision] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [printBatch, setPrintBatch] = useState(null);
  const [filterShippings, setFilterShippings] = useState([]);
  const [filterShipping, setFilterShipping] = useState(1);
  const [press, setPress] = useState(0);
  const [uniqueName, setUniqueName] = useState('');
  const [name, setName] = useState('');

  const viewOnly = props.viewOnly;
  const editOnly = props.editOnly;

  let rowsPerPage = 50;

  useEffect(() => {
    getShippings(db, 100, fetchShippings);

    const downHandler = (e) => {
      setPress(e.keyCode);
    };

    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  useEffect(() => {
    if (press == 13) {
      if (!printModal) {
        handlePrint();
      } else {
        handlePrintBatch();
      }
    }
  }, [press]);

  const fetchShippings = (items) => {
    const _filterShippings = [{ id: 1, name: 'All', val: '' }];

    const shippings = _.map(items, (item, index) => ({
      id: index + 2,
      name: item.name,
      value: item.id,
    }));
    const _shippings = _filterShippings.concat(shippings);
    setFilterShippings([..._shippings]);
  };

  const handleClosePrintModal = () => {
    setPrintModal(false);
  };

  const handlePrint = async () => {
    await getUniqueBatchName(setUniqueName, setName);
    if (selected.length == 0) {
      setPress(0);
      return;
    }
    setPrintModal(true);
    setPress(0);
  };

  const handlePrintBatch = async (
    name,
    secondaryName = '',
    color = '#000000',
    type = 'belt',
    barcode = '',
  ) => {
    createCustomBatch(
      db,
      name,
      secondaryName,
      color,
      type,
      barcode,
      selectedOrders,
      handleBatchResult,
    );

    setSelected([]);
    setPrintModal(false);
    setPress(0);

    if (uniqueName === name) {
      saveNameIfUsed(name);
    } else {
      console.log('name', name);
    }
  };

  const renderPrintDialog = (ordersCnt, productsCnt, bundlesCnt) => (
    <CustomPrintModal
      {...props}
      open={printModal}
      onPrint={handlePrintBatch}
      onClose={handleClosePrintModal}
      source="Order"
      orders={ordersCnt}
      products={productsCnt}
      bundles={bundlesCnt}
      barcodes={[]}
      name={name}
      setName={setName}
    />
  );

  const handleNeedsRevision = () => {
    markAsRevision(db, selectedOrders);
    setRevision(false);
  };

  const handleCloseRevision = () => {
    setRevision(false);
  };

  const handleRevision = () => {
    setRevision(true);
  };

  const renderRevisionDialog = () => (
    <ConfirmDialog
      label="revision"
      title="Needs Revision"
      open={revision}
      onClose={handleCloseRevision}
      onConfirm={handleNeedsRevision}
    >
      <Typography gutterBottom>
        Sending <strong>{selectedInfo.total}</strong>{' '}
        {selectedInfo.total > 1 ? 'orders' : 'order'} back to the custom crop
        queue
      </Typography>
    </ConfirmDialog>
  );

  const handleBatchResult = (_batch) => {
    setPrintBatch({ ..._batch });
    setPress(0);
  };

  const handleCloseBatch = () => {
    setPrintBatch(null);
  };

  const renderBatchResultDialog = () => (
    <GeneralDialog
      label="batch"
      title="Batch Information"
      open={!!printBatch}
      onClose={handleCloseBatch}
      onConfirm={handleCloseBatch}
    >
      <Typography variant="body2" gutterBottom>
        Custom BATCH name is <strong>{printBatch.baseName} - Custom</strong>
      </Typography>
      {!!printBatch.inlineBatchId && (
        <Typography variant="body2" gutterBottom>
          Inline BATCH name is <strong>{printBatch.baseName} - In-Line</strong>
        </Typography>
      )}
    </GeneralDialog>
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // let  _currentRows = __orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      const newSelecteds = currentOrders.map((n) => n.order);
      const _newSelecteds = _.uniq(newSelecteds);
      setSelected(_newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (shows) => {
    setFilterShow(shows);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  rowsPerPage = _.find(filterShows, (filter) => filter.id === filterShow).value;

  let shipping = '';
  if (filterShipping != 1) {
    shipping = _.find(filterShippings, { id: filterShipping })
      ? _.find(filterShippings, { id: filterShipping }).name
      : '';
  }

  // create orders array
  const __orders = stableSort(
    filterCustomOrders(
      orders,
      filterType,
      filterSource,
      filterOrder,
      shipping,
      selectedBatchDate,
    ),
    getComparator(order, orderBy),
  );

  const currentOrders = __orders.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const pageSelectedOrders = _.filter(currentOrders, (row) =>
    isSelected(row.order),
  );

  const selectedOrders = _.filter(__orders, (row) => isSelected(row.order));

  const isSelectedAll = currentOrders.length === pageSelectedOrders.length;

  const pages = parseInt((__orders.length + rowsPerPage - 1) / rowsPerPage);

  const currentRows = convertToRows(currentOrders);

  // true is to check for where it came from i.e : DS, Shopify, etc.
  // ordersWithOrderNumber is a function that adds the order number to each item in the order
  const { overallCounter, shopifyWebCounter, dropShipCounter } =
    calculateProducts(ordersWithOrderNumber(initialCustomOrders), true);

  const selectedInfo = React.useMemo(
    () => calculateProducts(ordersWithOrderNumber(selectedOrders), true),
    [selectedOrders],
  );

  const filteredData = React.useMemo(
    () => calculateProducts(currentOrders),
    [currentOrders],
  );

  const [toastMessage, setToastMessage] = useState('');

  const [showToast, setShowToast] = useState(false);

  // Assuming you have a state to track selected rows
  const [selectedShift, setSelectedRows] = useState([]);

  const handleClick = (event, orderNumbers) => {
    const orderNumbersArray = Array.isArray(orderNumbers)
      ? orderNumbers
      : [orderNumbers];

    let newSelectedOrders = [...selected];

    orderNumbersArray.forEach((orderNumber) => {
      const matchingOrders = currentOrders.filter(
        (row) => row.order === orderNumber,
      );

      const isAnyOrderSelected = newSelectedOrders.some((selectedOrder) =>
        matchingOrders.some((order) => order.order === selectedOrder),
      );

      if (isAnyOrderSelected) {
        if (orderNumbersArray.length === 1) {
          newSelectedOrders = newSelectedOrders.filter(
            (order) => order !== orderNumber,
          );
        }
      } else if (matchingOrders.length > 0) {
        newSelectedOrders.push(orderNumber);
      }
    });

    setSelected(newSelectedOrders);
  };

  // Initialize the hook
  const handleRowClick = useShiftSelected(
    currentRows,
    setSelectedRows,
    handleClick,
    true,
  );

  return (
    <Layout page={props.page} user={props.user}>
      <PageHeader
        initial={overallCounter}
        selected={selectedInfo.overallCounter}
        shopifyWebCounter={shopifyWebCounter}
        dropShipCounter={dropShipCounter}
        showFilterData={showFilterData}
        filteredData={filteredData.overallCounter}
      />

      {filterShippings.length > 0 && (
        <FilterProvider
          value={{
            types: filterTypes,
            sources: filterSources,
            shows: filterShows,
            orders: filterOrders,
            shippings: filterShippings,
            dateSelectedForBatch,
            filterType,
            setFilterType,
            filterSource,
            setFilterSource,
            filterShow,
            setFilterOrder,
            filterOrder,
            setFilterShow: handleChangeRowsPerPage,
            filterShipping,
            setFilterShipping,
            setSelectedBatchDate,
            selectedBatchDate,
          }}
        >
          <PageToolbar
            numSelected={selected.length}
            viewOnly={viewOnly}
            editOnly={editOnly}
            allSelected={isSelectedAll}
            onSelectAllClick={handleSelectAllClick}
            onPrint={handlePrint}
            onRevision={handleRevision}
          />
        </FilterProvider>
      )}

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              useCheckbox={true}
              cells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={__orders.length}
            />
            <TableBody>
              {currentRows.map((row, index) => {
                const isItemSelected = isSelected(row.order);
                const labelId = `enhanced-table-checkbox-${index}`;
                const keyId = `key-${row.order}-${index}`;

                reWriteStyle(row);

                if (
                  typeof row?.order === 'string' &&
                  String(row?.order).startsWith('DS')
                ) {
                  row.source = 'Fanatics';
                }

                // update what type of bundle it is
                if (
                  row?.items &&
                  Array.isArray(row?.items) &&
                  row.items.length !== 0
                ) {
                  getWhatTypeOfBundle(row);
                }

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}
                    classes={{
                      root: row.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
                      selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
                    }}
                    selected={isItemSelected}
                    className="no-select"
                  >
                    <TableCell
                      padding="checkbox"
                      className={classes.tableCheckbox}
                    >
                      {row.index == 0 && (
                        <Checkbox
                          onClick={(event) => handleRowClick(event, row)}
                          sx={{ paddingRight: '40px' }}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      )}
                    </TableCell>

                    <TableCell
                      padding="none"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied Date: ${row.date}`,
                          row.date,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={{
                          fontWeight: isDateExceedingFiveDays(row.date)
                            ? 'bold'
                            : 'normal',
                          color: isDateExceedingFiveDays(row.date)
                            ? '#FF0000'
                            : 'inherit',
                        }}
                      >
                        {row.index === 0 ? row.date : ''}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied Order Number: ${row.order}`,
                          row.order,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={rowStyle(row.date, row)}
                      >
                        {row.index === 0 ? row.order : ''}
                      </Typography>
                      {row.index === 0 && row.restored && (
                        <Box className={classes.restoreIcon}>
                          <FlipCameraAndroidIcon />
                        </Box>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied type: ${row.type}`,
                          row.type,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant="tableText"
                        sx={rowStyle(row.date, row, true)}
                      >
                        {row.type}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() =>
                        handleCopyToBoard(
                          `Copied SKU: ${row.sku}`,
                          row.sku,
                          setToastMessage,
                          setShowToast,
                        )
                      }
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        variant={
                          row.exist && row.available
                            ? 'tableText'
                            : 'tableErrorText'
                        }
                        sx={rowStyle(row.date, row, true)}
                      >
                        {row.sku}
                      </Typography>
                      {row.batchDate !== '' && (
                        <Typography
                          variant="tableText"
                          sx={{ marginLeft: '30px', color: '#1F7CF9' }}
                        >
                          Batch Date: {row.batchDate}
                        </Typography>
                      )}
                      <Typography
                        variant="tableErrorText"
                        sx={{ marginLeft: '10px' }}
                      >
                        {row.error}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="tableText"
                        sx={rowStyle(row.date, row)}
                      >
                        {row.shipping}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="tableText"
                        sx={rowStyle(row.date, row)}
                      >
                        {row.qty}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography
                        variant="tableText"
                        sx={rowStyle(row.date, row)}
                      >
                        {formatSource(row.source)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}

              {!isLoading && customOrders.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    <Typography variant="tableText">No Orders </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <div className={classes.pagination}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </div>

      {renderRevisionDialog()}
      {renderPrintDialog(
        selectedInfo.overallCounter.total,
        selectedInfo.overallCounter.products,
        selectedInfo.overallCounter.boxes,
      )}
      {printBatch && renderBatchResultDialog()}

      <ToastCopyCLip show={showToast} message={toastMessage} />
    </Layout>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(CustomOrders);
