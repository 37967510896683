import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

import { Toast } from '../Toast';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '3.125rem 0 3.125rem 1rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '.0625rem solid',
    borderColor: 'rgba(217, 223, 233, 0.4)',
  },
  toolBox: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    padding: '.5625rem 2rem',
    fontSize: '1rem',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '1rem',
  },
  formLabel: {
    color: '#000000',
    fontSize: '.875rem',
    fontWeight: '500',
    letterSpacing: '.0938rem',
  },

  search: {
    width: '470px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    // place at the end of the formBox
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const PageToolbar = (props) => {
  const {
    keyWords,
    setKeyWords,
    handleSearch,
    handleInputChange,
    searchInput,
  } = props;
  const classes = useToolbarStyles();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderList, setShowOrderList] = useState(false);
  useState(false);
  const [errorText, setErrorText] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});

  const {
    numSelected,
    allSelected,
    onSelectAllClick,
    onPrint,
    enable,
    editOnly,
    dsErrors,
  } = props;

  return (
    <div className="pageToolbar">
      <div className={classes.buttonBox}>
        <FormControl
          component="fieldset"
          sx={{
            marginRight: '2.5rem',
            height: '2.875rem',
            width: '10.3125rem',
          }}
        >
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  color="primary"
                  disabled={!enable}
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={allSelected}
                  onChange={onSelectAllClick}
                />
              }
              label={allSelected ? 'SELECT NONE' : 'SELECT ALL'}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

        {editOnly && numSelected > 0 && (
          <Button variant="blue" disabled={!enable} onClick={onPrint}>
            Print Selected
          </Button>
        )}
      </div>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>

        <InputBase
          placeholder="Search Orders"
          sx={{ width: '100%' }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          value={searchInput}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleInputChange}
          onKeyUp={handleSearch}
        />
      </div>
      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  enable: PropTypes.bool,
  editOnly: PropTypes.bool,
};

export default PageToolbar;
