import { getHistoryDocument } from '../../db/history';
import {
  FETCHED_HISTORY,
  FETCH_HISTORY_ERROR,
  START_FETCH_HISTORY,
  PROCESS_ROW_DATA,
  UPDATE_PAGE_NUMBER,
  DELETE_HISTORY_DOC,
  INJECT_ONE_ITEM_HISTORY,
  INJECT_ONE_ROW_DATA_HISTORY,
  UPDATE_STATE_FOR_FETCH_RAN_DOC,
  RESET_STATE_FOR_FETCH_RAN_DOC,
  REPLACE_HISTORY_DOC,
  REPLACE_ONE_ROW_DATA_HISTORY,
  UPDATE_SINGLE_ITEM_BY_ID,
  UPDATE_LOADING,
  SET_DOWNLOAD_STATUS,
  CHANGED_PAGE,
  UPDATE_DOCS_WHEN_CHANGED_SHOW,
  USE_SEARCH,
  FILTER_HISTORY,
  RESET_STATE_HISTORY,
} from './types';

export const startFetchHistory = () => ({
  type: START_FETCH_HISTORY,
});

export const injectHistory = (data) => ({
  type: FETCHED_HISTORY,
  payload: data,
});

export const fetchHistoryError = (error) => ({
  type: FETCH_HISTORY_ERROR,
  payload: error,
});

export const processRowData = (data) => async (dispatch, getState) => {
  // go through every item in the array and append the object as data:
  // {data: item}

  const processedData = data.map((item) => ({ data: item }));

  dispatch({
    type: PROCESS_ROW_DATA,
    payload: processedData,
  });
};

export const injectOneItemHistory = (data) => ({
  type: INJECT_ONE_ITEM_HISTORY,
  payload: data,
});

export const injectOneRowDataHistory =
  (data, db, storage) => async (dispatch, getState) => {
    try {
      const rowData = await new Promise((resolve, reject) => {
        try {
          getHistoryDocument(
            db,
            data.batchId,
            (rowData) => {
              // Convert Timestamp to a serializable form
              resolve({ data: rowData });
            },
            storage,
          );
        } catch (error) {
          console.error('Error getting history document:', error);
          reject(error);
        }
      });

      dispatch({
        type: INJECT_ONE_ROW_DATA_HISTORY,
        payload: rowData,
      });
    } catch (error) {
      console.error('Error injecting one row data:', error);
      dispatch({
        type: FETCH_HISTORY_ERROR,
        payload: error.toString(),
      });
    }
  };

export const updatePageNumber = (data) => ({
  type: UPDATE_PAGE_NUMBER,
  payload: data,
});

export const deleteHistoryDoc = (batchId) => ({
  type: DELETE_HISTORY_DOC,
  payload: batchId,
});

export const updateStateForFetchRanDoc = (data) => ({
  type: UPDATE_STATE_FOR_FETCH_RAN_DOC,
  payload: data,
});

export const resetStateForFetchRanDoc = () => ({
  type: RESET_STATE_FOR_FETCH_RAN_DOC,
  payload: false,
});

export const replaceHistoryDoc =
  (data, db, storage, downloaded = true) =>
  async (dispatch) => {
    // Dispatch an action to replace the document in the history
    // If `downloaded` is true, update the `downloads` property accordingly
    const updatedData = downloaded
      ? { ...data, downloads: data.downloads + 1 }
      : data;

    dispatch({
      type: REPLACE_HISTORY_DOC,
      payload: updatedData,
    });

    try {
      const rowData = await new Promise((resolve, reject) => {
        getHistoryDocument(
          db,
          data.batchId,
          (documentData) => {
            // If downloaded is true, ensure the specific row data reflects this change
            const updatedRowData = downloaded
              ? { ...documentData, downloads: documentData.downloads + 1 }
              : documentData;

            // Convert Timestamp to a serializable form if needed and resolve the promise
            resolve({ data: updatedRowData });
          },
          storage,
        );
      });

      // Dispatch an action to replace the row data in history with the updated data
      dispatch({
        type: REPLACE_ONE_ROW_DATA_HISTORY,
        payload: rowData,
      });
    } catch (error) {
      console.error('Error getting history document:', error);
      // Handle the error appropriately
    }
  };

export const updateKeyValueInHistory = (data) => ({
  type: UPDATE_SINGLE_ITEM_BY_ID,
  payload: data,
});

export const updateLoading = (data) => ({
  type: UPDATE_LOADING,
  payload: data,
});

export const setDownloadStatus = (batchId, downloaded) => ({
  type: SET_DOWNLOAD_STATUS,
  payload: { batchId, downloaded },
});

export const setNewPageData = () => ({
  type: CHANGED_PAGE,
});

export const updateDocsWhenChangedShow = (updatedRowsPerPage) => ({
  type: UPDATE_DOCS_WHEN_CHANGED_SHOW,
  payload: { updatedRowsPerPage },
});

export const useSearch = (searchKeys) => {
  console.log('Using search');
  return {
    type: USE_SEARCH,
    payload: { searchKeys },
  };
};

export const filterHistoryByDropdowns = (filterData) => ({
  type: FILTER_HISTORY,
  payload: { filterData },
});

export const resetHistory = () => {
  console.log('Resetting history state');
  return {
    type: RESET_STATE_HISTORY,
    payload: false,
  };
};
