// @ts-check

export const getSizeAndColor = (
  /** @type {{ sizeMB: { value: any; unit: any; }; } | null} */ dimension,
) => {
  if (!dimension || !dimension.sizeMB) {
    return { size: '', unit: '', color: 'rgb(20, 183, 121)' }; // Default color when dimension is not defined
  }

  const size = dimension.sizeMB.value;
  const unit = dimension.sizeMB.unit;
  let color = 'rgb(20, 183, 121)'; // Default color

  if (size >= 15.0 && size < 25.0 && unit === 'MB') {
    color = 'yellow';
  } else if (size >= 25.0 && unit === 'MB') {
    color = 'red';
  }
  return { size, unit, color };
};
