import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import Button from '@mui/material/Button';

import { BootstrapInput } from '../Page/input';
import { withAuthorization } from '../Session';
import { StoragePath } from '../../constants/defines';
import { StorageImage } from '../Products/components';
import { uploadToStorage, existStorageFile } from '../../module/storage';

const useStyles = makeStyles((theme) => ({
  layer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    position: 'relative',
    padding: '5px 10px',
    marginTop: '15px',
    height: '40px',
    borderRadius: '5px',
    boxSizing: 'border-box',
    '&:hover svg': {
      opacity: 1,
    },
  },
  view: {
    width: '20px',
    opacity: 0.3,
  },
  title: {
    padding: '0 10px',
    margin: 0,
    fontSize: '14px',
    fontWeight: '500',
  },
  position: {
    position: 'absolute',
    right: '10px',
    width: '24px',
    transform: 'translateY(-50%)',
    top: '50%',
    cursor: 'pointer',
  },
  edit: {
    position: 'absolute',
    right: '40px',
    width: '16px',
    transform: 'translateY(-50%)',
    top: '50%',
    cursor: 'pointer',
  },
  label: {
    fontSize: '13px',
  },
  inputField: {
    position: 'relative',
    paddingRight: '30px',
  },
  inputFieldControl: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '100%',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0',
  },
  dropBox: {
    textAlign: 'center',
    padding: '20px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginTop: '10px',
    marginBottom: '10px',
    '& svg': {
      opacity: '0.56',
    },
  },
  imagePreview: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    marginBottom: '10px',
    border: 'solid 1px rgba(217, 223, 233, 0.4)',
    '& img': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  lineBox: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
  },
}));

const dropzoneRef1 = createRef();

function Module(props) {
  const { onUpdate } = props;
  const [layer, setLayer] = useState({});
  const [locked, setLocked] = useState(true);
  const classes = useStyles();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    const newLayer = { ...props.layer };
    newLayer.pos = { ...props.layer.pos };
    setLayer(newLayer);
  }, [props.layer]);

  const toggleLayer = () => {
    const _layer = { ...layer };
    _layer.visible = !_layer.visible;

    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = parseFloat(value);

    // Handle face slots if num changed
    const _layer = { ...layer };
    _layer[name] = value;
    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const handleChangePosRatio = (event) => {
    let { name, value } = event.target;
    value = parseFloat(value);
    const _layer = { ...layer };
    const { width, height, _width, _height } = _layer.pos;
    let newH, newW;

    if (name === 'width' || name === 'height') {
      if (name === 'width') {
        newW = value;
        newH = (_height / _width) * value;
      } else {
        newH = value;
        newW = (_width / _height) * value;
      }
      _layer.pos.width = parseInt(newW);
      _layer.pos.height = parseInt(newH);
    } else {
      _layer.pos[name] = value;
    }

    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const handleChangePos = (event) => {
    let { name, value } = event.target;
    value = parseFloat(value);

    // Handle face slots if num changed
    const _layer = { ...layer };
    _layer.pos[name] = value;
    if (name === 'width') {
      _layer.pos['_width'] = value;
    } else if (name === 'height') {
      _layer.pos['_height'] = value;
    }

    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const handleLocked = () => {
    setLocked(!locked);
  };

  const handleExpland = () => {
    const _layer = { ...layer };
    _layer.expanded = !_layer.expanded;
    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const renderInput = (name, type, label, value, control = false) => {
    const onChange =
      name == 'fontSize'
        ? handleChange
        : locked
          ? handleChangePosRatio
          : handleChangePos;
    return (
      <Box className={classes.inputField}>
        <label className={classes.label} htmlFor="admin-label-helper">
          {label}
        </label>
        <BootstrapInput
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          inputProps={{ min: 0, step: 1 }}
        />

        {control && (
          <Box
            className={classes.inputFieldControl}
            onClick={() => handleLocked()}
          >
            {locked ? <LockIcon /> : <LockOpenIcon />}
          </Box>
        )}
      </Box>
    );
  };

  const handleUploadImage = () => {
    console.log('handleUploadImage');
    dropzoneRef1.current.open();
  };

  const handleDeleteImage = () => {
    console.log('handleDeleteImage');

    // Handle face slots if num changed
    const _layer = { ...layer };
    _layer.image = '';

    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const onUploadComplete = (path, width, height) => {
    const _layer = { ...layer };
    _layer.image = path;
    _layer.pos.width = _layer.pos._width = width;
    _layer.pos.height = _layer.pos._height = height;

    if (onUpdate) {
      onUpdate(_layer);
    }
  };

  const handleDrop = (file) => {
    const fr = new FileReader();
    fr.onload = function () {
      const img = new Image();
      img.onload = function () {
        existStorageFile(storage, file.name).then((exist) => {
          console.log(`Image Art '${file.name}' exist:${exist}`);
          let filename = file.name;
          if (exist) {
            const _filename = filename.split('.').slice(0, -1).join('.');
            const ext = filename.substr(filename.lastIndexOf('.') + 1);
            filename = `${_filename}-${Date.now()}.${ext}`;
          }

          uploadToStorage(
            storage,
            `${StoragePath.Barcode}/${filename}`,
            file,
          ).then(() => {
            console.log('Uploaded');
            onUploadComplete(filename, img.width, img.height);
          });
        });
      };
      img.src = fr.result;
    };
    fr.readAsDataURL(file);
  };

  const renderDropzone = (label, path) => (
    <Box>
      <label className={classes.label} htmlFor="admin-label-helper">
        {label}
      </label>

      {path !== '' ? (
        <Box>
          <StorageImage
            classes={classes}
            name="logo"
            path={`${StoragePath.Barcode}/${path}`}
            storage={storage}
          />
          <Button
            variant="contained"
            onClick={handleDeleteImage}
            className={classes.dropButton}
          >
            Delete
          </Button>
        </Box>
      ) : (
        <Box>
          <Dropzone
            ref={dropzoneRef1}
            onDrop={(files) => handleDrop(files[0])}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} className={classes.dropBox}>
                <input {...getInputProps()} accept="image/png;image/jpg" />
                <CloudUploadIcon />
                <p className={classes.dropLabel}>Drag & Drop Artwork File</p>
              </Box>
            )}
          </Dropzone>
          <Button
            variant="contained"
            onClick={handleUploadImage}
            className={classes.dropButton}
          >
            Upload
          </Button>
        </Box>
      )}
    </Box>
  );

  const renderTextModule = () => (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {renderInput('x', 'number', 'X Position', layer.pos.x)}
      </Grid>

      <Grid item xs={4}>
        {renderInput('y', 'number', 'Y Position', layer.pos.y)}
      </Grid>

      <Grid item xs={4}>
        {renderInput('fontSize', 'number', 'Font Size', layer.fontSize)}
      </Grid>

      <Grid item xs={4}>
        {renderInput('width', 'number', 'Width', layer.pos.width, true)}
      </Grid>

      <Grid item xs={4}>
        {renderInput('height', 'number', 'Height', layer.pos.height)}
      </Grid>
    </Grid>
  );

  const renderImageModule = () => (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {renderInput('x', 'number', 'X Position', layer.pos.x)}
        {renderInput('width', 'number', 'Width', layer.pos.width, true)}
      </Grid>

      <Grid item xs={4}>
        {renderInput('y', 'number', 'Y Position', layer.pos.y)}
        {renderInput('height', 'number', 'Height', layer.pos.height)}
      </Grid>

      <Grid item xs={4}>
        {renderDropzone('Vendor Logo', layer.image)}
      </Grid>
    </Grid>
  );

  const width = layer.pos ? layer.pos.width : 0;
  const height = layer.pos ? layer.pos.height : 0;
  const visible = layer.visible;
  return (
    <Box>
      <div className={classes.layer}>
        {visible ? (
          <VisibilityIcon className={classes.view} onClick={toggleLayer} />
        ) : (
          <VisibilityOffIcon className={classes.view} onClick={toggleLayer} />
        )}
        <p className={classes.title}>{layer.name}</p>

        {layer.expanded ? (
          <ArrowUpwardIcon
            className={classes.position}
            onClick={() => handleExpland()}
          />
        ) : (
          <ArrowDownwardIcon
            className={classes.position}
            onClick={() => handleExpland()}
          />
        )}
      </div>

      {layer.expanded && (
        <Box sx={{ padding: '15px 0 40px' }}>
          {layer.type === 'text' && renderTextModule()}
          {layer.type === 'image' && renderImageModule()}
        </Box>
      )}
    </Box>
  );
}

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(Module);
