import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  Box,
  Tooltip,
} from '@mui/material';

import useToolTip from '../../hooks/useToolTip';

const VariantsModal = ({
  openVariantsModal,
  handleCloseModal,
  renderVariants,
  nameOfSize,
}) => {
  // State to manage whether the tooltip is shown
  const [showTooltip, setShowTooltip] = useState(false);

  useToolTip({
    setFunction: setShowTooltip,
  });

  console.log('nameOfSize', nameOfSize);

  return (
    <Dialog
      open={openVariantsModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      PaperProps={{
        style: {
          width: '50vw',
          height: '50vh',
          borderRadius: '20px',
          overflow: 'hidden',
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0,0,0,0.5)',
        },
      }}
    >
      <Box
        sx={{
          paddingTop: '2%',
          display: 'flex',
          alignItems: 'center',
          gap: '5%',
        }}
      >
        <DialogTitle id="modal-title">Variants</DialogTitle>
      </Box>

      {(nameOfSize === 'L/XL' || nameOfSize === 'L') && (
        <Tooltip
          title={
            <span style={{ fontSize: '12px' }}>
              To pass down to all size bundles, add '{nameOfSize.toUpperCase()}'
              at the end of the variant.{' '}
            </span>
          } // Increase font size directly in title
          open={showTooltip}
          enterDelay={3000}
          leaveDelay={100}
          placement="top"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                padding: '8px', // Increase padding
              },
            },
          }}
          arrow={true}
        ></Tooltip>
      )}

      <DialogContent>
        <Box sx={styles.renderVariantsBox}>{renderVariants()}</Box>
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  parentChildButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '33%',
  },
  button: {
    height: '50px',
    padding: '0 20px',
    borderRadius: '50px',
    color: 'white',
  },
  renderVariantsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    // backgroundColor: "pink"
  },
};

export default VariantsModal;
