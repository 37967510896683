import { Box, LinearProgress, Typography, Backdrop } from '@mui/material';
import React from 'react';

import PaulELoading from '../../images/noResults.png';

export default function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Backdrop
        open={true} // Set `open` to true for demonstration
        style={{
          zIndex: 9999,
          color: '#fff',
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adds a slight backdrop overlay
          flexDirection: 'column',
          width: '100%', // Ensures the loading bar spans the full width
        }}
      >
        <img
          src={PaulELoading}
          alt="Loading"
          style={{ width: '200px', height: '200px', marginBottom: '20px' }}
        />
        <Box sx={{ width: '50%' }}>
          <LinearProgress
            sx={{
              backgroundColor: 'white',
            }}
          />
        </Box>
      </Backdrop>
    </Box>
  );
}
