import { makeStyles } from '@mui/styles';

const webStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    '& td': {
      position: 'relative',
    },
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important',
  },
  fieldLabel: {
    fontSize: '16px',
    fontWeight: '500!important',
    color: '#000000',
  },
  fieldError: {
    fontSize: '14px',
    fontWeight: '400!important',
    color: '#f44336',
    marginTop: '5px',
  },
  restoreIcon: {
    position: 'absolute',
    right: '-10px',
    top: '22px',
    '& svg': {
      opacity: '0.76',
    },
  },
}));

export default webStyles;
