import { doc, onSnapshot } from 'firebase/firestore';

import { collections } from '../constants/defines';

// / This function now sets up a real-time listener
export const listenToDownloadStatus = (db, batchId, setPrintStatus) => {
  if (!batchId) {
    return;
  }

  const docRef = doc(db, collections.HISTORY, batchId);

  const unsubscribe = onSnapshot(
    docRef,
    (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();

        setPrintStatus(data.downloads !== 0 ? true : false);
      } else {
        setPrintStatus(false); // Or any default state
      }
    },
    (error) => {
      console.log('Error in listenToShipstationBatchDownloadStatus:', error);
    },
  );

  return unsubscribe;
};
