import * as React from 'react';

const TrackingSVG = (props) => {
  const { hide } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="svg2"
      width="40"
      height="40"
      version="1.1"
      viewBox="0 0 816 425.333"
      onClick={() => props.onClick()}
      style={{ cursor: 'pointer', marginLeft: '20%' }}
    >
      {!hide && (
        <>
          <defs id="defs6">
            <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
              <path id="path14" d="M0 319h612V0H0Z"></path>
            </clipPath>
          </defs>
          <g id="g8" transform="matrix(1.33333 0 0 -1.33333 0 425.333)">
            <g id="g10">
              <g id="g12" clipPath="url(#clipPath16)">
                <g id="g18" transform="translate(563.947 167.842)">
                  <path
                    id="path20"
                    fill="#000"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    d="M0 0c-.338 1.709-2.072 3.67-3.194 4.994-12.118 14.304-26.232 27.082-38.11 41.638-2.858 2.643-9.285 5.641-13.162 6.062-12.295 1.337-29.911.094-42.755-.057a9.9 9.9 0 0 0-3.562.588c-1.034-.186-1.572-5.8-1.745-7.304-1.428-12.475-2.796-26.667-3.557-39.163-.137-2.245.07-4.519-.007-6.758zm-115.348 67.643h64.438c3.987 0 15.197-5.79 18.529-8.528C-16.655 40.426 4.02 22.619 18.878 3.569c13.653-17.505 7.669-33.631 6.047-53.771-1.19-14.781-1.32-31.866-3.571-46.271-2.091-13.389-10.608-17.259-23.494-16.031C.001-84.119-20.854-58.371-49.495-56.259c-33.111 2.442-63.53-23.214-66.565-56.241h-180.855v11.748c0 11.238-11.143 27.592-20.011 34.103-37.091 27.232-90.196-2.006-93.198-45.856-12.523.86-26.488-1.128-38.838-.024-6.822.61-13.041 6.094-13.169 13.236-.136 7.621 2.213 18.331 2.87 26.314 1.101 13.391 2.019 26.821 2.852 40.226h53.046c8.269 0 13.596 16.436.526 18.326l-135.849.149c-9.358 2.639-7.065 16.028 2.157 17.142l191.593.655c8.855 3.057 9.522 16.313-.815 17.78l-160.318.6c-7.511 4.312-4.766 14.659 3.067 16.505l122.949.232c9.635.853 13.193 14.535 3.259 17.839l-145.437.528c-9.156 2.685-7.454 15.295 1.367 17.064l165.128.063c12.448.713 14.762 17.559 1.532 18.463l-131.709.016c-6.219.96-8.31 8.002-5.31 13.053 1.866 3.139 6.05 4.451 9.511 4.728 12.234.979 25.752-.778 38.122-.03-.425 9.732 6.593 16.865 16.024 17.798l303.238-.072c9.427-1.488 13.867-8.747 12.531-18.072z"
                  ></path>
                </g>
                <g id="g22" transform="translate(208.472 77.23)">
                  <path
                    id="path24"
                    fill="#000"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    d="M0 0c-26.865-3.631-28.276-44.735-.183-44.735C31.038-44.735 32.926 4.45 0 0m2.876 22.754c40.129 2.458 57.375-42.894 32.47-72.339-19.374-22.907-58.85-25.094-73.991 3.476-16.113 30.402 8.72 66.855 41.521 68.863"
                  ></path>
                </g>
                <g id="g26" transform="translate(519.551 39.215)">
                  <path
                    id="path28"
                    fill="#000"
                    fillOpacity="1"
                    fillRule="nonzero"
                    stroke="none"
                    d="M0 0c21.154 20.159-4.51 51.143-27.858 33.198C-53.872 13.204-23.363-22.264 0 0m-49.177-16.212C-89.278 26.507-16.1 91.235 21.264 46.041 55.939 4.098-10.769-57.127-49.177-16.212"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </>
      )}
    </svg>
  );
};

export default TrackingSVG;
