import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { EnhancedTableHead } from '../Page/table';
import { getUploadsHistory } from '../../db/uploads';
import { withAuthorization } from '../Session';
import Row from './row';

const headCells = [
  {
    id: 'name',
    numeric: false,
    align: 'left',
    disablePadding: true,
    label: 'DATE',
    width: '150px',
  },
  {
    id: 'error',
    numeric: false,
    align: 'center',
    disablePadding: true,
    label: '',
    width: '30px',
  },
  {
    id: 'batch',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'BATCH NAME',
    width: '400px',
  },
  {
    id: 'type',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'BATCH TYPE',
    width: '180px',
  },
  {
    id: 'files',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: 'FILES',
    width: '120px',
  },
  {
    id: 'mockup',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: 'MOCKUPS',
    width: '120px',
  },
  {
    id: 'action',
    numeric: false,
    align: 'center',
    disablePadding: false,
    label: '',
    width: '80px',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filterBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  table: {
    minWidth: 750,
  },
  itemTable: {
    width: '1000px',
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)',
  },
  tableHeadCell: {
    lineHeight: '27.5px',
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500!important',
    color: '#000000',
    letterSpacing: '1.5px',
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000',
  },
  itemTableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000',
    borderBottom: 'none',
  },
  itemTableCell2: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#E0491B!important',
    borderBottom: 'none',
  },
  itemTableCell3: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#14B779!important',
    borderBottom: 'none',
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    },
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  progressPrimaryError: {
    backgroundColor: '#14B779',
  },
  progressPrimaryNormal: {
    backgroundColor: 'rgba(31, 124, 242, 0.16)',
  },
  progressBarError: {
    backgroundColor: '#14B779',
  },
  progressBarNormal: {
    backgroundColor: '#1F7CF2',
  },
  jobActionButton: {
    width: '100%',
    color: '#FFFFFF',
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '6px',
  },
  jobActionButton1: {
    color: '#FFFFFF',
    borderRadius: '6px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
  },
  jobActionButton2: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginLeft: '15px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
  },
  jobActionButton3: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginRight: '15px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
  },
  buttonProducts: {
    background: '#1F7CF2',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    borderRadius: '6px',
  },
  buttonQueue: {
    background: '#e0491b',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    borderRadius: '6px',
    marginTop: '20px',
    '&:hover': {
      background: '#e0491b',
    },
  },
  itemNavButton: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.5px',
    color: '#000000',
    borderRadius: '0',
    width: '100%',
  },
}));

function History(props) {
  const classes = useStyles();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const db = props.firebase.getdb();

  useEffect(() => {
    getUploadsHistory(db, fetchHistoryItems);
  }, []);

  const fetchHistoryItems = (orders) => {
    console.log('Fetch Custom Jobs');
    setHistory([...orders]);
    setLoading(false);
  };

  const _jobs = [...history];
  const jobs = _jobs.slice(0, 100);

  return (
    <Box sx={{ marginTop: '90px' }}>
      <div className="pageHeader">
        <div className="pageHeading">
          <Typography variant="h1">Recently Uploaded</Typography>
        </div>
      </div>

      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            useCheckbox={true}
            cells={headCells}
          />
          <TableBody>
            {jobs.map((row, index) => {
              const odd = index % 2 === 0;
              const labelId = `history-table-checkbox-${index}`;
              const keyId = `key-${row.id}-${index}`;
              row.odd = odd;
              return (
                <Row
                  key={keyId}
                  label={labelId}
                  row={row}
                  classes={classes}
                  db={db}
                  firebase={props.firebase.getFirebase()}
                  sizes={props.sizes}
                  viewOnly={props.viewOnly}
                  editOnly={props.editOnly}
                />
              );
            })}
            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={9}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(History);
