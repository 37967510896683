import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { API } from '../../constants/defines';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const DevModal = ({ isOpen, setOpenDevModal, setShowToast }) => {
  const [orderNumberInput, setOrderNumberInput] = useState('');
  const [orderNumbers, setOrderNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => {
    setOpenDevModal(false);
    setOrderNumberInput('');
    setOrderNumbers([]);
    setError(null);
  };

  const handleAddOrderNumber = () => {
    if (orderNumberInput.trim()) {
      setOrderNumbers([...orderNumbers, orderNumberInput.trim()]);
      setOrderNumberInput('');
    }
  };

  const handleRemoveOrderNumber = (index) => {
    setOrderNumbers(orderNumbers.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Include the current input in the submission if it is not empty
    const finalOrderNumbers = [...orderNumbers];
    if (orderNumberInput.trim()) {
      finalOrderNumbers.push(orderNumberInput.trim());
      setOrderNumberInput('');
    }

    console.log('Processing orders:', finalOrderNumbers);

    try {
      const response = await fetch(API.retrieveAListOfOrdersAndCreate, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderNumbers: finalOrderNumbers,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to process orders');
      }

      const data = await response.json();
      console.log('Orders processed:', data);
      setShowToast(true);
      handleClose();
    } catch (err) {
      console.error('Error processing orders:', err);
      setError(`Failed to process orders. ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Process Shopify Orders
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Add orders that are missing in artomator.
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <TextField
            fullWidth
            label="Order Number"
            value={orderNumberInput}
            onChange={(e) => setOrderNumberInput(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <IconButton
            color="primary"
            onClick={handleAddOrderNumber}
            sx={{ ml: 1 }}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <List>
          {orderNumbers.map((orderNumber, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemoveOrderNumber(index)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={orderNumber} />
            </ListItem>
          ))}
        </List>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? 'Processing...' : 'Submit'}
        </Button>
      </Box>
    </Modal>
  );
};

export default DevModal;
