import { BatchProvider } from './batchContext';
import History from './History';
import Running from './Running';

export const BatchesRunningAndHIstory = (props) => (
  <BatchProvider>
    <Running {...props} />
    <History {...props} />
  </BatchProvider>
);
