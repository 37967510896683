import { Box, Typography } from '@mui/material';

export const OrderSummary = ({
  title,
  counter,
  palette,
  hideOrdersString,
  hideBundleCount,
}) => (
  <Box display="flex" flexDirection="row" alignItems="center" flexWrap="wrap">
    <Typography variant="subtitle1" style={{ width: '6.5vw' }}>
      {title.toUpperCase()}
    </Typography>

    <Box
      flexGrow={1}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      {/* ORDERS */}
      <Typography
        variant="subtitle1"
        component="span"
        style={{
          marginRight: '8px',
          fontWeight: 'bold',
          minWidth: '30px',
          textAlign: 'right',
        }}
      >
        {hideOrdersString ? counter.products : counter.total}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        style={{ marginRight: '8px' }}
      >
        {hideOrdersString ? 'Total' : 'ORDERS'}
      </Typography>

      {hideOrdersString && !hideBundleCount && (
        <>
          {/* BOXES */}
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              marginRight: '8px',
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.boxes}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ marginRight: '8px' }}
          >
            Bundles
          </Typography>
        </>
      )}

      {/* SOCKS */}
      <Typography
        variant="subtitle1"
        component="span"
        style={{
          marginRight: '8px',
          fontWeight: 'bold',
          minWidth: '30px',
          textAlign: 'right',
        }}
      >
        {counter.socks}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        style={{ marginRight: '8px' }}
      >
        SOCKS
      </Typography>

      {/* UNDERWEAR */}
      <Typography
        variant="subtitle1"
        component="span"
        style={{
          marginRight: '8px',
          fontWeight: 'bold',
          minWidth: '30px',
          textAlign: 'right',
        }}
      >
        {counter.underwear}
      </Typography>
      <Typography
        variant="subtitle1"
        component="span"
        style={{ marginRight: '8px' }}
      >
        UNDERWEAR
      </Typography>

      {/* FINISHED */}
      {counter.finished > 0 ? (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              marginRight: '8px',
              color: palette.productPurple,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.finished}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ color: palette.productPurple, marginRight: '8px' }}
          >
            FINISHED
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              marginRight: '8px',
              color: palette.productPurple,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.finished}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              color: palette.productPurple,
              marginRight: '8px',
            }}
          >
            FINISHED
          </Typography>
        </>
      )}

      {/* PRODUCT NOT FOUND */}
      {counter.notFound > 0 ? (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              marginRight: '8px',
              color: palette.errors.tableRowError,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.notFound}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ color: palette.errors.tableRowError, marginRight: '8px' }}
          >
            PRODUCT NOT FOUND
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              marginRight: '8px',
              color: palette.errors.tableRowError,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.notFound}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              color: palette.errors.tableRowError,
              marginRight: '8px',
            }}
          >
            PRODUCT NOT FOUND
          </Typography>
        </>
      )}

      {/* MISSING ART */}
      {counter.missingArt > 0 ? (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              marginRight: '8px',
              color: palette.errors.tableRowError,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.missingArt}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{ color: palette.errors.tableRowError, marginRight: '8px' }}
          >
            MISSING ART
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              marginRight: '8px',
              color: palette.errors.tableRowError,
              fontWeight: 'bold',
              minWidth: '30px',
              textAlign: 'right',
            }}
          >
            {counter.missingArt}
          </Typography>
          <Typography
            variant="subtitle1"
            component="span"
            style={{
              visibility: 'hidden',
              color: palette.errors.tableRowError,
              marginRight: '8px',
            }}
          >
            MISSING ART
          </Typography>
        </>
      )}
    </Box>
  </Box>
);
