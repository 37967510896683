import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 22,
    width: 22,
    '&::before': {
      border: '1px solid #dadde9',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
    },
  },
}));

export { HtmlTooltip };
