import { getDocs, query, where, collection } from 'firebase/firestore';

import { collections } from '../constants/defines';

export const lookForAnyOrderByTrackDb = async (track, db) => {
  try {
    // Array of collections to search
    const collectionsArray = [
      collections.INLINE_PRODUCTS_QUEUE,
      collections.CUSTOM_PRODUCTS_QUEUE,
      collections.DS_INLINE_QUEUE,
      collections.DS_CUSTOM_QUEUE,
    ];

    // Create an array of Promises for concurrent execution
    const queryPromises = collectionsArray.map(async (collectionName) => {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where('trackingNumber', '==', track));
      const querySnapshot = await getDocs(q);

      // Process and return results for this collection
      return querySnapshot.docs.map((doc) => ({
        collection: collectionName,
        id: doc.id,
        ...doc.data(),
      }));
    });

    // Resolve all promises concurrently
    const queryResults = await Promise.all(queryPromises);

    // Flatten the results
    const results = queryResults.flat();

    return results.length > 0 ? results : null;
  } catch (error) {
    console.error('Error searching Firestore collections:', error);
    throw error;
  }
};
