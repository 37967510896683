import _ from 'lodash';

import { TYPE_OF_ANALYTICS } from './types';

export const processWhatToSet = (data, typeOfAnalytic, selections) => {
  let stateObject = {};

  switch (typeOfAnalytic) {
    case TYPE_OF_ANALYTICS.UNITS:
      const dataToUse = prepWhatDataSetToUseForUnits(data, selections);

      stateObject = {
        units: dataToUse,
        originalUnits: data,
      };
      return stateObject;

    case TYPE_OF_ANALYTICS.TIMED_ANALYTICS:
      return {
        timedAnalytics: data,
      };

    default:
      return data;
  }
};

export const prepWhatDataSetToUseForUnits = (data, selections) => {
  const defaultUnits = {
    title: '',
    typeOfProduct: selections.selectedTypeOfProduct,
    data: [],
    value: '',
    productSelected: selections.selectedProduct,
  };

  let unitsProduced = {
    ...defaultUnits,
    title: 'Units Produced',
    value: 'produced',
  };
  let unitsToBeProduced = {
    ...defaultUnits,
    title: 'Units To Be Produced',
    value: 'toBeProduced',
  };

  // Separating data based on 'value'
  data.forEach((unit) => {
    if (unit.value === 'produced') {
      unitsProduced = { ...unitsProduced, ...unit };
    } else {
      unitsToBeProduced = { ...unitsToBeProduced, ...unit };
    }
  });

  return [unitsProduced, unitsToBeProduced];

  // Access the relevant 'data' arrays within the objects
  const unitsProducedData = unitsProduced.data[0] || [];

  const unitsToBeProducedData = unitsToBeProduced.data[0] || [];

  // Find the relevant data for 'units to be produced'
  const productToBeProduced = unitsToBeProducedData?.products?.find(
    (product) => product.type === selections.selectedProduct,
  );

  const productToBeProducedFiltered =
    productToBeProduced?.types?.[selections.selectedTypeOfProduct] || [];

  unitsToBeProduced = {
    ...unitsToBeProduced,
    data: productToBeProducedFiltered,
  };

  // Find the relevant data for 'units produced'
  const productProduced = unitsProducedData?.products?.find(
    (product) => product.type === selections.selectedProduct,
  );

  const productProducedFiltered =
    productProduced?.types?.[selections.selectedTypeOfProduct] || [];

  unitsProduced = {
    ...unitsProduced,
    data: productProducedFiltered,
  };

  return [unitsProduced, unitsToBeProduced];
};

export const dataSetToAdjust = (
  data,
  selections,
  nameOfDataSet,
  isSwitchedData = false,
) => {
  const defaultDataSet = {
    title:
      nameOfDataSet === 'produced' ? 'Units Produced' : 'Units To Be Produced',
    typeOfProduct: selections.selectedTypeOfProduct,
    data: [],
    value: nameOfDataSet,
  };

  let dataSet = {};

  let dataToUse = { ...defaultDataSet };

  // Select the correct dataset based on the value
  data.forEach((unit) => {
    if (unit.value === nameOfDataSet) {
      dataSet = unit;
    }
  });

  const dataSetData = dataSet.data[0] || [];

  const product = dataSetData.products?.find(
    (product) => product.type === selections.selectedProduct,
  );

  if (!product) {
    dataToUse.noDataFoundForProduct = true;
    return dataToUse;
  }

  // Find the specific type of product
  const productFiltered =
    product.types?.[selections.selectedTypeOfProduct] || [];

  dataToUse = {
    ...dataToUse,
    data: productFiltered,
  };

  return dataToUse;
};

export const adjustForBothShipments = (data) => {
  const cloned = _.cloneDeep(data);
  // const toBeShippedData = cloned.find(unit => unit.value === "toBeShipped");
  // const shippedData = cloned.find(unit => unit.value === "shipped");
  return cloned;
};
