import * as React from 'react';

const UnderwearSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width || 45} // Use props.width or default to 45
    height={props.height || 45} // Use props.height or default to 45
    viewBox="0 0 150 150" // Keep this the same for proper scaling
    {...props}
  >
    <path
      d="M111.869 27.677H29.863l-16.346 86.378h53.12l4.232-22.342 4.226 22.342h53.12l-16.346-86.378zm-90.05 79.495 12.245-64.694h20.684v23.748c0 7.7 3 14.943 8.447 20.395l1.371 1.371-3.63 19.18H21.819zM65.991 82.68c-4.203-4.491-6.564-10.277-6.564-16.454V42.478H82.35v23.748c0 6.172-2.362 11.958-6.565 16.454h-9.794zm11.181 5.349 1.407-1.407c5.447-5.458 8.447-12.701 8.447-20.395V42.478h20.641l12.245 64.694H80.795l-3.623-19.143z"
      fill={props.color ? props.color : 'white'}
    />
  </svg>
);

export default UnderwearSvg;
